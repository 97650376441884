import { Button, Grid, TextField } from '@material-ui/core';
import { load as exifLoad, ImageIFD } from 'piexifjs';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Country,
  countryNames,
  IdTemplateType,
  idTemplateTypes
} from './constants';
import { ReusableSelect as Select } from 'components/reusable/select';
import { Option, SelectEvent } from 'components/reusable/select/select';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { adminCreateTemplate } from 'store/sagas/sagas';

const resizeImage = async ({
  img,
  ratio,
  width,
  height,
  type
}: {
  file: File;
  img: CanvasImageSource;
  width: number;
  height: number;
  type: string;
  ratio: number;
}): Promise<{ blob: Blob; imgSrc: string }> => {
  const elem = document.createElement('canvas');
  width *= ratio;
  height *= ratio;

  elem.width = width;
  elem.height = height;
  const ctx = elem.getContext('2d');

  if (!ctx) {
    throw new Error("Couldn't find context for canvas. Can't draw image");
  }

  ctx.drawImage(img, 0, 0, width, height);

  const blob = await new Promise<Blob>((resolve, reject) => {
    ctx.canvas.toBlob(
      (b) => {
        if (!b) {
          reject(new Error('Failed to convert canvas to blob'));
          return;
        }

        resolve(b);
      },
      type,
      1
    );
  });

  return { blob, imgSrc: await blobToBase64(blob) };
};

const resizeImageCanvas = ({
  img,
  max = 250
}: {
  img: HTMLImageElement;
  max?: number;
}) => {
  const ratio = img.width > img.height ? max / img.width : max / img.height;
  const width = ratio ? ratio * img.width : img.width;
  const height = ratio ? ratio * img.height : img.height;
  return { width, height };
};

const toExif = (imgSrc: string) => {
  try {
    return exifLoad(imgSrc);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const blobToBase64 = async (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.addEventListener(
      'abort',
      () => {
        reject(new Error('file reading was aborted'));
      },
      { once: true }
    );

    reader.addEventListener(
      'error',
      () => {
        reject(new Error('file reading has failed'));
      },
      { once: true }
    );

    reader.addEventListener(
      'loadend',
      () => {
        resolve(reader.result as string);
      },
      { once: true }
    );
  });

const rotateImageByExif = async ({
  imgSrc,
  type
}: {
  imgSrc: string;
  type: string;
}) =>
  new Promise<string>((resolve) => {
    const exif = toExif(imgSrc);
    if (!exif) {
      resolve(imgSrc);
      return;
    }

    const image = new Image();
    image.src = imgSrc;
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      let x = 0;
      let y = 0;

      if (!ctx) {
        throw new Error("Couldn't find context for canvas. Can't rotate image");
      }

      ctx.save();

      if (exif['0th']) {
        const orientation = exif['0th'][ImageIFD.Orientation];

        switch (orientation) {
          case 2: {
            x = -canvas.width;
            ctx.scale(-1, 1);

            break;
          }

          case 3: {
            x = -canvas.width;
            y = -canvas.height;
            ctx.scale(-1, -1);

            break;
          }

          case 4: {
            y = -canvas.height;
            ctx.scale(1, -1);

            break;
          }

          case 5: {
            canvas.width = image.height;
            canvas.height = image.width;
            ctx.translate(canvas.width, canvas.height / canvas.width);
            ctx.rotate(Math.PI / 2);
            y = -canvas.width;
            ctx.scale(1, -1);

            break;
          }

          case 6: {
            canvas.width = image.height;
            canvas.height = image.width;
            ctx.translate(canvas.width, canvas.height / canvas.width);
            ctx.rotate(Math.PI / 2);

            break;
          }

          case 7: {
            canvas.width = image.height;
            canvas.height = image.width;
            ctx.translate(canvas.width, canvas.height / canvas.width);
            ctx.rotate(Math.PI / 2);
            x = -canvas.height;
            ctx.scale(-1, 1);

            break;
          }

          case 8: {
            canvas.width = image.height;
            canvas.height = image.width;
            ctx.translate(canvas.width, canvas.height / canvas.width);
            ctx.rotate(Math.PI / 2);
            x = -canvas.height;
            y = -canvas.width;
            ctx.scale(-1, -1);

            break;
          }

          default:
            // noop
            break;
        }
      }

      ctx.drawImage(image, x, y);
      ctx.restore();
      resolve(canvas.toDataURL(type, 1));
    });
  });

const MAX_RESOLUTION = 2048;

const convertFile = async ({
  file,
  max = MAX_RESOLUTION
}: {
  file: File;
  max: number;
}): Promise<{
  file: Blob;
  width: number;
  height: number;
  imgSrc: string;
}> => {
  const { type } = file;
  let imgSrc = await blobToBase64(file);
  // eslint-disable-next-line require-atomic-updates
  imgSrc = await rotateImageByExif({ imgSrc, type });
  const img = new Image();
  await new Promise((resolve, reject) => {
    img.addEventListener('error', ({ error }) => {
      reject(error as Error);
    });

    img.addEventListener('load', resolve);

    img.src = imgSrc;
  });

  if (!img.width) {
    throw new Error('no image loaded');
  }

  const ratio = resizeRatio({
    width: img.width,
    height: img.height,
    max
  });

  let blob: Blob = file;
  if (ratio) {
    // eslint-disable-next-line require-atomic-updates
    ({ blob, imgSrc } = await resizeImage({
      file,
      img,
      width: img.width,
      height: img.height,
      type,
      ratio
    }));
  }

  const { width, height } = resizeImageCanvas({ img });
  return { file: blob, width, height, imgSrc };
};

const resizeRatio = ({
  width,
  height,
  max
}: {
  width: number;
  height: number;
  max: number;
  min?: number | null;
}): number | null => {
  if (width > max || height > max) {
    return width > height ? max / width : max / height;
  }

  return null;
};

export const TemplateCreateForm: React.FC = () => {
  const { asyncDispatch, dispatch } = useGlobalState();

  const [type, setType] = useState(IdTemplateType.DriversLicense);
  const [country, setCountry] = useState<Country>(Country.US);
  const [idState, setIdState] = useState('');

  const [imgSrc, setImgSrc] = useState('');

  const [version, setVersion] = useState('');

  const [invalidData, setInvalidData] = useState(false);

  const createTemplate = () => {
    const data = {
      type,
      country,
      state: idState === '' ? null : idState,
      version,
      image: imgSrc
    };

    asyncDispatch(adminCreateTemplate(data))
      .then(() => {
        dispatch(
          setMessages({
            value: `The template has been created`,
            severity: 'success'
          })
        );
      })
      .catch(console.error);
  };

  const handleType = useCallback((e: SelectEvent<IdTemplateType>) => {
    setType(e.target.value);
    setInvalidData(false);
  }, []);

  const handleCountry = useCallback((e: SelectEvent<Country>) => {
    setCountry(e.target.value);
    setInvalidData(false);
  }, []);

  const handleState = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIdState(e.target.value);
    setInvalidData(false);
  }, []);

  const handleVersion = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVersion(e.target.value);
    setInvalidData(false);
  }, []);

  const handlePhoto = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (!file) {
      return;
    }

    if (file.size > 10 * 1_048_576) {
      // eslint-disable-next-line no-alert
      alert('file too large');
      return;
    }

    convertFile({
      max: 1600,
      file
    }).then((r) => {
      setImgSrc(r.imgSrc);
    }, console.error);
  }, []);

  const idTemplateTypeOptions = useMemo(
    () => idTemplateTypes.map((v) => ({ name: v, id: v })),
    []
  );

  const countryOptions = useMemo(() => {
    const out: Option<Country>[] = [];
    for (const id of Object.values(Country)) {
      out.push({ id, name: `${countryNames[id]} (${id})` });
    }

    return out;
  }, []);

  return (
    <div>
      <h1>Create Template</h1>
      <div className="filters-container">
        <Grid container>
          <Grid container item xs={9}>
            <Grid item xs={3}>
              <Select<IdTemplateType>
                className="g-full-width u-margin-bottom-small"
                isClearable={false}
                items={idTemplateTypeOptions}
                label="Type"
                onChange={handleType}
                required
                value={type}
              />
            </Grid>
            <Grid item xs={3}>
              <Select<Country>
                className="g-full-width u-margin-bottom-small"
                isClearable={false}
                items={countryOptions}
                label="Country"
                onChange={handleCountry}
                value={country}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="State"
                onChange={handleState}
                value={idState}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Version"
                onChange={handleVersion}
                required
                value={version}
              />
            </Grid>
          </Grid>
          <Grid container item xs={3}>
            <Button component="label" variant="contained">
              Upload File
              <input
                accept="image/jpg,image/jpeg,image/png"
                hidden
                multiple={false}
                onChange={handlePhoto}
                type="file"
              />
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Button onClick={createTemplate}>Create</Button>
        </Grid>
      </div>
    </div>
  );
};
