import { CvsResponseItem } from './ssn_model';

export const getSSNResultFailed = (): string => {
  return 'Request to the SSN endpoint failed';
};

export const getRecordErrorCode = (response: CvsResponseItem): string => {
  if (!response.recordErrorCode) return 'Could not find the error code';
  return response.recordErrorCode;
};

export const getRecordErrorCodeDesc = (response: CvsResponseItem): string => {
  if (!response.recordErrorCodeDesc)
    return 'Could not find the error description';
  return response.recordErrorCodeDesc;
};

export const getVerificationResult = (
  response: CvsResponseItem
): 'N' | 'undefined' | 'Y' => {
  if (response.verificationCode) return response.verificationCode;
  return 'undefined';
};

export const getDeathIndicator = (
  response: CvsResponseItem
): 'N' | 'undefined' | 'Y' => {
  if (response.verificationData?.deathIndicator)
    return response.verificationData.deathIndicator;
  return 'undefined';
};

export const getSignatureType = (
  signatureType: 'E' | 'W' | undefined
): 'E' | 'undefined' | 'W' => {
  if (signatureType) return signatureType;
  return 'undefined';
};

export const getDOB = (dob: string): string => {
  if (!dob) return dob;
  return `${dob.slice(0, 2)}/${dob.slice(2, 4)}/${dob.slice(4)}`;
};
