import {
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { List } from 'components/reusable/list';
import { ReusableSelect as Select } from 'components/reusable/select';
import { Tick } from 'components/reusable/tick';
import { InviteApi } from 'models/invite/invite.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  Candidate,
  copyInvite,
  InvitesFilter,
  loadInvites,
  LoadInvitesResponse,
  postInvite,
  resendInvite
} from 'store/sagas/sagas';
import { capitalize } from 'utils/format';
import { history } from 'utils/history';
import { useDebounce, useFlags } from 'utils/hooks';
import { humanDate, humanPhone } from 'utils/ui.utils';
import {
  isQueriesSame,
  MapData,
  pushFiltersSearchPath,
  QueryData,
  rawParseQuery,
  transformQueryTypes
} from 'utils/url';
import { useIsMobile } from 'utils/window';
import './candidates.css';

const isButtonDisabled = (
  newCandidate: InviteApi,
  candidatesFeature: boolean
) => {
  const hasBasicInfo = newCandidate.firstName && newCandidate.lastName;

  if (candidatesFeature) {
    return !(hasBasicInfo && newCandidate.email && newCandidate.phone);
  }

  const canSend =
    !newCandidate.send ||
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (newCandidate.contact === 'email' && newCandidate.email) ||
    (newCandidate.contact === 'phone' && newCandidate.phone);

  return !(hasBasicInfo && canSend);
};

const initialData: InvitesFilter = {
  pageSize: 50,
  page: 1,
  id: null,
  lastName: null,
  email: null,
  phone: null,
  accountGroupSid: null
};

const mapData: MapData = {
  pageSize: 'number',
  page: 'number'
};

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: '38px'
  },
  main_div: ({ isMobile }: { isMobile: boolean }) => ({
    width: isMobile ? '100%' : 'auto',
    display: isMobile ? 'flex' : 'inherit',
    flexDirection: isMobile ? 'column' : 'inherit'
  })
}));

export const Candidates: React.FC = () => {
  const { state, asyncDispatch, dispatch } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState<{
    el: HTMLElement | null;
    id: string | null;
  }>({ el: null, id: null });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: string) => {
      setAnchor({ el: event.currentTarget, id });
    },
    []
  );

  const toAccountGroupName = (sid: string): string | null => {
    if (!state.accountGroups || !sid) {
      return null;
    }

    const found = state.accountGroups.find((a) => a.sid === sid);
    return found ? found.name : null;
  };

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setAnchor({ el: null, id: null });
    }, 0);
  }, []);

  const qsData = transformQueryTypes(mapData, rawParseQuery());

  const [filter, setFilter] = useState<InvitesFilter>({
    ...initialData,
    ...qsData
  });

  const debouncedFilter = useDebounce(filter, 250);

  const updateFilter = (newFilter: Partial<InvitesFilter>) => {
    setFilter({ ...initialData, ...newFilter });
  };

  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const [invites, setInvites] = useState<LoadInvitesResponse>({
    items: [],
    total: 0,
    totalPages: 0
  });

  const [newCandidate, setNewCandidate] = useState<InviteApi>({
    phone: null,
    email: null,
    send: true,
    contact: 'phone',
    firstName: '',
    lastName: '',
    internalId: null,
    accountGroupSid: null,
    callbackURL: null
  });

  const [copiedLink, setCopiedLink] = useState('');
  const [resendIds, setResendIds] = useState<string[]>([]);

  const resetFields = () => {
    setNewCandidate({
      phone: null,
      email: null,
      contact: '',
      send: newCandidate.send,
      firstName: '',
      lastName: '',
      internalId: null,
      accountGroupSid: null
    });
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const newQsData = transformQueryTypes(
        mapData,
        rawParseQuery(location.search.replace(/\?/, ''))
      );

      // same?
      if (!isQueriesSame(newQsData, filter as unknown as QueryData)) {
        updateFilter(newQsData);
      }
    });

    return () => {
      unlisten();
    };
  });

  useEffect(() => {
    const { isInitial } = pushFiltersSearchPath({
      filter: debouncedFilter as unknown as QueryData,
      initialData: initialData as unknown as QueryData,
      mapData,
      path: '/account/candidates'
    });

    if (!isOpenFilters && !isInitial) {
      setIsOpenFilters(true);
    }

    setLoading(true);
    asyncDispatch(loadInvites(debouncedFilter))
      .then(setInvites, console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedFilter]);

  const handleChangeCheck = useCallback(
    (e: ChangeEvent<{ name: string; checked?: boolean }>) => {
      setNewCandidate({
        ...newCandidate,
        [e.target.name]: e.target.checked
      });
    },
    [newCandidate]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<{ value: string; name: string }>) => {
      setNewCandidate((cand) => ({
        ...cand,
        [e.target.name]: e.target.value
      }));
    },
    []
  );

  const fixCandidate = (candidate: InviteApi) => {
    return { ...candidate };
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    updateFilter({
      ...filter,
      page
    });
  };

  const handleChangeFilters = useCallback(
    (e: ChangeEvent<{ value: number | string; name: string }>) => {
      updateFilter({
        ...filter,
        [e.target.name]: e.target.value
      });
    },
    [filter]
  );

  const isMobile = useIsMobile();

  const { candidatesFeature } = useFlags();

  const isDisabled = isButtonDisabled(newCandidate, !!candidatesFeature);

  const classes = useStyles({ isMobile });
  const groupsDropDown = state.user?.isAdmin
    ? state.accountGroups
    : state.userAccountGroups;

  return (
    <div className={classes.main_div}>
      <h1 className="g-page-name">Candidates</h1>
      <div className="filters-container-container">
        <div className="filters-container">
          <Grid className="u-margin-top-medium" container>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={1}>
                <span className="text-weak">Send Message</span>
              </Grid>
              <Grid item xs={1}>
                <Checkbox
                  checked={newCandidate.send}
                  name="send"
                  onChange={handleChangeCheck}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={12}>
                <TextField
                  className="u-margin-bottom-small g-full-width"
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={newCandidate.firstName}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={12}>
                <TextField
                  className="u-margin-bottom-small g-full-width"
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={newCandidate.lastName}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={3}>
                <Select
                  className="g-full-width u-margin-top-small"
                  isClearable={false}
                  items={[
                    { id: 'email', name: 'Email' },
                    { id: 'phone', name: 'Phone' }
                  ]}
                  label="Contact"
                  name="contact"
                  onChange={handleChange}
                  value={newCandidate.contact ?? ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="g-full-width"
                  label="Phone"
                  name="phone"
                  onChange={handleChange}
                  style={{
                    marginTop: 6
                  }}
                  value={newCandidate.phone ?? ''}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="g-full-width"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  style={{
                    marginTop: 6
                  }}
                  value={newCandidate.email ?? ''}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={3}>
                <TextField
                  className="u-margin-bottom-small g-full-width"
                  label="CallbackURL (Optional)"
                  name="callbackURL"
                  onChange={handleChange}
                  value={newCandidate.callbackURL ?? ''}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  className="g-full-width"
                  label="Internal Id (Optional)"
                  name="internalId"
                  onChange={handleChange}
                  style={{}}
                  value={newCandidate.internalId ?? ''}
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  className="g-full-width"
                  items={(groupsDropDown ?? []).map((a) => ({
                    id: a.sid,
                    name: a.name
                  }))}
                  label="Account Group"
                  name="accountGroupSid"
                  onChange={handleChange}
                  value={newCandidate.accountGroupSid ?? ''}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={3} xs={12}>
              <Grid item xs={3}>
                <Button
                  className="u-margin-top-large u-margin-bottom-large"
                  color="primary"
                  disabled={isDisabled}
                  onClick={() => {
                    asyncDispatch(postInvite(fixCandidate(newCandidate)))
                      .then(() => {
                        dispatch(
                          setMessages({
                            value: 'Candidate created!',
                            severity: 'success'
                          })
                        );

                        resetFields();
                        asyncDispatch(loadInvites({}))
                          .then((res) => {
                            setInvites(res);
                          })
                          .catch(console.error);
                      })
                      .catch(() => {
                        resetFields();
                      });
                  }}
                  variant="contained"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="filters__container">
        <div
          className="filters"
          onClick={() => {
            setIsOpenFilters(!isOpenFilters);
          }}
        >
          <FilterListIcon
            style={{
              color: isOpenFilters ? '#4122af' : 'grey'
            }}
          />
          <span>Filter</span>
        </div>
      </div>
      {isOpenFilters && (
        <div className="jobs__filters-container-container">
          <div className="jobs__filters-container u-margin-bottom-large">
            <div className="u-margin-bottom-large">
              <Grid className="u-margin-top-medium" container>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={3}>
                    <Select
                      className="g-full-width"
                      items={[
                        { id: 10, name: '10' },
                        { id: 25, name: '25' },
                        { id: 50, name: '50' }
                      ]}
                      label="Size"
                      name="pageSize"
                      onChange={handleChangeFilters}
                      value={filter.pageSize}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={3}>
                    <TextField
                      className="u-margin-bottom-small  g-full-width"
                      label="Id"
                      name="id"
                      onChange={handleChangeFilters}
                      value={filter.id ?? ''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className="u-margin-bottom-small g-full-width"
                      label="Last Name"
                      name="lastName"
                      onChange={handleChangeFilters}
                      value={filter.lastName ?? ''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      className="select-group u-margin-top-large"
                      items={(state.userAccountGroups ?? []).map((a) => ({
                        id: a.sid,
                        name: a.name
                      }))}
                      name="accountGroupSid"
                      onChange={handleChangeFilters}
                      value={filter.accountGroupSid ?? ''}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={3}>
                    <TextField
                      className="u-margin-bottom-small g-full-width"
                      label="Phone"
                      name="phone"
                      onChange={handleChangeFilters}
                      value={filter.phone ?? ''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className="u-margin-bottom-small g-full-width"
                      label="Email"
                      name="email"
                      onChange={handleChangeFilters}
                      value={filter.email ?? ''}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={3} xs={12}>
                  <Grid item xs={4}>
                    <Button
                      className="u-margin-top-large"
                      color="primary"
                      onClick={() => {
                        updateFilter({});
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}

      <List<Candidate>
        columns={[
          {
            name: 'Status',
            xs: 2,
            customRenderValue: ({ status }) => {
              let displayState:
                | 'active'
                | 'approved'
                | 'rejected'
                | 'removed'
                | 'warning'
                | null = 'active';

              status = capitalize(status ?? '');
              // let color = '#f4d348';
              const FAILED = ['failed', 'undelivered'];
              const COMPLETED = ['completed'];
              if (FAILED.includes(status.toLowerCase())) {
                displayState = 'rejected';
              } else if (COMPLETED.includes(status.toLowerCase())) {
                displayState = 'approved';
              }

              const dot = (
                <Tick
                  state={displayState}
                  style={{ marginRight: 8, marginTop: 6 }}
                />
              );

              return (
                <div className="list_span_dot">
                  {dot}
                  <div className="text-ellipsis">{status}</div>
                </div>
              );
            }
          },

          {
            name: 'Id',
            xs: 3,
            customRenderValue: ({ id, accountGroupSid, internalId }) => {
              return (
                <>
                  <div className="text-ellipsis">{id}</div>
                  <div className="text-ellipsis">
                    {toAccountGroupName(accountGroupSid)}
                  </div>
                  <div className="text-ellipsis">{internalId}</div>
                </>
              );
            }
          },
          {
            name: 'Name',
            xs: 2,
            customRenderValue: ({ firstName, lastName }) => {
              return (
                <div style={{ width: '100%' }}>
                  <div className="text-ellipsis">{firstName}</div>
                  <div className="text-ellipsis">{lastName}</div>
                </div>
              );
            }
          },
          {
            name: 'Contact',
            xs: 2,
            customRenderValue: ({ contact, phone, email }) => {
              if (!contact) return null;
              const first = contact === 'phone' ? humanPhone(phone) : email;

              const second = contact === 'phone' ? email : humanPhone(phone);

              return (
                <div style={{ width: '100%' }}>
                  <div className="text-ellipsis">To: {first}</div>
                  <div className="text-ellipsis">{second ?? null}</div>
                </div>
              );
            }
          },

          {
            name: 'Updated',
            xs: 2,
            isClickable: false,
            customRenderValue: ({ updatedAt }) => (
              <span>{humanDate(updatedAt)}</span>
            )
          },
          {
            xs: 1,
            name: '',
            isClickable: false,
            customRenderValue: ({ id, status }) => {
              const isLinkWasCopied = copiedLink === id;
              const isCompleted = status === 'completed';

              return (
                !isCompleted && (
                  <div
                    className="list-pointer"
                    onClick={(e) => {
                      handleClick(e, id);
                    }}
                  >
                    <i className="candidates__menu_icon fas fa-ellipsis-v" />
                    <Menu
                      anchorEl={anchor.el}
                      className={classes.menu}
                      id={id}
                      keepMounted
                      onClose={handleClose}
                      open={anchor.id === id}
                    >
                      <MenuItem
                        onClick={() => {
                          setResendIds([...resendIds, id]);
                          asyncDispatch(resendInvite(id))
                            .then(() => {
                              setTimeout(() => {
                                handleClose();
                              }, 500);
                            })
                            .catch(console.error);
                        }}
                      >
                        {resendIds.includes(id) ? 'Resent' : 'Resend'}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          asyncDispatch(copyInvite(id))
                            .then((res) => {
                              setCopiedLink(id);
                              if (res.message) {
                                window.navigator.clipboard
                                  .writeText(res.message)
                                  .catch(console.error);
                              }

                              setTimeout(() => {
                                handleClose();
                              }, 500);
                            })
                            .catch(console.error);
                        }}
                      >
                        {isLinkWasCopied ? 'Copied' : 'Copy'}
                      </MenuItem>
                    </Menu>
                  </div>
                )
              );
            }
          }
        ]}
        handlePageChange={handlePageChange}
        isClickable={(item) => {
          const LINKABLE = ['completed', 'active', 'started'];
          return !!item.status && LINKABLE.includes(item.status.toLowerCase());
        }}
        items={invites.items}
        label="candidates"
        listSource="candidates"
        loading={loading}
        onClick={(item) => {
          history.push(`/account/jobs?ids=${item.id}`);
        }}
        page={filter.page}
        total={invites.total}
        totalPages={invites.totalPages}
      />
    </div>
  );
};
