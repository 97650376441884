import React from 'react';
import './error-page.css';

export const ErrorPage: React.FC = () => {
  return (
    <div className="error__container">
      <span>Something Went Wrong</span>
    </div>
  );
};
