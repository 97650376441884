import Button from '@material-ui/core/Button';
import React, { FC, useCallback } from 'react';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { adminReloadTemplates } from 'store/sagas/sagas';

export const TemplateReloadButton: FC = () => {
  const { asyncDispatch, dispatch } = useGlobalState();

  const handleClick = useCallback(() => {
    if (
      // eslint-disable-next-line no-alert
      !confirm('This will reload all templates, this should be used sparingly.')
    ) {
      return;
    }

    asyncDispatch(adminReloadTemplates()).then(
      () => {
        dispatch(
          setMessages({
            severity: 'success',
            value: 'Templates invalidated'
          })
        );
      },
      (error) => {
        console.error(error);

        dispatch(
          setMessages({
            severity: 'error',
            value: 'Templates invalidation failed'
          })
        );
      }
    );
  }, []);

  return <Button onClick={handleClick}>Reload templates</Button>;
};
