import { GoogleMap, Marker } from '@react-google-maps/api';
import { Loader } from 'google-maps';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { JobResult } from 'models/jobs/jobs';
import './map.css';

type LatLngLiteral = google.maps.LatLngLiteral;
interface Props {
  readonly result: JobResult;
  readonly idGeocode: LatLngLiteral | null;
  readonly setDistance: (distance: number) => void;
}

const containerStyle = {
  width: '800px',
  height: '300px'
};

function distanceFromLatLon(
  idGeoCode: LatLngLiteral,
  ipGeoCode: LatLngLiteral
) {
  /*
   * using Haversine Algorithm
   * https://en.wikipedia.org/wiki/Haversine_formula
   */
  const p = Math.PI / 180;
  const c = Math.cos;
  const diameterInMiles = 7917.5;
  const a =
    0.5 -
    c((idGeoCode.lat - ipGeoCode.lat) * p) / 2 +
    (c(ipGeoCode.lat * p) *
      c(idGeoCode.lat * p) *
      (1 - c((idGeoCode.lng - ipGeoCode.lng) * p))) /
      2;

  return diameterInMiles * Math.asin(Math.sqrt(a));
}

function getMapcenter(idGeoCode: LatLngLiteral, ipGeocode: LatLngLiteral) {
  const lat_max = Math.max(idGeoCode.lat, ipGeocode.lat);
  const long_max = Math.max(idGeoCode.lng, ipGeocode.lng);
  const lat_min = Math.min(idGeoCode.lat, ipGeocode.lat);
  const long_min = Math.min(idGeoCode.lng, ipGeocode.lng);
  return {
    lat: (lat_max + lat_min) / 2,
    lng: (long_max + long_min) / 2
  };
}

export const Map: React.FC<Props> = ({ result, idGeocode, setDistance }) => {
  const mapRef = useRef<google.maps.Map>();
  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!window.google) {
      const loader = new Loader(process.env['REACT_APP_GOOGLE_MAPS_KEY']);
      loader.load().then((google) => {
        window.google = google;
        setLoaded(true);
      }, console.error);
    }
  }, [loaded]);

  const geocodeLocation = result.ipAddress?.location;
  const ipGeocode: LatLngLiteral | null = geocodeLocation
    ? { lat: geocodeLocation.latitude, lng: geocodeLocation.longitude }
    : null;

  if (ipGeocode && idGeocode) {
    const distanceBetween = distanceFromLatLon(ipGeocode, idGeocode);
    setDistance(distanceBetween);
  }

  const mapCenter =
    ipGeocode && idGeocode
      ? getMapcenter(ipGeocode, idGeocode)
      : ipGeocode ?? idGeocode!;

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {(loaded || window.google) && (
        <GoogleMap
          center={mapCenter}
          mapContainerClassName="map-container"
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          zoom={2}
        >
          {ipGeocode && <Marker position={ipGeocode} title="Device Location" />}
          {idGeocode && (
            <Marker position={idGeocode} title="Document Location" />
          )}
        </GoogleMap>
      )}
    </div>
  );
};
