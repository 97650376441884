import React, { FC, ReactElement } from 'react';
import { extractedAddress } from '../../utils';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { JobResult } from 'models/jobs/jobs';
import { humanIdType } from 'utils/jobs';

export interface RenderItem {
  name: string;
  customRenderType: ReactElement;
  customRenderValue: ReactElement;
}

export const IdInformation: FC<{ readonly result: JobResult }> = ({
  result
}) => {
  const items: RenderItem[] = [
    {
      name: 'Document',
      customRenderType: (
        <div className="job-modal-ip">
          <Text strongest={false} value="Type" />
          <Text strongest={false} value="State" />
          <Text strongest={false} value="Country" />
        </div>
      ),
      customRenderValue: (
        <div className="job-modal-ip">
          <Text value={humanIdType(result.type)} />
          <Text value={result.state} />
          <Text value={result.country} />
        </div>
      )
    },
    {
      name: 'Primary',
      customRenderType: (
        <div className="job-modal-ip">
          <Text strongest={false} value="ID Number" />
          <Text strongest={false} value="First Name" />
          <Text strongest={false} value="Middle Name" />
          <Text strongest={false} value="Last Name" />
          <Text strongest={false} value="Birth Date" />
          <Text strongest={false} value="Expiration Date" />
          <Text strongest={false} value="Issue Date" />
        </div>
      ),
      customRenderValue: (
        <div className="job-modal-ip">
          <Text value={result.id} />
          <Text value={result.firstName} />
          <Text value={result.middleName} />
          <Text value={result.lastName} />
          <Text value={result.birthDate} />
          <Text value={result.expireDate} />
          <Text value={result.issueDate} />
        </div>
      )
    },

    {
      name: 'Secondary',
      customRenderType: (
        <div className="job-modal-ip">
          <Text strongest={false} value="Class" />
          <Text strongest={false} value="Endorsements" />
          <Text strongest={false} value="Motorcycle" />
        </div>
      ),
      customRenderValue: (
        <div className="job-modal-ip">
          <Text value={result.class} />
          <Text value={result.endorsements} />
          <Text value={result.motorcycle} />
        </div>
      )
    },
    result.address ?? result.unverifiedIdAddress
      ? {
          name: 'Address',
          customRenderType: (
            <div className="job-modal-ip">
              <Text strongest={false} value="Extracted Address" />
            </div>
          ),
          customRenderValue: (
            <div className="job-modal-ip">
              <Text
                value={extractedAddress(
                  result.address ?? result.unverifiedIdAddress
                )}
              />
            </div>
          )
        }
      : null,
    result.barcodeData && Object.keys(result.barcodeData).length !== 0
      ? {
          name: 'Barcode Data',
          customRenderType: (
            <div className="job-modal-ip">
              <Text strongest={false} value="ID Number" />
              <Text strongest={false} value="First Name" />
              <Text strongest={false} value="Middle Name" />
              <Text strongest={false} value="Last Name" />
              <Text strongest={false} value="Birth Date" />
              <Text strongest={false} value="Expiration Date" />
              <Text strongest={false} value="Issue Date" />
            </div>
          ),
          customRenderValue: (
            <div className="job-modal-ip">
              <Text value={result.barcodeData.id} />
              <Text value={result.barcodeData.firstName} />
              <Text value={result.barcodeData.middleName} />
              <Text value={result.barcodeData.lastName} />
              <Text value={result.barcodeData.birthDate} />
              <Text value={result.barcodeData.expireDate} />
              <Text value={result.barcodeData.issueDate} />
            </div>
          )
        }
      : null,
    result.gender
      ? {
          name: 'Gender',
          customRenderType: (
            <div className="job-modal-ip">
              <Text strongest={false} value="Gender" />
            </div>
          ),
          customRenderValue: (
            <div className="job-modal-ip">
              <Text value={result.gender.gender} />
            </div>
          )
        }
      : null
  ].filter(Boolean) as RenderItem[];

  return (
    <List
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'type',
          xs: 3,
          id: 'customRenderType'
        },
        {
          name: 'value',
          xs: 7,
          id: 'customRenderValue'
        }
      ]}
      items={items}
      page={1}
      showHeaders={false}
    />
  );
};
