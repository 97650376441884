import { Switch, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { JobType } from '../../job_type';
import {
  Aamva,
  Aml,
  Crosscheck,
  Debug,
  Delete,
  Dlv,
  DobView,
  Files,
  IdInformation,
  LocationColumn,
  Request,
  Review,
  Scores,
  SecondaryPhotos,
  Ssn,
  SummaryAML,
  SummaryAuthentication,
  SummaryCrosscheck,
  SummaryIdv,
  Survey
} from './components';
import { DlvNotEnabled } from './components/dlv/utils';
import { DobSummary } from './components/dob/dob-sumary';
import { SummarySSN } from './components/summary-ssn/summary-ssn';
import {
  a11yProps,
  AuthorizationActions,
  FACE_AUTH_BLACKLIST_TABS,
  isOpen,
  jobFinished,
  tabPermission
} from './utils';
import { Job, JobUpdateReview } from 'models/jobs/jobs';
import { User, UserUpdateReview } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import {
  AMLJobResult,
  downloadJob,
  retrieveAamvaDetailed,
  retrieveAmlDetailed
} from 'store/sagas/sagas';
import './job-modal-tabs.css';
import { base64ToArrayBuffer, saveByteArray } from 'utils/ui.utils';

export const StyledSwitch = withStyles({
  switchBase: {
    color: '#635BFF',
    '&$checked': {
      color: '#635BFF'
    },
    '&$checked + $track': {
      backgroundColor: '#635BFF'
    }
  },
  checked: {},
  track: {
    border: `1px solid #7f90a0`,
    backgroundColor: '#ffffff'
  }
})(Switch);

interface Props {
  readonly job: Job;
  readonly user: User;
  readonly readonly: boolean | null;
  readonly debug: boolean | null;
  readonly onClose: () => void;
  readonly handleReview: (body: UserUpdateReview) => void;
  readonly handleJobReview: (id: string, body: JobUpdateReview | null) => void;
  readonly handleRemove: (id: string) => void;
}

const AllTabs = [
  'Summary',
  'Review',
  'Request',
  'Location',
  'ID',
  'Scores',
  'Crosscheck',
  'DLV',
  'AML',
  'SSN',
  'DOB',
  'Photos',
  'Files',
  'Survey',
  'Debug',
  'Delete'
];

export const ModalTabs: FC<Props> = ({
  debug,
  user,
  job,
  readonly,
  handleReview,
  handleJobReview,
  onClose,
  handleRemove
}) => {
  const { state, asyncDispatch } = useGlobalState();
  const [openedTab, setOpenedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [detailedAamva, setDetailedAamva] = useState<AMLJobResult | null>(null);
  const [collapseValidID, setCollapseValidID] = useState(false);
  const [collapseValidSelfie, setCollapseValidSelfie] = useState(false);
  const [collapseValidFace, setCollapseValidFace] = useState(false);
  const [detailedAml, setDetailedAml] = useState<AMLJobResult | undefined>();
  const [distanceBetween, setDistanceBetween] = useState(0);
  const deleteInput = useRef(null as HTMLInputElement | null);
  const isSandboxMode = state.sandboxToggled && !readonly;

  const tabs = AllTabs.filter((f) => {
    const permissions = AuthorizationActions[f.toLowerCase()];
    if (permissions && !permissions(user)) {
      return false;
    }

    return tabPermission(f, job, { readonly, debug });
  }).filter((col) => {
    if (job.request.type === JobType.idReverification) {
      return !FACE_AUTH_BLACKLIST_TABS.includes(col.toLowerCase());
    }

    return true;
  });

  useEffect(() => {
    Promise.all([
      asyncDispatch(retrieveAamvaDetailed(job.id, isSandboxMode)),
      job.result?.aml
        ? asyncDispatch(retrieveAmlDetailed(job.id, isSandboxMode))
        : undefined
    ])
      .then(([aamva, aml]) => {
        setDetailedAamva(aamva);
        setDetailedAml(aml);
      })
      .catch(console.error);
  }, [job]);

  const handleChange = useCallback(
    (_event: ChangeEvent<object>, newValue: number) => {
      setOpenedTab(newValue);
    },
    []
  );

  const handleDownload = (id: string, confValue: boolean) => {
    asyncDispatch(downloadJob(id, confValue, isSandboxMode))
      .then((res) => {
        const fileName = `IDV_${id}_Internal_Use.pdf`;
        const sampleBytes = base64ToArrayBuffer(res.pdf);
        saveByteArray([sampleBytes], fileName);
      })
      .catch(console.error);
  };

  const createDlvTab = () => {
    if (job.result?.featuresEnabled?.dlvEnabled) {
      return (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <Dlv result={job.result} />
          </div>
        </div>
      );
    }

    if (job.result?.aamva?.enabled) {
      return (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <Aamva detailedAamva={detailedAamva} job={job} />
          </div>
        </div>
      );
    }

    return <DlvNotEnabled />;
  };

  return (
    <>
      <AppBar color="default" position="static" style={{ boxShadow: 'none' }}>
        <Tabs
          aria-label="scrollable auto tabs example"
          indicatorColor="primary"
          onChange={handleChange}
          scrollButtons="auto"
          style={{
            backgroundColor: 'white',
            color: '#274661',
            fontWeight: 'bold'
          }}
          value={openedTab}
          variant="scrollable"
        >
          {tabs.map((item, index) => (
            <Tab key={item} label={item} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {isOpen(openedTab, tabs, 'summary') &&
        job.request.type === JobType.idVerification && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <SummaryIdv
                amlConfidence={detailedAml?.confidence?.normalized}
                collapseValidFace={collapseValidFace}
                collapseValidID={collapseValidID}
                collapseValidSelfie={collapseValidSelfie}
                handleReview={handleReview}
                job={job}
                setCollapseValidFace={setCollapseValidFace}
                setCollapseValidID={setCollapseValidID}
                setCollapseValidSelfie={setCollapseValidSelfie}
              />
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'summary') &&
        job.request.type === JobType.idCrosscheck && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <SummaryCrosscheck jobId={job.id} />
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'summary') &&
        job.request.type === JobType.idAml && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              {detailedAml ? (
                <SummaryAML
                  detailedAml={detailedAml}
                  isAdmin={!!user.isAdmin}
                  job={job}
                />
              ) : (
                'not available'
              )}
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'summary') &&
        (job.request.type === JobType.ecbsvSSN ||
          job.request.type === JobType.privateSSN) && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <SummarySSN job={job} />
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'summary') &&
        job.request.type === JobType.dob && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <DobSummary job={job} />
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'summary') &&
        job.request.type === JobType.idReverification && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <SummaryAuthentication isAdmin={user.isAdmin} job={job} />
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'debug') && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <Debug job={job} />
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'review') && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            {jobFinished(job) && (
              <Review
                handleJobReview={handleJobReview}
                job={job}
                readonly={readonly}
              />
            )}
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'files') && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            {jobFinished(job) && (
              <Files handleDownload={handleDownload} jobId={job.id} />
            )}
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'delete') && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <Delete
              deleteInput={deleteInput}
              handleRemove={handleRemove}
              isLoading={isLoading}
              job={job}
              onClose={onClose}
              readonly={readonly}
              setIsLoading={setIsLoading}
            />
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'ID') && job.result && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <IdInformation result={job.result} />
          </div>
        </div>
      )}
      {job.request.type !== JobType.idReverification &&
        isOpen(openedTab, tabs, 'CROSSCHECK') &&
        job.result && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <Crosscheck result={job.result} />
            </div>
          </div>
        )}
      {isOpen(openedTab, tabs, 'PHOTOS') && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <SecondaryPhotos job={job} />
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'SCORES') && job.result && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <Scores job={job} result={job.result} />
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'REQUEST') && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <Request job={job} />
          </div>
        </div>
      )}
      {isOpen(openedTab, tabs, 'location') && job.result && (
        <div className="tab_list">
          <div className="tab_list-scroll">
            <LocationColumn
              distanceBetween={distanceBetween}
              result={job.result}
              setDistanceBetween={setDistanceBetween}
            />
          </div>
        </div>
      )}
      {job.request.type !== JobType.idReverification &&
        isOpen(openedTab, tabs, 'SURVEY') && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              {jobFinished(job) && job.surveyPoll && (
                <Survey
                  surveyMessage={job.surveyMessage}
                  surveyPoll={job.surveyPoll}
                />
              )}
            </div>
          </div>
        )}
      {job.request.type !== JobType.idReverification &&
        isOpen(openedTab, tabs, 'DLV') &&
        createDlvTab()}
      {job.request.type !== JobType.idReverification &&
        job.result &&
        isOpen(openedTab, tabs, 'AML') && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <Aml detailedAml={detailedAml} result={job.result} />
            </div>
          </div>
        )}
      {job.request.type !== JobType.idReverification &&
        isOpen(openedTab, tabs, 'SSN') && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <Ssn job={job} />
            </div>
          </div>
        )}

      {isOpen(openedTab, tabs, 'DOB') &&
        job.request.type !== JobType.idReverification && (
          <div className="tab_list">
            <div className="tab_list-scroll">
              <DobView job={job} />
            </div>
          </div>
        )}
    </>
  );
};
