import React, { FC } from 'react';
import { DataTable } from '../../DataTable';
import { CvsRequestItem } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/ssn_model';
import { getDOB } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/utils';

interface ECBSVRequestDataTableProps {
  readonly requestData: CvsRequestItem;
}

export const ECBSVRequestDataTable: FC<ECBSVRequestDataTableProps> = ({
  requestData
}) => {
  let signatureType: string | undefined;
  switch (requestData.additionalParams?.signatureType) {
    case 'E':
      signatureType = 'Electronic Signature';
      break;
    case 'W':
      signatureType = 'Wet Signature';
      break;
    case undefined:
  }

  const items = {
    'First Name': requestData.firstName,
    'Middle Name': requestData.middleName,
    'Last Name': requestData.lastName,
    'Date of Birth': getDOB(requestData.dateOfBirth),
    'Signature Type': signatureType
  };

  return <DataTable items={items} />;
};
