import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const Loader: React.FC = () => {
  return (
    <div className="loader u-align-horizontal-center">
      <CircularProgress size={36} />
    </div>
  );
};
