import './edit-user-form.css';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { theme } from 'components/app/theme';
import { ReusableSelect as Select } from 'components/reusable/select';
import { useGlobalState } from 'store/reducers/reducer';
import { adminUpdateUser, loadAccounts, loadUser } from 'store/sagas/sagas';

interface EditUserRouteParams {
  id: string;
}

export const EditUserForm: React.FC<
  RouteComponentProps<EditUserRouteParams>
> = () => {
  const { state, asyncDispatch } = useGlobalState();
  const { id } = useParams<EditUserRouteParams>();
  const [sid, setSid] = useState('');
  const [email, setEmail] = useState('');

  const [accountId, setAccountId] = useState(null as number | null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [isAccountAdmin, setIsAccountAdmin] = useState(false);
  const [isDownloader, setIsDownloader] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);

  useEffect(() => {
    asyncDispatch(loadUser(id))
      .then((data) => {
        setSid(data.sid);
        setEmail(data.email);
        setAccountId(data.accountId);
        setIsDisabled(!!data.isDisabled);
        setIsAdmin(!!data.isAdmin);
        setIsAccountAdmin(!!data.isAccountAdmin);
        setIsDownloader(!!data.isDownloader);
        setIsReviewer(!!data.isReviewer);
        setIsSupport(!!data.isSupport);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (!state.accounts && state.user?.isAdmin) {
      asyncDispatch(loadAccounts()).catch(console.error);
    }
  }, [asyncDispatch, state.accounts, state.user?.isAdmin, state]);

  const updateUser = () => {
    asyncDispatch(
      adminUpdateUser({
        accountId,
        id,
        isAccountAdmin,
        isAdmin,
        isDisabled,
        isDownloader,
        isReviewer,
        isSupport
      })
    ).catch(console.error);
  };

  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="edit-user-container">
      <h1>Edit User</h1>
      <Box className="filters-container edit-filters">
        <Grid
          alignItems="flex-start"
          className="grid-class-container"
          container
          direction={breakpoint ? 'column' : 'row'}
          justifyContent="space-between"
          style={{
            marginBottom: breakpoint ? '1.6rem' : '0',
            maxWidth: breakpoint ? '100%' : '90rem'
          }}
        >
          <Grid item md={2} style={{ width: '100%' }} xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              disabled
              label="ID"
              value={id}
            />
          </Grid>
          <Grid item md={2} style={{ width: '100%' }} xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              disabled
              label="SID"
              value={sid}
            />
          </Grid>

          <Grid item md={2} style={{ width: '100%' }} xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              disabled
              label="Email"
              value={email}
            />
          </Grid>

          <Grid item md={2} style={{ width: '100%' }} xs={12}>
            {state.accounts && state.account && (
              <Select<number>
                className="g-full-width"
                isClearable={false}
                items={state.accounts.map((a) => ({
                  id: a.id,
                  name: a.name
                }))}
                label="Account"
                name="pageSize"
                onChange={(e) => {
                  setAccountId(e.target.value);
                }}
                value={accountId ?? undefined}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          className="grid-class-container"
          container
          direction="column"
          style={{
            alignItems: 'start',
            paddingLeft: '8px'
          }}
        >
          <Grid container direction="row" item xs={2}>
            <FormControlLabel
              className="form-checkbox"
              control={
                <Checkbox
                  checked={isDisabled}
                  onChange={() => {
                    setIsDisabled(!isDisabled);
                  }}
                />
              }
              label="Disabled"
            />
          </Grid>

          <Grid container direction="row" item xs={2}>
            <FormControlLabel
              className="form-checkbox"
              control={
                <Checkbox
                  checked={isAccountAdmin}
                  onChange={() => {
                    setIsAccountAdmin(!isAccountAdmin);
                  }}
                />
              }
              label="Account Admin"
            />
          </Grid>

          <Grid container direction="row" item xs={2}>
            <FormControlLabel
              className="form-checkbox"
              control={
                <Checkbox
                  checked={isAdmin}
                  onChange={() => {
                    setIsAdmin(!isAdmin);
                  }}
                />
              }
              label="System Admin"
            />
          </Grid>

          <Grid container direction="row" item xs={2}>
            <FormControlLabel
              className="form-checkbox"
              control={
                <Checkbox
                  checked={isSupport}
                  onChange={() => {
                    setIsSupport(!isSupport);
                  }}
                />
              }
              label="Support Admin"
            />
          </Grid>

          <Grid container direction="row" item xs={2}>
            <FormControlLabel
              className="form-checkbox"
              control={
                <Checkbox
                  checked={isReviewer}
                  onChange={() => {
                    setIsReviewer(!isReviewer);
                  }}
                />
              }
              label="Reviewer"
            />
          </Grid>

          <Grid container direction="row" item xs={2}>
            <FormControlLabel
              className="form-checkbox"
              control={
                <Checkbox
                  checked={isDownloader}
                  onChange={() => {
                    setIsDownloader(!isDownloader);
                  }}
                />
              }
              label="Downloader"
            />
          </Grid>
        </Grid>
        <Box marginTop="1.6rem">
          <Button onClick={updateUser}>Update</Button>
        </Box>
      </Box>
    </div>
  );
};
