import { datadogRum } from '@datadog/browser-rum';

const rumAppId = process.env['REACT_APP_DATADOG_RUM_APP_ID'];
const rumClientToken = process.env['REACT_APP_DATADOG_RUM_CLIENT_TOKEN'];
const onboardApiUrl = process.env['REACT_APP_ONBOARD_API'];
const apiUrl = process.env['REACT_APP_API'];

export const setDDUserData = (
  userId: number,
  accountId: number,
  email?: string
): void => {
  if (rumAppId && rumClientToken) {
    datadogRum.setUser({ id: String(userId), accountId, email });
  }
};

export const initDatadogRum = (): void => {
  if (rumAppId && rumClientToken && onboardApiUrl && apiUrl) {
    datadogRum.init({
      applicationId: rumAppId,
      clientToken: rumClientToken,
      site: 'datadoghq.com',
      service: 'onboard-client',
      env: process.env['REACT_APP_NODE_ENV'],
      version: process.env['REACT_APP_BUILD_ID'],
      silentMultipleInit: true,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      allowedTracingUrls: [onboardApiUrl, apiUrl],
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
  }
};
