import React, { useEffect } from 'react';
import { history } from '../../../utils/history';
import { Password } from './password';
import { UserAccount } from './user-account';
import { UserEmail } from './user-email';
import { UserInfo } from './user-info';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  ADMIN_PERMISSIONS,
  isAuthorized,
  REVIEWER_PERMISSIONS,
  SUPPORT_ADMIN_PERMISSIONS
} from 'security/authorization';
import { useGlobalState } from 'store/reducers/reducer';

export const Profile: React.FC = () => {
  const { state } = useGlobalState();
  const isAdmin = state.user
    ? isAuthorized(state.user, ADMIN_PERMISSIONS)
    : false;

  const isSupport = state.user
    ? isAuthorized(state.user, SUPPORT_ADMIN_PERMISSIONS)
    : false;

  const isReviewer = state.user
    ? isAuthorized(state.user, REVIEWER_PERMISSIONS)
    : false;

  const isAccountAdmin = state.user
    ? isAuthorized(state.user, ACCOUNT_ADMIN_PERMISSIONS)
    : false;

  useEffect(() => {
    if (isReviewer && window.location.pathname !== '/account') {
      history.push('/account/jobs');
    }
  }, []);

  return (
    <div className="profile-main">
      <h1 className="g-page-name">Profile</h1>
      <UserEmail />
      <UserInfo />
      <Password />
      {(isAdmin || isSupport || isAccountAdmin) && <UserAccount />}
    </div>
  );
};
