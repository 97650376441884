import React, { useRef, useState } from 'react';
import ReactImageMagnify, { LargeImageType } from 'react-image-magnify';
import { ImageCanvasRotation } from './image-canvas-rotation';
interface Props {
  readonly src: string;
  readonly width: number;
  readonly zoomSize: number;
  readonly enableZoom?: boolean;
  readonly height: number;
  readonly degrees: number;
  readonly imageDimensions: imageDimensions;
  readonly onImageLoaded?: () => void;
}
interface imageDimensions {
  height?: number;
  width?: number;
}

export const ImageZoom: React.FC<Props> = ({
  src,
  enableZoom = true,
  zoomSize,
  width,
  height,
  degrees,
  imageDimensions,
  onImageLoaded
}) => {
  let toZoomSize = 0;
  const SCALE_CANVAS_IMAGE = 3;

  const currentContainer = useRef<HTMLDivElement>(null);
  const [newImgUrl, setNewImgUrl] = useState('');
  const [currentSize, setCurrentSize] = useState<{
    width: number;
    height: number;
  }>({ width, height });

  toZoomSize = enableZoom ? zoomSize : 1;

  const onCanvasLoadHandle = (src64: string) => {
    resizeImageAfterLoad();
    setNewImgUrl(src64);
  };

  const resizeImageAfterLoad = () => {
    const isLateralRotation =
      degrees === 90 || degrees === -90 || degrees === 270 || degrees === -270;

    if (isLateralRotation) {
      const resize = resizeVerticalPosition(width, height);

      setCurrentSize({
        width: resize.height,
        height: resize.width
      });
    } else {
      setCurrentSize({
        width,
        height
      });
    }
  };

  const resizeVerticalPosition = (newWidth: number, newHeight: number) => {
    if (currentContainer.current) {
      const wHeight = currentContainer.current.clientHeight || 0;

      if (wHeight >= newWidth) return { height: newHeight, width: newWidth };

      const percentReduce = wHeight / newWidth - 0.1;

      return {
        height: newHeight * percentReduce,
        width: newWidth * percentReduce
      };
    }

    return { height: newHeight, width: newWidth };
  };

  const largeSizeWidth = currentSize.width * toZoomSize;
  const largeSizeHeight = currentSize.height * toZoomSize;

  let canvasWidth = largeSizeWidth * toZoomSize * SCALE_CANVAS_IMAGE;
  let canvasHeight = largeSizeHeight * toZoomSize * SCALE_CANVAS_IMAGE;

  canvasWidth =
    imageDimensions.width && canvasWidth > imageDimensions.width
      ? imageDimensions.width
      : canvasWidth;

  canvasHeight =
    imageDimensions.height && canvasHeight > imageDimensions.height
      ? imageDimensions.height
      : canvasHeight;

  const largeImageSetting: LargeImageType = enableZoom
    ? {
        src: newImgUrl,
        width: largeSizeWidth,
        height: largeSizeHeight
      }
    : {
        src: '',
        width: -1,
        height: -1
      };

  return (
    <div className="image__zoom" ref={currentContainer}>
      {newImgUrl && (
        <ReactImageMagnify
          className="image-zoom-content"
          enlargedImagePosition="over"
          largeImage={largeImageSetting}
          smallImage={{
            width: enableZoom ? currentSize.width : width,
            height: enableZoom ? currentSize.height : height,
            src: enableZoom ? newImgUrl : src,
            isFluidWidth: false,
            onLoad: onImageLoaded
          }}
        />
      )}
      <ImageCanvasRotation
        imageHeight={canvasHeight}
        imageWidth={canvasWidth}
        isVisible={false}
        onCanvasLoaded={onCanvasLoadHandle}
        rotate={degrees}
        src={src}
      />
    </div>
  );
};
