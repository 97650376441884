import React from 'react';
import { RenderItem } from '../id-information/id-information';
import { DobRequestData } from './dob_model';
import { Text } from 'components/text';

export const dobRequestDataSummary = (
  requestData?: DobRequestData
): RenderItem => {
  return {
    name: 'Request',
    customRenderType: (
      <div className="job-modal-ip">
        <Text strongest={false} value="First Name" />
        <Text strongest={false} value="Last Name" />
        <Text strongest={false} value="Date of Birth" />
        <Text strongest={false} value="Email" />
        <Text strongest={false} value="Phone" />
        <Text strongest={false} value="Street Address" />
        <Text strongest={false} value="Unit" />
        <Text strongest={false} value="City" />
        <Text strongest={false} value="State" />
        <Text strongest={false} value="Country" />
        <Text strongest={false} value="Postal Code" />
      </div>
    ),
    customRenderValue: (
      <div className="job-modal-ip">
        <Text value={requestData?.firstName} />
        <Text value={requestData?.lastName} />
        <Text value={requestData?.dob} />
        <Text value={requestData?.email} />
        <Text value={requestData?.phone} />
        <Text value={requestData?.address?.streetAddress} />
        <Text value={requestData?.address?.unit} />
        <Text value={requestData?.address?.city} />
        <Text value={requestData?.address?.state} />
        <Text value={requestData?.address?.country} />
        <Text value={requestData?.address?.postalCode} />
      </div>
    )
  };
};
