import { Image } from 'components/account/jobs/image-slider/smart-image';
import { CrossCheckResultError } from 'models/jobs/crosscheck';
import { Insights, JobRequestParameters } from 'models/jobs/jobs';
import { capitalize } from 'utils/format';

export interface JobsOptions {
  billable: {
    id: string;
    name: string;
  }[];
  status: {
    id: string;
    name: string;
  }[];
  pageSize: {
    id: number;
    name: string;
  }[];
  aamvaSource: {
    id: string;
    name: string;
  }[];
  billableType: {
    id: string;
    name: string;
  }[];
  sortOrder: {
    id: string;
    name: string;
  }[];
  tableView: {
    id: number;
    name: string;
  }[];
  type: {
    id: string;
    name: string;
  }[];
  success: {
    id: string;
    name: string;
  }[];
  is_disabled: {
    id: string;
    name: string;
  }[];
  sortBy: {
    id: string;
    name: string;
  }[];
  accountReviewed: {
    id: string;
    name: string;
  }[];
  amLJobs: {
    id: string;
    name: string;
  }[];
}

export const jobsOptions: JobsOptions = {
  tableView: [
    { id: 1, name: 'List' },
    { id: 2, name: 'With Selfies' },
    { id: 3, name: 'With Ids' }
  ],
  type: [
    { id: 'id-verification', name: 'Idv' },
    { id: 'id-crosscheck', name: 'Crosscheck' },
    { id: 'id-aamva', name: 'Aamva' },
    { id: 'id-reverification', name: 'Reverification' },
    { id: 'id-aml', name: 'AML' },
    { id: 'private-ssn', name: 'SSN' },
    { id: 'ecbsv-ssn', name: 'ECBSV SSN' },
    { id: 'dob', name: 'DOB' }
  ],
  success: [
    { id: 'false', name: 'None' },
    { id: 'true', name: 'Only' }
  ],
  is_disabled: [
    { id: 'true', name: 'Disabled' },
    { id: 'false', name: 'Enabled' }
  ],
  sortBy: [
    { id: 'submitted', name: 'Created' },
    { id: 'updated', name: 'Updated' },
    { id: 'status', name: 'Status' }
  ],
  accountReviewed: [
    { id: 'false', name: 'None' },
    { id: 'true', name: 'Only' }
  ],
  status: [
    { id: 'active', name: 'Active' },
    { id: 'completed', name: 'Completed' },
    { id: 'removed', name: 'Removed' }
  ],
  sortOrder: [
    { id: 'desc', name: 'Desc' },
    { id: 'asc', name: 'Asc' }
  ],
  billableType: [
    { id: 'idv_billable', name: 'Idv' },
    { id: 'physical_address_billable', name: 'Physical Address' },
    { id: 'ip_address_billable', name: 'Ip Address' },
    { id: 'crosscheck_billable', name: 'Crosscheck' },
    { id: 'darkweb_billable', name: 'Darkweb' },
    { id: 'aamva_billable', name: 'Aamva' },
    { id: 'aml_billable', name: 'Aml' }
  ],
  billable: [
    { id: 'true', name: 'Billable' },
    { id: 'false', name: 'Not Billable' }
  ],
  aamvaSource: [
    { id: 'US', name: 'USA' },
    { id: 'CA', name: 'CANADA' }
  ],
  pageSize: [
    { id: 10, name: '10' },
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 250, name: '250' },
    { id: 500, name: '500' }
  ],
  amLJobs: [
    { id: 'sanction', name: 'Sanction' },
    { id: 'warning', name: 'Warning' },
    { id: 'pep', name: 'Politically Exposed Person (PEP)' },
    { id: 'all', name: 'All' }
  ]
};

export const humanIdType = (s: string | null | undefined): string | null => {
  if (!s) {
    return null;
  }

  return s
    .split('-')
    .map((m) => {
      return capitalize(m);
    })
    .join(' ');
};

export const humanPropertyName = {
  internal_id: 'Internal Id',
  'vouched.first_name': 'First Name',
  'vouched.last_name': 'Last Name',
  'vouched.email': 'Email',
  'vouched.phone': 'Phone'
};

interface TImageMapEntry {
  url: keyof JobRequestParameters;
  dimensions: keyof JobRequestParameters;
}

const ImageMap: Record<string, TImageMapEntry> = {
  id: {
    url: 'idPhotoUrl',
    dimensions: 'idPhotoDimensions'
  },
  user: {
    url: 'userPhotoUrl',
    dimensions: 'userPhotoDimensions'
  },
  backId: {
    url: 'backIdPhotoUrl',
    dimensions: 'backIdPhotoDimensions'
  },
  userDistance: {
    url: 'userDistancePhotoUrl',
    dimensions: 'userDistanceDimensions'
  },
  idDistance: {
    url: 'idDistancePhotoUrl',
    dimensions: 'idDistanceDimensions'
  },
  idDetect: {
    url: 'idPhotoDetectUrl',
    dimensions: 'idPhotoDetectDimensions'
  },
  userDetect: {
    url: 'userPhotoDetectUrl',
    dimensions: 'userPhotoDetectDimensions'
  },
  source: {
    url: 'sourceUrl',
    dimensions: 'sourceDimensions'
  },
  dest: {
    url: 'destUrl',
    dimensions: 'destDimensions'
  }
};

export const createImages = (
  parameters: JobRequestParameters,
  imageNames: string[]
): Image[] => {
  return imageNames
    .map((img) => {
      const subImg = ImageMap[img];
      if (!subImg) {
        return null;
      }

      const { url, dimensions } = subImg;
      if (parameters[url]) {
        const d = parameters[dimensions] ?? { width: 1024, height: 1024 };

        return { url: parameters[url], dimensions: d };
      }

      return null;
    })
    .filter(Boolean) as Image[];
};

export const colors = {
  red: '#FC1B4E',
  black: '##110341',
  blue: '#0D6EBA',
  lightBlue: '#87D0FF',
  neonGreen: '#4DD08B',
  green: '#4FA051',
  lightYellow: 'rgba(254, 196, 9, 0.4)',
  gray: 'rgba(0, 0, 0, 0.4)',
  lightGray: 'rgba(0, 0, 0, 0.1)',
  lighterGray: 'rgba(0, 0, 0, 0.05)'
};

export const cleanArray = (
  arrayVal: CrossCheckResultError[] | null | undefined,
  type: string
): (string | undefined)[] => {
  if (!arrayVal) {
    return [' '];
  }

  return arrayVal.map((x) => {
    if (type === 'warnings' && x.suggestion) {
      return `Name Match is below threshold. Suggestion - ${x.suggestion}`;
    }

    return x.message;
  });
};

const groupBy = (list: Insights[]) => {
  const map = new Map<string, string[]>();
  for (const item of list) {
    const key = item.category;
    const collection = map.get(key);
    if (collection) {
      collection.push(item.message);
    } else {
      map.set(key, [item.message]);
    }
  }

  return map;
};

export const getInsight = (
  signal: Insights[] | null
): Record<string, string[] | undefined>[] | null => {
  let signalCategories = signal?.map((x) => x.category) ?? [];

  signalCategories = signalCategories.filter((c, index) => {
    return signalCategories.indexOf(c) === index;
  });

  if (!signal) {
    return null;
  }

  const groupedVal = groupBy(signal);
  return signalCategories.map((x) => {
    const message = groupedVal.get(x);

    return { [x]: message };
  });
};

const isEnvProd = () => process.env['REACT_APP_NODE_ENV'] === 'production';

export const generateJobsLink = (isAdmin: boolean, jobId: string): string => {
  const jobAccount = isAdmin ? 'admin/jobs/' : 'account/jobs/';
  const hostUrl = isEnvProd()
    ? 'https://app.vouched.id/'
    : 'https://app.stage.vouched.id/';

  return jobId ? `${hostUrl}${jobAccount}${jobId}` : '';
};
