export enum Tooltips {
  ValidId = 'Determination of the validity of the ID based on proprietary fraud analyses. Invalid ID will result in a visual identity verification rejection.',
  ExpirationDate = 'The expiration date shown on the ID has expired which will result in warning.',
  ValidSelfie = 'Selfie photo is determined to be a real person. If determined to not be a real person, this will result in a visual identity verification rejection  If this is grayed out, this feature was not enabled.',
  IdMatchesSelfie = 'Selfie photo matches primary face photo on ID. A mismatch will result in a visual identity verification rejection.  If this is grayed out, then no selfie photo was provided.',
  IdMatchesBarcode = 'Data scanned from the Barcode matches data extracted from the front of the ID.  A mismatch will result in a visual identity verification rejection. If grayed out, this feature was not enabled.',
  IdMatchesSubmittedName = 'First and Last name provided matches first and last name extracted/scanned from ID. A mismatch will result in a visual identity verification rejection.  If grayed out, this feature was not enabled.',
  IdMatchesSubmittedDOB = 'Date of Birth provided matches Date of Birth extracted/scanned from ID. A mismatch will result in a visual identity verification rejection.  If grayed out, this feature was not enabled.',
  AddressValid = 'The address from the ID or the one provided is confirmed as real and complete, including street, city, state, and zip code, by checking against verified data sources. An incorrect or missing address will lower the Identity Score.',
  AddressMatch = 'Verifies if the name linked to the address on the ID or the one provided aligns with the name associated with that address against verified data sources. If names and addresses don’t align, the Identity Score will be affected.',
  PhoneNumberValid = 'Checks if the phone number provided is real and correctly formatted. An incorrect or missing phone number will lower the Identity Score.',
  PhoneNumberMatch = 'Checks if the phone number provided matches the name from the ID or the name provided. If the name and phone number don’t match, the Identity Score will be affected.',
  EmailValid = 'Checks if the email provided is real and uses a known email domain. An incorrect or missing email will affect the Identity Score.',
  EmailMatch = 'Checks if the name associated with the email provided matches the name on the ID or the name provided. If the email and name don’t match, the Identity Score will be affected.',
  IpValid = 'Checks if the IP address from the user’s device is real and from a public network. Private network IPs are seen as risky and affect the Identity Score.',
  IpDistance = 'Risk assessment based on the distance of the IP address from the device and the physical address (provided or extracted/scanned from ID). Significant distances are considered risky and will negatively impact the Crosscheck Identity Score.'
}
