import React from 'react';
import { JobCharts } from '../jobs/job-charts/job-charts';

export const Dashboard: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <JobCharts />
    </div>
  );
};
