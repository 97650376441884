// eslint-disable-next-line import/order
import { initDatadogRum } from 'datadog-rum';

initDatadogRum();

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppContext } from 'components/app/app-context';
import { AppRootWithProvider } from 'components/app/app-root';
import { AppRoutes } from 'components/app/app-routes';
import './index.css';

ReactDOM.render(
  <AppContext>
    <AppRootWithProvider>
      <AppRoutes />
    </AppRootWithProvider>
  </AppContext>,
  document.getElementById('root')
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
