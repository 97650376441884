import React from 'react';

export const FeatureNotEnabled: React.FC<{ copy: string[] }> = ({
  copy
}: {
  readonly copy: string[];
}) => {
  return (
    <div>
      <h1 className="not-enabled-header">Feature Not Enabled</h1>
      <div className="not-enabled-body">
        {copy.map((item) => {
          return (
            <p
              className="not-enabled-text"
              key={item}
              style={{ textAlign: 'center' }}
            >
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
};
