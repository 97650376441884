import React, { ReactElement } from 'react';
import {
  getAmlCountries,
  getAmlHitsCount,
  getAmlMatchedTypes,
  getAmlMatchlists,
  getAmlPoliticalPositions
} from '../../utils';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { JobResult } from 'models/jobs/jobs';
import { AMLJobResult } from 'store/sagas/sagas';
import { capitalize } from 'utils/format';
import { humanDate } from 'utils/ui.utils';

const getAmlSanctionListName = (
  sanctionList: SanctionDetails
): ReactElement | string => {
  if (!sanctionList.name) {
    return 'None';
  }

  if (sanctionList.url) {
    return (
      <a href={sanctionList.url} rel="noreferrer" target="_blank">
        {sanctionList.name}
      </a>
    );
  }

  return sanctionList.name;
};

interface SanctionRenderItem {
  name: string;
  customRenderType: React.ReactNode | (() => ReactElement);
  customRenderValue: React.ReactNode | (() => ReactElement);
}

interface SanctionDetails {
  dates: {
    start: string | null;
    end: string | null;
  };
  name?: string;
  url?: string;
}

interface SanctionRenderItemWithDetails extends SanctionRenderItem {
  sanctionDetails: SanctionDetails;
}

/**
 * Sorts sanctions items by end date then by start date
 */
const sortLists = (
  sanctionLists: SanctionRenderItemWithDetails[]
): SanctionRenderItemWithDetails[] => {
  return sanctionLists.sort((a, b) => {
    const { start: aStart, end: aEnd } = a.sanctionDetails.dates;
    const { start: bStart, end: bEnd } = b.sanctionDetails.dates;

    const aStartDate = new Date(aStart ?? 0);
    const aEndDate = new Date(aEnd ?? Number.POSITIVE_INFINITY);
    const bStartDate = new Date(bStart ?? 0);
    const bEndDate = new Date(bEnd ?? Number.POSITIVE_INFINITY);

    const endDateDiff = bEndDate.getTime() - aEndDate.getTime();
    if (endDateDiff !== 0) {
      return endDateDiff;
    }

    return bStartDate.getTime() - aStartDate.getTime();
  });
};

interface AmlProps {
  readonly result: JobResult;
  readonly detailedAml?: AMLJobResult;
}

export const Aml: React.FC<AmlProps> = ({ result, detailedAml }) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (!result.aml?.data || detailedAml?.type || !detailedAml?.data) {
    const amlCopy = [
      'Aml was not enabled for this job.',
      'Aml is an database of enhanced and consolidated data on people and entities in regards to regulatory sanctions and other official lists, Politically Exposed Persons (PEPs), Relatives and Close Associates (RCAs), Special Interest Persons (SIPs) who have been involved in a legal process in relation to defined criminal categories, and Sanctioned by Control and Ownership entities',
      'In order to enable Aml for your account, please contact sales.'
    ];

    return (
      <div>
        <h1 className="not-enabled-header">Feature Not Enabled</h1>
        <div className="not-enabled-body">
          {amlCopy.map((copy) => {
            return (
              <p className="not-enabled-text" key={copy}>
                {copy}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  const amlData = detailedAml.data;
  const hits = getAmlHitsCount(amlData);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const userName = amlData.submittedTerm || amlData.submitted_term;

  const items: SanctionRenderItem[] = [
    {
      name: 'Summary',
      customRenderType: (
        <div className="job-modal-ip">
          <Text strongest={false} value="Name" />
          <Text strongest={false} value="Hits" />
          <Text strongest={false} value="Matched Types" />
          <Text strongest={false} value="Countries" />
          <Text strongest={false} value="Year of Birth" />
          <Text strongest={false} value="AKA" />
        </div>
      ),
      customRenderValue: (
        <div className="job-modal-ip">
          <Text value={userName} />
          <Text htmlColor={hits && hits > 0 ? 'red' : 'default'} value={hits} />
          <Text
            htmlColor={hits && hits > 0 ? 'red' : 'default'}
            value={getAmlMatchedTypes(amlData)}
          />
          <Text value={getAmlCountries(amlData)} />
          <Text value={amlData.dob ?? 'None'} />
          <Text overflow={false} value={getAmlMatchlists(amlData)} wrap />
        </div>
      )
    },
    {
      name: 'Political Positions',
      customRenderType: (
        <div className="job-modal-ip">
          <Text strongest={false} value="" />
        </div>
      ),
      customRenderValue: (
        <div className="job-modal-ip">
          <Text
            overflow={false}
            value={getAmlPoliticalPositions(amlData)}
            wrap
          />
        </div>
      )
    },
    {
      name: 'Extracted Data',
      customRenderType: (
        <div className="job-modal-ip">
          <Text strongest={false} value="First Name" />
          <Text strongest={false} value="Last name" />
          <Text strongest={false} value="Address" />
        </div>
      ),
      customRenderValue: (
        <div className="job-modal-ip">
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Text value={result.firstName || 'N/A'} />
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Text value={result.lastName || 'N/A'} />
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Text value={result.address || result.unverifiedIdAddress || 'N/A'} />
        </div>
      )
    }
  ];

  if (amlData.hits?.matches && Object.keys(amlData.hits.matches).length > 0) {
    for (const listElements of Object.values(amlData.hits.matches)) {
      const additionalSanctions: SanctionRenderItemWithDetails[] =
        listElements.map((element) => {
          let start: string | null = null;
          let end: string | null = null;

          if (element.listing_started_utc) {
            start = humanDate(new Date(element.listing_started_utc), true);
          }

          if (element.listing_ended_utc) {
            end = humanDate(new Date(element.listing_ended_utc), true);
          }

          const sanctionDetails: SanctionDetails = {
            dates: {
              start,
              end
            },
            name: element.name,
            url: element.url
          };

          return {
            name: capitalize(element.type),
            sanctionDetails,
            customRenderType: (
              <div className="job-modal-ip">
                <Text strongest={false} value="Listed Name" />
                <Text strongest={false} value="Listed" />
                <Text strongest={false} value="Listing Ended" />
              </div>
            ),
            customRenderValue: (
              <div className="job-modal-ip">
                <Text value={getAmlSanctionListName(sanctionDetails)} />
                {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                <Text value={sanctionDetails.dates.start || 'N/A'} />
                {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                <Text value={sanctionDetails.dates.end || 'N/A'} />
              </div>
            )
          };
        });

      items.push(...sortLists(additionalSanctions));
    }
  }

  return (
    <List<SanctionRenderItem>
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'type',
          xs: 2,
          customRenderValue: (item) =>
            typeof item.customRenderType === 'function'
              ? (item.customRenderType as () => React.ReactNode)()
              : item.customRenderType
        },
        {
          name: 'value',
          xs: 8,
          customRenderValue: (item) =>
            typeof item.customRenderValue === 'function'
              ? (item.customRenderValue as () => React.ReactNode)()
              : item.customRenderValue
        }
      ]}
      items={items}
      page={1}
      showHeaders={false}
    />
  );
};
