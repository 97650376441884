import Link from '@material-ui/core/Link';
import React from 'react';
import { List } from 'components/reusable/list';
interface FilesProps {
  readonly jobId: string;
  readonly handleDownload: (id: string, confValue: boolean) => void;
}

export const Files: React.FC<FilesProps> = ({ jobId, handleDownload }) => {
  const resultMapper = [
    {
      label: 'ID1',
      value: (
        <div>
          <i
            aria-hidden="true"
            className="pointer fas fa-download"
            onClick={() => {
              handleDownload(jobId, true);
            }}
          />
          <Link
            onClick={() => {
              handleDownload(jobId, true);
            }}
          >
            Report
          </Link>
        </div>
      )
    },
    {
      label: 'ID2',
      value: (
        <div>
          <i
            aria-hidden="true"
            className="pointer fas fa-download"
            onClick={() => {
              handleDownload(jobId, false);
            }}
          />
          <Link
            onClick={() => {
              handleDownload(jobId, false);
            }}
          >
            NoConfReport
          </Link>
        </div>
      )
    }
  ];

  return (
    <List
      columns={[
        {
          name: 'Label',
          id: 'value',
          xs: 3
        }
      ]}
      items={resultMapper}
      page={1}
      showHeaders={false}
    />
  );
};
