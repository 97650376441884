import React from 'react';
import './support.css';

export const Support: React.FC = () => {
  return (
    <div className="support__container">
      <div className="support__body">
        <span>You can contact us by email: </span>
        <span>&nbsp;</span>
        <a href="mailto: support@vouched.id">support@vouched.id</a>
      </div>
    </div>
  );
};
