import React, { FC, ReactElement } from 'react';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { JobResult } from 'models/jobs/jobs';
import { cleanArray, humanIdType } from 'utils/jobs';
import { humanBoolean, roundNumber } from 'utils/ui.utils';

const humanAgeRange = (
  ageRange: { to: number | null; from: number | null } | null
): string | null => {
  if (!ageRange) {
    return null;
  }

  return `${ageRange.from}-${ageRange.to}`;
};

interface RenderItem {
  name: ReactElement | string;
  customRenderType: ReactElement;
  customRenderValue: ReactElement;
}

export const Crosscheck: FC<{ readonly result: JobResult }> = ({ result }) => {
  const {
    crosscheck,
    firstName,
    lastName,
    unverifiedIdAddress,
    address: resultAddress
  } = result;

  if (!crosscheck) {
    const crosscheckCopy = [
      'Crosscheck was not enabled for this job.',
      "Crosscheck is an database of personal information associated with a person's identifiable information. Crosscheck matches data to IDs that are associated with the ID but not necessarily present in the ID.",
      'In order to enable Crosscheck for your account, please contact sales.'
    ];

    return (
      <div>
        <h1 className="not-enabled-header">Feature Not Enabled</h1>
        <div className="not-enabled-body">
          {crosscheckCopy.map((copy) => {
            return (
              <p className="not-enabled-text" key={copy}>
                {copy}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  const { address, email, phone, darkWeb, ageRange, confidences } = crosscheck;
  const errorsAddress = cleanArray(address?.errors, 'error');
  const warningsAddress = cleanArray(address?.warnings, 'warnings');
  const errorsEmail = cleanArray(email?.errors, 'error');
  const warningsEmail = cleanArray(email?.warnings, 'warnings');
  const errorsPhone = cleanArray(phone?.errors, 'error');
  const warningsPhone = cleanArray(phone?.warnings, 'warnings');
  const items = [
    {
      name: (
        <a
          href="https://docs.vouched.id/docs/crosscheck-2"
          rel="noreferrer"
          target="_blank"
        >
          <span>Summary</span>
        </a>
      ),
      customRenderType: (
        <>
          <Text strongest={false} value="Age" />
          <Text strongest={false} value="Identity Score" />
          <Text strongest={false} value="Activity Score" />
        </>
      ),
      customRenderValue: (
        <>
          <Text value={humanAgeRange(ageRange)} />
          <Text value={roundNumber(confidences.identity)} />
          <Text value={roundNumber(confidences.activity)} />
        </>
      )
    },
    {
      name: 'Extracted Data',
      customRenderType: (
        <>
          <Text strongest={false} value="First Name" />
          <Text strongest={false} value="Last Name" />
          <Text strongest={false} value="Address" />
        </>
      ),
      customRenderValue: (
        <>
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Text value={firstName || 'N/A'} />
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Text value={lastName || 'N/A'} />
          {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
          <Text value={resultAddress || unverifiedIdAddress || 'N/A'} />
        </>
      )
    },
    {
      name: 'Address',
      customRenderType: (
        <>
          <Text strongest={false} value="Match" />
          <Text strongest={false} value="Valid" />
          <Text strongest={false} value="Name" />
          <Text strongest={false} value="Type" />
          <Text strongest={false} value="Forwarder" />
          <Text strongest={false} value="Commercial" />
          <Text strongest={false} value="Warnings" />
          <p>&nbsp;</p>
          <Text strongest={false} value="Errors" />
        </>
      ),
      customRenderValue: (
        <>
          <Text value={humanBoolean(address?.isMatch)} />
          <Text value={humanBoolean(address?.isValid)} />
          <Text value={address?.name} />
          <Text value={humanIdType(address?.type)} />
          <Text value={humanBoolean(address?.isForwarder)} />
          <Text value={humanBoolean(address?.isCommercial)} />
          <Text overflow={false} value={warningsAddress.toString()} wrap />
          <p>&nbsp;</p>
          {errorsAddress.map((m) => (
            <div key={m}>
              {' '}
              <Text value={m} />{' '}
            </div>
          ))}
        </>
      )
    },
    {
      name: 'Email',
      customRenderType: (
        <>
          <Text strongest={false} value="Match" />
          <Text strongest={false} value="Valid" />
          <Text strongest={false} value="Name" />
          <Text strongest={false} value="Days First Seen" />
          <Text strongest={false} value="Email Domain Creation Date" />
          <Text strongest={false} value="AutoGenerated" />
          <Text strongest={false} value="Disposable" />
          <Text strongest={false} value="Warnings" />
          <p>&nbsp;</p>
          <Text strongest={false} value="Errors" />
        </>
      ),
      customRenderValue: (
        <>
          <Text value={humanBoolean(email?.isMatch)} />
          <Text value={humanBoolean(email?.isValid)} />
          <Text value={email?.name} />
          <Text value={email?.daysFirstSeen} />
          <Text value={email?.emailDomainCreationDate} />
          <Text value={humanBoolean(email?.isAutoGenerated)} />
          <Text value={humanBoolean(email?.isDisposable)} />
          <Text overflow={false} value={warningsEmail.toString()} wrap />
          <p>&nbsp;</p>
          {errorsEmail.map((m) => (
            <div key={m}>
              {' '}
              <Text value={m} />{' '}
            </div>
          ))}
        </>
      )
    },
    {
      name: 'Phone',
      customRenderType: (
        <>
          <Text strongest={false} value="Match" />
          <Text strongest={false} value="Valid" />
          <Text strongest={false} value="Name" />
          <Text strongest={false} value="Type" />
          <Text strongest={false} value="Carrier" />
          <Text strongest={false} value="Last Seen Days" />
          <Text strongest={false} value="Prepaid" />
          <Text strongest={false} value="Disposable" />
          <Text strongest={false} value="Commercial" />
          <Text strongest={false} value="Warnings" />
          <p>&nbsp;</p>
          <Text strongest={false} value="Errors" />
        </>
      ),
      customRenderValue: (
        <>
          <Text value={humanBoolean(phone?.isMatch)} />
          <Text value={humanBoolean(phone?.isValid)} />
          <Text value={phone?.name} />
          <Text value={humanIdType(phone?.type)} />
          <Text value={phone?.carrier} />
          <Text value={phone?.lastSeenDays} />
          <Text value={humanBoolean(phone?.isPrepaid)} />
          <Text value={humanBoolean(phone?.isDisposable)} />
          <Text value={humanBoolean(phone?.isCommercial)} />
          <Text overflow={false} value={warningsPhone.toString()} wrap />
          <p>&nbsp;</p>
          {errorsPhone.map((m) => (
            <div key={m}>
              {' '}
              <Text value={m} />{' '}
            </div>
          ))}
        </>
      )
    },
    darkWeb
      ? {
          name: 'Dark Web',
          customRenderType: (
            <>
              <Text strongest={false} value="Criminal Count" />
              <Text strongest={false} value="Criminal Last Seen" />
              <Text strongest={false} value="Criminal Max Score" />
            </>
          ),
          customRenderValue: (
            <>
              <Text value={darkWeb.criminalCount} />
              <Text value={darkWeb.criminalLastSeen} />
              <Text value={darkWeb.criminalMaxScore} />
            </>
          )
        }
      : null
  ].filter((v) => !!v) as RenderItem[];

  return (
    <List<RenderItem>
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'type',
          xs: 3,
          id: 'customRenderType'
        },
        {
          name: 'value',
          xs: 7,
          id: 'customRenderValue'
        }
      ]}
      items={items}
      page={1}
      showHeaders={false}
    />
  );
};
