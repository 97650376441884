import { Button, Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { isURL } from '../../../utils/url';
import { ReusableSelect as Select } from 'components/reusable/select';
import { SelectEvent } from 'components/reusable/select/select';
import { CompanyAccount } from 'models/company-account/company-account.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { adminCreateAccount, getAccounts } from 'store/sagas/sagas';

const validateFormBeforeSubmit = function validateFormBeforeSubmit(
  form: { url?: string },
  fnMessage: (msg: string) => void
): boolean {
  const { url = '' } = form;

  // check if url value is a valid value
  if (url && !isURL(url)) {
    fnMessage(`the URL format is incorrect`);
    return false;
  }

  return true;
};

export const AccountCreateForm: React.FC = () => {
  const { state, asyncDispatch, dispatch } = useGlobalState();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [url, setUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [setupAccountStep, setSetupAccountStep] = useState('');
  const [setupStatus, setSetupStatus] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [industry, setIndustry] = useState('');

  const [companySize, setCompanySize] = useState('');
  const [lastUsageRecordUpdate, setLastUsageRecordUpdate] = useState('');
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [parentId, setParentId] = useState(undefined as number | undefined);
  const [parentAccounts, setParentAccounts] = useState<CompanyAccount[] | null>(
    null
  );

  const [name, setName] = useState('');
  const [invalidData, setInvalidData] = useState(false);

  useEffect(() => {
    getAccounts(state.accessToken, {
      isDisabled: 'false',
      parents: 'true'
    }).then(({ accounts }) => {
      setParentAccounts(accounts);
    }, console.error);
  }, []);

  const createAccount = () => {
    const data = {
      firstName,
      lastName,
      name,
      url,
      email,
      phoneNumber,
      setupAccountStep,
      setupStatus,
      companySize,
      city,
      province,
      country,
      industry,
      lastUsageRecordUpdate,
      stripeCustomerId,
      subscriptionId,
      subscriptionTier,
      parentId
    };

    const isValid = validateFormBeforeSubmit(data, (msg) => {
      dispatch(
        setMessages({
          value: msg,
          severity: 'warning'
        })
      );
    });

    if (!isValid) return;
    // overwrite url param
    if (!data.url) {
      data.url = 'https://www.vouched.id/';
    }

    asyncDispatch(adminCreateAccount(data))
      .then(() => {
        dispatch(
          setMessages({
            value: `The account has been created`,
            severity: 'success'
          })
        );
      })
      .catch(console.error);
  };

  const handleName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setInvalidData(false);
  }, []);

  const handleEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setInvalidData(false);
  }, []);

  const handleLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setInvalidData(false);
  }, []);

  const handleFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setInvalidData(false);
  }, []);

  const handleUrl = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
    setInvalidData(false);
  }, []);

  const handlePhoneNumber = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    setInvalidData(false);
  }, []);

  const handleSetupAccountStep = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSetupAccountStep(e.target.value);
      setInvalidData(false);
    },
    []
  );

  const handleSetupStatus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSetupStatus(e.target.value);
    setInvalidData(false);
  }, []);

  const handleCountry = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCountry(e.target.value);
    setInvalidData(false);
  }, []);

  const handleCity = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
    setInvalidData(false);
  }, []);

  const handleProvince = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setProvince(e.target.value);
    setInvalidData(false);
  }, []);

  const handleCompanySize = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCompanySize(e.target.value);
    setInvalidData(false);
  }, []);

  const handleStripeCustomerId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setStripeCustomerId(e.target.value);
      setInvalidData(false);
    },
    []
  );

  const handleSubscriptionId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSubscriptionId(e.target.value);
      setInvalidData(false);
    },
    []
  );

  const handleSubscriptionTier = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSubscriptionTier(e.target.value);
      setInvalidData(false);
    },
    []
  );

  const handleIndustry = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIndustry(e.target.value);
    setInvalidData(false);
  }, []);

  const handleLastUsageRecordUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLastUsageRecordUpdate(e.target.value);
      setInvalidData(false);
    },
    []
  );

  const handleParentAccount = useCallback((e: SelectEvent<number>) => {
    setParentId(e.target.value);
    setInvalidData(false);
  }, []);

  return (
    <div>
      <h1>Create Account</h1>
      <div className="filters-container">
        <Grid container>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={6}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Email"
                onChange={handleEmail}
                required
                value={email}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="First name"
                onChange={handleFirstName}
                value={firstName}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Last name"
                onChange={handleLastName}
                value={lastName}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Name"
                onChange={handleName}
                required
                value={name}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="URL"
                onChange={handleUrl}
                required
                value={url}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Phone Number"
                onChange={handlePhoneNumber}
                value={phoneNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Setup Account Step"
                onChange={handleSetupAccountStep}
                value={setupAccountStep}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={3} xs={12}>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Setup Status"
                onChange={handleSetupStatus}
                value={setupStatus}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="City"
                onChange={handleCity}
                value={city}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Country"
                onChange={handleCountry}
                value={country}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Province"
                onChange={handleProvince}
                value={province}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={3} xs={12}>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Industry"
                onChange={handleIndustry}
                value={industry}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Last Usage Record Update"
                onChange={handleLastUsageRecordUpdate}
                value={lastUsageRecordUpdate}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Company Size"
                onChange={handleCompanySize}
                value={companySize}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={3} xs={12}>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Stripe Customer ID"
                onChange={handleStripeCustomerId}
                required
                value={stripeCustomerId}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Subscription ID"
                onChange={handleSubscriptionId}
                required
                value={subscriptionId}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="g-full-width u-margin-bottom-small"
                error={invalidData}
                label="Subscription Tier"
                onChange={handleSubscriptionTier}
                value={subscriptionTier}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            {parentAccounts ? (
              <Select
                className="select-group"
                items={parentAccounts.map((a) => ({
                  id: a.id,
                  name: a.name
                }))}
                label="Parent Account"
                name="pageSize"
                onChange={handleParentAccount}
                value={parentId}
              />
            ) : null}
          </Grid>
        </Grid>

        <Button onClick={createAccount} style={{ marginTop: '15px' }}>
          Create
        </Button>
      </div>
    </div>
  );
};
