import React, { FC, ReactElement } from 'react';
import { errorChecked, warningChecked } from '../../utils';
import { Tick } from 'components/reusable/tick';
import { Job, JobError, JobUpdateReview } from 'models/jobs/jobs';

export interface JobCheckItem {
  check?: ReactElement;
  questionType?: string | null;
  reviewType?: keyof JobUpdateReview | null;
  label?: string;
}

export const JobCheck: FC<{
  readonly item: JobCheckItem;
  readonly errors: JobError[];
  readonly job: Job;
}> = ({ item, errors, job }) => {
  if (item.check) return item.check;
  if (!item.questionType) return null;

  let state: 'active' | 'approved' | 'notApplicable' | 'rejected' | 'warning' =
    'active';

  state = 'approved';
  if (
    errorChecked({
      errors,
      type: item.questionType
    })
  ) {
    state = 'rejected';
  } else if (
    warningChecked({
      errors,
      type: item.questionType
    })
  ) {
    state = 'warning';
  }

  // includeBackId only - pass and fail (1 of 3)
  if (
    item.label === 'Barcode Match' &&
    !job.result?.clientOutput?.includeBarcode
  ) {
    state = 'notApplicable';
  }

  const newLocal = item.reviewType && job.review?.[item.reviewType] && (
    <div>
      <Tick state={job.review[item.reviewType] ? 'approved' : 'rejected'} />
    </div>
  );

  return (
    <>
      {newLocal}
      {job.status === 'active' ? null : (
        <div>
          <Tick state={state} />
        </div>
      )}
    </>
  );
};
