import { Text } from '@vouched-id/vault';
import React, { FC } from 'react';
import { JobType } from 'components/account/jobs/job_type';

interface SSNCaptionProps {
  readonly type: JobType.ecbsvSSN | JobType.privateSSN;
}

export const SSNCaption: FC<SSNCaptionProps> = ({ type }) => {
  switch (type) {
    case JobType.ecbsvSSN:
      return (
        <Text style={{ marginBottom: '15px' }}>
          SSN - ECBSV SSN Verification
        </Text>
      );
    case JobType.privateSSN:
      return (
        <Text style={{ marginBottom: '15px' }}>
          SSN - Private SSN Verification
        </Text>
      );
  }
};
