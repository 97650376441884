import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { FC } from 'react';

export interface MessageProps {
  value: string | null;
  severity: 'error' | 'info' | 'success' | 'warning' | undefined;
  close?: boolean | null;
}

export const Message: FC<{
  readonly handleClose?: () => void;
  readonly severity?: 'error' | 'info' | 'success' | 'warning' | undefined;
  readonly value: string | null;
  readonly open: boolean;
}> = ({ value, severity, handleClose, open }) => {
  return (
    <Snackbar
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6 * 1000}
      onClose={handleClose}
      open={open}
    >
      <MuiAlert
        elevation={6}
        onClose={handleClose}
        severity={severity}
        variant="filled"
      >
        {value}
      </MuiAlert>
    </Snackbar>
  );
};
