import { TextField } from '@material-ui/core';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { setAuthData, setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { twoFactorLogin } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { humanDate } from 'utils/ui.utils';

export const TwoFactor: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();

  const { otpImage } = state;
  const { otpToken } = state;
  const [userToken, setUserToken] = useState<string | null>('');

  const updateUserToken = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserToken(e.target.value);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (userToken) {
      setIsLoading(true);
      void asyncDispatch(twoFactorLogin({ userToken, otpToken }))
        .then((data) => {
          setIsLoading(false);
          if (data.user.loginAt) {
            dispatch(
              setMessages({
                severity: 'info',
                value: `Last login at ${humanDate(data.user.loginAt)!}`
              })
            );
          }

          dispatch(setAuthData(data));

          history.push('/account/jobs');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="u-align-center">
      {/* <style jsx>*/}
      <style>
        {`
          img {
            max-width: 500px;
          }
          li a {
            margin-left: 0.65em;
          }
        `}
      </style>
      <div className="g-page-without-header-footer">
        <h1>2FA Authentication</h1>
        {otpImage ? (
          <div>
            <div>
              <ol>
                <li>
                  Install Authy
                  <a
                    href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_US"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Android
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/authy/id494168017"
                    rel="noreferrer"
                    target="_blank"
                  >
                    iOS
                  </a>
                </li>
                <li>Add Account</li>
                <li>Scan barcode</li>
                <li>Enter the 6 digit token</li>
              </ol>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <ol>
                <li>
                  Go to the Authy App
                  <a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_US">
                    Android
                  </a>
                  <a href="https://apps.apple.com/us/app/authy/id494168017">
                    iOS
                  </a>
                </li>
                <li>Enter the 6 digit token</li>
              </ol>
            </div>
          </div>
        )}
        <form className="g-form" onSubmit={onSubmit}>
          <div>{otpImage ? <img src={otpImage} /> : null}</div>
          <TextField
            className="g-input-height u-margin-bottom-medium"
            label="Token"
            onChange={updateUserToken}
            required
          />
          <span className="u-margin-bottom-large u-text-align-right">
            If you do not have access to your two factor auth please contact
            your organization admin or our{' '}
            <a href="mailto:support@vouched.id">support</a>.
          </span>
          <ButtonWithLoader
            disabled={false}
            isLoading={isLoading}
            name="Sign in"
          />
        </form>
      </div>
    </div>
  );
};
