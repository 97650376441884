import './manage-subscription.css';

import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { setCurrentSubscription, setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  cancelSubscription,
  getCurrentSubscriptionObject,
  reactivateSubscription
} from 'store/sagas/sagas';

export const ManageSubscription: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();

  useEffect(() => {
    if (!state.currentSubscription) {
      asyncDispatch(getCurrentSubscriptionObject())
        .then((data) => {
          dispatch(setCurrentSubscription(data));
        })
        .catch(console.error);
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const submitCancelSubscription = (event: React.UIEvent) => {
    event.preventDefault();
    setIsLoading(true);
    asyncDispatch(cancelSubscription())
      .then(async () => asyncDispatch(getCurrentSubscriptionObject()))
      .then((data) => {
        dispatch(setCurrentSubscription(data));
        dispatch(
          setMessages({
            severity: 'success',
            value: `Your subscription has been canceled`
          })
        );
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitReactivateSubscription = (event: React.UIEvent) => {
    event.preventDefault();
    setIsLoading(true);
    asyncDispatch(reactivateSubscription())
      .then(async () => {
        return asyncDispatch(getCurrentSubscriptionObject());
      })
      .then((data) => {
        dispatch(setCurrentSubscription(data));
        dispatch(
          setMessages({
            severity: 'success',
            value: `Subscription Reactivated!`
          })
        );
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="manage-subscription-main-div">
      <div className="manage-subscription-container">
        <div>
          <h1>Manage Subscription</h1>
          {state.currentSubscription ? (
            state.account?.subscriptionTier ? (
              state.currentSubscription.status === 'canceled' ? (
                <div>
                  <p>
                    Your subscription is currently <b>inactive</b>
                  </p>
                  <p>
                    Since your subscription is inactive, you can view/download
                    jobs but you cannot run new jobs in live mode
                  </p>
                  <p>
                    In order to run new jobs, you need an active subscription.
                    You can reactivate your subscription at any time.
                  </p>
                  <p>
                    When you reactivate your subscription, your billing cycle
                    will be reset to the instant you click "Reactivate" and you
                    will be immediately charged for the monthly fee associated
                    with your subscription
                  </p>
                  {isLoading ? (
                    <CircularProgress
                      size="1.2rem"
                      style={{ color: '#290e88' }}
                    />
                  ) : (
                    <Button onClick={submitReactivateSubscription}>
                      Reactivate
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <p>
                    Your subscription is currently <b>active</b>.
                  </p>

                  <p>
                    You can cancel your subscription at any time. When your
                    subscription is not active, you will still be able to view
                    and download jobs that have already been created but you
                    will not be able to create new jobs in live mode.
                  </p>
                  <p>
                    Please note that if you cancel your subscription, you will
                    be sent one final bill for any billable jobs you've created
                    since your last billing statement.
                  </p>
                  {isLoading ? (
                    <CircularProgress
                      size="1.2rem"
                      style={{ color: '#290e88' }}
                    />
                  ) : (
                    <Button onClick={submitCancelSubscription}>Cancel</Button>
                  )}
                </div>
              )
            ) : (
              <div>
                <p>
                  Your account's subscription is managed by the Vouched team.
                  Reach out to <a href="mailto:support@vouched.id">support</a>{' '}
                  in order to cancel your subscription.
                </p>
              </div>
            )
          ) : (
            <div>Your account does not have a subscription</div>
          )}
        </div>
      </div>
    </div>
  );
};
