import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { UpdateAccountInfo } from './update-account-info';

import './update-account-info-page.css';

const stripePromise = loadStripe(process.env['REACT_APP_STRIPE_PK'] ?? '');

export const UpdateAccountInfoPage: React.FC = () => {
  return (
    <div className="full-page">
      <Elements stripe={stripePromise}>
        <UpdateAccountInfo />
      </Elements>
    </div>
  );
};
