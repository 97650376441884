import { Location } from 'history';
import React, { ComponentType, ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useGlobalState } from 'store/reducers/reducer';

interface Props<T> {
  readonly path: string;
  readonly component: ComponentType<T>;
  readonly componentProps?: T;
  readonly location?: Location;
  readonly exact?: boolean;
  readonly waitForAccount?: boolean;
}

export const PrivateRoute = <T,>({
  component: Component,
  componentProps,
  waitForAccount,
  ...rest
}: Props<T>): ReactElement => {
  const { state } = useGlobalState();
  const { account } = state;
  const { user } = state;

  let needsRedirect = false;
  let redirectPath = '';

  if (account) {
    if (
      account.setupStatus === 'UNVERIFIED_EMAIL' &&
      rest.location!.pathname !== '/account/needs-verification'
    ) {
      needsRedirect = true;
      redirectPath = '/account/needs-verification';
    } else if (account.setupStatus === 'INCOMPLETE_ACCOUNT_INFO') {
      needsRedirect = true;
      redirectPath = '/account/update-account-info';
    } else if (account.setupStatus === 'COMPLETED') {
      needsRedirect = false;
    }
  }

  if (
    redirectPath === rest.location!.pathname ||
    (user && (user.isAdmin || user.isSupport))
  ) {
    needsRedirect = false;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        needsRedirect ? (
          <Redirect
            to={{ pathname: redirectPath, state: { from: props.location } }}
          />
        ) : !!waitForAccount && !account ? null : (
          <Component {...(props as T)} {...componentProps} />
        )
      }
    />
  );
};
