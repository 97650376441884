import React from 'react';
import { EcbsvSSN } from './ecbsv_ssn';
import { PrivateSSN } from './private_ssn/private_ssn';
import { FeatureNotEnabled } from 'components/account/jobs/feature_not_enabled';
import { JobType } from 'components/account/jobs/job_type';
import { Job } from 'models/jobs/jobs';

interface SSNProps {
  readonly job: Job;
}

export const Ssn: React.FC<SSNProps> = ({ job }) => {
  const SSNCopy = ['The SSN job type associated with this jobId is invalid'];

  switch (job.request.type) {
    case JobType.ecbsvSSN:
      return <EcbsvSSN job={job} />;
    case JobType.privateSSN:
      return <PrivateSSN job={job} />;
    default:
      return <FeatureNotEnabled copy={SSNCopy} />;
  }
};
