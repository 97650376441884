import { Box, Text } from '@vouched-id/vault';
import React from 'react';

interface SubmittedIdDataProps {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly dob?: string;
  readonly email?: string;
  readonly phone?: string;
}

export const SubmittedIdData: React.FC<SubmittedIdDataProps> = ({
  firstName,
  lastName,
  dob,
  email,
  phone
}) => {
  return (
    <Box
      display="grid"
      style={{
        gridTemplateColumns: '1fr 1fr',
        gap: '15px',
        justifyItems: 'start',
        alignItems: 'start'
      }}
    >
      <Text>First Name</Text>
      <Text style={{ fontWeight: '600' }}>{firstName ?? 'Not Submitted'}</Text>
      <Text>Last Name</Text>
      <Text style={{ fontWeight: '600' }}>{lastName ?? 'Not Submitted'}</Text>
      <Text>Birth Date</Text>
      <Text style={{ fontWeight: '600' }}>{dob ?? 'Not Submitted'}</Text>
      <Text>Email</Text>
      <Text style={{ fontWeight: '600' }}>{email ?? 'Not Submitted'}</Text>
      <Text>Phone</Text>
      <Text style={{ fontWeight: '600' }}>{phone ?? 'Not Submitted'}</Text>
    </Box>
  );
};
