import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import qs from 'qs';

const apiRoot = `${process.env['REACT_APP_ONBOARD_API']!}/api`;
const sandboxApiServiceRoot = process.env['REACT_APP_SANDBOX_APP_API']!;

export async function http<T>({
  method,
  url,
  data,
  accessToken,
  params,
  apiService,
  headers,
  isSandbox = false
}: {
  method: AxiosRequestConfig['method'];
  url?: string;
  data?: object;
  accessToken?: string;
  params?: string[] | string;
  apiService?: string;
  headers?: Record<string, string>;
  isSandbox?: boolean | null | undefined;
}): Promise<T> {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      ...headers
    },
    method,
    url: apiService ?? apiRoot + url!,
    withCredentials: apiService?.includes('/admin'),
    params,
    paramsSerializer(p) {
      return qs.stringify(p, { arrayFormat: 'repeat' });
    },
    data
  };

  if (accessToken)
    (config.headers as RawAxiosRequestHeaders).Authorization = accessToken;
  if (isSandbox && config.url && apiService) {
    try {
      const sandbox = new URL(sandboxApiServiceRoot);
      const oldUrl = config.url;
      const newUrl = new URL(oldUrl);

      newUrl.hostname = sandbox.hostname;
      newUrl.port = sandbox.port;

      config.url = newUrl.href;
    } catch (error) {
      console.error(error);
    }
  }

  const result = await axios.request<T>(config);
  return result.data;
}
