import { Link } from '@material-ui/core';
import React, { FC } from 'react';
import { Job } from 'models/jobs/jobs';

export const SecondaryPhotos: FC<{ readonly job: Job }> = ({ job }) => {
  if (!job.secondaryPhotos) {
    return null;
  }

  return (
    <>
      {job.secondaryPhotos.map((p) => (
        <div key={p.name}>
          <i
            aria-hidden="true"
            className="pointer fas fa-image u-margin-right-medium"
          />
          <Link href={p.url} target="_blank">
            {p.name}
          </Link>
        </div>
      ))}
    </>
  );
};
