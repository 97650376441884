import React from 'react';

export const CameraIcon: React.FC = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 5.25H16.9012L15.6234 3.33375C15.555 3.23114 15.4623 3.147 15.3535 3.08879C15.2448 3.03057 15.1233 3.00007 15 3H9C8.87665 3.00007 8.75522 3.03057 8.64648 3.08879C8.53773 3.147 8.44502 3.23114 8.37656 3.33375L7.09781 5.25H4.5C3.90326 5.25 3.33097 5.48705 2.90901 5.90901C2.48705 6.33097 2.25 6.90326 2.25 7.5V18C2.25 18.5967 2.48705 19.169 2.90901 19.591C3.33097 20.0129 3.90326 20.25 4.5 20.25H19.5C20.0967 20.25 20.669 20.0129 21.091 19.591C21.5129 19.169 21.75 18.5967 21.75 18V7.5C21.75 6.90326 21.5129 6.33097 21.091 5.90901C20.669 5.48705 20.0967 5.25 19.5 5.25ZM15.375 12.375C15.375 13.0425 15.1771 13.695 14.8062 14.25C14.4354 14.8051 13.9083 15.2376 13.2916 15.4931C12.6749 15.7485 11.9963 15.8154 11.3416 15.6852C10.6869 15.5549 10.0855 15.2335 9.61351 14.7615C9.14151 14.2895 8.82007 13.6881 8.68985 13.0334C8.55962 12.3787 8.62646 11.7001 8.88191 11.0834C9.13735 10.4667 9.56993 9.93964 10.125 9.56879C10.68 9.19794 11.3325 9 12 9C12.8951 9 13.7535 9.35558 14.3865 9.98851C15.0194 10.6214 15.375 11.4799 15.375 12.375Z"
      fill="#424242"
    />
  </svg>
);
