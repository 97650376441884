export enum IdTemplateType {
  DriversLicense = 'drivers-license',
  Passport = 'passport',
  Handgun = 'handgun',
  Identification = 'identification',
  DriversLicensePermit = 'drivers-license-permit',
  CommercialLicense = 'commercial-license',
  Residence = 'residence',
  Indian = 'indian',
  GlobalEntry = 'global-entry',
  HealthInsurance = 'health-insurance',
  AetnaInsurance = 'aetna-insurance',
  CvsRx = 'cvs-rx',
  UhcInsurance = 'uhc-insurance',
  CignaInsurance = 'cigna-insurance',
  PassportCard = 'passport-card',
  Other = 'other',

  Citizen = 'citizen',
  Nexus = 'nexus',
  Visa = 'visa',
  Employment = 'employment'
}

export const idTemplateTypes = Object.values(IdTemplateType);

// eslint-disable-next-line import/no-unused-modules
export enum ExtractionFieldName {
  Optional12 = '__optional__12.__',
  Optional2 = '__optional__2.__',
  Optional4b = '__optional__4b__',
  OptionalAlphabets = '__optional__alphabets__',
  OptionalAlphanumeric = '__optional__alphanumeric__',
  OptionalDdlasthalf = '__optional__ddlasthalf__',
  OptionalDobmonth_ = '__optional__dob__month__',
  OptionalDobyear_ = '__optional__dob__year__',
  OptionalDONOR = '__optional__DONOR__',
  OptionalEXACT = '__optional__EXACT__',
  OptionalMonth = '__optional__month__',
  OptionalNOT = '__optional__NOT__',
  OptionalNumber = '__optional__number__',
  OptionalNumber1 = '__optional__number1__',
  OptionalNumber2 = '__optional__number2__',
  OptionalOriginal_ = '__optional__original_',
  OptionalYear = '__optional__year__',
  Optional1Name = '__optional_1_name__',
  Optional8Address = '__optional_8_address__',
  Address = 'address',
  Address1 = 'address1',
  Affiliation1 = 'affiliation1',
  AHCD = 'AHCD',
  Audit = 'audit',
  BloodType = 'blood_type',
  CdlRestrictions = 'cdl_restrictions',
  Cheveux = 'cheveux',
  CINo = 'CI_no',
  Class = 'class',
  ClientNo = 'client_no',
  Copies = 'copies',
  CountryCode = 'country_code',
  CountryOfBirth = 'country_of_birth',
  Cpf = 'cpf',
  CUIL = 'CUIL',
  CURP = 'CURP',
  DateOfRegistration = 'date_of_registration',
  Dd = 'dd',
  DdContd = 'dd_contd',
  Dob = 'dob',
  Dob2 = 'dob2',
  Donor = 'donor',
  Dups = 'dups',
  EarliestExam = 'earliest_exam',
  Endorsements = 'endorsements',
  Expires = 'expires',
  Eyes = 'eyes',
  FamilyName = 'family_name',
  FatherName = 'father_name',
  FilingDate = 'filing_date',
  FirstLicensed = 'first_licensed',
  FirstName = 'first_name',
  FirstName1 = 'first_name1',
  FirstName2 = 'first_name2',
  Flag = 'flag',
  Gender = 'gender',
  Hair = 'hair',
  Header = 'header',
  Height = 'height',
  HoldersSignature = "holder's signature",
  Hologram = 'hologram',
  IdCardNumber = 'id_card_number',
  IdNumber = 'id_number',
  IdNumber2 = 'id_number2',
  Issued = 'issued',
  IssuingAuthority = 'issuing_authority',
  LastName = 'last_name',
  LastName1 = 'last_name1',
  LastName2 = 'last_name2',
  Logo = 'logo',
  Logo2 = 'logo2',
  MaritalStatus = 'marital_status',
  Name = 'name ',
  NameAddress = 'name_address',
  Nationality = 'nationality',
  Occupation = 'occupation',
  Office = 'office',
  Parish = 'parish',
  PersonalNumber = 'personal_number',
  PlaceOfBirth = 'place_of_birth',
  PlaceOfIssuance = 'place_of_issuance',
  Remarks = 'remarks',
  Restrictions = 'restrictions',
  Revision = 'revision',
  RfcNumber = 'rfc_number',
  Seal = 'seal',
  Sim = 'sim',
  Status = 'status',
  Type = 'type',
  Type2 = 'type2',
  Under18Until = 'under_18_until',
  Under21Until = 'under_21_until',
  Weight = 'weight',
  Yeux = 'yeux'
}

// eslint-disable-next-line import/no-unused-modules
export const extractionFieldNames = Object.values(ExtractionFieldName);

export enum Country {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CS = 'CS',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export const countryNames: Record<Country, string> = {
  [Country.AD]: 'AndorrA',
  [Country.AE]: 'United Arab Emirates',
  [Country.AF]: 'Afghanistan',
  [Country.AG]: 'Antigua and Barbuda',
  [Country.AI]: 'Anguilla',
  [Country.AL]: 'Albania',
  [Country.AM]: 'Armenia',
  [Country.AN]: 'Netherlands Antilles',
  [Country.AO]: 'Angola',
  [Country.AQ]: 'Antarctica',
  [Country.AR]: 'Argentina',
  [Country.AS]: 'American Samoa',
  [Country.AT]: 'Austria',
  [Country.AU]: 'Australia',
  [Country.AW]: 'Aruba',
  [Country.AX]: 'Åland Islands',
  [Country.AZ]: 'Azerbaijan',
  [Country.BA]: 'Bosnia and Herzegovina',
  [Country.BB]: 'Barbados',
  [Country.BD]: 'Bangladesh',
  [Country.BE]: 'Belgium',
  [Country.BF]: 'Burkina Faso',
  [Country.BG]: 'Bulgaria',
  [Country.BH]: 'Bahrain',
  [Country.BI]: 'Burundi',
  [Country.BJ]: 'Benin',
  [Country.BM]: 'Bermuda',
  [Country.BN]: 'Brunei Darussalam',
  [Country.BO]: 'Bolivia',
  [Country.BR]: 'Brazil',
  [Country.BS]: 'Bahamas',
  [Country.BT]: 'Bhutan',
  [Country.BV]: 'Bouvet Island',
  [Country.BW]: 'Botswana',
  [Country.BY]: 'Belarus',
  [Country.BZ]: 'Belize',
  [Country.CA]: 'Canada',
  [Country.CC]: 'Cocos (Keeling) Islands',
  [Country.CD]: 'Congo, The Democratic Republic of the',
  [Country.CF]: 'Central African Republic',
  [Country.CG]: 'Congo',
  [Country.CH]: 'Switzerland',
  [Country.CI]: "Cote D'Ivoire",
  [Country.CK]: 'Cook Islands',
  [Country.CL]: 'Chile',
  [Country.CM]: 'Cameroon',
  [Country.CN]: 'China',
  [Country.CO]: 'Colombia',
  [Country.CR]: 'Costa Rica',
  [Country.CS]: 'Serbia and Montenegro',
  [Country.CU]: 'Cuba',
  [Country.CV]: 'Cape Verde',
  [Country.CX]: 'Christmas Island',
  [Country.CY]: 'Cyprus',
  [Country.CZ]: 'Czech Republic',
  [Country.DE]: 'Germany',
  [Country.DJ]: 'Djibouti',
  [Country.DK]: 'Denmark',
  [Country.DM]: 'Dominica',
  [Country.DO]: 'Dominican Republic',
  [Country.DZ]: 'Algeria',
  [Country.EC]: 'Ecuador',
  [Country.EE]: 'Estonia',
  [Country.EG]: 'Egypt',
  [Country.EH]: 'Western Sahara',
  [Country.ER]: 'Eritrea',
  [Country.ES]: 'Spain',
  [Country.ET]: 'Ethiopia',
  [Country.FI]: 'Finland',
  [Country.FJ]: 'Fiji',
  [Country.FK]: 'Falkland Islands (Malvinas)',
  [Country.FM]: 'Micronesia, Federated States of',
  [Country.FO]: 'Faroe Islands',
  [Country.FR]: 'France',
  [Country.GA]: 'Gabon',
  [Country.GB]: 'United Kingdom',
  [Country.GD]: 'Grenada',
  [Country.GE]: 'Georgia',
  [Country.GF]: 'French Guiana',
  [Country.GG]: 'Guernsey',
  [Country.GH]: 'Ghana',
  [Country.GI]: 'Gibraltar',
  [Country.GL]: 'Greenland',
  [Country.GM]: 'Gambia',
  [Country.GN]: 'Guinea',
  [Country.GP]: 'Guadeloupe',
  [Country.GQ]: 'Equatorial Guinea',
  [Country.GR]: 'Greece',
  [Country.GS]: 'South Georgia and the South Sandwich Islands',
  [Country.GT]: 'Guatemala',
  [Country.GU]: 'Guam',
  [Country.GW]: 'Guinea-Bissau',
  [Country.GY]: 'Guyana',
  [Country.HK]: 'Hong Kong',
  [Country.HM]: 'Heard Island and Mcdonald Islands',
  [Country.HN]: 'Honduras',
  [Country.HR]: 'Croatia',
  [Country.HT]: 'Haiti',
  [Country.HU]: 'Hungary',
  [Country.ID]: 'Indonesia',
  [Country.IE]: 'Ireland',
  [Country.IL]: 'Israel',
  [Country.IM]: 'Isle of Man',
  [Country.IN]: 'India',
  [Country.IO]: 'British Indian Ocean Territory',
  [Country.IQ]: 'Iraq',
  [Country.IR]: 'Iran, Islamic Republic Of',
  [Country.IS]: 'Iceland',
  [Country.IT]: 'Italy',
  [Country.JE]: 'Jersey',
  [Country.JM]: 'Jamaica',
  [Country.JO]: 'Jordan',
  [Country.JP]: 'Japan',
  [Country.KE]: 'Kenya',
  [Country.KG]: 'Kyrgyzstan',
  [Country.KH]: 'Cambodia',
  [Country.KI]: 'Kiribati',
  [Country.KM]: 'Comoros',
  [Country.KN]: 'Saint Kitts and Nevis',
  [Country.KP]: "Korea, Democratic People's Republic of",
  [Country.KR]: 'Korea, Republic of',
  [Country.KW]: 'Kuwait',
  [Country.KY]: 'Cayman Islands',
  [Country.KZ]: 'Kazakhstan',
  [Country.LA]: "Lao People's Democratic Republic",
  [Country.LB]: 'Lebanon',
  [Country.LC]: 'Saint Lucia',
  [Country.LI]: 'Liechtenstein',
  [Country.LK]: 'Sri Lanka',
  [Country.LR]: 'Liberia',
  [Country.LS]: 'Lesotho',
  [Country.LT]: 'Lithuania',
  [Country.LU]: 'Luxembourg',
  [Country.LV]: 'Latvia',
  [Country.LY]: 'Libyan Arab Jamahiriya',
  [Country.MA]: 'Morocco',
  [Country.MC]: 'Monaco',
  [Country.MD]: 'Moldova, Republic of',
  [Country.MG]: 'Madagascar',
  [Country.MH]: 'Marshall Islands',
  [Country.MK]: 'Macedonia, The Former Yugoslav Republic of',
  [Country.ML]: 'Mali',
  [Country.MM]: 'Myanmar',
  [Country.MN]: 'Mongolia',
  [Country.MO]: 'Macao',
  [Country.MP]: 'Northern Mariana Islands',
  [Country.MQ]: 'Martinique',
  [Country.MR]: 'Mauritania',
  [Country.MS]: 'Montserrat',
  [Country.MT]: 'Malta',
  [Country.MU]: 'Mauritius',
  [Country.MV]: 'Maldives',
  [Country.MW]: 'Malawi',
  [Country.MX]: 'Mexico',
  [Country.MY]: 'Malaysia',
  [Country.MZ]: 'Mozambique',
  [Country.NA]: 'Namibia',
  [Country.NC]: 'New Caledonia',
  [Country.NE]: 'Niger',
  [Country.NF]: 'Norfolk Island',
  [Country.NG]: 'Nigeria',
  [Country.NI]: 'Nicaragua',
  [Country.NL]: 'Netherlands',
  [Country.NO]: 'Norway',
  [Country.NP]: 'Nepal',
  [Country.NR]: 'Nauru',
  [Country.NU]: 'Niue',
  [Country.NZ]: 'New Zealand',
  [Country.OM]: 'Oman',
  [Country.PA]: 'Panama',
  [Country.PE]: 'Peru',
  [Country.PF]: 'French Polynesia',
  [Country.PG]: 'Papua New Guinea',
  [Country.PH]: 'Philippines',
  [Country.PK]: 'Pakistan',
  [Country.PL]: 'Poland',
  [Country.PM]: 'Saint Pierre and Miquelon',
  [Country.PN]: 'Pitcairn',
  [Country.PR]: 'Puerto Rico',
  [Country.PS]: 'Palestinian Territory, Occupied',
  [Country.PT]: 'Portugal',
  [Country.PW]: 'Palau',
  [Country.PY]: 'Paraguay',
  [Country.QA]: 'Qatar',
  [Country.RE]: 'Reunion',
  [Country.RO]: 'Romania',
  [Country.RU]: 'Russian Federation',
  [Country.RW]: 'RWANDA',
  [Country.SA]: 'Saudi Arabia',
  [Country.SB]: 'Solomon Islands',
  [Country.SC]: 'Seychelles',
  [Country.SD]: 'Sudan',
  [Country.SE]: 'Sweden',
  [Country.SG]: 'Singapore',
  [Country.SH]: 'Saint Helena',
  [Country.SI]: 'Slovenia',
  [Country.SJ]: 'Svalbard and Jan Mayen',
  [Country.SK]: 'Slovakia',
  [Country.SL]: 'Sierra Leone',
  [Country.SM]: 'San Marino',
  [Country.SN]: 'Senegal',
  [Country.SO]: 'Somalia',
  [Country.SR]: 'Suriname',
  [Country.ST]: 'Sao Tome and Principe',
  [Country.SV]: 'El Salvador',
  [Country.SY]: 'Syrian Arab Republic',
  [Country.SZ]: 'Swaziland',
  [Country.TC]: 'Turks and Caicos Islands',
  [Country.TD]: 'Chad',
  [Country.TF]: 'French Southern Territories',
  [Country.TG]: 'Togo',
  [Country.TH]: 'Thailand',
  [Country.TJ]: 'Tajikistan',
  [Country.TK]: 'Tokelau',
  [Country.TL]: 'Timor-Leste',
  [Country.TM]: 'Turkmenistan',
  [Country.TN]: 'Tunisia',
  [Country.TO]: 'Tonga',
  [Country.TR]: 'Turkey',
  [Country.TT]: 'Trinidad and Tobago',
  [Country.TV]: 'Tuvalu',
  [Country.TW]: 'Taiwan',
  [Country.TZ]: 'Tanzania, United Republic of',
  [Country.UA]: 'Ukraine',
  [Country.UG]: 'Uganda',
  [Country.UM]: 'United States Minor Outlying Islands',
  [Country.US]: 'United States',
  [Country.UY]: 'Uruguay',
  [Country.UZ]: 'Uzbekistan',
  [Country.VA]: 'Holy See (Vatican City State)',
  [Country.VC]: 'Saint Vincent and the Grenadines',
  [Country.VE]: 'Venezuela',
  [Country.VG]: 'Virgin Islands, British',
  [Country.VI]: 'Virgin Islands, U.S.',
  [Country.VN]: 'Viet Nam',
  [Country.VU]: 'Vanuatu',
  [Country.WF]: 'Wallis and Futuna',
  [Country.WS]: 'Samoa',
  [Country.YE]: 'Yemen',
  [Country.YT]: 'Mayotte',
  [Country.ZA]: 'South Africa',
  [Country.ZM]: 'Zambia',
  [Country.ZW]: 'Zimbabwe',
  [Country.BL]: 'Saint Barthélemy',
  [Country.BQ]: 'Bonaire, Sint Eustatius and Saba',
  [Country.CW]: 'Curaçao',
  [Country.ME]: 'Montenegro',
  [Country.MF]: 'Saint Martin (French part)',
  [Country.RS]: 'Serbia',
  [Country.SS]: 'South Sudan',
  [Country.SX]: 'Sint Maarten (Dutch part)'
};
