import { CompanyAccountGroup } from 'models/company-account/company-account.model';

export const toAccountGroupName = (
  sid: string,
  accountGroups: CompanyAccountGroup[] | null | undefined
): string | null => {
  if (!accountGroups || !sid) return null;

  const found = accountGroups.find((accounts) => accounts.sid === sid);

  return found?.name ?? null;
};
