import { Paper } from '@material-ui/core';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { FC, ReactElement } from 'react';
import './image-tooltip.css';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    width: '755px',
    color: '#635BFF',
    opacity: 1
  },
  arrow: {
    color: 'white'
  }
}))(MaterialTooltip);

const useTooltipStyles = makeStyles(() => ({
  header: {
    background: '#635BFF',
    marginBottom: '8px'
  },
  headerContent: {
    backgroundColor: '#635BFF',
    borderRadius: 5,
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: '10px',
    padding: '5px'
  }
}));

interface TooltipContentProps {
  readonly id?: string;
  readonly country?: string;
  readonly state?: string;
  readonly type?: string;
  readonly version?: string;
}

const TooltipContent: FC<TooltipContentProps> = ({
  id,
  country,
  state,
  type,
  version
}) => {
  const classes = useTooltipStyles();

  const rows = [
    { key: 'ID:', value: id },
    { key: 'COUNTRY:', value: country },
    { key: 'STATE:', value: state },
    { key: 'TYPE:', value: type },
    { key: 'VERSION:', value: version }
  ].filter((row) => !!row.value);

  return (
    <div>
      <Paper className={classes.header} elevation={3}>
        <div className={classes.headerContent}>Job image detail</div>
      </Paper>
      {rows.map((data) => (
        <div className="tooltip-row" key={data.key}>
          <div>{data.key}</div>
          <div>{data.value}</div>
        </div>
      ))}
    </div>
  );
};

interface ImageTooltipProps extends TooltipContentProps {
  readonly disable?: boolean;
}

export const ImageTooltip: FC<ImageTooltipProps> = ({
  id,
  country,
  state,
  type,
  version,
  disable = false,
  children
}) => {
  return (
    <LightTooltip
      disableHoverListener={disable}
      interactive
      placement="bottom-start"
      title={
        <TooltipContent
          country={country}
          id={id}
          state={state}
          type={type}
          version={version}
        />
      }
    >
      {children as ReactElement}
    </LightTooltip>
  );
};
