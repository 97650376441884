const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

const PHONE_REGEX = /^\(\d{3}\) \d{3} - \d{4}$/;
const AUTOFILLED_PHONE_REGEX = /^\d{10}$/; // chrome autofilled phone has 10 digits (minus dashes)

const isValidPhoneLength = (phone: string): boolean =>
  phone.replaceAll(/\D/g, '').length === 10;

const validatePattern = (pattern: string, value: string) => {
  const regEx = new RegExp(`^(?:${pattern})$`);
  return regEx.test(value);
};

export const isValidDate = (date: Date): boolean =>
  !Number.isNaN(date.getTime());

const isValidEmail = (email: string): boolean => EMAIL_REGEX.test(email);

const validateInputValue = (formattedValue = ''): boolean =>
  validatePattern(PHONE_REGEX.source, formattedValue) ||
  validatePattern(EMAIL_REGEX.source, formattedValue) ||
  validatePattern(AUTOFILLED_PHONE_REGEX.source, formattedValue);

const formatPhone = (phone: string) => {
  const numbersArr = phone.replaceAll(/\D/g, '').split('');
  return numbersArr.reduce((acc, char, idx) => {
    if (idx === 2) return `(${acc}${char}) `;
    if (idx === 6) return `${acc} - ${char}`;
    return acc + char;
  }, '');
};

/*
 * when we add dashes to a phone number react doesn't know where to put the cursor
 * (react defaults to placing at end of string)
 * so we have to manage the cursor ourselves
 */
const manageCursor = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: string
) => {
  if (value !== event.target.value) {
    const endPos = event.target.selectionEnd ?? 0;
    const oldValue = event.target.value;
    event.target.value = value;
    if (oldValue.length !== endPos && event.target === document.activeElement) {
      event.target.setSelectionRange(endPos, endPos);
    }
  }
};

/**
 * Formats events against regex patterns and returns isValid boolean
 * Change `name` value eg., name="ssn" for social security regex
 *
 * @returns {boolean} isValid for validation and error handling
 */

export const formatInput = (
  event: React.ChangeEvent<HTMLInputElement>
): { formattedValue: string; isValid: boolean; rawValue: string } => {
  switch (event.target.name) {
    case 'phone': {
      const rawValue = event.target.value.replaceAll(/\D/g, '') || '';
      const formattedValue = formatPhone(event.target.value);
      manageCursor(event, formattedValue);
      const isValid =
        validateInputValue(formattedValue) &&
        isValidPhoneLength(formattedValue);

      return { formattedValue, isValid, rawValue };
    }

    case 'email': {
      const rawValue = event.target.value; // .replace(/\D/g, '') || '';
      const formattedValue = event.target.value; // formatSin(event.target.value);
      manageCursor(event, formattedValue);
      const isValid =
        validateInputValue(formattedValue) && isValidEmail(formattedValue);

      return { formattedValue, isValid, rawValue };
    }

    default:
      return { formattedValue: '', isValid: false, rawValue: '' };
  }
};

export enum FieldState {
  NOT_SET,
  VALID,
  INVALID
}

export type ResponseType = Record<string, FieldState>;
