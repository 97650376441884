import React, { FC } from 'react';

export const DlvNotEnabled: FC = () => (
  <div>
    <h1 className="not-enabled-header" data-test="not-enabled-header">
      DLV Feature Not Enabled
    </h1>
    <div className="not-enabled-body">
      <p className="not-enabled-text" data-test="not-enabled-text">
        Driver's License Verification (DLV) is a Vouched product that verifies
        driver's licenses, learner's permits, and government issued IDs with
        government and proprietary data sources in 41 US states, including
        California. If you would like to enable DLV, please contact
        support@vouched.id.
      </p>
    </div>
  </div>
);

export const DlvDataUnavailable: FC = () => (
  <div>
    <h1 className="not-enabled-header" data-test="not-enabled-header">
      DLV
    </h1>
    <div className="not-enabled-body">
      <p className="not-enabled-text" data-test="not-enabled-text">
        Driver's License Verification (DLV) was enabled for this job, but there
        was an issue retrieving the data for this page. Please try refreshing or
        contacting support@vouched.id if the issue persists.
      </p>
    </div>
  </div>
);

export const AamvaDataUnavailable: FC = () => (
  <div>
    <h1 className="not-enabled-header" data-test="not-enabled-header">
      DLV
    </h1>
    <div className="not-enabled-body">
      <p className="not-enabled-text" data-test="not-enabled-text">
        Driver's License Verification (DLV) through AAMVA DMV Verification
        verifies a person's driver's license or state ID card by validating it
        against the database of the motor vehicle association of the state
        associated with the ID. DLV was enabled for this job, but if you are
        seeing this message, either the state that issued the driver's license
        is not available for DLV, the issuer is not from the United States, or
        the submitted document is not a driver's license. For more information,
        please reach out to support@vouched.id.
      </p>
    </div>
  </div>
);
