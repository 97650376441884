import React, { useEffect, useState } from 'react';
import { Loader } from 'components/loader/loader';
import { useGlobalState } from 'store/reducers/reducer';
import { confirmEmail, getAccount } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { getQueryVariable } from 'utils/url';

export const Confirm: React.FC = () => {
  const { asyncDispatch } = useGlobalState();

  const [message, setMessage] = useState('');

  const token = getQueryVariable('t');

  useEffect(() => {
    if (token) {
      asyncDispatch(confirmEmail(token))
        .then(async (data) => {
          setMessage('Thanks for confirming your email.');

          return asyncDispatch(getAccount(data.accessToken));
        })
        .catch((error: Error) => {
          console.error(error);
          setMessage(error.message);
        });

      setTimeout(() => {
        history.push('/account');
      }, 3000);
    } else {
      setMessage('Check your email please');
    }
  }, [token]);

  return (
    <div className="account u-flex-row g-page-without-header-footer u-align-vertical-center u-align-horizontal-center">
      <div className="u-flex u-align-vertical-center">
        {message ? <span>{message}</span> : <Loader />}
      </div>
    </div>
  );
};
