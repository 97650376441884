import { Container, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { JobModal } from './job-modal';
import { StyledSwitch } from './job-modal/job-modal-tabs/job-modal-tabs';
import { getJobsColumns } from './jobs-columns/jobs-columns';
import { JobsFilter } from './jobs-filter';
import { JobsFilterData } from './jobs-filter/JobsFilter';
import { mapData } from './utils';
import { ColumnProp, List } from 'components/reusable/list';
import { SandboxContainer } from 'components/reusable/sandbox-container';
import { Job, Jobs as JobsModel, JobUpdateReview } from 'models/jobs/jobs';
import { UserUpdateReview } from 'models/user/user.model';
import {
  AuthWithGoogleButton,
  isIapAuthRequired,
  resetIapAuthRequired
} from 'security/iap';
import { setSandboxToggled } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  loadAccounts,
  loadJob,
  loadJobs,
  removeJob,
  updateReview,
  updateReviewStatus
} from 'store/sagas/sagas';
import { history } from 'utils/history';
import { useDebounce, useFlags } from 'utils/hooks';
import { jobsOptions } from 'utils/jobs';
import {
  isQueriesSame,
  pushFiltersSearchPath,
  QueryData,
  rawParseQuery,
  transformQueryTypes
} from 'utils/url';
import { useIsMobile } from 'utils/window';
import './jobs.css';

interface JobsProps {
  readonly basePath: string;
  readonly isAdmin: boolean;
}

const initialData: JobsFilterData = {
  pageSize: 50,
  page: 1,
  status: '',
  success: '',
  accountGroupSid: '',
  accountReviewed: '',
  from: null,
  to: null,
  sortBy: '',
  sortOrder: '',
  tableView: 1,
  respIdType: '',
  respIdState: '',
  ids: '',
  respIdCountry: '',
  lastName: '',
  firstName: '',
  is_disabled: '',
  includeResults: true,
  billableType: '',
  billable: '',
  aamvaSource: '',
  type: '',
  accountId: '',
  aml: '',
  email: '',
  phone: ''
};

export const Jobs: React.FC<JobsProps> = ({ basePath, isAdmin }) => {
  const { state, asyncDispatch, dispatch } = useGlobalState();
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [jobs, setJobs] = useState<JobsModel>({
    items: [],
    totalPages: 0,
    total: 0,
    page: 0,
    pageSize: 0
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState<Job | null>(null);
  const [preModalPath, setPreModalPath] = useState<string | null>(null);

  const parsedQuery = transformQueryTypes(mapData, rawParseQuery());

  const [filter, setFilter] = useState<JobsFilterData>({
    ...initialData,
    accountId: isAdmin && !parsedQuery['ids'] ? state.account!.sid : '',
    ...parsedQuery
  });

  const debouncedFilter = useDebounce(filter, 250);

  const { jobPageRedesign } = useFlags();

  // It is in sandbox mode when the sandbox toggle is on and the jobs are not "all jobs" option
  const isSandboxMode = state.sandboxToggled && !isAdmin;

  const isMobile = useIsMobile();

  const jobsColumns = getJobsColumns(
    isAdmin,
    state.accounts,
    state.accountGroups
  );

  const jobsColumnsProps = jobsColumns
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    .filter((column) => (isMobile && column.showMobile) || !isMobile)
    .map((column) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { showMobile: _unused, ...restColumns } = column;
      return restColumns as ColumnProp<Job>;
    });

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (checkModalUrl(location.pathname)) {
        return;
      }

      const qsData = transformQueryTypes(
        mapData,
        rawParseQuery(location.search.replace(/\?/, ''))
      ) as Partial<JobsFilterData>;

      // same?
      if (!isQueriesSame(qsData, filter as unknown as QueryData)) {
        qsData.page ||= 1;

        updateFilter(qsData);
      }
    });

    return () => {
      unlisten();
    };
  });

  useEffect(() => {
    if (!state.accounts && !!state.user) {
      asyncDispatch(loadAccounts()).catch(console.error);
    }
  }, [!state.accounts && !!state.user]);

  useEffect(() => {
    const modalIsOpening = checkModalUrl(window.location.pathname);
    if (modalIsOpening) {
      openJobModal(window.location.pathname, false);
      return;
    }

    const { isInitial } = pushFiltersSearchPath({
      filter: debouncedFilter as unknown as QueryData,
      initialData: initialData as unknown as QueryData,
      mapData,
      path: basePath,
      reloadPage: false
    });

    if (!isOpenFilters && !isInitial) {
      setIsOpenFilters(true);
    }

    setLoading(true);
    const { tableView, ...applicableFilters } = debouncedFilter;
    resetIapAuthRequired();
    asyncDispatch(
      loadJobs(
        {
          ...applicableFilters,
          ids: debouncedFilter.ids ? [debouncedFilter.ids] : '',
          withPhotoUrls: tableView !== 1
        },
        isAdmin,
        isSandboxMode
      )
    )
      .then(setJobs, console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedFilter]);

  const updateFilter = (newFilter: Partial<JobsFilterData>) => {
    setFilter({ ...initialData, ...newFilter });
  };

  const checkModalUrl = useCallback(
    (pathname: string) => {
      const id = pathname.replace(`${basePath}/`, '');
      return id && id !== basePath;
    },
    [basePath]
  );

  const openJobModal = useCallback(
    (pathname: string, pushHistory = true) => {
      const id = pathname.replace(`${basePath}/`, '');
      if (checkModalUrl(pathname)) {
        setLoading(true);
        asyncDispatch(loadJob(id, isAdmin, isSandboxMode))
          .then((res) => {
            setJob(res);
            setIsModalOpen(true);
            if (pushHistory) {
              history.push(pathname);
            }

            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    },
    [asyncDispatch, basePath, checkModalUrl, isAdmin, isSandboxMode]
  );

  const handleOpenModal = useCallback(
    (id: string) => {
      setPreModalPath(window.location.pathname);
      const pathname = `${basePath}/${id}`;
      openJobModal(pathname);
    },
    [basePath, openJobModal]
  );

  const handleReview = (body: UserUpdateReview) => {
    asyncDispatch(updateReviewStatus(body, isSandboxMode))
      .then((res) => {
        setJob(res);
        updateJob(res);
      })
      .catch(console.error);
  };

  const handleJobReview = (id: string, body: JobUpdateReview | null) => {
    asyncDispatch(updateReview(id, body, isSandboxMode))
      .then((res) => {
        // set parameters since it has image urls and res does not
        const newJob = { ...res, request: job?.request } as Job;
        setJob(newJob);
        updateJob(newJob);
      })
      .catch(console.error);
  };

  const handleRemove = (id: string) => {
    asyncDispatch(removeJob(id, isSandboxMode))
      .then(() => {
        removeJobInner(id);
      })
      .catch(console.error);
  };

  const handleChangeFilter = (
    fieldName: string,
    value: Date | number | string | null
  ) => {
    updateFilter({
      ...filter,
      [fieldName]: value,
      page: 1
    });
  };

  const handleResetFilter = () => {
    updateFilter(initialData);
  };

  const handlePageChange = (_event: unknown, page: number) => {
    updateFilter({
      ...filter,
      page
    });
  };

  const removeJobInner = (jobId: string): void => {
    const idx = jobs.items.findIndex((j) => j.id === jobId);
    if (idx === -1) {
      return;
    }

    jobs.items[idx]!.status = 'removed';
    setJobs(jobs);
  };

  const updateJob = (newJob: Job): void => {
    const idx = jobs.items.findIndex((j) => j.id === newJob.id);
    if (idx === -1) {
      return;
    }

    jobs.items[idx] = newJob;
    setJobs(jobs);
  };

  const toggleSandboxMode = (isToggled: boolean) => {
    dispatch(setSandboxToggled(isToggled));
  };

  return (
    <div className="Jobs">
      {isAdmin && isIapAuthRequired() && (
        <Container>
          This page is protected by IAP - <AuthWithGoogleButton />
        </Container>
      )}
      <div className="jobs-header g-page-name">
        <h1 className="jobs-header__title">{isAdmin ? 'All' : ''} Jobs</h1>
        {!isAdmin && (
          <FormGroup row>
            <FormControlLabel
              color="primary"
              control={
                <StyledSwitch
                  checked={state.sandboxToggled}
                  name="checkedA"
                  onChange={() => {
                    toggleSandboxMode(!state.sandboxToggled);
                  }}
                />
              }
              label={
                state.sandboxToggled
                  ? 'Viewing Sandbox Data'
                  : 'View Sandbox Data'
              }
            />
          </FormGroup>
        )}
      </div>
      {isModalOpen && job && (
        <JobModal
          debug={isAdmin}
          handleJobReview={handleJobReview}
          handleRemove={handleRemove}
          handleReview={handleReview}
          job={job}
          onClose={() => {
            if (preModalPath) {
              history.goBack();
            } else {
              history.push(`${basePath}/`);
            }

            setIsModalOpen(false);
          }}
          readonly={isAdmin}
          user={state.user!}
        />
      )}
      <JobsFilter
        filterFields={filter}
        isAdmin={isAdmin}
        jobsOptions={jobsOptions}
        onFilter={handleChangeFilter}
        onReset={handleResetFilter}
      />
      {filter.tableView === 1 && (
        <SandboxContainer isAdmin={isAdmin}>
          <List
            columns={jobsColumnsProps}
            data-test="jobs-list"
            handlePageChange={handlePageChange}
            isClickable={(item) => {
              const NOT_CLICKABLE = ['removed'];
              return !NOT_CLICKABLE.includes(item.status.toLowerCase());
            }}
            items={jobs.items}
            label="jobs"
            loading={loading}
            onClick={(item) => {
              if (jobPageRedesign) {
                history.push(
                  `/${isAdmin ? 'admin' : 'account'}/job/${item.id}`
                );

                return;
              }

              handleOpenModal(item.id);
            }}
            page={filter.page}
            total={jobs.total}
            totalPages={jobs.totalPages}
          />
        </SandboxContainer>
      )}
    </div>
  );
};
