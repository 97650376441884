import { TextField } from '@material-ui/core';
import React from 'react';
import { useGlobalState } from 'store/reducers/reducer';

export const UserEmail: React.FC = () => {
  const { state } = useGlobalState();

  return (
    <form
      // onSubmit={onSubmitName}
      autoComplete="off"
      className="g-form"
      noValidate
    >
      <span className="title">Email</span>
      <TextField
        className="u-margin-bottom-small"
        defaultValue={state.user?.email}
        /*
         * error={lastName === null}
         * onChange={handleChangeLastName}
         */
        disabled
        label="Email"
        required
      />
    </form>
  );
};
