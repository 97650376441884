import { Button, ButtonProps, PropTypes } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  readonly loading?: boolean;
}

const AdornedButton: FC<Props> = ({ children, loading, ...rest }) => {
  if (loading) {
    rest = { ...rest, onClick: undefined };
  }

  return (
    <Button {...rest}>
      {children}
      {loading && (
        <CircularProgress
          size="0.8125rem"
          style={{ marginLeft: 6, color: 'white' }}
        />
      )}
    </Button>
  );
};

interface ButtonLoaderProps {
  readonly name: string;
  readonly message?: string;
  readonly disabled: boolean;
  readonly isLoading: boolean;
  readonly color?: PropTypes.Color;
  readonly className?: string;
  readonly onClick?: () => void;
}

export const ButtonWithLoader: React.FC<ButtonLoaderProps> = ({
  onClick,
  className,
  message,
  disabled,
  isLoading,
  color,
  name
}) => {
  const type = onClick ? 'button' : 'submit';
  const additionalClass = className ?? '';

  return message && message.length !== 0 ? (
    <span className="u-padding-top-medium u-padding-bottom-medium">
      {message}
    </span>
  ) : (
    <AdornedButton
      className={`u-margin-bottom-small ${additionalClass}`}
      color={color ?? 'primary'}
      disabled={disabled}
      loading={isLoading}
      onClick={onClick}
      type={type}
      variant="contained"
    >
      {name}
    </AdornedButton>
  );
};
