import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1920,
      xs: 0
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#635BFF'
      },
      root: {
        backgroundColor: '#635BFF',
        borderRadius: 30,
        boxShadow: 'none !important',
        color: 'white',
        fontFamily: 'Helvetica,Arial,sans-serif !important',
        minWidth: '170px',
        padding: '10px !important'
      },
      textPrimary: {
        color: 'white'
      }
    },

    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#4122af'
        },
        '&:hover': {
          backgroundColor: '#dad2ed !important'
        },
        color: '#eaeaea',
        marginLeft: 0,
        padding: '0px'
      }
    },

    MuiCollapse: {
      wrapper: {
        width: '100%'
      }
    },

    MuiDialog: {
      paper: {
        '@media screen and (orientation:portrait) and (max-width: 600px), screen and (orientation:landscape) and (max-height: 600px)':
          {
            overflowY: 'unset'
          }
      }
    },

    MuiFormControlLabel: {
      label: {
        fontSize: '1.6rem !important',
        marginLeft: '0.8rem',
        userSelect: 'none'
      }
    },

    MuiFormLabel: {
      root: {
        color: '#7f90a0',
        fontSize: '1.3rem'
      }
    },
    MuiGrid: {
      container: {
        '@media screen and (orientation:portrait) and (max-width: 600px), screen and (orientation:landscape) and (max-height: 600px)':
          {
            alignItems: 'center',

            display: 'flex',
            width: '100%'
          }
      },

      item: {
        '@media screen and (orientation:portrait) and (max-width: 600px), screen and (orientation:landscape) and (max-height: 600px)':
          {
            display: 'flex'
          }
      },

      'spacing-xs-3': {
        '@media screen and (orientation:portrait) and (max-width: 600px), screen and (orientation:landscape) and (max-height: 600px)':
          {
            margin: 0
          }
      }
    },
    MuiInput: {
      root: {
        color: '#5d5d5d !important',
        fontFamily: 'Colfax,Helvetica,Arial,sans-serif !important',
        fontSize: '0.875rem !important'
      },
      underline: {
        '&&&&:hover:before': {
          borderBottom: '2px solid #635BFF'
        },
        '&:after': {
          borderBottom: '2px solid #635BFF'
        },
        '&:before': {
          borderBottom: '1px solid #eaeaea'
        }
      }
    },
    MuiInputBase: {
      input: {
        color: '#7f90a0 !important'
      },
      root: {
        color: '#5d5d5d !important',
        fontFamily: 'Helvetica,Arial,sans-serif !important',
        fontSize: '0.875rem !important'
      }
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          // increase the specificity for the pseudo class
          color: '#4122af'
        }
      }
    },
    MuiListItem: {
      root: {
        '&:hover': {
          backgroundColor: 'unset !important',
          opacity: '1'
        },
        fontFamily: 'Colfax,Helvetica,Arial,sans-serif !important',
        fontStyle: 'normal',
        fontWeight: 400,
        marginBottom: '10px',
        opacity: '0.7'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.8125rem'
      }
    },
    MuiSvgIcon: {
      root: {
        marginTop: '0px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.3rem'
      }
    },
    MuiTypography: {
      root: {
        fontFamily: 'Colfax,Helvetica,Arial,sans-serif !important',
        fontSize: '13px !important'
      }
    }
  },
  palette: {}
});
