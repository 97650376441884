import React from 'react';
import { User } from 'models/user/user.model';

interface Props {
  readonly user: User | null;
}

export const UserVerification: React.FC<Props> = ({ user }: Props) => {
  return user?.emailVerified ? null : (
    <div className="u-margin-bottom-large">
      <span className="g-error">
        You've been sent a verification email. Before you can access your
        account you need to verify your email address. Please check your inbox
        or click "Resend Verification Email" to resend
      </span>
    </div>
  );
};
