import { Box, Text } from '@vouched-id/vault';
import React from 'react';
import { humanIdType } from 'utils/jobs';

interface ExtractedIdDataProps {
  readonly firstName?: string;
  readonly middleName?: string;
  readonly lastName?: string;
  readonly birthDate?: string;
  readonly type?: string;
  readonly id?: string;
  readonly country?: string;
  readonly state?: string;
  readonly expireDate?: string;
  readonly issueDate?: string;
}

export const ExtractedIdData: React.FC<ExtractedIdDataProps> = ({
  firstName,
  middleName,
  lastName,
  birthDate,
  type,
  id,
  country,
  state,
  expireDate,
  issueDate
}) => {
  return (
    <Box
      display="grid"
      style={{
        gridTemplateColumns: '1fr 1fr',
        gap: '15px',
        justifyItems: 'start',
        alignItems: 'start'
      }}
    >
      <Text>First Name</Text>
      <Text style={{ fontWeight: '600' }}>{firstName ?? 'Not Found'}</Text>
      <Text>Middle Name</Text>
      <Text style={{ fontWeight: '600' }}>{middleName ?? 'Not Found'}</Text>
      <Text>Last Name</Text>
      <Text style={{ fontWeight: '600' }}>{lastName ?? 'Not Found'}</Text>
      <Text>Birth Date</Text>
      <Text style={{ fontWeight: '600' }}>{birthDate ?? 'Not Found'}</Text>
      <Text>Type</Text>
      <Text style={{ fontWeight: '600' }}>
        {humanIdType(type) ?? 'Not Found'}
      </Text>
      <Text>ID Number</Text>
      <Text style={{ fontWeight: '600' }}>{id ?? 'Not Found'}</Text>
      <Text>Country</Text>
      <Text style={{ fontWeight: '600' }}>{country ?? 'Not Found'}</Text>
      <Text>State</Text>
      <Text style={{ fontWeight: '600' }}>{state ?? 'Not Found'}</Text>
      <Text>Expiration Date</Text>
      <Text style={{ fontWeight: '600' }}>{expireDate ?? 'Not Found'}</Text>
      <Text>Issue Date</Text>
      <Text style={{ fontWeight: '600' }}>{issueDate ?? 'Not Found'}</Text>
    </Box>
  );
};
