import { TextField } from '@material-ui/core';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { User } from 'models/user/user.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { changeUserInfo, resendVerification } from 'store/sagas/sagas';
import './profile.css';

export const UserInfo: React.FC = () => {
  const { state, asyncDispatch, dispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [verify, setVerify] = useState(true);

  type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const user = {
    ...state.user
  } as User;

  const [firstName, setFirstName] = useState(user.firstName);

  const handleChangeFirstName = useCallback((e: InputEvent) => {
    setFirstName(e.target.value);
  }, []);

  const [lastName, setLastName] = useState(user.lastName);
  const handleChangeLastName = useCallback((e: InputEvent) => {
    setLastName(e.target.value);
  }, []);

  const submitIsDisabled =
    firstName.length === 0 ||
    lastName.length === 0 ||
    (firstName === user.firstName && lastName === user.lastName);

  const onSubmitName = (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);
    asyncDispatch(
      changeUserInfo({
        firstName,
        lastName
      })
    )
      .then(() => {
        setIsLoading(false);
        dispatch(
          setMessages({
            value: 'Name changed successfully',
            severity: 'success'
          })
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleVerifyEmail = () => {
    if (state.user?.email) {
      asyncDispatch(resendVerification(state.user.email))
        .then(() => {
          setVerify(false);
        })
        .catch(console.error);
    }
  };

  return (
    <>
      {!user.emailVerified && verify && (
        <div className="g-form">
          <ButtonWithLoader
            disabled={false}
            isLoading={false}
            name="Resend Verification Email"
            onClick={handleVerifyEmail}
          />
        </div>
      )}
      <form
        autoComplete="off"
        className="g-form"
        noValidate
        onSubmit={onSubmitName}
      >
        <span className="title">Change Name</span>
        <TextField
          className="u-margin-bottom-small"
          defaultValue={state.user?.firstName}
          disabled={!user.emailVerified}
          error={!firstName}
          label="First Name"
          onChange={handleChangeFirstName}
          required
        />
        <TextField
          className="u-margin-bottom-small"
          defaultValue={state.user?.lastName}
          disabled={!user.emailVerified}
          error={!lastName}
          label="Last Name"
          onChange={handleChangeLastName}
          required
        />
        <ButtonWithLoader
          disabled={submitIsDisabled}
          isLoading={isLoading}
          name="Update your name"
        />
      </form>
    </>
  );
};
