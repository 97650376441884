import JSONEditor from 'jsoneditor';
import React, { FC, useEffect, useRef } from 'react';
import 'jsoneditor/dist/jsoneditor.css';

interface EditorProps {
  readonly values: object;
}

export const TemplateJSONEditor: FC<EditorProps> = ({ values }) => {
  const container = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<JSONEditor | null>(null);

  useEffect(() => {
    if (!container.current) {
      return;
    }

    editorRef.current = new JSONEditor(container.current, {
      mode: 'view',
      sortObjectKeys: true
    });
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.update(values);
    }
  }, [values]);

  return (
    <div
      className="properties-editor"
      data-cy="properties-editor"
      ref={container}
      style={{ width: '100%', height: '30em' }}
    />
  );
};
