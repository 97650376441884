import React, { useEffect, useState } from 'react';
import { privateSSNRequestDataSummary } from './private_request_data_summary';
import { privateSSNResponseDataSummary } from './private_ssn_response_data_summary';
import { List } from 'components/reusable/list';
import { Job } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { getPrivateSSNJob, PrivateSSNJob } from 'store/sagas/sagas';

interface PrivateSSNProps {
  readonly job: Job;
}

export const PrivateSSN: React.FC<PrivateSSNProps> = ({ job }) => {
  const { asyncDispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PrivateSSNJob>({});

  useEffect(() => {
    asyncDispatch(getPrivateSSNJob(job.id)).then(
      (res) => {
        setData(res);
        setIsLoading(true);
      },
      (error: Error) => {
        setData({});
        console.error(error);
        setIsLoading(false);
      }
    );
  }, [job.id]);

  return isLoading ? (
    <div data-test="private-ssn-details">
      <List
        columns={[
          {
            name: 'name',
            id: 'name',
            xs: 2
          },
          {
            name: 'type',
            xs: 2,
            customRenderValue: (item) => item.customRenderType
          },
          {
            name: 'value',
            xs: 8,
            customRenderValue: (item) => item.customRenderValue
          }
        ]}
        items={[
          privateSSNRequestDataSummary(data.request_data),
          privateSSNResponseDataSummary(data.response_data)
        ]}
        page={1}
        showHeaders={false}
      />
    </div>
  ) : (
    <div>Loading</div>
  );
};
