import {
  Box,
  Button,
  Heading,
  Input,
  Stack,
  Text,
  ThemeContext
} from '@vouched-id/vault';
import React, {
  FC,
  FormEvent,
  useContext,
  useLayoutEffect,
  useState
} from 'react';
import { RouteComponentProps } from 'react-router';
import { VouchedLogo } from 'components/reusable/vouched-logo';
import { set2FAData, setAuthData, setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { createAccount } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { humanDate } from 'utils/ui.utils';
import { formatInput } from 'utils/validation';

const getQueryVariable = (searchQuery: string, variable: string) => {
  for (const v of searchQuery.split('&')) {
    const [key = '', value = ''] = v.split('=');
    if (decodeURIComponent(key) === variable) {
      return decodeURIComponent(value);
    }
  }

  console.warn('Query variable %s not found', variable);
  return undefined;
};

interface MatchParams {
  tier: string;
}

enum FieldState {
  NOT_SET,
  VALID,
  INVALID
}

type RouteProps = RouteComponentProps<MatchParams>;
type ResponseType = Record<string, FieldState>;

export const CreateAccount: FC<RouteProps> = ({ location }) => {
  const query = location.search.slice(1);
  const tier = getQueryVariable(query, 'tier');

  const { dispatch, asyncDispatch } = useGlobalState();

  const [userProfile, setUserProfile] = useState({ email: '', password: '' });
  const [isInputInvalid, setIsInputInvalid] = useState<ResponseType>({
    email: FieldState.NOT_SET,
    password: FieldState.NOT_SET
  });

  const { tokens } = useContext(ThemeContext).theme;
  const desktopLogoColor = tokens.colors.backgroundPrimary;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'email') {
      const { formattedValue } = formatInput(e);
      value = formattedValue;
    }

    setUserProfile((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'email') {
      if (value === '') {
        setIsInputInvalid({ ...isInputInvalid, [name]: FieldState.INVALID });
      } else {
        const { isValid } = formatInput(e);
        setIsInputInvalid({
          ...isInputInvalid,
          [name]: isValid ? FieldState.VALID : FieldState.INVALID
        });
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const submit = (event: FormEvent) => {
    event.preventDefault();

    const { email } = userProfile;
    const { password } = userProfile;

    if (email && password && tier) {
      setIsLoading(true);
      asyncDispatch(createAccount({ email, password, tier }))
        .then((data) => {
          setIsLoading(false);

          if ('otpToken' in data) {
            dispatch(set2FAData(data));
            history.push('/verify-2fa');
          } else {
            if (data.user.loginAt) {
              dispatch(
                setMessages({
                  severity: 'info',
                  value: `Last login at ${humanDate(data.user.loginAt)!}`
                })
              );
            }

            dispatch(setAuthData(data));

            history.push('/account/needs-verification');
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsInputInvalid({
            email: FieldState.INVALID,
            password: FieldState.INVALID
          });
        });
    }
  };

  // eventually update vault theme
  const midPurple = '#453FC1';
  const brightGreen = '#9FEA99';
  const darkGreen = '#64A782';

  useLayoutEffect(() => {
    document.title = 'Vouched | Create Account Page';
  }, []);

  return (
    <Box
      display="flex"
      padding={['spacing.large', 'spacing.none']}
      style={{
        boxSizing: 'border-box',
        backgroundColor: midPurple,
        minHeight: '100vh'
      }}
      tag="main"
    >
      <Box
        alignItems="center"
        backgroundColor="colors.backgroundPrimary"
        display="flex"
        flex={['1', '1 1 50%']}
        flexDirection="column"
        justifyContent="center"
        style={{
          borderRadius: ['spacing.default', 'spacing.none'],
          boxSizing: 'border-box'
        }}
      >
        <Box maxWidth={['75%', null, '60%']} paddingY="spacing.large">
          <Box display={['block', 'none']} marginBottom="spacing.xlarge">
            <VouchedLogo fill={midPurple} maxWidth="none" width="100%" />
            <Text
              style={{
                color: darkGreen,
                fontWeight: '600',
                fontSize: ['text.size.default'],
                marginTop: 'spacing.xsmall2',
                textAlign: 'center'
              }}
            >
              Self Serve
            </Text>
          </Box>
          <Heading
            style={{
              fontSize: ['text.size.large', null, 'text.size.xlarge2'],
              fontWeight: '400',
              color: midPurple,
              textAlign: 'center',
              marginBottom: 'spacing.default'
            }}
            tag="h1"
          >
            Welcome
          </Heading>
          <Heading
            style={{
              fontWeight: 'normal',
              fontSize: ['text.size.default', null, '24px'],
              textAlign: 'center',
              marginBottom: 'spacing.xlarge'
            }}
            tag="h2"
            variation="h5"
          >
            Start verifying your customers by creating an account with a 14-day
            free trial.
          </Heading>
          <Box
            marginBottom="spacing.xlarge"
            noValidate
            onSubmit={submit}
            tag="form"
            width="100%"
          >
            <Stack spacing="spacing.large">
              <Box>
                <Input
                  autoComplete="email"
                  inputMode="email"
                  isInvalid={isInputInvalid['email'] === FieldState.INVALID}
                  label="Email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  validationMessage="Invalid email address."
                />
              </Box>
              <Box>
                <Input
                  inputMode="text"
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  validationMessage="Invalid password."
                />
              </Box>
              <Text
                style={{ textAlign: 'right', fontSize: 'text.size.xsmall' }}
              >
                <a href="/signin" style={{ color: midPurple }}>
                  Already have an account?
                </a>
              </Text>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                marginTop="spacing.default"
                paddingTop="spacing.default"
                width="100%"
              >
                <Button
                  data-cy="submit"
                  style={{ backgroundColor: midPurple }}
                  title={isLoading ? 'loading' : ''}
                >
                  Create Account
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box
            paddingY="spacing.small"
            style={{ borderTop: '1px solid lightgray' }}
          >
            <Text style={{ textAlign: 'center', fontSize: 'text.size.xsmall' }}>
              Want more information?{' '}
              <a href="https://vouched.id" style={{ color: midPurple }}>
                Check out our site
              </a>{' '}
              or you can{' '}
              <a
                href="https://www.vouched.id/get-started"
                style={{ color: midPurple }}
              >
                Request a Demo
              </a>
              .
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display={['none', 'flex']}
        flex="1 1 50%"
        flexDirection="column"
        justifyContent="center"
      >
        <VouchedLogo fill={desktopLogoColor} maxWidth="50rem" width="80%" />
        <Text
          colorVariation="inversePrimary"
          style={{
            color: brightGreen,
            fontWeight: '600',
            fontSize: ['text.size.default', null, 'text.size.large'],
            marginTop: 'spacing.large'
          }}
        >
          Self Serve
        </Text>
      </Box>
    </Box>
  );
};
