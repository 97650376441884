import React from 'react';
import { JobType } from 'components/account/jobs/job_type';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { Job } from 'models/jobs/jobs';
import { capitalize } from 'utils/format';
interface SummarySSNProps {
  readonly job: Job;
}

export const SummarySSN: React.FC<SummarySSNProps> = ({ job }) => {
  const resultMapper = [
    {
      label: 'ID',
      value: <Text value={job.id} />
    },
    {
      label: 'Type',
      value: (
        <div>
          <Text
            value={job.request.type === JobType.ecbsvSSN ? 'ECBSV SSN' : 'SSN'}
          />
        </div>
      )
    },
    {
      label: 'Status',
      value: (
        <div>
          <Text value={capitalize('Completed')} />
        </div>
      )
    }
  ];

  return (
    <List
      columns={[
        {
          name: 'Label',
          id: 'label',
          xs: 3
        },
        {
          name: 'Value',
          id: 'value',
          xs: 7
        }
      ]}
      items={resultMapper}
      page={1}
      showHeaders={false}
    />
  );
};
