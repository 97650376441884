import React, { FC } from 'react';
import { DataTable } from '../../DataTable';
import { PrivateSSNRequestData } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/private_ssn/private_request_data_summary';

interface PrivateSSNRequestDataTableProps {
  readonly requestData: PrivateSSNRequestData;
}

export const PrivateSSNRequestDataTable: FC<
  PrivateSSNRequestDataTableProps
> = ({ requestData }) => {
  const items = {
    'First Name': requestData.firstName,
    'Last Name': requestData.lastName,
    Phone: requestData.phone,
    Email: requestData.email,
    'Date of Birth': requestData.dob,
    'Street Address': requestData.address?.streetAddress,
    Unit: requestData.address?.unit,
    City: requestData.address?.city,
    State: requestData.address?.state,
    'Postal Code': requestData.address?.postalCode,
    Country: requestData.address?.country
  };

  return <DataTable items={items} />;
};
