import {
  Box,
  FormGroup,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { WordsPointConfig } from './template-form';
import { useTableScroll } from './utils';
import {
  Dim,
  SmartImage
} from 'components/account/jobs/image-slider/smart-image';
import './template-labels.css';

interface Point {
  x: number;
  y: number;
  weight?: number;
}

interface Props {
  readonly drawRectangleMode: boolean;
  readonly editWord: number | null;
  readonly listPoints: WordsPointConfig[];
  readonly newWord: string;
  readonly overwriteRectangle: (index: number, a: Point[]) => void;
  readonly photoDimensions?: Dim;
  readonly photoUrl?: string;
  readonly setDrawRectangleMode: (mode: boolean) => void;
  readonly setEditWord: (val: number | null) => void;
  readonly setListPoints: (points: WordsPointConfig[]) => void;
  readonly setNewWord: (val: string) => void;
  readonly layout: 'column' | 'row';
  readonly onAddNewWord: () => void;
}

const LargeTableCell = styled(TableCell)({
  fontSize: '1.3rem'
});

export const TemplateLabelsForm: React.FC<Props> = ({
  drawRectangleMode,
  editWord,
  listPoints,
  newWord,
  onAddNewWord,
  overwriteRectangle,
  photoDimensions,
  photoUrl,
  setDrawRectangleMode,
  setEditWord,
  setListPoints,
  setNewWord,
  layout
}) => {
  const [currentPointIndex, setCurrentPointIndex] = useState<number | null>(
    null
  );

  const [currentRowIndex, setCurrentRowIndex] = useState<number | undefined>();

  const handleListPointWeightChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const val = Number.parseFloat(e.target.value);

    const newPoints = [...listPoints];
    newPoints[index]!.weight = val;

    setListPoints(newPoints);
  };

  const hasBadWhitespaces = newWord !== newWord.trim();
  const tableBodyRef = useTableScroll(currentPointIndex, layout === 'row');

  const handleRowMouseExit = useCallback(() => {
    setCurrentRowIndex(undefined);
  }, []);

  return (
    <Grid container direction={layout} spacing={2} wrap="nowrap">
      <Grid item>
        <SmartImage
          className={layout === 'row' ? 'sticky-image' : ''}
          editIndex={editWord}
          highlightIndex={currentRowIndex}
          image={{
            dimensions: photoDimensions!,
            url: photoUrl!
          }}
          listPoints={listPoints.map((point, idx) =>
            point.points.map((pointPoints) => ({
              ...pointPoints,
              widx: idx
            }))
          )}
          maxHeightPerc={0.7}
          noPoints={false}
          onMouseOutPoint={(pts) => {
            if (pts.length) {
              setCurrentPointIndex(null);
            }
          }}
          onMouseOverPoint={(pts) => {
            if (pts.length) {
              setCurrentPointIndex(
                (pts[0]! as unknown as { widx: number }).widx
              );
            }
          }}
          readonly={!drawRectangleMode}
          twoPointClick
          updatePoints={overwriteRectangle}
        />
      </Grid>
      <Grid item>
        <Box marginBottom={6}>
          <FormGroup>
            <TextField
              InputProps={{
                endAdornment: (
                  <IconButton
                    className="fa fa-plus fa-solid"
                    disabled={newWord === ''}
                    onClick={onAddNewWord}
                    title="Add new word"
                  />
                )
              }}
              className="g-full-width u-margin-bottom-small"
              error={hasBadWhitespaces}
              helperText={
                hasBadWhitespaces ? 'Leading or trailing whitespaces.' : ''
              }
              label="New Word"
              onChange={(e) => {
                setNewWord(e.target.value);
              }}
              style={{ marginBottom: '24px' }}
              value={newWord}
            />
          </FormGroup>
        </Box>
        <Table
          padding="none"
          size="medium"
          stickyHeader
          style={{
            fontSize: '2rem'
          }}
        >
          <TableHead>
            <TableRow>
              <LargeTableCell>Word</LargeTableCell>
              <LargeTableCell>Weight</LargeTableCell>
              <LargeTableCell align="right">Actions</LargeTableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableBodyRef}>
            {listPoints.map((listPoint, pointIndex) => {
              const widx = pointIndex;

              return (
                <TableRow
                  className={`template-cell ${
                    currentPointIndex === widx ? 'highlight' : null
                  }`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={pointIndex}
                  onMouseEnter={() => {
                    setCurrentRowIndex(pointIndex);
                  }}
                  onMouseLeave={handleRowMouseExit}
                >
                  <LargeTableCell style={{ fontSize: '1.5rem' }}>
                    {listPoint.word}
                  </LargeTableCell>
                  <LargeTableCell>
                    <TextField
                      className="g-full-width u-margin-bottom-small"
                      inputProps={{
                        type: 'number',
                        step: 0.01
                      }}
                      onChange={(e) => {
                        handleListPointWeightChange(widx, e);
                      }}
                      value={listPoint.weight}
                    />
                  </LargeTableCell>
                  <LargeTableCell align="right">
                    <IconButton
                      className="fa fa-solid fa-trash"
                      onClick={() => {
                        const newPoints = listPoints.filter((_val, idx) => {
                          return idx !== widx;
                        });

                        setListPoints(newPoints);
                      }}
                      title="Delete"
                    />{' '}
                    <IconButton
                      className={`fa fa-solid fa-crop-alt ${
                        editWord !== null && editWord === widx ? 'on' : ''
                      }`}
                      onClick={() => {
                        if (widx === editWord) {
                          setEditWord(null);
                          setDrawRectangleMode(false);
                        } else {
                          setEditWord(widx);
                          const newPoints = [...listPoints];
                          newPoints[widx]!.points = [];

                          setListPoints(newPoints);
                          setDrawRectangleMode(true);
                        }
                      }}
                      title="Select area"
                    />{' '}
                    <IconButton
                      className={`fa fa-image ${
                        listPoints[widx]!.image ? 'on' : ''
                      }`}
                      onClick={() => {
                        const newPoints = [...listPoints];
                        // TODO: Figure this out?
                        (
                          newPoints[widx]! as unknown as { image: boolean }
                        ).image = !newPoints[widx]!.image;

                        setListPoints(newPoints);
                      }}
                      title="Mark as image"
                    />
                  </LargeTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
