import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as LogoutIcon } from './icons/icon_log_out.svg';
import {
  dashboardItem,
  filterItems,
  menuItemsBottom,
  menuItemsTop
} from './menu-item-util';
import { SidebarMenuItem } from './sidebar-menu-item';
import { SidebarProfile } from './sidebar-profile';
import { User } from 'models/user/user.model';
import { logout } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { useFlags } from 'utils/hooks';
import './sidebar-v2.css';

interface SBprops {
  readonly toggleScroll: (value: React.SetStateAction<boolean>) => void;
}

export const SidebarV2: FC<SBprops> = ({ toggleScroll }) => {
  const { state, dispatch } = useGlobalState();
  const user = {
    ...state.user
  } as User;

  const signOff = () => {
    dispatch(logout());
  };

  const { dashboardFeature } = useFlags();

  /*
   * Returns the list of items that can be rendered.
   * NOTE: The filter method determines which items the user has access to
   * Use this to conditionally add items based on a feature flag
   */
  const addFeatureItems = () => {
    const items = [...menuItemsTop];
    if (dashboardFeature) {
      items.unshift(dashboardItem);
    }

    return items;
  };

  const topItems = filterItems(user, addFeatureItems());
  const bottomItems = filterItems(user, menuItemsBottom);

  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (width >= 768) {
      toggleScroll(false);
      setOpen(true);
    } else {
      setOpen(false);
      toggleScroll(false);
    }
  }, [width]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleReset = () => {
    setOpen(false);
    toggleScroll(false);
  };

  const isMobile = width < 768;

  return (
    <>
      <div className="hamburger">
        <i
          className="fa fa-bars"
          onClick={() => {
            toggleScroll((prevState) => !prevState);
            setOpen((prevState) => !prevState);
          }}
          style={{ color: 'black', fontSize: '20px' }}
        />
        <img alt="logo" src="assets/vouched_logo.svg" />
      </div>
      {open && (
        <div className="sidebar-v2">
          <div className="sidebar-container">
            <span
              className="close"
              onClick={() => {
                toggleScroll((prevState) => !prevState);
                setOpen((prevState) => !prevState);
              }}
            >
              x
            </span>
            <div>
              <div className="logo-container">
                <img
                  alt="logo"
                  className="sidebar__logo"
                  src="assets/vouched_logo.svg"
                />
              </div>
              <SidebarProfile menuReset={isMobile ? handleReset : undefined} />
              {topItems.map((item) => (
                <SidebarMenuItem
                  item={item}
                  key={item.name}
                  menuReset={isMobile ? handleReset : undefined}
                />
              ))}
              <div>
                <hr className="divider" />
                <div className="divider__label">Documentation</div>
              </div>
              {bottomItems.map((item) => (
                <SidebarMenuItem
                  item={item}
                  key={item.name}
                  menuReset={isMobile ? handleReset : undefined}
                />
              ))}
            </div>
            <div>
              <div
                className="logout"
                data-cy="Logout"
                onClick={() => {
                  signOff();
                }}
              >
                <div className="logout__items">
                  <LogoutIcon />
                  <span>Log Out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
