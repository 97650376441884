import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { CrossCheckDropdowns } from '../CrossCheck/CrossCheckDropdowns';
import { DLVDropdown } from '../DLV/DLVDropdown';
import { FilesDropdown } from '../Files/FilesDropdown';
import { IdDocument } from '../IdDocument/IdDocument';
import { FeatureNotEnabledDropdown } from '../utils';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import { JobError, JobRequestParameters, JobResult } from 'models/jobs/jobs';

interface IDVDropdownsProps {
  readonly jobErrors: JobError[];
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobStatus: JobStatus;
  readonly jobResult: JobResult | undefined;
  readonly crossCheckResult: CrossCheckResult | undefined;
  readonly filesDropdownVisible: boolean;
}

export const IDVDropdowns: FC<IDVDropdownsProps> = ({
  jobErrors,
  jobRequestParams,
  jobStatus,
  jobResult,
  crossCheckResult,
  filesDropdownVisible
}) => {
  return (
    <>
      {!!jobResult && (
        <IdDocument
          jobErrors={jobErrors}
          jobRequestParams={jobRequestParams}
          jobResult={jobResult}
          jobStatus={jobStatus}
        />
      )}
      {jobResult?.featuresEnabled?.crosscheckEnabled ? (
        <CrossCheckDropdowns
          crossCheckResult={crossCheckResult}
          jobErrors={jobErrors}
          jobStatus={jobStatus}
        />
      ) : (
        <FeatureNotEnabledDropdown
          title="CrossCheck"
          url="https://docs.vouched.id/docs/crosscheck-2"
        />
      )}
      {jobResult?.dlv?.dlvEnabled ? (
        <DLVDropdown
          dlvJobResult={jobResult.dlv}
          dlvSubmittedData={{
            id: jobResult.id,
            state: jobResult.state,
            lastName: jobResult.lastName,
            birthDate: jobResult.birthDate,
            expirationDate: jobResult.expireDate
          }}
          jobStatus={jobStatus}
        />
      ) : (
        <FeatureNotEnabledDropdown
          title="DLV"
          url="https://docs.vouched.id/docs/dlv-1"
        />
      )}
      {filesDropdownVisible && <FilesDropdown jobStatus={jobStatus} />}
    </>
  );
};
