import React from 'react';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
interface CrossCheckProps {
  readonly jobId: string;
}

export const SummaryCrosscheck: React.FC<CrossCheckProps> = ({ jobId }) => {
  const resultMapper = [
    {
      label: 'ID',
      value: <Text value={jobId} />
    },
    {
      label: 'Type',
      value: (
        <div>
          <Text value="CrossCheck" />
        </div>
      )
    },
    {
      label: 'Status',
      value: (
        <div>
          <Text value="Completed" />
        </div>
      )
    }
  ];

  return (
    <List
      columns={[
        {
          name: 'Label',
          id: 'label',
          xs: 3
        },
        {
          name: 'Value',
          id: 'value',
          xs: 7
        }
      ]}
      items={resultMapper}
      page={1}
      showHeaders={false}
    />
  );
};
