import { TextField } from '@material-ui/core';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { User } from 'models/user/user.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { changePassword } from 'store/sagas/sagas';

export const Password: React.FC = () => {
  const { state, asyncDispatch, dispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);

  const user = {
    ...state.user
  } as User;

  type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const [oldPassword, setOldPassword] = useState('');
  const handleChangeOldPassword = useCallback((e: InputEvent) => {
    setOldPassword(e.target.value);
  }, []);

  const [password, setPassword] = useState('');
  const handleChangePassword = useCallback((e: InputEvent) => {
    setPassword(e.target.value);
  }, []);

  const submitIsDisabled = password.length === 0;

  const onSubmitPassword = (event: FormEvent) => {
    event.preventDefault();

    setIsLoading(true);
    asyncDispatch(
      changePassword({
        newPassword: password,
        oldPassword
      })
    )
      .then(() => {
        setIsLoading(false);
        setPassword('');
        setOldPassword('');
        dispatch(
          setMessages({
            severity: 'success',
            value: 'Password changed successfully'
          })
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <form
      autoComplete="off"
      className="g-form"
      noValidate
      onSubmit={onSubmitPassword}
    >
      <span className="title">Change Password</span>
      <TextField
        className="u-margin-bottom-small"
        disabled={!user.emailVerified}
        label="Old password"
        onChange={handleChangeOldPassword}
        required
        type="password"
        value={oldPassword}
      />
      <TextField
        className="u-margin-bottom-small"
        disabled={!user.emailVerified}
        label="New password"
        onChange={handleChangePassword}
        required
        type="password"
        value={password}
      />
      <ButtonWithLoader
        disabled={submitIsDisabled}
        isLoading={isLoading}
        name="Update password"
      />
      <Link
        className="link-without-underline g-link u-margin-top-medium u-margin-bottom-medium"
        to="/forgot-password"
      >
        Forgot Password?
      </Link>
    </form>
  );
};
