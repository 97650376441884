import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React from 'react';

interface Props {
  readonly date?: Date | null;
  readonly onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => void;
  readonly label?: string;
  readonly minDate?: Date | null;
  readonly maxDate?: Date | null;
  readonly className?: string;
}

export const Datepicker: React.FC<Props> = ({
  date,
  onChange,
  label,
  minDate,
  maxDate,
  className
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={className}>
        <KeyboardDatePicker
          disableToolbar
          format="MMM d yyyy"
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          value={date}
          variant="inline"
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};
