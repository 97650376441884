import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { PasswordInput } from 'components/reusable/password-input';
import { setAuthData } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { acceptInvite } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { MapData, rawParseQuery, transformQueryTypes } from 'utils/url';

const mapData: MapData = {
  t: 'string'
};

export const AcceptUserInvite: React.FC = () => {
  const { dispatch, asyncDispatch } = useGlobalState();
  const [loading, setLoading] = useState(false);

  const { t: token } = transformQueryTypes(mapData, rawParseQuery());
  const [password, setPassword] = useState('');
  type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      asyncDispatch(acceptInvite(token as string, password))
        .then((data) => {
          setLoading(false);
          dispatch(setAuthData(data));
          history.push('/account/jobs');
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [asyncDispatch, dispatch, password, token]
  );

  const handleChangePassword = useCallback((e: InputEvent) => {
    setPassword(e.target.value);
  }, []);

  return (
    <div className="u-align-center">
      <div className="g-page-without-header-footer">
        <div className="u-align-center">
          <div className="logo">
            <img alt="logo" src="/assets/vouched-logo.svg" />
          </div>
        </div>
        {!token && (
          <div className="u-margin-top-xlarge u-align-center">
            Invalid token
          </div>
        )}
        {token && (
          <div className="u-align-horizontal-center u-margin-top-xlarge">
            <form
              autoComplete="off"
              className="g-form"
              noValidate
              onSubmit={handleFormSubmit}
            >
              <p>Enter a new password to join Vouched.</p>
              <div className="u-margin-bottom-medium u-margin-top-xlarge">
                <PasswordInput
                  handleChangePassword={handleChangePassword}
                  invalidData={false}
                  value={password}
                />
              </div>
              <ButtonWithLoader
                disabled={!password}
                isLoading={loading}
                name="Accept Invite"
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
