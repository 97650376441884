const getRandomId = () => {
  const returnedIdLength = 7;
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const charactersLength = characters.length;
  for (let i = 0; i < returnedIdLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export type VagueErrorType =
  | string
  | { error: string }
  | { errors: { type: string; message: string }[] };

export const convertErrors = (
  errors: VagueErrorType
): { type: string; message: string; id: string }[] => {
  const err: { type: string; message: string; id: string }[] = [];
  const id = getRandomId();

  if (typeof errors === 'string') {
    err.push({ type: errors, message: errors, id });
  } else if (typeof errors === 'object' && 'error' in errors) {
    err.push({ type: errors.error, message: errors.error, id });
  } else if (Array.isArray(errors.errors)) {
    for (const item of errors.errors) {
      err.push({
        ...item,
        id
      });
    }
  }

  return err;
};
