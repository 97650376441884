import { TextField } from '@material-ui/core';
import React from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { Job } from 'models/jobs/jobs';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { noop } from 'utils/ui.utils';

interface DeleteProps {
  readonly job: Job;
  readonly handleRemove: (id: string) => void;
  readonly isLoading: boolean;
  readonly setIsLoading: (s: boolean) => void;
  readonly onClose: () => void;
  readonly deleteInput: React.MutableRefObject<HTMLInputElement | null>;
  readonly readonly: boolean | null;
}

export const Delete: React.FC<DeleteProps> = ({
  job,
  handleRemove,
  isLoading,
  setIsLoading,
  onClose,
  deleteInput,
  readonly
}) => {
  const { dispatch } = useGlobalState();

  return (
    <>
      <p>
        Once deleted, it will be gone forever. Please be certain. Type in '
        {job.id}' for ID to proceed.
      </p>
      <form autoComplete="off" noValidate>
        <div>
          <TextField
            className="u-margin-bottom-large"
            inputRef={deleteInput}
            label="ID"
            name="ID"
            required
          />
        </div>
        <div>
          <ButtonWithLoader
            color="secondary"
            disabled={!!readonly}
            isLoading={isLoading}
            name="Remove"
            onClick={
              readonly
                ? noop
                : () => {
                    if (deleteInput.current?.value !== job.id) {
                      dispatch(
                        setMessages({
                          value: 'ID is incorrect',
                          severity: 'error'
                        })
                      );

                      return;
                    }

                    try {
                      setIsLoading(true);
                      handleRemove(job.id);
                      onClose();
                    } finally {
                      setIsLoading(false);
                    }
                  }
            }
          />
        </div>
      </form>
    </>
  );
};
