export const statusMapper = (
  status: string,
  success: boolean,
  warnings?: boolean | null
): {
  state: 'active' | 'approved' | 'rejected' | 'removed' | 'warning' | null;
} => {
  let state: 'active' | 'approved' | 'rejected' | 'removed' | 'warning' | null =
    'active';

  if (status === 'removed') {
    state = 'removed';
  } else if (status === 'completed' && !success) {
    state = 'rejected';
  } else if (status === 'completed' && success && warnings) {
    state = 'warning';
  } else if (status === 'completed' && success) {
    state = 'approved';
  }

  return { state };
};
