import { Action } from 'store/actions/actions';
import { initialState, StoreState } from 'store/reducers/reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: {
      connect(opts: object): {
        init(state: object): void;
        send(action: Action | string, state: object): void;
      };
    };
  }
}

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__?.connect({});

if (devTools) devTools.init(initialState);

export function storeDevtoolsMiddleware(
  reducer: (state: StoreState, action: Action) => StoreState
): (state: StoreState, action: Action) => StoreState {
  if (!devTools) {
    return reducer;
  }

  return (state: StoreState, action: Action) => {
    const updatedState = reducer(state, action);
    devTools.send(action, updatedState);
    return updatedState;
  };
}
