import classNames from 'classnames';
import React, {
  FC,
  forwardRef,
  MouseEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { ImageZoom } from './image-zoom';
import { colors } from 'utils/jobs';
import { roundNumberStrict } from 'utils/ui.utils';

export interface Dim {
  width: number;
  height: number;
}

interface Point {
  x: number;
  y: number;
}

const imageSize = (imgSize: Dim, imgWrapSize: Dim) => {
  if (imgWrapSize.width === 0 || imgWrapSize.height === 0) {
    return { width: 0, height: 0, heightPadding: 0 };
  }

  imgSize = { ...imgSize };
  imgWrapSize = { ...imgWrapSize };

  if (imgSize.width > imgWrapSize.width) {
    const ratio = imgSize.height / imgSize.width;
    imgSize.width = imgWrapSize.width;
    imgSize.height = ratio * imgSize.width;
  }

  if (imgSize.height > imgWrapSize.height) {
    const ratio = imgSize.width / imgSize.height;
    imgSize.height = imgWrapSize.height;
    imgSize.width = ratio * imgSize.height;
  }

  const width = Math.round(imgSize.width);
  const height = Math.round(imgSize.height);
  const heightPadding = Math.round((imgWrapSize.height - height) / 2);

  return {
    heightPadding,
    width,
    height
  };
};

const pointSizeMap = {
  small: '2',
  med: '6',
  large: '10'
};

const toPosition = (e: MouseEvent, canvas: HTMLElement): Point => {
  const { top, left } = canvas.getBoundingClientRect();
  return { x: Math.round(e.clientX - left), y: Math.round(e.clientY - top) };
};

let _lastMove = 0;

interface CanvasProps {
  readonly width: number;
  readonly pointSize: 'large' | 'med' | 'small';
  readonly readonly: boolean;
  readonly height: number;
  readonly listPoints?: (Point & { highlight?: boolean })[][];
  readonly updatePoints?: (index: number, a: Point[]) => void;
  readonly twoPointClick?: boolean | null;
  readonly onMouseOutPoint?: (a: Point[], b: number) => void;
  readonly onMouseOverPoint?: (a: Point[], b: number) => void;
  readonly editIndex?: number | null;
  readonly highlightIndex?: number;
}

const Canvas = ({
  width,
  height,
  updatePoints,
  twoPointClick,
  readonly,
  listPoints,
  onMouseOutPoint,
  onMouseOverPoint,
  pointSize,
  editIndex,
  highlightIndex
}: CanvasProps) => {
  const [canvasRef, setCanvasRef] = useState<HTMLElement | null>(null);
  const [coords, setCoords] = useState<{ x: number | null; y: number | null }>({
    x: null,
    y: null
  });

  const [points, setPoints] = useState<Point[]>([]);

  const onMouseMove = (e: MouseEvent) => {
    const pos = toPosition(e, canvasRef!);
    const lastMove = Date.now();
    _lastMove = lastMove;
    setTimeout(() => {
      if (lastMove === _lastMove) {
        setCoords({ x: null, y: null });
      }
    }, 5 * 1000);

    setCoords(pos);
  };

  const handleUpdatePoints = (newPoints: Point[]) => {
    if (updatePoints && editIndex !== undefined && editIndex !== null) {
      updatePoints(
        editIndex,
        newPoints.map((p) => {
          return {
            x: p.x / width,
            y: 1 + p.y / height
          };
        })
      );

      setPoints(newPoints);
    }
  };

  const onMouseLeave = () => {
    setCoords({ x: null, y: null });
  };

  const onMouseClick = (e: MouseEvent) => {
    if (twoPointClick && points.length === 1) {
      let pos = toPosition(e, canvasRef!);
      pos = {
        x: roundNumberStrict(pos.x || 0 / width, 4),
        y: roundNumberStrict(pos.y || 0 / height, 4)
      };

      const fp = points[0]!;
      const tr = { x: pos.x, y: fp.y };
      const bl = { x: fp.x, y: pos.y };
      handleUpdatePoints([...points, tr, pos, bl]);
      return;
    }

    const pos = toPosition(e, canvasRef!);

    handleUpdatePoints([
      ...points,
      {
        x: roundNumberStrict(pos.x || 0 / width, 4),
        y: roundNumberStrict(pos.y || 0 / height, 4)
      }
    ]);
  };

  useEffect(() => {
    setPoints([]);
  }, [readonly]);

  listPoints ||= [];
  if (!listPoints.length && points.length) {
    listPoints = [points];
  }

  return (
    <div
      onClick={readonly ? () => true : onMouseClick}
      onMouseLeave={readonly ? () => true : onMouseLeave}
      onMouseMove={readonly ? () => true : onMouseMove}
      ref={(re) => {
        setCanvasRef(re);
      }}
    >
      <svg className="highlight-svg" height={height} width={width}>
        {listPoints.map((pts, idx) => {
          const highlight = pts.some((p) => p.highlight);
          pts = pts.map((p) => ({
            ...p,
            x: width * p.x,
            y: height * p.y
          }));

          if (pts.some((p) => Number.isNaN(p.x) || Number.isNaN(p.y))) {
            return null;
          }

          return (
            <g
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              onMouseOut={() => {
                onMouseOutPoint?.(pts, idx);
              }}
              onMouseOver={() => {
                onMouseOverPoint?.(pts, idx);
              }}
            >
              <polygon
                fill={
                  highlight || highlightIndex === idx
                    ? colors.red
                    : colors.green
                }
                fillOpacity="0.50"
                points={pts.map((p) => `${p.x},${p.y}`).join(' ')}
              />
              {pts.map((p) => (
                <circle
                  cx={p.x}
                  cy={p.y}
                  fill={highlight ? colors.red : colors.green}
                  key={`${p.x}${p.y}`}
                  r={pointSizeMap[pointSize]}
                />
              ))}
            </g>
          );
        })}

        {!readonly && coords.x && coords.y ? (
          <line
            style={{ stroke: colors.red, strokeWidth: 2 }}
            x1="0"
            x2={width}
            y1={coords.y}
            y2={coords.y}
          />
        ) : null}
        {!readonly && coords.x && coords.y ? (
          <line
            style={{ stroke: colors.red, strokeWidth: 2 }}
            x1={coords.x}
            x2={coords.x}
            y1={0}
            y2={height}
          />
        ) : null}
        {!readonly && coords.x !== null && coords.y !== null ? (
          <circle
            cx={coords.x}
            cy={coords.y}
            fill={colors.red}
            r={pointSizeMap[pointSize]}
          />
        ) : null}
      </svg>
    </div>
  );
};

interface SmartImagePureProps {
  readonly image?: { url: string; dimensions: Dim };
  readonly listPoints?: Point[][];
  readonly imageWrapDimensions: Dim;
  readonly pointSize: 'large' | 'med' | 'small';
  readonly updatePoints?: (index: number, a: Point[]) => void;
  readonly noPoints?: boolean;
  readonly addHeightPadding?: boolean;
  readonly zoomSize: number;
  readonly degrees: number;
  readonly enableZoom?: boolean;
  readonly readonly?: boolean;
  readonly noImageLabel?: string;
  readonly onMouseOutPoint?: (a: Point[], b: number) => void;
  readonly onMouseOverPoint?: (a: Point[], b: number) => void;
  readonly onImageLoaded?: () => void;
  readonly twoPointClick?: boolean;
  readonly editIndex?: number | null;
  readonly type?: string | null;
  readonly className?: string;
  readonly highlightIndex?: number;
}

const SmartImagePure = forwardRef<HTMLDivElement, SmartImagePureProps>(
  (
    {
      image,
      readonly = true,
      enableZoom = false,
      noPoints = false,
      degrees,
      pointSize,
      zoomSize,
      addHeightPadding = true,
      listPoints,
      onMouseOutPoint,
      onMouseOverPoint,
      onImageLoaded,
      twoPointClick = false,
      imageWrapDimensions,
      updatePoints,
      noImageLabel = 'Please wait for a label...',
      editIndex,
      type = '',
      className,
      highlightIndex
    },
    ref
  ) => {
    const dimensions = image
      ? imageSize(image.dimensions, imageWrapDimensions)
      : { width: 0, height: 0, heightPadding: 0 };

    if (!addHeightPadding) {
      dimensions.heightPadding = 0;
      imageWrapDimensions = {
        ...imageWrapDimensions,
        height: dimensions.height
      };
    }

    const originalImageDimensions = image ? image.dimensions : {};

    return (
      <div className={classNames('smart-image', className)} ref={ref}>
        {image && dimensions.width ? (
          <div className="div-1">
            <div
              className="div-2"
              style={{
                position: 'relative',
                marginTop: `${dimensions.heightPadding}px`
              }}
            >
              <ImageZoom
                degrees={degrees}
                enableZoom={enableZoom}
                height={dimensions.height}
                imageDimensions={originalImageDimensions}
                onImageLoaded={onImageLoaded}
                src={image.url}
                width={dimensions.width}
                zoomSize={zoomSize}
              />
              {noPoints ? null : (
                <Canvas
                  editIndex={editIndex}
                  height={dimensions.height}
                  highlightIndex={highlightIndex}
                  listPoints={listPoints}
                  onMouseOutPoint={onMouseOutPoint}
                  onMouseOverPoint={onMouseOverPoint}
                  pointSize={pointSize}
                  readonly={readonly}
                  twoPointClick={readonly ? null : twoPointClick}
                  updatePoints={updatePoints}
                  width={dimensions.width}
                />
              )}
              {type === '' ? null : (
                <div>
                  <br />
                  <b> {type} </b>
                </div>
              )}
            </div>
          </div>
        ) : (
          <h4>{noImageLabel}</h4>
        )}
      </div>
    );
  }
);

SmartImagePure.displayName = 'SmartImagePure';

export interface Image {
  url: string;
  dimensions: Dim;
}

type AugPoint = Point & { highlight?: boolean };

interface SmartImageProps {
  readonly maxHeightPerc: number;
  readonly enableZoom?: boolean;
  readonly noPoints: boolean;
  readonly image: Image;
  readonly listPoints?: AugPoint[][];
  readonly onMouseOutPoint?: (a: AugPoint[], b: number) => void;
  readonly onMouseOverPoint?: (a: AugPoint[], b: number) => void;
  readonly twoPointClick?: boolean;
  readonly readonly?: boolean;
  readonly updatePoints?: (index: number, a: Point[]) => void;
  readonly editIndex?: number | null;
  readonly type?: string | null;
  readonly degree?: number;
  readonly className?: string;
  /**
   * Needs refactor, points should contain meta-data but types are messy.
   */
  readonly highlightIndex?: number;
}

export const SmartImage: FC<SmartImageProps> = ({
  maxHeightPerc,
  enableZoom = false,
  noPoints,
  listPoints,
  image,
  onMouseOverPoint,
  onMouseOutPoint,
  twoPointClick,
  readonly,
  updatePoints,
  editIndex,
  type = '',
  degree = 0,
  className,
  highlightIndex
}) => {
  const [degrees, setDegrees] = useState(degree);
  const [imageWrapDimensions, setImageWrapDimensions] = useState({
    // HACK: SmartImage doesn't work right when this isn't done, it needs to be refactored badly
    width: enableZoom ? 0 : 1,
    height: enableZoom ? 0 : 1
  });

  const imageRef = useRef<HTMLDivElement>(null);

  const redoDimensions = () => {
    if (!imageRef.current) {
      return;
    }

    const width = imageRef.current.clientWidth;
    setImageWrapDimensions({ height: maxHeightPerc * width || width, width });
  };

  useEffect(() => {
    setDegrees(degree);
  }, [degree]);

  useEffect(() => {
    if (enableZoom) {
      setTimeout(() => {
        redoDimensions();
      }, 10);
    }

    window.addEventListener('resize', redoDimensions);
    return () => {
      window.removeEventListener('resize', redoDimensions);
    };
  }, []);

  const onImageLoadedHandle = () => {
    setTimeout(redoDimensions, 10);
  };

  return (
    <SmartImagePure
      className={className}
      degrees={degrees}
      editIndex={editIndex}
      enableZoom={enableZoom}
      highlightIndex={highlightIndex}
      image={image}
      imageWrapDimensions={imageWrapDimensions}
      listPoints={listPoints}
      noPoints={noPoints}
      onImageLoaded={onImageLoadedHandle}
      onMouseOutPoint={onMouseOutPoint}
      onMouseOverPoint={onMouseOverPoint}
      pointSize="small"
      readonly={readonly}
      ref={imageRef}
      twoPointClick={twoPointClick}
      type={type}
      updatePoints={updatePoints}
      zoomSize={2}
    />
  );
};
