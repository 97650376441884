import { Box, Button, Heading, Input, Stack } from '@vouched-id/vault';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { VouchedLogo } from 'components/reusable/vouched-logo';
import { setAuthData, setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { resetPassword } from 'store/sagas/sagas';
import { history } from 'utils/history';

export const ResetPassword: React.FC = () => {
  const { dispatch, asyncDispatch } = useGlobalState();

  const [invalidConfirm, setInvalidConfirm] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [token, setToken] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation<{ sid: string }>();
  const { sid } = location.state;

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const pw = e.target.value;
    setPassword(pw);

    if (pw === '' && confirmPassword === '') {
      setInvalidConfirm(false);
      return;
    }

    if (confirmPassword !== '') {
      setInvalidConfirm(pw !== confirmPassword);
    }
  };

  const handleChangeConfirmPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const confirm = e.target.value;
      setConfirmPassword(confirm);

      setInvalidConfirm(confirm !== password);
    },
    [password]
  );

  const handleToken = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value);
  }, []);

  const submit = (event: FormEvent) => {
    event.preventDefault();

    if (!isLoading && password && confirmPassword && !invalidConfirm) {
      setIsLoading(true);
      asyncDispatch(resetPassword({ password, token, sid, recaptchaToken }))
        .then((data) => {
          dispatch(setAuthData(data));
          history.push('/account');
          dispatch(
            setMessages({
              value: 'Password changed successfully',
              severity: 'success'
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // eventually update vault theme
  const midPurple = '#453FC1';

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      height="100vh"
      justifyContent="center"
      padding="spacing.large"
      style={{ boxSizing: 'border-box' }}
      tag="main"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        maxWidth="52rem"
      >
        <Box marginBottom="spacing.xlarge">
          <VouchedLogo fill={midPurple} maxWidth="100%" width="250px" />
        </Box>

        <Box marginBottom="spacing.large">
          <Heading style={{ fontWeight: 'bold' }} tag="h1" variation="h3">
            Reset Password
          </Heading>
        </Box>
        <Box noValidate onSubmit={submit} tag="form" width="100%">
          <Stack spacing="spacing.large">
            <Box>
              <Input
                inputMode="text"
                label="Password"
                name="password"
                onChange={handleChangePassword}
                required
                type="password"
              />
            </Box>
            <Box>
              <Input
                inputMode="text"
                isInvalid={invalidConfirm}
                label="Confirm Password"
                name="confirm-password"
                onChange={handleChangeConfirmPassword}
                required
                type="password"
                validationMessage="Passwords do not match."
              />
            </Box>
            <Box>
              <Input
                helperText="Please check your email for a reset code."
                inputMode="text"
                label="Verification Code"
                name="verification-code"
                onChange={handleToken}
                required
                type="text"
              />
            </Box>
            <ReCAPTCHA
              onChange={setRecaptchaToken}
              sitekey={process.env['REACT_APP_RECAPTCHA_KEY']!}
            />
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Button
                data-cy="submit"
                style={{ backgroundColor: midPurple }}
                title={isLoading ? 'loading' : ''}
              >
                Reset Password
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
