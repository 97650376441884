import React, { ReactElement } from 'react';
import { getDlvSummaryStatus } from '../../utils';
import { DlvDataUnavailable } from './utils';
import { List } from 'components/reusable/list';
import { Tick } from 'components/reusable/tick';
import { Text } from 'components/text';
import {
  DlvPipelineError,
  JobResult,
  VerifyDlvResponse
} from 'models/jobs/jobs';

interface DlvProps {
  readonly result: JobResult;
}

const dlvPipelineErrorRec: Record<DlvPipelineError, string> = {
  [DlvPipelineError.UnsupportedLicenseType]: 'Unsupported License Type',
  [DlvPipelineError.UnsupportedCountry]: 'Unsupported Country',
  [DlvPipelineError.MissingState]: 'Missing State',
  [DlvPipelineError.MissingLicenseNumber]: 'Missing License Number',
  [DlvPipelineError.IdentificationTypeUnsupportedByState]:
    'Identification Card Validation Unsupported By State'
};

const getMatch = (dlvResult: VerifyDlvResponse, field: string) => {
  if (dlvResult.fieldMatchesUnavailable.includes(field)) {
    return 'Unavailable';
  }

  if (dlvResult.fieldMatchesAvailable.includes(field)) {
    if (dlvResult.fieldMatchesSucceeded.includes(field)) {
      return 'True';
    }

    if (dlvResult.fieldMatchesFailed.includes(field)) {
      return 'False';
    }
  }

  return '';
};

export const Dlv: React.FC<DlvProps> = ({ result }) => {
  if (!result.dlv) {
    return <DlvDataUnavailable />;
  }

  interface MatchItem {
    name: string;
    customRenderField: ReactElement;
    customRenderRequestData: ReactElement;
    customRenderMatch: ReactElement;
  }

  const dlvData = result.dlv;
  const statusItems = [];
  let matchItems: MatchItem[] = [];
  statusItems.push({
    name: 'Job Status',
    customRenderValue: (
      <>
        <Text value={dlvData.dlvStatusMessage} />
        {dlvData.error && dlvPipelineErrorRec[dlvData.error] && (
          <Text strongest={false} value={dlvPipelineErrorRec[dlvData.error]} />
        )}
      </>
    )
  });

  const dlvResult = dlvData.result;

  if (dlvResult) {
    statusItems.push({
      name: 'Check Performed',
      customRenderValue: (
        <>
          <Text value={dlvResult.wasCheckPerformed ? 'True' : 'False'} />
          {!dlvResult.stateSupported && (
            <Text strongest={false} value="Unsupported State" />
          )}
        </>
      )
    });

    if (dlvResult.wasCheckPerformed) {
      statusItems.push(
        {
          name: 'Approval',
          customRenderValue: <Text value={dlvResult.status} />,
          checkRender: (
            <Tick id="dlv-tick" state={getDlvSummaryStatus(dlvResult.status)} />
          )
        },
        {
          name: 'Source',
          customRenderValue: <Text value={dlvResult.source} />
        }
      );

      // TODO (https://github.com/vouched/product/issues/55): remove issueDate results, the check on backend is temporarily removed b/c re-issue dates cause failures, bring back when resolved
      matchItems = [
        {
          name: 'Matches',
          customRenderField: (
            <div className="job-modal-ip">
              <Text strongest={false} value="License Number" />
              {dlvResult.source === 'governmental' && (
                <Text strongest={false} value="State" />
              )}
              <Text strongest={false} value="Last Name" />
              <Text strongest={false} value="Birth Date" />
              <Text strongest={false} value="Expiration Date" />
            </div>
          ),
          customRenderRequestData: (
            <div className="job-modal-ip">
              <Text value={result.id} />
              {dlvResult.source === 'governmental' && (
                <Text value={result.state} />
              )}
              <Text value={result.lastName} />
              <Text value={result.birthDate} />
              <Text value={result.expireDate} />
            </div>
          ),
          customRenderMatch: (
            <div className="job-modal-ip">
              <Text value={getMatch(dlvResult, 'licenseNumber')} />
              {dlvResult.source === 'governmental' && (
                <Text value={getMatch(dlvResult, 'state')} />
              )}
              <Text value={getMatch(dlvResult, 'lastName')} />
              <Text value={getMatch(dlvResult, 'birthDate')} />
              <Text value={getMatch(dlvResult, 'expirationDate')} />
            </div>
          )
        }
      ];
    }
  } else {
    statusItems.push({
      name: 'Check Performed',
      customRenderValue: <Text value="False" />
    });
  }

  return (
    <div>
      {statusItems.length > 0 && (
        <List
          columns={[
            {
              name: 'name',
              id: 'name',
              xs: 2
            },
            {
              name: 'value',
              xs: 7,
              customRenderValue: (item) => item.customRenderValue
            },
            {
              name: 'pass',
              xs: 3,
              customRenderValue: (item) => item.checkRender ?? null
            }
          ]}
          items={statusItems}
          margin={false}
          page={1}
          showHeaders={false}
        />
      )}
      {matchItems.length > 0 && (
        <List<MatchItem>
          columns={[
            {
              name: 'name',
              id: 'name',
              xs: 2
            },
            {
              name: 'field',
              xs: 4,
              customRenderValue: (item) => item.customRenderField
            },
            {
              name: 'requestData',
              xs: 4,
              customRenderValue: (item) => item.customRenderRequestData
            },
            {
              name: 'match',
              xs: 2,
              customRenderValue: (item) => item.customRenderMatch
            }
          ]}
          items={matchItems}
          margin={false}
          page={1}
          showHeaders={false}
        />
      )}
    </div>
  );
};
