import { CircularProgress } from '@material-ui/core';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdf';
import React, { useEffect, useState } from 'react';
import { setInvoices } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { getInvoices } from 'store/sagas/sagas';
import { longFormatDate } from 'utils/longFormatDate';
import './manage-subscription.css';

export const InvoiceList: React.FC = () => {
  const { state, dispatch, asyncDispatch } = useGlobalState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!state.invoices) {
      setLoading(true);
      asyncDispatch(getInvoices())
        .then((data) => {
          dispatch(setInvoices(data));
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div>
      <h1>Invoices</h1>
      <div>
        {loading ? (
          <CircularProgress size="1.2rem" style={{ color: '#290e88' }} />
        ) : state.invoices ? (
          <div className="billing__container">
            <div className="billing__container-head">
              <span>Number</span>
              <span>Status</span>
              <span>Total</span>
              <span>Created</span>
              <span>View/Pay Invoice</span>
              <span>Download PDF</span>
            </div>
            <div className="billing__container-body">
              {state.invoices.map((i) => {
                if (i.status === 'draft') {
                  return null;
                }

                const dollars = i.total / 100;

                return (
                  <div key={i.id}>
                    <span>{i.number}</span>
                    <span>
                      {i.status.charAt(0).toUpperCase() + i.status.slice(1)}
                    </span>
                    <span>
                      {dollars.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      })}
                    </span>
                    <span>{longFormatDate(i.created)}</span>
                    <a href={i.hosted_invoice_url}>
                      <span>View/Pay Invoice</span>
                    </a>
                    {i.invoice_pdf && (
                      <a href={i.invoice_pdf}>
                        <PictureAsPdfOutlinedIcon
                          style={{ color: '#4122af' }}
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            Your account does not currently have any past or outstanding
            invoices
          </div>
        )}
      </div>
    </div>
  );
};
