const dateOnly = (date: Date | string): Date => {
  const out = new Date(date);
  out.setMilliseconds(0);
  out.setHours(0);
  out.setMinutes(0);
  out.setSeconds(0);
  return out;
};

export const passwordResetCheck = (lastDate: Date | string): boolean => {
  const lastCopy = dateOnly(lastDate);
  const today = dateOnly(new Date());

  return (today.getTime() - lastCopy.getTime()) / 1000 / 60 / 60 / 24 > 90;
};
