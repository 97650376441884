import * as Nominatim from 'nominatim-browser';
import React from 'react';
import GeolookupSelect, {
  GeoData,
  GeoLookupSuggestion
} from 'react-geolookup-v2';

import './geolocator.css';

interface Props {
  readonly onSelect: (suggest?: GeoLookupSuggestion) => GeoData;
  readonly error: boolean;
}
const onSuggestsLookup = async (userInput: string | undefined) => {
  if (userInput === undefined) {
    return [];
  }

  return Nominatim.geocode({
    q: userInput,
    addressdetails: true
  });
};

interface SuggestThing {
  address: {
    village?: string;
    hamlet?: string;
    city?: string;
    state?: string;
    country: string;
  };
}

const getSuggestLabel = (suggest: SuggestThing) => {
  let city: string | undefined;

  if (suggest.address.village) {
    city = suggest.address.village;
  } else if (suggest.address.hamlet) {
    city = suggest.address.hamlet;
  } else if (suggest.address.city) {
    ({ city } = suggest.address);
  }

  let displayName = '';

  if (city) {
    displayName += `${city}, `;
  }

  if (suggest.address.state) {
    displayName += `${suggest.address.state}, `;
  }

  displayName += suggest.address.country;

  return displayName;
};

export const GeoLocator: React.FC<Props> = ({ error, onSelect }) => {
  return (
    <div>
      <GeolookupSelect
        error={error}
        getSuggestLabel={getSuggestLabel}
        onGeocodeSuggest={onSelect}
        onSuggestsLookup={onSuggestsLookup}
        placeholder="Search for a city, e.g. Seattle"
        radius="20"
      />
    </div>
  );
};
