import React, { FC, ReactNode } from 'react';
import { emptyStar, fillStar } from '../../../svg';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';

const getOneStar = (svg: ReactNode, number: number) => {
  const style = {
    margin: '30px 30px 30px 0',
    cursor: 'pointer',
    display: 'inline'
  };

  return (
    <div key={number} style={style}>
      {svg}
    </div>
  );
};

const getStars = (number: number) => {
  const starsQuantity = [1, 2, 3, 4, 5];
  return starsQuantity.map((item, i) => {
    if (item <= number) {
      return getOneStar(fillStar, i);
    }

    return getOneStar(emptyStar, i);
  });
};

export const Survey: FC<{
  readonly surveyPoll: number;
  readonly surveyMessage?: string;
}> = ({ surveyPoll, surveyMessage }) => {
  return (
    <List
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'value',
          xs: 10,
          customRenderValue: (item) => item.customRenderValue
        }
      ]}
      items={[
        {
          name: 'Rating',
          customRenderValue: getStars(surveyPoll)
        },
        {
          name: 'Message',
          customRenderValue: (
            <Text overflow={false} value={surveyMessage} wrap />
          )
        }
      ]}
      page={1}
      showHeaders={false}
    />
  );
};
