import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { FC, useCallback, useContext, useState } from 'react';
import { TooltipIcon } from '../Icons/TooltipIcon';

export const Tooltip: FC = ({ children }) => {
  const { tokens } = useContext(ThemeContext).theme;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleHoverEnter = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      setHover(true);
    },
    []
  );

  const handleHoverLeave = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      setHover(false);
    },
    []
  );

  const handleFocus = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    if (event.currentTarget === event.target) {
      setFocus(true);
    }
  }, []);

  const handleBlur = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    if (event.currentTarget === event.target) {
      setFocus(false);
    }
  }, []);

  return (
    <Box
      height="20px"
      onBlur={handleBlur}
      onFocus={handleFocus}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      position="relative"
      style={{
        boxShadow: hover || focus ? tokens.shadows.hover : 'none',
        borderRadius: '10px'
      }}
      tabIndex={0}
      width="20px"
    >
      <TooltipIcon />
      {(hover || focus) && (
        <Box
          style={{
            zIndex: '1000',
            position: 'absolute',
            top: ['20px', '-50%'],
            left: ['-285px', '35px'],
            width: '250px',
            backgroundColor: tokens.colors.interactiveMuted,
            borderRadius: '5px',
            padding: '10px',
            boxShadow: tokens.shadows.hover
          }}
        >
          <Text>{children}</Text>
        </Box>
      )}
    </Box>
  );
};
