import React, { FC } from 'react';
import { DataTable } from '../DataTable';
import { AMLSanctionDetails } from './AMLCompletedResult';
import { JobExtractedData } from './AMLDropdowns';

interface AMLDetailsTablesProps {
  readonly summary: {
    name?: string;
    hits: number;
    matchedTypes: string;
    countries: string;
    yearOfBirth: string;
    aka: string;
  };
  readonly politicalPositions: string;
  readonly extractedData: JobExtractedData;
  readonly sanctions: AMLSanctionDetails[];
}

export const AMLDetailsTables: FC<AMLDetailsTablesProps> = ({
  summary,
  politicalPositions,
  extractedData,
  sanctions
}) => {
  return (
    <>
      <DataTable
        caption="Summary"
        items={{
          Name: summary.name,
          Hits: summary.hits,
          'Matched Types': summary.matchedTypes,
          Countries: summary.countries,
          'Year of Birth': summary.yearOfBirth,
          AKA: summary.aka
        }}
      />
      {sanctions.map((sanction) => {
        return (
          <DataTable
            caption={sanction.type.toUpperCase()}
            items={{
              'Listed Name': sanction.name.toUpperCase(),
              'Listed URL': sanction.url,
              Listed: sanction.dates.start,
              'Listing Ended': sanction.dates.end
            }}
            key={sanction.name}
          />
        );
      })}
      <DataTable
        caption="Political Positions"
        items={{ 'Political Positions': politicalPositions }}
      />
      <DataTable
        caption="Extracted Data"
        items={{
          'First Name': extractedData.firstName,
          'Last Name': extractedData.lastName,
          Address: extractedData.address
        }}
      />
    </>
  );
};
