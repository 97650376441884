import { Grid, Menu, MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { List } from 'components/reusable/list';
import { CompanyAccountGroup } from 'models/company-account/company-account.model';
import { setAccountGroups } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { createAccountGroup, deleteAccountGroup } from 'store/sagas/sagas';
import './company-groups.css';

export const CompanyGroups: React.FC = () => {
  type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const { state, asyncDispatch, dispatch } = useGlobalState();
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const [anchor, setAnchor] = useState<{
    el: HTMLElement | null;
    id: number | null;
  }>({ el: null, id: null });

  const nameRef = useRef<HTMLInputElement | null>(null);
  const [name, setName] = useState('');
  const [invalidData, setInvalidData] = useState(false);

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setAnchor({ el: null, id: null });
    }, 0);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: number) => {
      setAnchor({ el: event.currentTarget, id });
    },
    []
  );

  const updateAccountGroup = (
    accountGroup: CompanyAccountGroup,
    remove = false
  ): void => {
    if (state.accountGroups) {
      const newGroups: CompanyAccountGroup[] = [...state.accountGroups];

      const idx = state.accountGroups.findIndex(
        (j) => j.id === accountGroup.id
      );

      if (idx >= 0) {
        if (remove) {
          newGroups.splice(idx, 1);
        } else {
          newGroups[idx] = accountGroup;
        }

        dispatch(setAccountGroups(newGroups));
      }
    }
  };

  const clearForm = () => {
    if (nameRef.current) {
      nameRef.current.value = '';
    }

    setName('');
  };

  const handleName = useCallback((e: InputEvent) => {
    setName(e.target.value);
    setInvalidData(false);
  }, []);

  const onCreateAccountGroup = () => {
    const environment = state.sandboxToggled ? 'sandbox' : 'live';
    setIsCreateLoading(true);
    asyncDispatch(
      createAccountGroup({
        name,
        environment
      })
    )
      .then(({ accountGroup }) => {
        setIsCreateLoading(false);
        dispatch(
          setAccountGroups([accountGroup, ...(state.accountGroups ?? [])])
        );

        clearForm();
      })
      .catch(() => {
        setIsCreateLoading(false);
      });
  };

  return (
    <div className="full-page">
      <div className="groups-main">
        <h1 className="g-page-name">Groups</h1>
        <div>
          <div style={{ display: 'flex' }}>
            <Grid container>
              <Grid container item spacing={3} xs={12}>
                <Grid item xs={6}>
                  <TextField
                    className="g-full-width u-margin-bottom-small"
                    error={invalidData}
                    inputRef={nameRef}
                    label="Name"
                    onChange={handleName}
                    required
                    style={{ minWidth: 200 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container item spacing={3} xs={12}>
                <Grid item xs={3}>
                  <ButtonWithLoader
                    className="u-margin-top-large"
                    disabled={!name}
                    isLoading={isCreateLoading}
                    name="Create Group"
                    onClick={onCreateAccountGroup}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          {!!state.accountGroups?.length && (
            <List
              columns={[
                {
                  name: 'Id',
                  id: 'sid',
                  xs: 4
                },
                {
                  name: 'Name',
                  id: 'name',
                  xs: 7
                },
                {
                  xs: 1,
                  name: '',
                  isClickable: false,
                  customRenderValue: (item) => {
                    const { id } = item;
                    return (
                      <div
                        className="pointer"
                        onClick={(e) => {
                          handleClick(e, id);
                        }}
                      >
                        <i className="candidates__menu_icon fas fa-ellipsis-v" />
                        <Menu
                          anchorEl={anchor.el}
                          id={String(id)}
                          keepMounted
                          onClose={handleClose}
                          open={anchor.id !== null && anchor.id === id}
                        >
                          <MenuItem
                            onClick={() => {
                              asyncDispatch(
                                deleteAccountGroup({ accountGroupId: id })
                              )
                                .then(() => {
                                  updateAccountGroup(item, true);
                                  setTimeout(() => {
                                    handleClose();
                                  }, 500);
                                })
                                .catch(console.error);
                            }}
                          >
                            Remove
                          </MenuItem>
                        </Menu>
                      </div>
                    );
                  }
                }
              ]}
              items={state.accountGroups}
              label="groups"
              loading={false}
              total={state.accountGroups.length}
            />
          )}
        </div>
      </div>
    </div>
  );
};
