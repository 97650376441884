import {
  Box,
  Button,
  ButtonLink,
  Heading,
  Input,
  Stack,
  Text
} from '@vouched-id/vault';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { VouchedLogo } from 'components/reusable/vouched-logo';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { forgotPassword } from 'store/sagas/sagas';
import { validateEmail } from 'utils/email';
import { history } from 'utils/history';
import { FieldState } from 'utils/validation';

export const ForgotPassword: React.FC = () => {
  const { asyncDispatch, dispatch } = useGlobalState();
  const [email, setEmail] = useState('');
  const [emailValidity, setEmailValidity] = useState<FieldState>(
    FieldState.NOT_SET
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handleEmailBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setEmailValidity(FieldState.NOT_SET);
    } else {
      setEmailValidity(
        validateEmail(e.target.value) ? FieldState.VALID : FieldState.INVALID
      );
    }
  }, []);

  const submit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (!validateEmail(email)) {
      setEmailValidity(FieldState.INVALID);
      return;
    }

    setIsLoading(true);
    setEmailValidity(FieldState.VALID);
    asyncDispatch(forgotPassword(email))
      .then((res) => {
        dispatch(
          setMessages({ value: 'Check your email', severity: 'success' })
        );

        history.push({
          pathname: '/reset-password',
          state: { sid: res.data }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setEmailValidity(FieldState.INVALID);
        console.error(error);
      });
  };

  // eventually update vault theme
  const midPurple = '#453FC1';

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      height="100vh"
      justifyContent="center"
      padding="spacing.large"
      style={{ boxSizing: 'border-box' }}
      tag="main"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maxWidth="52rem"
      >
        <Box marginBottom="spacing.xlarge3" marginTop="spacing.default">
          <VouchedLogo fill={midPurple} maxWidth="100%" width="250px" />
        </Box>

        <Box marginBottom="spacing.xlarge">
          <Heading style={{ fontWeight: 'bold' }} tag="h1" variation="h3">
            Forgot Password
          </Heading>
        </Box>
        <Text>
          Enter the email associated with your account and we'll send you a code
          to reset your password.
        </Text>
        <Box
          marginTop="spacing.large"
          noValidate
          onSubmit={submit}
          tag="form"
          width="100%"
        >
          <Stack spacing="spacing.large">
            <Box>
              <Input
                autoComplete="email"
                inputMode="email"
                isInvalid={emailValidity === FieldState.INVALID}
                label="Email"
                name="email"
                onBlur={handleEmailBlur}
                onChange={handleEmailChange}
                type="email"
                validationMessage="Invalid email address."
              />
            </Box>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Button
                data-cy="submit"
                style={{ backgroundColor: midPurple }}
                title={isLoading ? 'loading' : ''}
              >
                Send Code
              </Button>
            </Box>
            <ButtonLink data-cy="signin" href="/signin" variation="text">
              Back to Login
            </ButtonLink>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
