import React from 'react';
import { RenderItem } from '../../id-information/id-information';
import { Text } from 'components/text';

export interface PrivateSSNResponseData {
  verified?: boolean;
  identifiers?: {
    ssn?: boolean;
    last4?: boolean;
  };
  errors?: Error[];
}

export const privateSSNResponseDataSummary = (
  data?: PrivateSSNResponseData
): RenderItem => {
  if (data?.errors) {
    return {
      name: 'Error',
      customRenderType: (
        <div>
          {data.errors.map((error) => (
            <Text key={error.message} strongest={false} value="Error Message" />
          ))}
        </div>
      ),
      customRenderValue: (
        <div>
          {data.errors.map((error) => (
            <Text key={error.message} value={error.message} />
          ))}
        </div>
      )
    };
  }

  return {
    name: 'Response',
    customRenderType: (
      <div>
        <Text strongest={false} value="SSN Match" />
      </div>
    ),
    customRenderValue: (
      <div>
        <Text
          value={
            data?.identifiers?.ssn?.toString() ??
            data?.identifiers?.last4?.toString() ??
            data?.verified?.toString() ??
            'n/a'
          }
        />
      </div>
    )
  };
};
