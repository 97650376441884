import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { User } from 'models/user/user.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { forgotPassword } from 'store/sagas/sagas';
import { passwordResetCheck } from 'utils/currentDate';
import { history } from 'utils/history';

export const ResetPasswordCheck: React.FC = ({ children }) => {
  const { asyncDispatch, dispatch, state } = useGlobalState();
  const { user } = state;
  const location = useLocation();

  const resetPassword = user ? needsPasswordReset(user) : false;

  useEffect(() => {
    if (!resetPassword) {
      return;
    }

    dispatch(
      setMessages({
        severity: 'warning',
        value:
          'Your Password is older than 90 days. Please reset it. Check your email for a reset token.'
      })
    );

    asyncDispatch(forgotPassword(user!.email)).then((res) => {
      history.push('/reset-password', { sid: res.data });
    }, console.error);
  }, [resetPassword, user?.email, history]);

  if (resetPassword && location.pathname !== '/reset-password') {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const needsPasswordReset = (user: User) => {
  const lastDate = user.passwordDate
    ? new Date(Date.parse(user.passwordDate))
    : new Date(0);

  return passwordResetCheck(lastDate);
};
