import { RefObject, useEffect, useRef } from 'react';

export const useTableScroll = (
  index: number | null,
  enabled: boolean
): RefObject<HTMLTableSectionElement> => {
  const ref = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (!ref.current || index === null || !enabled) {
      return;
    }

    const el = ref.current.children[index];
    if (!el) {
      return;
    }

    const y = el.getBoundingClientRect().top + window.scrollY - 20;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }, [index]);

  return ref;
};
