import { ChartConfiguration, ChartOptions } from 'chart.js';
import { JobStats } from 'store/sagas/sagas';

const COLORS = {
  GREEN: '#3ED194',
  ORANGE: '#FFA64D',
  RED: '#FF4D4D',
  VOUCHE_VIOLET: 'rgb(99,91,255)'
};

export const COLORS_STATUS = {
  APPROVED: COLORS.GREEN,
  REJECTED: COLORS.RED,
  COMPLETED: COLORS.GREEN,
  ACTIVE: COLORS.ORANGE,
  REMOVED: COLORS.RED
};

export interface JobsCount {
  jobsByStatus: JobStats[];
  countJobsByStatus: string;
}
export interface JobsApproved {
  success: boolean;
  count: number;
}

export const createDataGetJobsCountByStatus = (
  job_data: JobsCount
): ChartConfiguration<'doughnut', string[], string> => {
  const activeJobs = job_data.jobsByStatus.some(
    (item: JobStats) => item.status === 0
  );

  const removedJobs = job_data.jobsByStatus.some(
    (item: JobStats) => item.status === 1
  );

  const approvedJobs = job_data.jobsByStatus.some(
    (item: JobStats) => item.status === 2
  );

  if (!activeJobs)
    job_data.jobsByStatus = [...job_data.jobsByStatus, { status: 0, count: 0 }];
  if (!removedJobs)
    job_data.jobsByStatus = [...job_data.jobsByStatus, { status: 1, count: 0 }];
  if (!approvedJobs) {
    job_data.jobsByStatus = [...job_data.jobsByStatus, { status: 2, count: 0 }];
  }

  const dashData = job_data.jobsByStatus;
  const arr_color = [
    COLORS_STATUS.ACTIVE,
    COLORS_STATUS.REMOVED,
    COLORS_STATUS.COMPLETED
  ];

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      tooltip: {
        callbacks: {
          label(context) {
            const data = context.dataset.data[context.dataIndex]!;
            return `${data}%`;
          }
        }
      },
      legend: {
        position: 'top' as const
      }
    }
  };

  if (dashData.length === 0) {
    return {
      type: 'doughnut',
      options,
      data: {
        labels: ['Active', 'Removed', 'Completed'],
        datasets: [
          {
            label: 'Verification Completion Rate',
            data: ['0', '0', '0'],
            backgroundColor: arr_color,
            borderColor: arr_color,
            borderWidth: 1,
            hoverOffset: 4
          }
        ]
      }
    };
  }

  let removedExists = false;
  for (const dashItem of dashData) {
    if (dashItem.status === 1) {
      removedExists = true;
    }
  }

  if (!removedExists) {
    dashData.push({ status: 1, count: 0 });
  }

  return {
    type: 'doughnut',
    data: {
      datasets: [
        {
          label: 'Verification Completion Rate',
          data: dashData
            .sort((a, b) => {
              if (a.status && b.status && a.status < b.status) {
                return -1;
              }

              return 1;
            })
            .map((job) => (job.count * 100).toFixed(2)),
          backgroundColor: arr_color,
          borderColor: arr_color,
          borderWidth: 1
        }
      ]
    },
    options
  };
};

export const createDataGetCreatedJobsSinceLogin = (
  job_data: JobStats[]
): number => {
  if (job_data.length === 0) {
    return 0;
  }

  return job_data[0]!.count;
};

export const createDataGetJobsApproved = (
  job_data: JobsApproved[]
): ChartConfiguration<'doughnut', string[], string> => {
  const unapprovedJobs = job_data.some((item: JobsApproved) => !item.success);

  const approvedJobs = job_data.some((item: JobsApproved) => item.success);

  if (!unapprovedJobs) job_data = [...job_data, { success: false, count: 0 }];
  if (!approvedJobs) job_data = [...job_data, { success: true, count: 0 }];

  const arr_color = [COLORS_STATUS.APPROVED, COLORS_STATUS.REJECTED];

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        position: 'top' as const
      },
      tooltip: {
        callbacks: {
          label(context) {
            return `${context.dataset.data[context.dataIndex]!}%`;
          }
        }
      }
    }
  };

  if (job_data.length === 0) {
    return {
      type: 'doughnut',
      options,
      data: {
        labels: ['Approved', 'Rejected'],
        datasets: [
          {
            data: ['0', '0'],
            backgroundColor: arr_color,
            borderColor: arr_color,
            borderWidth: 1,
            hoverOffset: 4
          }
        ]
      }
    };
  }

  return {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: job_data
            .sort((a) => {
              if (a.success) {
                return -1;
              }

              return 1;
            })
            .map((job) => (job.count * 100).toFixed(2)),
          backgroundColor: arr_color,
          borderColor: arr_color,
          borderWidth: 1
        }
      ]
    },
    options
  };
};

export const createDataGetJobs = (
  job_data: JobStats[]
): ChartConfiguration<'line', number[], string> => {
  const labels = Array.from(
    new Set(
      job_data.map((item: JobStats) => {
        return item.week;
      })
    )
  ) as string[];

  const jobs_status_0: number[] = [];
  const jobs_status_1: number[] = [];
  const jobs_status_2: number[] = [];
  for (const week of labels) {
    let dataExists_0 = false;
    let dataExists_1 = false;
    let dataExists_2 = false;
    for (const job of job_data) {
      if (job.week === week) {
        switch (job.status) {
          case 0:
            dataExists_0 = true;
            jobs_status_0.push(job.count);
            break;
          case 1:
            dataExists_1 = true;
            jobs_status_1.push(job.count);
            break;
          case 2:
            dataExists_2 = true;
            jobs_status_2.push(job.count);
            break;
        }
      }
    }

    if (!dataExists_0) {
      jobs_status_0.push(0);
    }

    if (!dataExists_1) {
      jobs_status_1.push(0);
    }

    if (!dataExists_2) {
      jobs_status_2.push(0);
    }
  }

  const chartLabel = labelDashboard(labels.length);

  return {
    type: 'line',
    data: {
      labels: chartLabel,
      datasets: [
        {
          label: 'Complete',
          backgroundColor: COLORS.GREEN,
          borderColor: COLORS.GREEN,
          borderWidth: 2,
          data: jobs_status_2
        },
        {
          label: 'Active',
          backgroundColor: COLORS.ORANGE,
          borderColor: COLORS.ORANGE,
          borderWidth: 2,
          data: jobs_status_0
        },
        {
          label: 'Removed',
          backgroundColor: COLORS.RED,
          borderColor: COLORS.RED,
          borderWidth: 2,
          data: jobs_status_1
        }
      ]
    },
    options: {
      animations: {
        tension: {
          easing: 'easeInOutSine',
          from: 0,
          to: 0.35
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          },
          title: {
            display: true,
            text: 'Weeks',
            padding: 3,
            font: {
              weight: 'bold'
            }
          }
        },
        y: {
          min: 0,
          title: {
            display: true,
            text: 'Number of Jobs',
            padding: 5,
            font: {
              weight: 'bold'
            }
          }
        }
      },
      plugins: {
        legend: {
          position: 'top' as const,
          display: true
        },
        title: {
          display: false,
          text: 'Job Volume'
        }
      }
    }
  };
};

export const createDataGetJobsVolumeByMonth = (
  job_data: JobStats[]
): ChartConfiguration<'line', number[], string> => {
  const options: ChartOptions<'line'> = {
    animations: {
      tension: {
        easing: 'easeInOutSine',
        from: 0,
        to: 0.35
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        title: {
          display: true,
          text: 'Weeks',
          padding: 3,
          font: {
            weight: 'bold'
          }
        }
      },
      y: {
        min: 0,
        title: {
          display: true,
          text: 'Number of Jobs',
          padding: 5,
          font: {
            weight: 'bold'
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: true
      },
      title: {
        display: false,
        text: 'Job Volume'
      }
    }
  };

  const jobs_by_week: number[] = job_data.map((j) => j.count);

  return {
    type: 'line',
    data: {
      labels: labelDashboard(job_data.length),
      datasets: [
        {
          label: 'Jobs',
          backgroundColor: COLORS.VOUCHE_VIOLET,
          borderColor: COLORS.VOUCHE_VIOLET,
          data: jobs_by_week,
          borderWidth: 2
        }
      ]
    },
    options
  };
};

const labelDashboard = (count: number): string[] => {
  const out: string[] = [];
  for (let i = 1; i <= count; ++i) {
    out.push(`Week ${i + 1}`);
  }

  return out;
};
