import { ReactElement } from 'react';

export const Verification_Map = {
  Y: 'Verified',
  y: 'Verified',
  N: 'Not verified',
  n: 'Not verified',
  undefined: 'No result'
};

export const Deceased_Map = {
  Y: 'Deceased',
  y: 'Deceased',
  N: 'Not deceased',
  n: 'Not deceased',
  undefined: 'No result'
};

export const SignatureType_Map = {
  E: 'Electronic Signature',
  e: 'Electronic Signature',
  W: 'Wet Signature',
  w: 'Wet Signature',
  undefined: 'No result'
};

export interface CvsRequestItem {
  additionalParams?: {
    signatureType?: 'E' | 'W';
  };
  dateOfBirth: string;
  lastName: string;
  middleName: string;
  firstName: string;
}

interface RequestData {
  cvsRequestList: CvsRequestItem[];
}

export interface CvsResponseItem {
  verificationData?: {
    deathIndicator?: 'N' | 'Y';
  };
  verificationCode?: 'N' | 'Y';
  recordErrorCodeDesc?: string;
  recordErrorCode?: string;
}

interface ResponseData {
  cvsResponseData?: {
    data: {
      cvsResponseList: CvsResponseItem[];
      errorCode?: string;
      errorCodeDesc?: string;
    };
  };
}

export interface SSNJob {
  id: string;
  request_data: RequestData;
  response_data?: ResponseData;
}

export interface ListRenderItem {
  name: string;
  customRenderType: ReactElement;
  customRenderValue: ReactElement;
}
