import React, { FC } from 'react';
import { DataTable } from '../DataTable';
import { DobRequestData } from 'components/account/jobs/job-modal/job-modal-tabs/components/dob/dob_model';

interface DOBRequestDataTableProps {
  readonly requestData: DobRequestData;
}

export const DOBRequestDataTable: FC<DOBRequestDataTableProps> = ({
  requestData
}) => {
  const items = {
    'First Name': requestData.firstName,
    'Last Name': requestData.lastName,
    'Date of Birth': requestData.dob,
    Email: requestData.email,
    Phone: requestData.phone,
    'Street Address': requestData.address?.streetAddress,
    Unit: requestData.address?.unit,
    City: requestData.address?.city,
    State: requestData.address?.state,
    'Postal Code': requestData.address?.postalCode,
    Country: requestData.address?.country
  };

  return <DataTable items={items} />;
};
