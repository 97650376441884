import React from 'react';
import './loader.css';

export const Loader: React.FC = () => {
  return (
    <div className="loader g-page-without-header-footer">
      <span>Loading...</span>
    </div>
  );
};
