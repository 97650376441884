export const roundNumberStrict = (num: number, decimals = 4): number => {
  return Math.round(num * 10 ** decimals) / 10 ** decimals;
};

export const roundNumber = (
  num: number | string | null | undefined,
  decimals = 4
): number | null => {
  if (num === null || num === undefined) return null;
  if (typeof num === 'string') {
    num = Number(num);
  }

  return roundNumberStrict(num, decimals);
};

export const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    // eslint-disable-next-line unicorn/prefer-code-point
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  return bytes;
};

export const removeSpace = (data: string): string => data.replaceAll(/\s/g, '');

let a: HTMLAnchorElement | null = null;
export const saveByteArray = (data: BlobPart[], name: string): void => {
  if (!a) {
    a = document.createElement('a');
    document.body.append(a);
    a.style.display = 'none';
  }

  const blob = new Blob(data, { type: 'octet/stream' });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const humanDate = (
  data: Date | number | string | null | undefined,
  dateOnly = false
): string | null => {
  if (!data) return null;
  try {
    return dateOnly
      ? new Date(data).toLocaleDateString()
      : new Date(data).toLocaleString();
  } catch {
    return 'invalid date';
  }
};

export const humanBoolean = (v: boolean | null | undefined): string => {
  if (v === undefined || v === null) {
    return 'N/A';
  }

  return v ? 'Yes' : 'No';
};

export const humanPhone = (phone: string | null | undefined): string | null => {
  if (!phone) return null;
  phone = phone.replaceAll(/\D/g, '');
  phone = /^1.*/.test(phone)
    ? phone.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '+$1-$2-$3-$4')
    : phone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1-$2-$3-$4');

  const defaultPrefix = '1';
  // no prefix
  phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, `+${defaultPrefix}-$1-$2-$3`);

  return phone;
};

export const noop = (): void => {
  // noop
};
