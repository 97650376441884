import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import './confirm-dialog.css';

interface ConfirmDialogProps {
  readonly title: string;
  readonly children?: string;
  readonly open: boolean;
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onConfirm: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  children,
  open,
  setOpen,
  onConfirm
}) => {
  return (
    <Dialog
      aria-labelledby="confirm-dialog"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <div className="confirm-dialog-background">
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
            variant="contained"
          >
            No
          </Button>
          <Button
            color="secondary"
            data-test="delete-bundle-yes-button"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
