import { Checkbox, Collapse, Tooltip } from '@material-ui/core';
import cx from 'classnames';
import React, { ReactElement } from 'react';
import { useIsAdminRoute } from '../../../../../../../utils/hooks';
import { statusMapper } from '../../../../job-status';
import {
  filterVal,
  getAamvaSummaryStatus,
  getAmlStatus,
  getDlvSummaryStatus,
  getIPSummaryStatus,
  getLivenessPerformedStatus,
  getLivenessSessionScoreStatus,
  toName
} from '../../utils';
import { JobCheck, JobCheckItem } from '../job-check/job-check';
import { JobType } from 'components/account/jobs/job_type';
import { InfoIcon } from 'components/reusable/info-icon/info-icon';
import { Tick } from 'components/reusable/tick';
import { Text } from 'components/text';
import { CompanyAccountGroup } from 'models/company-account/company-account.model';
import { Job } from 'models/jobs/jobs';
import { UserUpdateReview } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import { capitalize } from 'utils/format';
import { getInsight } from 'utils/jobs';
import { toAccountGroupName } from 'utils/toAccountGroupName';

interface IdvProps {
  readonly job: Job;
  readonly handleReview: (body: UserUpdateReview) => void;
  readonly collapseValidID: boolean;
  readonly collapseValidSelfie: boolean;
  readonly collapseValidFace: boolean;
  readonly setCollapseValidID: (col: boolean) => void;
  readonly setCollapseValidSelfie: (col: boolean) => void;
  readonly setCollapseValidFace: (col: boolean) => void;
  readonly amlConfidence: number | undefined;
}

interface JobCheckListItem extends JobCheckItem {
  value?: ReactElement;
  tooltip?: string;
  big?: boolean;
}

const buildSummaryItems = ({
  job,
  accountGroups,
  isAdminView,
  amlConfidence,
  handleReview
}: {
  job: Job;
  accountGroups: CompanyAccountGroup[] | null | undefined;
  isAdminView: boolean;
  amlConfidence: number | null | undefined;
  handleReview: (body: UserUpdateReview) => void;
}) => {
  const {
    result,
    request,
    reviewSuccess,
    errors,
    id,
    accountId,
    status,
    accountReviewed
  } = job;

  const { state } = statusMapper(
    status,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    !!(result?.successWithSuggestion || result?.success)
  );

  const out: JobCheckListItem[] = [
    {
      label: 'ID',
      value: <Text value={id} />
    }
  ];

  if (isAdminView) {
    out.push({
      label: 'Account',
      value: (
        <Text
          value={toAccountGroupName(accountId, accountGroups) ?? 'No account'}
        />
      )
    });
  }

  out.push({
    label: 'Reviewed',
    check: (
      <Checkbox
        checked={!!accountReviewed}
        onChange={() => {
          handleReview({ id, value: !accountReviewed });
        }}
        style={{
          marginLeft: '-5px'
        }}
      />
    )
  });

  if (
    request.parameters?.userConfirmation?.confirmed !== undefined &&
    request.parameters.userConfirmation.confirmed !== null
  ) {
    out.push({
      label: 'User Confirmation',
      tooltip: 'Did the user confirm the extracted information?',
      value: (
        <Text
          value={capitalize(
            request.parameters.userConfirmation.confirmed
              ? 'User Confirmed'
              : 'User Refuted'
          )}
        />
      ),
      check: (
        <Tick
          state={
            request.parameters.userConfirmation.confirmed
              ? 'approved'
              : 'rejected'
          }
        />
      )
    });
  }

  out.push({
    label: 'Status',
    value: (
      <div>
        {reviewSuccess !== null && (
          <Text value={capitalize(reviewSuccess ? 'approved' : 'rejected')} />
        )}
        <Text value={capitalize(state ?? '')} />
      </div>
    ),
    check: (
      <>
        {reviewSuccess !== null && (
          <Tick state={reviewSuccess ? 'approved' : 'rejected'} />
        )}
        <Tick state={state} />
      </>
    )
  });

  if (
    request.type !== JobType.idReverification &&
    result?.confidences?.id !== null
  ) {
    out.push({
      label: 'Valid ID',
      questionType: 'InvalidIdPhotoError',
      reviewType: 'idValid',
      big: true
    });
  }

  if (result?.confidences?.selfie !== null) {
    out.push({
      label: 'Valid Selfie',
      questionType: 'InvalidUserPhotoError',
      reviewType: 'selfieValid',
      big: true
    });
  }

  if (result?.confidences?.faceMatch !== null) {
    out.push({
      label: 'Face Match',
      questionType: 'FaceMatchError',
      reviewType: 'faceMatch',
      big: true
    });
  }

  if (result?.confidences?.idExpired !== null) {
    out.push({ label: 'Expiration Date', questionType: 'ExpiredIdError' });
  }

  if (result?.confidences?.barcodeMatch !== null) {
    out.push({
      label: 'Barcode Match',
      questionType: 'BarcodeMatchError',
      big: true
    });
  }

  if (errors.some((err) => err.type === 'BarcodeDetectError')) {
    out.push({
      label: 'Barcode Presence',
      tooltip: 'Was the barcode recognized?',
      questionType: 'BarcodeDetectError'
    });
  }

  if (typeof result?.confidences?.liveness === 'number') {
    out.push({
      label: 'Liveness Bypass',
      tooltip: 'Indicates if the liveness check was performed',
      check: (
        <Tick state={getLivenessPerformedStatus(result.confidences.liveness)} />
      )
    });
  }

  // only display liveness score if defined and the liveness check was performed
  if (
    typeof result?.confidences?.livenessSession === 'number' &&
    result.confidences.liveness === 1
  ) {
    out.push({
      label: 'Selfie Liveness',
      tooltip: 'Indicates if the liveness score was successful',
      check: (
        <Tick
          state={getLivenessSessionScoreStatus(
            result.confidences.livenessSession
          )}
        />
      )
    });
  }

  if (result?.confidences?.nameMatch !== null) {
    out.push({
      label: 'Name',
      questionType: 'NameMatchError',
      reviewType: 'nameMatch'
    });
  }

  if (result?.confidences?.birthDateMatch !== null) {
    out.push({
      label: 'Birthday',
      questionType: 'BirthDateMatchError',
      reviewType: 'birthDateMatch'
    });
  }

  if (result?.confidences?.idCrosscheckIdentity !== null) {
    out.push({ label: 'Crosscheck', questionType: 'CrosscheckIdentityError' });
  }

  if (result?.confidences?.idCrosscheckDarkweb !== null) {
    out.push({ label: 'Dark Web', questionType: 'DarkWebError' });
  }

  if (
    result?.featuresEnabled?.dlvEnabled &&
    result.dlv?.result?.wasCheckPerformed &&
    result.dlv.result.status
  ) {
    out.push({
      label: 'DLV',
      check: (
        <Tick
          id="dlv-tick"
          state={getDlvSummaryStatus(result.dlv.result.status)}
        />
      )
    });
  }

  if (
    !result?.featuresEnabled?.dlvEnabled &&
    result?.aamva?.confidenceScore !== null &&
    result?.aamva?.confidenceScore !== undefined
  ) {
    out.push({
      label: 'DLV',
      questionType: 'AamvaConfidenceScoreTooLow',
      check: <Tick state={getAamvaSummaryStatus(result.aamva)} />
    });
  }

  if (amlConfidence !== null && amlConfidence !== undefined) {
    out.push({
      label: 'AML',
      questionType: 'AmlConfidenceScoreTooLow',
      check: <Tick state={getAmlStatus(amlConfidence)} />
    });
  }

  if (result?.ipAddress !== null) {
    out.push({
      label: 'IP Address',
      questionType: 'ipAddressError',
      check: <Tick state={getIPSummaryStatus(result)} />
    });
  }

  return out;
};

export const SummaryIdv: React.FC<IdvProps> = ({
  job,
  handleReview,
  collapseValidID,
  collapseValidSelfie,
  collapseValidFace,
  setCollapseValidID,
  setCollapseValidSelfie,
  setCollapseValidFace,
  amlConfidence
}) => {
  const {
    state: { accountGroups }
  } = useGlobalState();

  const InsightValues = getInsight(job.signals ?? []);
  const idInsight = InsightValues && filterVal(InsightValues, 'id');
  const selfieInsight = InsightValues && filterVal(InsightValues, 'selfie');
  const faceInsight = InsightValues && filterVal(InsightValues, 'faceMatch');
  const backIDInsight = InsightValues && filterVal(InsightValues, 'backId');
  const parameters = job.request.parameters!;
  const { dob } = parameters;
  const name = toName(parameters);

  const resultMapper = buildSummaryItems({
    job,
    accountGroups,
    isAdminView: useIsAdminRoute(),
    amlConfidence,
    handleReview
  });

  return (
    <>
      {resultMapper.map((item, index) => {
        return (
          <div
            className={cx(
              'job-modal-row',
              index === resultMapper.length - 1
                ? 'job-modal-row-last'
                : index === 0
                  ? 'job-modal-row-first'
                  : 'job-modal-row-not-last'
            )}
            key={item.label}
            style={{
              width: '98%',
              display: item.big ? '' : 'flex'
            }}
          >
            {item.label === 'Valid ID' ? (
              <div>
                <div
                  onClick={() => {
                    setCollapseValidID(!collapseValidID);
                  }}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div className="job-modal-itemLabel">
                    {`${item.label} `}
                    <Tooltip
                      enterTouchDelay={1}
                      title="Did the ID receive a score above 0.9?"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <div className="job-modal-itemValue">
                    {idInsight && idInsight.length > 0 && (
                      <i
                        className={
                          collapseValidID
                            ? cx('fa fa-angle-up')
                            : cx('fas fa-angle-down')
                        }
                        style={{
                          color: '#999999',
                          fontSize: '15px',
                          padding: '0px 5px'
                        }}
                      />
                    )}
                  </div>
                  <div className="job-modal-itemCheck">
                    <JobCheck errors={job.errors} item={item} job={job} />
                  </div>
                </div>
                <Collapse
                  in={collapseValidID}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  {idInsight?.map((m) => (
                    <div className="collapsableText" key={m}>
                      {' '}
                      {m}{' '}
                    </div>
                  ))}
                </Collapse>
              </div>
            ) : item.label === 'Valid Selfie' ? (
              <>
                <div
                  onClick={() => {
                    setCollapseValidSelfie(!collapseValidSelfie);
                  }}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div className="job-modal-itemLabel">
                    {`${item.label} `}
                    <Tooltip
                      enterTouchDelay={1}
                      title="Did the Selfie receive a score above 0.9?"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <div className="job-modal-itemValue">
                    {selfieInsight && selfieInsight.length > 0 && (
                      <i
                        className={
                          collapseValidSelfie
                            ? cx('fa fa-angle-up')
                            : cx('fas fa-angle-down')
                        }
                        style={{
                          color: '#999999',
                          fontSize: '15px',
                          padding: '0px 5px'
                        }}
                      />
                    )}
                  </div>
                  <div className="job-modal-itemCheck">
                    <JobCheck errors={job.errors} item={item} job={job} />
                  </div>
                </div>
                <Collapse
                  in={collapseValidSelfie}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  {selfieInsight?.map((m) => (
                    <div className="collapsableText" key={m}>
                      {' '}
                      {m}{' '}
                    </div>
                  ))}
                </Collapse>
              </>
            ) : item.label === 'Face Match' ? (
              <>
                <div
                  onClick={() => {
                    setCollapseValidFace(!collapseValidFace);
                  }}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div className="job-modal-itemLabel">
                    {`${item.label} `}
                    <Tooltip
                      enterTouchDelay={1}
                      title="Did the face in the ID match the face in the selfie?"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <div className="job-modal-itemValue">
                    {faceInsight && faceInsight.length > 0 && (
                      <i
                        className={
                          collapseValidFace
                            ? cx('fa fa-angle-up')
                            : cx('fas fa-angle-down')
                        }
                        style={{
                          color: '#999999',
                          fontSize: '15px',
                          padding: '0px 5px'
                        }}
                      />
                    )}
                  </div>
                  <div className="job-modal-itemCheck">
                    <JobCheck errors={job.errors} item={item} job={job} />
                  </div>
                </div>
                <Collapse
                  in={collapseValidFace}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  {faceInsight?.map((m) => (
                    <div className="collapsableText" key={m}>
                      {' '}
                      {m}{' '}
                    </div>
                  ))}
                </Collapse>
              </>
            ) : item.label === 'Barcode Match' ? (
              <>
                <div
                  onClick={() => {
                    setCollapseValidFace(!collapseValidFace);
                  }}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div className="job-modal-itemLabel">
                    {`${item.label} `}
                    <Tooltip
                      enterTouchDelay={1}
                      title="Does the information on the ID card match the information encoded in the barcode?"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                  <div className="job-modal-itemValue">
                    {backIDInsight && backIDInsight.length > 0 && (
                      <i
                        className={
                          collapseValidFace
                            ? cx('fa fa-angle-up')
                            : cx('fas fa-angle-down')
                        }
                        style={{
                          color: '#999999',
                          fontSize: '15px',
                          padding: '0px 5px'
                        }}
                      />
                    )}
                  </div>
                  <div className="job-modal-itemCheck">
                    <JobCheck errors={job.errors} item={item} job={job} />
                  </div>
                </div>
                <Collapse
                  in={collapseValidFace}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  {backIDInsight?.map((m) => (
                    <div className="collapsableText" key={m}>
                      {' '}
                      {m}{' '}
                    </div>
                  ))}
                </Collapse>
              </>
            ) : item.label === 'Name' ? (
              <>
                <div className="job-modal-itemLabel">
                  {`${item.label} `}
                  <Tooltip
                    enterTouchDelay={1}
                    title="Does the provided name match the name from the ID?"
                  >
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="job-modal-itemValue">
                  {item.value ?? name.toUpperCase()}
                </div>
                <div className="job-modal-itemCheck">
                  <JobCheck errors={job.errors} item={item} job={job} />
                </div>
              </>
            ) : item.label === 'Birthday' ? (
              <>
                <div className="job-modal-itemLabel">
                  {`${item.label} `}
                  <Tooltip
                    enterTouchDelay={1}
                    title="Does the provided date of birth match the date of birth from the ID?"
                  >
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="job-modal-itemValue">{item.value ?? dob}</div>
                <div className="job-modal-itemCheck">
                  <JobCheck errors={job.errors} item={item} job={job} />
                </div>
              </>
            ) : item.label === 'Expiration Date' ? (
              <>
                <div className="job-modal-itemLabel">
                  {`${item.label} `}
                  <Tooltip enterTouchDelay={1} title="Has the ID expired?">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="job-modal-itemValue">{item.value}</div>
                <div className="job-modal-itemCheck">
                  <JobCheck errors={job.errors} item={item} job={job} />
                </div>
              </>
            ) : item.label === 'AML' ? (
              <>
                <div className="job-modal-itemLabel">
                  {`${item.label} `}
                  <Tooltip
                    enterTouchDelay={1}
                    title="Red indicates presence on the Sanction/Warning lists. Orange indicates presence on one or multiple PEP lists."
                  >
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="job-modal-itemValue">{item.value}</div>
                <div className="job-modal-itemCheck">
                  <JobCheck errors={job.errors} item={item} job={job} />
                </div>
              </>
            ) : (
              <>
                <div className="job-modal-itemLabel">
                  {item.tooltip === undefined ? (
                    item.label
                  ) : (
                    <>
                      {item.label}{' '}
                      <Tooltip enterTouchDelay={1} title={item.tooltip}>
                        <InfoIcon />
                      </Tooltip>
                    </>
                  )}
                </div>
                <div className="job-modal-itemValue">
                  {item.value ??
                    (item.label === 'Name'
                      ? name.toUpperCase()
                      : item.label === 'Birthday'
                        ? dob
                        : null)}
                </div>
                <div className="job-modal-itemCheck">
                  <JobCheck errors={job.errors} item={item} job={job} />
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
