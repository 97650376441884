import React, { useEffect, useState } from 'react';
import { getSSNJob } from '../../../../../../../store/sagas/sagas';
import { sSNRequestDataSummary } from './request_data_summary';
import {
  sSNRecordErrorSummary,
  sSNRequestErrorSummary
} from './request_error_summary';
import { sSNRequestSuccessSummary } from './request_success_summary';
import { ListRenderItem, SSNJob } from './ssn_model';
import { FeatureNotEnabled } from 'components/account/jobs/feature_not_enabled';
import { List } from 'components/reusable/list';
import { Job } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';

interface EcbsvSSNProps {
  readonly job: Job;
}
export const EcbsvSSN: React.FC<EcbsvSSNProps> = ({ job }) => {
  const [ssnJob, setSSNJob] = useState<SSNJob>();
  const [ssnJobSuccess, setSSNJobSuccess] = useState(true);
  const { asyncDispatch } = useGlobalState();
  const [items, setItems] = useState([] as ListRenderItem[]);
  const [failedItems, setFailedItems] = useState([] as ListRenderItem[]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    asyncDispatch(getSSNJob(job.id)).then((res) => {
      if (res.length !== 0 && res[0]) {
        setSSNJob(res[0]);
        setLoaded(true);
        return;
      }

      // TODO: 200 response can have error data because of course
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
      setSSNJob(res as any);
      setLoaded(true);
    }, console.error);
  }, []);

  useEffect(() => {
    const responseData = ssnJob?.response_data?.cvsResponseData?.data;
    const requestData = ssnJob?.request_data.cvsRequestList[0];
    const isResponseDataEmpty =
      !!responseData &&
      Object.keys(responseData).length === 0 &&
      typeof responseData === 'object';

    if (isResponseDataEmpty || responseData?.errorCode) {
      setSSNJobSuccess(false);
    }

    if (requestData && responseData && !responseData.errorCode) {
      const tempItems: ListRenderItem[] = [];

      for (const cvsResponse of responseData.cvsResponseList) {
        if (cvsResponse.recordErrorCode) {
          tempItems.push(
            sSNRecordErrorSummary(job, cvsResponse),
            sSNRequestDataSummary(requestData)
          );
        } else {
          tempItems.push(
            sSNRequestSuccessSummary(job, cvsResponse),
            sSNRequestDataSummary(requestData)
          );
        }
      }

      setItems(tempItems);
    }

    setFailedItems([
      sSNRequestErrorSummary(ssnJob),
      requestData
        ? sSNRequestDataSummary(requestData)
        : {
            name: 'Request data',
            customRenderType: <>Request data</>,
            customRenderValue: <>n/a</>
          }
    ]);
  }, [ssnJob]);

  const isNotEnabled = () => {
    return (
      loaded &&
      // ESLINT WHAT?
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!ssnJob?.response_data?.cvsResponseData?.data || !ssnJob?.request_data)
    );
  };

  if (isNotEnabled()) {
    const SSNCopy = [
      'There is no SSN job associated with this jobId.',
      'In order to enable SSN for your account, please contact sales.'
    ];

    return <FeatureNotEnabled copy={SSNCopy} />;
  }

  return loaded ? (
    <List
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'type',
          xs: 2,
          customRenderValue: (item) => item.customRenderType
        },
        {
          name: 'value',
          xs: 8,
          customRenderValue: (item) => item.customRenderValue
        }
      ]}
      items={ssnJobSuccess ? items : failedItems}
      page={1}
      showHeaders={false}
    />
  ) : null;
};
