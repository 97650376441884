import { Text } from '@vouched-id/vault';
import React from 'react';
import { ApprovedIcon } from '../Icons/Approved';
import { NotApplicableIcon } from '../Icons/NotApplicable';
import { RejectedIcon } from '../Icons/Rejected';
import { WarningIcon } from '../Icons/Warning';
import { JobStatus } from '../utils';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItemsSection';

export const dropdownWrapperStyle = {
  margin: '0 auto 0 0',
  width: ['100%', '70%']
};

export const DropdownStatusIcon: React.FC<{
  readonly jobStatus: JobStatus;
  readonly dropdownHasRejections: boolean;
  readonly dropdownHasWarnings: boolean;
}> = ({ jobStatus, dropdownHasRejections, dropdownHasWarnings }) => {
  if (jobStatus === 'approved' || jobStatus === 'rejected') {
    if (dropdownHasRejections) {
      return <RejectedIcon />;
    }

    if (dropdownHasWarnings) {
      return <WarningIcon />;
    }

    return <ApprovedIcon />;
  }

  return null;
};

export const FeatureNotEnabledDropdown: React.FC<{
  readonly url: string;
  readonly title: string;
}> = ({ url, title }) => {
  return (
    <Dropdown title={title}>
      <Text>
        {title} was not enabled for this job. If you would like to enable it,
        please contact support@vouched.id. You can also refer to our docs for
        this feature {url}.
      </Text>
    </Dropdown>
  );
};

interface DropdownItemWithoutIcon {
  text: string;
  hasErrorOrWarning: {
    hasError: boolean;
    hasWarning: boolean;
  };
  tooltip?: string;
  submittedData?: string;
}

export enum DropdownItemTypes {
  BooleanDropdownItem = 'BooleanDropdownItem',
  ConfidenceDropdownItem = 'ConfidenceDropdownItem'
}

export interface BooleanDropdownItem extends DropdownItemWithoutIcon {
  type: DropdownItemTypes.BooleanDropdownItem; // need to know type at runtime
  value?: boolean;
  available: boolean; // value is not undefined
}

export interface ConfidenceDropdownItem extends DropdownItemWithoutIcon {
  type: DropdownItemTypes.ConfidenceDropdownItem; // need to know type at runtime
  confidence?: number | null;
  threshold: number;
  available: boolean; // confidence is not null | undefined
}

export const addIconsToDropdownItems = (
  dropdownItems: (BooleanDropdownItem | ConfidenceDropdownItem)[]
): {
  dropdownHasRejections: boolean;
  dropdownHasWarnings: boolean;
  dropdownItems: DropdownItem[];
} => {
  let dropdownHasRejections = false;
  let dropdownHasWarnings = false;

  const dropdownItemsWithIcons = dropdownItems.map((item) => {
    const { hasError, hasWarning } = item.hasErrorOrWarning;
    dropdownHasRejections ||= hasError;
    dropdownHasWarnings ||= hasWarning;

    if (hasError) {
      return {
        ...item,
        statusIcon: <RejectedIcon />
      };
    }

    if (hasWarning) {
      return {
        ...item,
        statusIcon: <WarningIcon />
      };
    }

    if (item.type === DropdownItemTypes.BooleanDropdownItem) {
      dropdownHasRejections ||= item.value === false;
      return {
        ...item,
        statusIcon: <BooleanItemIcon value={item.value} />
      };
    }

    // item is a ConfidenceDropdownItem here
    dropdownHasRejections ||=
      item.available && item.confidence! < item.threshold;

    return {
      ...item,
      statusIcon: (
        <ConfidenceItemIcon
          confidence={item.confidence}
          threshold={item.threshold}
        />
      )
    };
  });

  return {
    dropdownItems: dropdownItemsWithIcons,
    dropdownHasRejections,
    dropdownHasWarnings
  };
};

const BooleanItemIcon: React.FC<{ readonly value: boolean | undefined }> = ({
  value
}) => {
  if (value === true) {
    return <ApprovedIcon />;
  }

  if (value === false) {
    return <RejectedIcon />;
  }

  return <NotApplicableIcon />;
};

const ConfidenceItemIcon: React.FC<{
  readonly confidence: number | null | undefined;
  readonly threshold: number;
}> = ({ confidence, threshold }) => {
  if (confidence === null || confidence === undefined) {
    return <NotApplicableIcon />;
  }

  if (confidence >= threshold) {
    return <ApprovedIcon />;
  }

  return <RejectedIcon />;
};

export const readableBoolean = (bool: boolean | undefined): string => {
  if (bool === undefined) {
    return 'N/A';
  }

  return bool ? 'True' : 'False';
};

export const readableString = (str: string | null | undefined): string => {
  if (str === null || str === undefined) {
    return 'N/A';
  }

  return str;
};

export const readableNumber = (num: number | null | undefined): string => {
  if (num === null || num === undefined) {
    return 'N/A';
  }

  return String(num);
};
