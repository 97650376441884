import React from 'react';
import {
  CvsResponseItem,
  Deceased_Map,
  ListRenderItem,
  Verification_Map
} from './ssn_model';
import { getDeathIndicator, getVerificationResult } from './utils';
import { Text } from 'components/text';
import { Job } from 'models/jobs/jobs';

export const sSNRequestSuccessSummary = (
  job: Job,
  cvsResponse: CvsResponseItem
): ListRenderItem => {
  return {
    name: `Summary`,
    customRenderType: (
      <div className="job-modal-ip">
        <Text strongest={false} value="ID" />
        <Text strongest={false} value="Verification Result" />
        <Text strongest={false} value="Death Indicator" />
      </div>
    ),
    customRenderValue: (
      <div className="job-modal-ip">
        <Text value={job.id} />
        <Text value={Verification_Map[getVerificationResult(cvsResponse)]} />
        <Text value={Deceased_Map[getDeathIndicator(cvsResponse)]} />
      </div>
    )
  };
};
