import { Box, Button, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Country, countryNames } from './constants';
import { TemplateCreateForm } from './template-create-form';
import { TemplateReloadButton } from './template-reload';
import { ColumnProp, List } from 'components/reusable/list';
import { ReusableSelect as Select } from 'components/reusable/select';
import { useGlobalState } from 'store/reducers/reducer';
import {
  loadTemplates,
  TemplateListItem,
  TemplatesFilter
} from 'store/sagas/sagas';
import { history } from 'utils/history';
import './template-manager.css';
import { useDebounce } from 'utils/hooks';
import {
  MapData,
  pushFiltersSearchPath,
  QueryData,
  rawParseQuery,
  transformQueryTypes
} from 'utils/url';

interface Props {
  readonly basePath: string;
}

const goToTemplate = (templateId: string) => {
  history.push(`/admin/templates/${templateId}`);
};

const filterOptions = {
  pageSize: [
    { id: 10, name: '10' },
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 250, name: '250' },
    { id: 500, name: '500' }
  ]
};

const initialData: TemplatesFilter = {
  page: 1,
  pageSize: 50,
  version: '',
  country: '',
  state: '',
  type: '',
  id: ''
};

const mapData: MapData = {
  page: 'number',
  pageSize: 'number',
  version: 'string',
  country: 'string',
  state: 'string',
  type: 'string',
  id: 'string'
};

export const TemplateManager: React.FC<Props> = ({ basePath }) => {
  const { asyncDispatch } = useGlobalState();
  const [loading, setLoading] = useState(false);

  const [templates, setTemplates] = useState([] as TemplateListItem[]);
  const [totalTemplates, setTotalTemplates] = useState(0);

  const qsData = transformQueryTypes(mapData, rawParseQuery());
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const [filter, setFilter] = useState<TemplatesFilter>({
    ...initialData,
    ...qsData
  });

  const debouncedFilter = useDebounce(filter, 250);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    updateFilter({
      ...filter,
      page
    });
  };

  useEffect(() => {
    const { isInitial } = pushFiltersSearchPath({
      filter: debouncedFilter as unknown as QueryData,
      initialData: initialData as unknown as QueryData,
      mapData,
      path: basePath
    });

    if (!isInitial) {
      setIsOpenFilters(true);
    }

    setLoading(true);
    asyncDispatch(loadTemplates(debouncedFilter))
      .then((res) => {
        setTotalTemplates(res.total);
        setTemplates(res.templates);
        setLoading(false);
      }, console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedFilter]);

  const updateFilter = (newFilter: Partial<TemplatesFilter>) => {
    setFilter({ ...initialData, ...newFilter });
  };

  const handleChangeFilters = (
    e: ChangeEvent<{ value: number | string; name: string }>
  ) => {
    updateFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  const columns: ColumnProp<TemplateListItem>[] = [
    {
      xs: 1,
      name: 'Id',
      id: 'id'
    },
    {
      xs: 2,
      name: 'Type',
      id: 'type'
    },
    {
      xs: 1,
      name: 'Country',
      customRenderValue: ({ country }) =>
        `${countryNames[country as Country]} (${country})`,
      id: 'country'
    },
    {
      xs: 1,
      name: 'State',
      id: 'state'
    },
    {
      xs: 1,
      name: 'Version',
      id: 'version'
    },
    {
      xs: 3,
      name: 'Created',
      id: 'createdAt'
    },
    {
      xs: 3,
      name: 'Updated',
      id: 'updatedAt'
    }
  ];

  return (
    <div className="template-manager-container">
      <TemplateCreateForm />
      <Box margin="16px">
        <TemplateReloadButton />
      </Box>
      <div className="filters__container">
        <div
          className="filters"
          onClick={() => {
            setIsOpenFilters(!isOpenFilters);
          }}
        >
          <FilterListIcon
            style={{
              color: isOpenFilters ? '#4122af' : 'grey'
            }}
          />
          <span>Filter</span>
        </div>

        {isOpenFilters && (
          <div className="jobs__filters-container-container">
            <div className="jobs__filters-container u-padding-top-medium u-margin-bottom-large">
              <div className="u-margin-bottom-large">
                <Select
                  className="g-input-width-16"
                  items={filterOptions.pageSize}
                  label="Page Size"
                  name="pageSize"
                  onChange={handleChangeFilters}
                  value={filter.pageSize}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Id"
                  name="id"
                  onChange={handleChangeFilters}
                  value={filter.id}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Version"
                  name="version"
                  onChange={handleChangeFilters}
                  value={filter.version}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="State"
                  name="state"
                  onChange={handleChangeFilters}
                  value={filter.state}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Country"
                  name="country"
                  onChange={handleChangeFilters}
                  value={filter.country}
                />
                <TextField
                  className="u-margin-bottom-small g-input-width-16"
                  label="Type"
                  name="type"
                  onChange={handleChangeFilters}
                  value={filter.type}
                />
              </div>
              <div className="u-margin-bottom-large">
                <Button
                  color="primary"
                  onClick={() => {
                    updateFilter(initialData);
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="template-grid">
        <List<TemplateListItem>
          columns={columns}
          handlePageChange={handlePageChange}
          isClickable={() => true}
          items={templates}
          label="Templates"
          loading={loading}
          onClick={(item) => {
            goToTemplate(item.id);
          }}
          page={filter.page}
          total={totalTemplates}
          totalPages={Math.ceil(totalTemplates / filter.pageSize)}
        />
      </div>
    </div>
  );
};
