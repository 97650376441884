import { Box } from '@vouched-id/vault';
import React, { useCallback, useState } from 'react';
import { ThumbsDownIcon } from './Icons/ThumbsDown';
import { ThumbsUpIcon } from './Icons/ThumbsUp';

enum SelectionState {
  Selected,
  NotSelected
}

const fillColors = {
  [SelectionState.Selected]: '#5047FF',
  [SelectionState.NotSelected]: '#424242'
};

export const UserApproval: React.FC = () => {
  const [thumbsUpState, setThumbsUpState] = useState<SelectionState>(
    SelectionState.NotSelected
  );

  const [thumbsDownState, setThumbsDownState] = useState<SelectionState>(
    SelectionState.NotSelected
  );

  const thumbsUpClick = useCallback(() => {
    setThumbsUpState(SelectionState.Selected);
    setThumbsDownState(SelectionState.NotSelected);
  }, []);

  const thumbsDownClick = useCallback(() => {
    setThumbsUpState(SelectionState.NotSelected);
    setThumbsDownState(SelectionState.Selected);
  }, []);

  return (
    <Box display="flex" style={{ gap: '10px' }}>
      <ThumbsUpIcon fill={fillColors[thumbsUpState]} onClick={thumbsUpClick} />
      <ThumbsDownIcon
        fill={fillColors[thumbsDownState]}
        onClick={thumbsDownClick}
      />
    </Box>
  );
};
