import React, { useEffect, useRef } from 'react';
interface ImageCanvasRotationProps {
  readonly src: string;
  readonly isVisible?: boolean;
  readonly rotate: number;
  readonly imageWidth: number;
  readonly imageHeight: number;
  readonly onCanvasLoaded: (src64: string, canvas: HTMLCanvasElement) => void;
}

export const ImageCanvasRotation: React.FC<ImageCanvasRotationProps> = ({
  src,
  rotate = 0,
  imageWidth,
  imageHeight,
  isVisible,
  onCanvasLoaded
}) => {
  const image = new Image();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const loadImageResource = () => {
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = src;
    image.width = imageWidth;
    image.height = imageHeight;
  };

  function deg0(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    canvas.height = image.height;
    canvas.width = image.width;
    ctx.save();
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  }

  function deg180(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    canvas.height = image.height;
    canvas.width = image.width;
    const rotation = (180 * Math.PI) / 180;
    ctx.translate(canvas.width, canvas.height);
    ctx.rotate(rotation);
    ctx.save();
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  }

  function deg90(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    canvas.height = image.width;
    canvas.width = image.height;

    ctx.save();
    ctx.translate(canvas.width, 0);
    ctx.rotate((90 * Math.PI) / 180);
    ctx.drawImage(image, 0, 0, canvas.height, canvas.width);
  }

  function deg270(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
    canvas.height = image.width;
    canvas.width = image.height;
    const rotation = (270 * Math.PI) / 180;
    ctx.translate(0, canvas.height);
    ctx.rotate(rotation);
    ctx.save();
    ctx.drawImage(image, 0, 0, canvas.height, canvas.width);
  }

  function drawRotate(degrees: number) {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.imageSmoothingEnabled = false;

    if (degrees === 90 || degrees === -270) deg90(canvas, ctx);
    if (degrees === 180 || degrees === -180) deg180(canvas, ctx);
    if (degrees === 270 || degrees === -90) deg270(canvas, ctx);
    if (degrees === 0) deg0(canvas, ctx);

    const dataURL = canvas.toDataURL();
    onCanvasLoaded(dataURL, canvas);
  }

  useEffect(() => {
    loadImageResource();
  }, [rotate]);

  image.addEventListener('load', () => {
    drawRotate(rotate);
  });

  return (
    <canvas
      ref={canvasRef}
      style={{ display: isVisible ? 'initial' : 'none' }}
    />
  );
};
