import { Button } from '@material-ui/core';
import React from 'react';
import { fillJobReview, toName } from '../../utils';
import { JobType } from 'components/account/jobs/job_type';
import { List } from 'components/reusable/list';
import { Tick } from 'components/reusable/tick';
import { Text } from 'components/text';
import { Job, JobUpdateReview } from 'models/jobs/jobs';
import { capitalize } from 'utils/format';
import { noop } from 'utils/ui.utils';

interface ReviewProps {
  readonly job: Job;
  readonly handleJobReview: (
    id: string,
    review: JobUpdateReview | null
  ) => void;
  readonly readonly: boolean | null;
}

interface Result {
  label: string;
  displayValue?: string;
  checked?: boolean;
  property?: keyof JobUpdateReview;
}

export const Review: React.FC<ReviewProps> = ({
  job,
  handleJobReview,
  readonly
}) => {
  const parameters = job.request.parameters!;
  const { dob } = parameters;
  const review = fillJobReview(job, job.review);
  const name = toName(parameters);
  const success =
    job.reviewSuccess === null
      ? // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        job.result?.successWithSuggestion || job.result?.success
      : job.reviewSuccess;

  let resultMapper: Result[] = [];

  if (review.idValid !== null) {
    resultMapper.push({
      label: 'Status',
      displayValue: success ? 'Approved' : 'Rejected',
      checked: success
    });
  }

  if (review.idValid !== null) {
    resultMapper.push({ label: 'Valid ID', property: 'idValid' });
  }

  if (review.selfieValid !== null) {
    resultMapper.push({ label: 'Valid Selfie', property: 'selfieValid' });
  }

  if (review.faceMatch !== null) {
    resultMapper.push({ label: 'Face Match', property: 'faceMatch' });
  }

  if (review.nameMatch !== null) {
    resultMapper.push({ label: 'Name', property: 'nameMatch' });
  }

  if (review.birthDateMatch !== null) {
    resultMapper.push({ label: 'Birthday', property: 'birthDateMatch' });
  }

  const REVIEW_AUTH_COLS = new Set(['status', 'face match']);

  resultMapper = resultMapper.filter((item) => {
    if (job.request.type === JobType.idReverification) {
      return REVIEW_AUTH_COLS.has(item.label.toLowerCase());
    }

    return true;
  });

  return (
    <>
      <List
        columns={[
          {
            name: 'Label',
            xs: 3,
            id: 'label'
          },
          {
            name: 'Value',
            xs: 7,
            customRenderValue: (item) => {
              if (item.displayValue) {
                return <Text value={capitalize(item.displayValue)} />;
              }

              const strongName =
                item.label === 'Name' ? name.toUpperCase() : null;

              const strongDob = item.label === 'Birthday' ? dob : null;
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              const label = strongName || strongDob;
              return <Text value={label} />;
            }
          },
          {
            name: 'Pass',
            xs: 2,
            customRenderValue: (item) => {
              if (job.status !== 'completed') {
                return null;
              }

              let state: 'approved' | 'rejected' = item.checked
                ? 'approved'
                : 'rejected';

              if (item.property) {
                state = review[item.property] ? 'approved' : 'rejected';
              }

              return (
                <div
                  onClick={
                    readonly
                      ? noop
                      : () => {
                          if (!item.property) return;
                          const updateReview = {
                            ...review,

                            [item.property]: !review[item.property]
                          };

                          handleJobReview(job.id, updateReview);
                        }
                  }
                >
                  <Tick disabled={!job.review} state={state} />
                </div>
              );
            }
          }
        ]}
        items={resultMapper}
        page={1}
        showHeaders={false}
      />
      <Button
        color="primary"
        disabled={!job.review}
        onClick={
          readonly
            ? noop
            : () => {
                handleJobReview(job.id, null);
              }
        }
        style={{ margin: '0 20px' }}
        variant="contained"
      >
        Reset
      </Button>
    </>
  );
};
