import { Button, Checkbox, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccountBundleItem } from './account-bundles-item';
import { ConfirmDialog } from 'components/reusable/confirm-dialog';
import { ReusableSelect as Select } from 'components/reusable/select';
import { SelectEvent } from 'components/reusable/select/select';
import { useGlobalState } from 'store/reducers/reducer';
import {
  createBundle,
  deleteBundle,
  loadAccountBundles
} from 'store/sagas/sagas';
import './account-bundles.css';

interface RouteParams {
  id: string;
}

export interface Bundle {
  id: number;
  sid: string;
  accountId: number;
  jobType: number;
  idvIncluded: boolean;
  crosscheckIncluded: boolean;
  darkwebIncluded: boolean;
  cdlIncluded: boolean;
  ipIncluded: boolean;
  physicalIncluded: boolean;
  amlIncluded: boolean;
  dlvIncluded: boolean;
}

interface BundleCheckboxes {
  includeIdv: boolean;
  includeDlv: boolean;
  includeCrosscheck: boolean;
  includeDarkweb: boolean;
  includeCdl: boolean;
  includePhys: boolean;
  includeAml: boolean;
  includeIp: boolean;
}

export type CreateBundleRequest = Omit<Bundle, 'accountId' | 'id' | 'sid'>;

export const AccountBundles: React.FC = () => {
  const { asyncDispatch } = useGlobalState();
  const { id } = useParams<RouteParams>();
  const defaultCheckedBoxes: BundleCheckboxes = {
    includeIdv: false,
    includeDlv: false,
    includeCrosscheck: false,
    includeDarkweb: false,
    includeCdl: false,
    includePhys: false,
    includeAml: false,
    includeIp: false
  };

  const allBundleTypes = {
    IDV: { id: 0, name: 'IDV Bundle Job' },
    Crosscheck: { id: 1, name: 'Crosscheck Bundle Job' }
  };

  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [jobType, setJobType] = useState(undefined as number | undefined);

  const [includeIdv, setIncludeIdv] = useState(false);
  const [includeDlv, setIncludeDlv] = useState(false);
  const [includeCrosscheck, setIncludeCrosscheck] = useState(false);
  const [includeDarkweb, setIncludeDarkweb] = useState(false);
  const [includeCdl, setIncludeCdl] = useState(false);
  const [includeIp, setIncludeIp] = useState(false);
  const [includePhys, setIncludePhys] = useState(false);
  const [includeAml, setIncludeAml] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null);
  const [disabledCheckboxes, setDisabledCheckboxes] =
    useState(defaultCheckedBoxes);

  const refreshAccountBundles = () => {
    asyncDispatch(loadAccountBundles(id))
      .then((data) => {
        setSelectedBundle(null);
        setBundles(data);
      })
      .catch(console.error);
  };

  useEffect(() => {
    refreshAccountBundles();
  }, []);

  const submitBundle = () => {
    if (jobType === undefined) {
      return;
    }

    const data: CreateBundleRequest = {
      jobType,
      idvIncluded: includeIdv,
      dlvIncluded: includeDlv,
      crosscheckIncluded: includeCrosscheck,
      darkwebIncluded: includeDarkweb,
      cdlIncluded: includeCdl,
      ipIncluded: includeIp,
      physicalIncluded: includePhys,
      amlIncluded: includeAml
    };

    asyncDispatch(createBundle(id, data))
      .then(() => {
        refreshAccountBundles();
      })
      .catch(console.error);
  };

  const setDisabledChecks = useCallback(
    (bundleId: number) => {
      // reusing default checked values as default disabled values
      const newDisabledCheckboxes = { ...defaultCheckedBoxes };

      switch (bundleId) {
        // Crosscheck Bundle Job
        case allBundleTypes.Crosscheck.id:
          // disabling all checks
          // eslint-disable-next-line no-case-declarations
          const crosscheckDisabled = [
            'includeCrosscheck',
            'includeDlv',
            'includeAml',
            'includeCdl',
            'includeIdv',
            'includePhys'
          ] as (keyof typeof disabledCheckboxes)[];

          for (const key of crosscheckDisabled) disabledCheckboxes[key] = true;

          newDisabledCheckboxes.includeDarkweb = false;
          newDisabledCheckboxes.includeIp = false;

          setCheckValues({
            includeDarkweb: false,
            includeCrosscheck: true, // default
            includeDlv: false,
            includeAml: false,
            includeCdl: false,
            includeIdv: false,
            includeIp: false,
            includePhys: false
          });

          break;
        // IDV Bundle Job
        case allBundleTypes.IDV.id:
          newDisabledCheckboxes.includeIdv = true;

          setCheckValues({
            includeDarkweb: false,
            includeCrosscheck: false,
            includeDlv: false,
            includeAml: false,
            includeCdl: false,
            includeIdv: true, // default
            includeIp: false,
            includePhys: false
          });

          break;
      }

      setDisabledCheckboxes(newDisabledCheckboxes);
    },
    [
      allBundleTypes.Crosscheck.id,
      allBundleTypes.IDV.id,
      defaultCheckedBoxes,
      disabledCheckboxes
    ]
  );

  const handleChangeJobType = useCallback(
    (e: SelectEvent<number>) => {
      const { value } = e.target;

      setJobType(value);
      setDisabledChecks(value);
    },
    [setDisabledChecks]
  );

  const setCheckValues = (values: BundleCheckboxes) => {
    setIncludeIdv(values.includeIdv);
    setIncludeDlv(values.includeDlv);
    setIncludeCrosscheck(values.includeCrosscheck);
    setIncludeDarkweb(values.includeDarkweb);
    setIncludeCdl(values.includeCdl);
    setIncludeIp(values.includeIp);
    setIncludePhys(values.includePhys);
    setIncludeAml(values.includeAml);
  };

  const submitDeleteBundle = (bundleId: number) => {
    asyncDispatch(deleteBundle(bundleId))
      .then(() => {
        refreshAccountBundles();
      })
      .catch(console.error);
  };

  const onRemoveBundle = (bundle: Bundle) => {
    setSelectedBundle(bundle);
    setConfirmOpen(true);
  };

  const availableTypes = [allBundleTypes.IDV, allBundleTypes.Crosscheck];

  const JOB_TYPE_MAP = Object.keys(allBundleTypes).map((ky) => {
    const key = ky as keyof typeof allBundleTypes;
    return {
      id: allBundleTypes[key].id,
      label: allBundleTypes[key].name
    };
  });

  return (
    <div className="main-container">
      <h1>Create Bundle</h1>
      <div className="filters-container">
        <Grid className="grid-class-container" container>
          <Select
            className="g-full-width dropdown u-margin-bottom-small"
            itemTestId="create-bundle__dropdown__option"
            items={availableTypes}
            label="Bundle Job Type"
            name="pageSize"
            onChange={handleChangeJobType}
            required
            testId="create-bundle__dropdown"
            value={jobType}
          />
          <br />
        </Grid>
        <Grid className="grid-class-container filter-wrapper" container>
          <Grid className="grid__col">
            <span className="text-weak" data-test="create-bundle-label__idv">
              Include ID Verification
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeIdv}
                className="form-checkbox"
                data-test="create-bundle-checkbox__idv"
                disabled={disabledCheckboxes.includeIdv}
                onChange={() => {
                  setIncludeIdv((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span
              className="text-weak"
              data-test="create-bundle-label__crosscheck"
            >
              Include Crosscheck
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeCrosscheck}
                className="form-checkbox"
                data-test="create-bundle-checkbox__crosscheck"
                disabled={disabledCheckboxes.includeCrosscheck}
                onChange={() => {
                  setIncludeCrosscheck((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span className="text-weak" data-test="create-bundle-label__dlv">
              Include DLV
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeDlv}
                className="form-checkbox"
                data-test="create-bundle-checkbox__dlv"
                disabled={disabledCheckboxes.includeDlv}
                onChange={() => {
                  setIncludeDlv((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span
              className="text-weak"
              data-test="create-bundle-label__darkweb"
            >
              Include Dark Web
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeDarkweb}
                className="form-checkbox"
                data-test="create-bundle-checkbox__darkweb"
                disabled={disabledCheckboxes.includeDarkweb}
                onChange={() => {
                  setIncludeDarkweb((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span className="text-weak" data-test="create-bundle-label__cadlv">
              Include Canadian Driver License Verification
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeCdl}
                className="form-checkbox"
                data-test="create-bundle-checkbox__cadlv"
                disabled={disabledCheckboxes.includeCdl}
                onChange={() => {
                  setIncludeCdl((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span className="text-weak" data-test="create-bundle-label__phaddr">
              Include Physical Address
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includePhys}
                className="form-checkbox"
                data-test="create-bundle-checkbox__phaddr"
                disabled={disabledCheckboxes.includePhys}
                onChange={() => {
                  setIncludePhys((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span className="text-weak" data-test="create-bundle-label__aml">
              Include Aml
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeAml}
                className="form-checkbox"
                data-test="create-bundle-checkbox__aml"
                disabled={disabledCheckboxes.includeAml}
                onChange={() => {
                  setIncludeAml((val) => !val);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="grid__col">
            <span className="text-weak" data-test="create-bundle-label__ip">
              Include IP Address
            </span>
            <Grid item xs={1}>
              <Checkbox
                checked={includeIp}
                className="form-checkbox"
                data-test="create-bundle-checkbox__ip"
                disabled={disabledCheckboxes.includeIp}
                onChange={() => {
                  setIncludeIp((val) => !val);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Button
          className="bundle-submit"
          data-test="create-bundle__button"
          disabled={!jobType && typeof jobType !== 'number'}
          onClick={submitBundle}
        >
          Create Bundle
        </Button>
      </div>
      <h1>Account Bundles</h1>
      <div
        className="bundles-list filters-container"
        data-test="account-bundles__list"
      >
        {bundles.map((bundle: Bundle) => {
          const typeId = bundle.jobType;
          const label = JOB_TYPE_MAP.find((jb) => jb.id === typeId)?.label;

          return (
            <div key={bundle.id}>
              <AccountBundleItem
                bundle={bundle}
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                jobTypeLabel={label || 'N/A'}
                key={bundle.id}
                onDelete={onRemoveBundle}
              />
            </div>
          );
        })}
        <ConfirmDialog
          onConfirm={() => {
            if (selectedBundle) {
              submitDeleteBundle(selectedBundle.id);
            }
          }}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          title="Delete Bundle?"
        >
          Are you sure you want to delete this bundle?
        </ConfirmDialog>
      </div>
    </div>
  );
};
