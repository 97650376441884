import Link from '@material-ui/core/Link';
import React from 'react';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { Job } from 'models/jobs/jobs';
import { AMLJobResult } from 'store/sagas/sagas';
import { capitalize } from 'utils/format';
import { generateJobsLink } from 'utils/jobs';

interface SummaryAmlProps {
  readonly job: Job;
  readonly isAdmin: boolean;
  readonly detailedAml: AMLJobResult;
}

export const SummaryAML: React.FC<SummaryAmlProps> = ({
  job,
  isAdmin,
  detailedAml
}) => {
  const associatedJobId = detailedAml.data?.associatedJobId;

  const resultMapper = [
    {
      label: 'ID',
      value: <Text value={job.id} />
    },
    {
      label: 'Type',
      value: (
        <div>
          <Text value="AML" />
        </div>
      )
    },
    {
      label: 'Status',
      value: (
        <div>
          <Text value={capitalize('Completed')} />
        </div>
      )
    }
  ];

  if (associatedJobId) {
    const jobUrl = generateJobsLink(isAdmin, associatedJobId);
    resultMapper.push({
      label: 'Associated Job ID',
      value: (
        <Link href={jobUrl} target="_blank">
          <Text value={associatedJobId} />
        </Link>
      )
    });
  }

  return (
    <List
      columns={[
        {
          name: 'Label',
          id: 'label',
          xs: 3
        },
        {
          name: 'Value',
          id: 'value',
          xs: 7
        }
      ]}
      items={resultMapper}
      page={1}
      showHeaders={false}
    />
  );
};
