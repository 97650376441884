import { Button, Collapse, Grid, TextField } from '@material-ui/core';
import JSONEditor from 'jsoneditor';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  loadAccountProperties,
  updateAccountProperties
} from 'store/sagas/sagas';
import './account-properties.css';
import 'jsoneditor/dist/jsoneditor.css';

interface RouteParams {
  id: string;
}

export const AccountProperties: React.FC = () => {
  const { asyncDispatch, dispatch } = useGlobalState();
  const { id } = useParams<RouteParams>();

  const [configurationProperties, setConfigurationProperties] = useState('');
  const [emailTitle, setEmailTitle] = useState('');
  const [emailText, setEmailText] = useState('');
  const [emailHtml, setEmailHtml] = useState('');
  const [downloadEmailHtml, setDownloadEmailHtml] = useState('');
  const [downloadNoConfidencesEmailHtml, setDownloadNoConfidencesEmailHtml] =
    useState('');

  const [showEmailConfigurations, setShowEmailConfigurations] = useState(false);

  const jsoneditorRef = useRef<JSONEditor | null>(null);
  const propertiesDivRef = useRef<HTMLDivElement | null>(null);

  const handleEmailTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailTitle(e.target.value);
  }, []);

  const handleEmailText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailText(e.target.value);
  }, []);

  const handleEmailHtml = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailHtml(e.target.value);
  }, []);

  const handleDownloadEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDownloadEmailHtml(e.target.value);
    },
    []
  );

  const handleDownloadEmailNoConfidences = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDownloadNoConfidencesEmailHtml(e.target.value);
    },
    []
  );

  const toggleEmailConfigurations = () => {
    setShowEmailConfigurations(!showEmailConfigurations);
  };

  const refreshAccountProperties = () => {
    asyncDispatch(loadAccountProperties(id))
      .then((data) => {
        if (!data.configurationProperties) {
          return;
        }

        setEmailHtml(data.configurationProperties.html);
        setEmailText(data.configurationProperties.text);
        setEmailTitle(data.configurationProperties.title);
        setDownloadNoConfidencesEmailHtml(
          data.configurationProperties.downloadNoConfidences
        );

        setDownloadEmailHtml(data.configurationProperties.download);
        setConfigurationProperties(data.configurationProperties.props);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (propertiesDivRef.current !== null) {
      if (!jsoneditorRef.current) {
        jsoneditorRef.current = new JSONEditor(propertiesDivRef.current, {
          mode: 'code',
          indentation: 2
        });
      }

      if (configurationProperties && configurationProperties.length > 1) {
        try {
          jsoneditorRef.current.set(JSON.parse(configurationProperties));
        } catch (error) {
          console.error(error);
          dispatch(
            setMessages([
              {
                value: 'Unable to load configuration properties',
                severity: 'error'
              }
            ])
          );
        }
      }
    }
  }, [jsoneditorRef, propertiesDivRef, configurationProperties]);

  useEffect(() => {
    refreshAccountProperties();
    return () => {
      jsoneditorRef.current?.destroy();
    };
  }, []);

  const updateProperties = () => {
    try {
      const json = jsoneditorRef.current?.get() as object | undefined;
      const data = {
        emailTitle: emailTitle || '',
        emailText: emailText || '',
        emailHtml: emailHtml || '',
        downloadEmailHtml: downloadEmailHtml || '',
        downloadNoConfidencesEmailHtml: downloadNoConfidencesEmailHtml || '',
        configurationProperties: json ? JSON.stringify(json, null, 2) : ''
      };

      asyncDispatch(updateAccountProperties(id, data))
        .then(() => {
          refreshAccountProperties();
        })
        .catch(console.error);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        dispatch(setMessages([{ value: error.message, severity: 'error' }]));
      } else {
        dispatch(
          setMessages([
            {
              value: 'Configuration Properties is not valid JSON',
              severity: 'error'
            }
          ])
        );
      }
    }
  };

  const resetProperties = () => {
    const data = { reset: true };

    asyncDispatch(updateAccountProperties(id, data))
      .then(() => {
        refreshAccountProperties();
      })
      .catch(console.error);
  };

  return (
    <div className="properties-container">
      <Grid container item spacing={3} xs={12}>
        <Grid item xs={12}>
          <div className="properties-editor-container">
            <div>Configuration Properties</div>
            <div
              className="properties-editor"
              ref={propertiesDivRef}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={toggleEmailConfigurations}>
            {showEmailConfigurations
              ? 'Hide Email Configurations'
              : 'Show Email Configurations'}
          </Button>
        </Grid>
        <Collapse in={showEmailConfigurations} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              label="Email Title"
              onChange={handleEmailTitle}
              value={emailTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              label="Email Template"
              multiline
              onChange={handleEmailHtml}
              rows={10}
              value={emailHtml}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              label="Email Text"
              multiline
              onChange={handleEmailText}
              rows={10}
              value={emailText}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              label="Download Email"
              multiline
              onChange={handleDownloadEmail}
              rows={10}
              value={downloadEmailHtml}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="g-full-width u-margin-bottom-small"
              label="Download No Confidences Email"
              multiline
              onChange={handleDownloadEmailNoConfidences}
              rows={10}
              value={downloadNoConfidencesEmailHtml}
            />
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          <Button onClick={resetProperties}>Reset to Null</Button>
          <Button onClick={updateProperties}>Update</Button>
        </Grid>
      </Grid>
    </div>
  );
};
