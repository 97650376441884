import React, { FC, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as ReactGA from 'react-ga';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ErrorsInterceptor } from '../errors-interceptor';
import { AcceptUserInvite } from 'components/accept-user-invite';
import { Account } from 'components/account';
import { Confirm } from 'components/account/confirm';
import { Admin } from 'components/admin';
import {
  CreateAccount,
  UpdateAccountInfoPage
} from 'components/create-account';
import { ForgotPassword } from 'components/forgot-password';
import { PrivateRoute } from 'components/private-route';
import { ResetPassword } from 'components/reset-password';
import { ResetPasswordCheck } from 'components/reset-password/reset-password-check';
import { Signin } from 'components/signin';
import { Support } from 'components/support';
import { TwoFactor } from 'components/two-factor';
import { VerificationPage } from 'components/verification-page';
import { Version } from 'components/version';
import { useGlobalState } from 'store/reducers/reducer';
import { history } from 'utils/history';

declare global {
  interface Window {
    process: NodeJS.Process;
  }
}
if (!('process' in window)) {
  (window as { process: object }).process = {}; // TODO: Delete?
}

export const AppRoutes: FC = () => {
  const { state } = useGlobalState();
  const isUserLogIn = state.accessToken;

  useEffect(() => {
    if (process.env['REACT_APP_NODE_ENV'] === 'production') {
      ReactGA.initialize('UA-151415696-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  return (
    <ErrorsInterceptor>
      <Router history={history}>
        <ResetPasswordCheck>
          <Switch>
            <Route component={isUserLogIn ? Account : Signin} exact path="/" />
            <Route component={Signin} exact path="/signin" />
            <Route component={TwoFactor} exact path="/verify-2fa" />
            <Route component={CreateAccount} exact path="/create-account" />
            <PrivateRoute
              component={UpdateAccountInfoPage}
              exact
              path="/update-account-info"
            />
            <PrivateRoute
              component={VerificationPage}
              exact
              path="/needs-verification"
            />
            <Route component={AcceptUserInvite} exact path="/join" />
            <Route component={ForgotPassword} exact path="/forgot-password" />
            <Route component={ResetPassword} exact path="/reset-password" />
            <PrivateRoute component={Support} path="/support" />
            <PrivateRoute component={Confirm} exact path="/account/confirm" />
            {isUserLogIn && (
              <PrivateRoute component={Account} path="/account" />
            )}
            {isUserLogIn && <PrivateRoute component={Admin} path="/admin" />}
            <PrivateRoute component={Version} path="/version" />
            <Redirect to="/" />
          </Switch>
        </ResetPasswordCheck>
      </Router>
    </ErrorsInterceptor>
  );
};
