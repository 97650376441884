import React from 'react';
import { RenderItem } from '../id-information/id-information';
import { DobResponseData } from './dob_model';
import { Text } from 'components/text';

export const dobResponseDataSummary = (data?: DobResponseData): RenderItem => {
  return {
    name: 'Response',
    customRenderType: (
      <div>
        <Text strongest={false} value="DOB Match" />
      </div>
    ),
    customRenderValue: (
      <div>
        <Text value={String(data?.dobMatch ?? false)} />
      </div>
    )
  };
};
