import { HttpStatusCode, isAxiosError } from 'axios';
import { Dispatch } from 'react';
import { isIapProtectedUrl, setIapAuthRequired } from 'security/iap';
import { Action, logout, setMessages } from 'store/actions/actions';
import { StoreProps, StoreState } from 'store/reducers/reducer';
import { ActionProps } from 'store/sagas/sagas';
import { convertErrors, VagueErrorType } from 'utils/convertError';

const dispatchErrors = (
  dispatch: Dispatch<Action>,
  errors: {
    type: string;
    message: string;
    id: string;
  }[]
) => {
  dispatch(
    setMessages(errors.map((m) => ({ value: m.message, severity: 'error' })))
  );
};

class APIAggregateError extends Error {
  public constructor(
    public readonly subErrors: {
      type: string;
      message: string;
      id: string;
    }[]
  ) {
    super(`Multiple errors ${subErrors.map((s) => s.message).join(', ')}`);
    this.name = 'APIAggregateError';
  }
}

export const createAsyncMiddleware =
  (state: StoreState, dispatch: React.Dispatch<Action>) =>
  async <T>(
    action: T | (({ state }: StoreProps) => Promise<T>)
  ): Promise<T> => {
    if (typeof action === 'function') {
      try {
        return await (action as ({ state }: ActionProps) => Promise<T>)({
          dispatch,
          state
        });
      } catch (error) {
        if (
          error &&
          isAxiosError(error) &&
          (error.response?.status === HttpStatusCode.Unauthorized ||
            error.response?.status === HttpStatusCode.Forbidden)
        ) {
          if (isIapProtectedUrl(error.config?.url)) {
            setIapAuthRequired();
          } else {
            dispatch(logout()); // TODO: Bug risk?
            return undefined as T;
          }
        }

        if (isAxiosError(error) && error.response) {
          const errors = convertErrors(error.response.data as VagueErrorType);
          if (errors.length >= 1) {
            dispatchErrors(dispatch, errors);
          }

          throw new APIAggregateError(errors);
        }

        throw error;
      }
    }

    return action;
  };
