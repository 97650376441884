import React from 'react';
import { JobChart } from './job-chart/job-chart';
import './job-charts.css';

export interface JobChartProps {
  type: JobChartType;
}

export enum JobChartType {
  getJobsLastSixMonths,
  getCreatedJobsSinceLogin,
  getJobs,
  getJobsApproved,
  getJobsCountByStatus
}

export const JobCharts: React.FC = () => {
  return (
    <div className="job-charts-main">
      <h2>Recent Identity Verification Jobs Activity</h2>
      <div
        className="job-charts-row"
        style={{
          marginBottom: '20px'
        }}
      >
        <JobChart type={JobChartType.getCreatedJobsSinceLogin} />
        <JobChart type={JobChartType.getJobsApproved} />
        <JobChart type={JobChartType.getJobsCountByStatus} />
      </div>
      <h2>Last 30 days Activity</h2>
      <div className="job-charts-row">
        <JobChart type={JobChartType.getJobsLastSixMonths} />
        <JobChart type={JobChartType.getJobs} />
      </div>
    </div>
  );
};
