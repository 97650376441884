import { Link } from '@material-ui/core';
import React from 'react';
import { statusMapper } from 'components/account/jobs/job-status';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { Job } from 'models/jobs/jobs';
import { capitalize } from 'utils/format';

interface SummaryAuthenticationProps {
  readonly job: Job;
  readonly isAdmin: boolean | null | undefined;
}

export const SummaryAuthentication: React.FC<SummaryAuthenticationProps> = ({
  job,
  isAdmin
}) => {
  const jobAccount = isAdmin ? 'admin/jobs/' : 'account/jobs/';
  const mainURL =
    process.env['REACT_APP_NODE_ENV'] === 'production'
      ? 'https://app.vouched.id/'
      : 'https://app.stage.vouched.id/'; // Use for local tests"http://localhost:3000/";

  const jobURL = mainURL + jobAccount + job.request.parameters?.id;
  const sourceJobID = <Text value={job.request.parameters?.id} />;
  const { state } = statusMapper(
    job.status,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    !!(job.result?.successWithSuggestion || job.result?.success)
  );

  const resultMapper = [
    {
      label: 'ID',
      value: <Text value={job.id} />
    },
    {
      label: 'Source Job ID',
      value: (
        <Link href={jobURL} target="_blank">
          {sourceJobID}
        </Link>
      )
    },
    {
      label: 'Type',
      value: (
        <div>
          <Text value="Reverification" />
        </div>
      )
    },
    {
      label: 'Status',
      value: (
        <div>
          {job.reviewSuccess !== null && (
            <Text
              value={capitalize(job.result?.success ? 'approved' : 'rejected')}
            />
          )}
          <Text value={capitalize(state ?? '')} />
        </div>
      )
    },
    {
      label: 'Name',
      value: <Text value={`${job.result?.firstName} ${job.result?.lastName}`} />
    }
  ];

  return (
    <List
      columns={[
        {
          name: 'Label',
          id: 'label',
          xs: 3
        },
        {
          name: 'Value',
          id: 'value',
          xs: 7
        }
      ]}
      items={resultMapper}
      page={1}
      showHeaders={false}
    />
  );
};
