import { useEffect, useState } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

function getWindowDimensions(): Dimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions(): Dimensions {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowDimensions;
}

export const useIsMobile = (): boolean => {
  const { width, height } = useWindowDimensions();

  const [orientation, setOrientation] = useState(
    window.matchMedia('(orientation: portrait)').matches
  );

  useEffect(() => {
    const orientationChange = () => {
      setOrientation(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.screen?.orientation?.type
          ? window.screen.orientation.type.startsWith('portrait')
          : // not supported in older safari
            // eslint-disable-next-line deprecation/deprecation
            window.orientation % 180 === 0
      );
    };

    window.addEventListener('orientationchange', orientationChange);
    return () => {
      window.removeEventListener('orientationchange', orientationChange);
    };
  });

  return (orientation && width < 600) || (!orientation && height < 600);
};
