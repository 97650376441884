import React from 'react';

export const fillStar = (
  <svg
    className="start_selected"
    fill="none"
    height="20"
    viewBox="0 0 30 30"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M29.9219 11.5079C29.7255 10.9004 29.1867 10.4689 28.5492 10.4115L19.8904 9.62526L16.4665 1.61121C16.214 1.02389 15.639 0.643703 15.0002 0.643703C14.3614 0.643703 13.7864 1.02389 13.534 1.61258L10.11 9.62526L1.44984 10.4115C0.813528 10.4703 0.2761 10.9004 0.0785696 11.5079C-0.118961 12.1153 0.063463 12.7816 0.544814 13.2016L7.08986 18.9417L5.15988 27.4432C5.01865 28.0683 5.26127 28.7145 5.77993 29.0894C6.05872 29.2908 6.38488 29.3934 6.7138 29.3934C6.99739 29.3934 7.27869 29.3169 7.53116 29.1659L15.0002 24.7019L22.4665 29.1659C23.0129 29.4945 23.7016 29.4646 24.2191 29.0894C24.738 28.7133 24.9804 28.067 24.8392 27.4432L22.9092 18.9417L29.4543 13.2028C29.9356 12.7816 30.1194 12.1165 29.9219 11.5079Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="30" width="30" />
      </clipPath>
    </defs>
  </svg>
);

export const emptyStar = (
  <svg
    className="start_not-selected"
    fill="none"
    height="20"
    viewBox="0 0 30 30"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M29.9219 11.5079C29.7255 10.9004 29.1867 10.4689 28.5492 10.4115L19.8904 9.62526L16.4665 1.61121C16.214 1.02389 15.639 0.643703 15.0002 0.643703C14.3614 0.643703 13.7864 1.02389 13.534 1.61258L10.11 9.62526L1.44984 10.4115C0.813528 10.4703 0.2761 10.9004 0.0785696 11.5079C-0.118961 12.1153 0.063463 12.7816 0.544814 13.2016L7.08986 18.9417L5.15988 27.4432C5.01865 28.0683 5.26127 28.7145 5.77993 29.0894C6.05872 29.2908 6.38488 29.3934 6.7138 29.3934C6.99739 29.3934 7.27869 29.3169 7.53116 29.1659L15.0002 24.7019L22.4665 29.1659C23.0129 29.4945 23.7016 29.4646 24.2191 29.0894C24.738 28.7133 24.9804 28.067 24.8392 27.4432L22.9092 18.9417L29.4543 13.2028C29.9356 12.7816 30.1194 12.1165 29.9219 11.5079Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect fill="white" height="30" width="30" />
      </clipPath>
    </defs>
  </svg>
);
