import React from 'react';
import './version.css';

export const Version: React.FC = () => {
  return (
    <div className="version_container">
      <div>Build: {process.env['REACT_APP_BUILD_ID']!}</div>
      <div>Color: {process.env['REACT_APP_COLOR']!}</div>
      <div>Environment: {process.env['REACT_APP_NODE_ENV']!}</div>
    </div>
  );
};
