import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from './icons/icon_profile.svg';
import { useGlobalState } from 'store/reducers/reducer';

interface SidebarProfileProps {
  readonly menuReset?: () => void;
}

export const SidebarProfile: FC<SidebarProfileProps> = ({ menuReset }) => {
  const { state } = useGlobalState();

  return (
    <NavLink
      className="nav-link profile"
      data-cy="Profile"
      onClick={() => {
        if (menuReset) menuReset();
      }}
      to="/account"
    >
      <div className="nav-link__items">
        <ProfileIcon />
        <div className="profile__names">
          {state.account && (
            <div className="account-name text-ellipsis text-weak">
              {state.account.name}
            </div>
          )}
          {state.user && (
            <div className="account-user text-ellipsis">
              {state.user.firstName} {state.user.lastName}
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};
