/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { ErrorPage } from './error-page';

interface InterceptorState {
  error: boolean;
}

// eslint-disable-next-line react/require-optimization
export class ErrorsInterceptor extends Component<object, InterceptorState> {
  public constructor(props: object) {
    super(props);
    this.state = {
      error: false
    };
  }

  public override componentDidCatch(): void {
    // eslint-disable-next-line react/no-set-state
    this.setState({ error: true });
  }

  public override render(): React.ReactNode {
    if (this.state.error) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
