import { Card, CardHeader, IconButton } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { MouseEvent, useState } from 'react';
import './images-viewer.css';
import { Image } from '../image-slider/smart-image';
import { ImageTooltip } from './image-tooltip';
import { JobImageType } from 'models/jobs/jobs';
interface ImageViewerProps {
  readonly mainImage: Image;
  readonly imageType?: JobImageType | undefined;
  readonly jobResult?: {
    id: string;
    country: string;
    state: string;
    type: string;
    version: string;
  };
}

export const ImagesViewer: React.FC<ImageViewerProps> = ({
  mainImage,
  imageType,
  jobResult,
  children
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (url: string) => {
    const [path] = url.split('?');
    if (!path) {
      return;
    }

    const fileName = path.slice(path.lastIndexOf('/') + 1);

    setAnchorEl(null);

    fetch(url)
      .then(async (response) => response.blob())
      .then((blob) => {
        const objUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objUrl;
        a.download = fileName;
        a.click();
      })
      .catch(console.error);
  };

  const open = Boolean(anchorEl);
  const showJobImage =
    jobResult && imageType !== undefined && imageType !== JobImageType.Selfie;

  let title = '';
  let id;
  let country;
  let state;
  let type;
  let version = '';

  if (showJobImage) {
    ({ id, country, state, type, version } = jobResult);

    title = [
      jobResult.id,
      jobResult.country,
      jobResult.state,
      jobResult.type,
      jobResult.version
    ]
      .filter((data: string) => !!data)
      .join(' - ');
  } else {
    title = 'Selfie image';
  }

  return (
    <Card>
      {jobResult && (
        <ImageTooltip
          country={country}
          disable={!showJobImage}
          id={id}
          state={state}
          type={type}
          version={version}
        >
          <div>
            <CardHeader
              action={
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              }
              title={title}
            />
          </div>
        </ImageTooltip>
      )}
      <div className="card-content">{children}</div>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        id="menu-popover"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List>
          <ListItem
            className="pointer"
            onClick={() => {
              handleDownload(mainImage.url);
            }}
          >
            <span className="pointer fas fa-download" />
            <ListItemText primary="Download File" />
          </ListItem>
        </List>
      </Popover>
    </Card>
  );
};
