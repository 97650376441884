import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CompanyAccount,
  CompanySize
} from 'models/company-account/company-account.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  adminDisableAccount,
  adminLoadAccount,
  adminResetMeteredUsage,
  adminUpdateAccount
} from 'store/sagas/sagas';
import { history } from 'utils/history';
import { isURL } from 'utils/url';

interface RouteParams {
  id: string;
}

const validateFormBeforeSubmit = (
  form: { url?: string },
  fnMessage?: (msg: string) => void
): boolean => {
  const { url = '' } = form;

  // check if url value is a valid value
  if (url && !isURL(url)) {
    fnMessage?.(`the URL format is incorrect`);
    return false;
  }

  return true;
};

export const AccountForm: React.FC = () => {
  const { asyncDispatch, dispatch } = useGlobalState();

  const [account, setAccount] = useState<CompanyAccount | null>(null);
  const [invalidData, setInvalidData] = useState(false);
  const [secretClientKey, setSecretClientKey] = useState('');

  const { id } = useParams<RouteParams>();

  useEffect(() => {
    asyncDispatch(adminLoadAccount(id)).then(setAccount).catch(console.error);
  }, []);

  const updateAccount = () => {
    if (!account) {
      return;
    }

    const data = {
      firstName: account.firstName,
      lastName: account.lastName,
      name: account.name,
      url: account.url,
      email: account.email,
      phoneNumber: account.phoneNumber,
      setupAccountStep: account.setupAccountStep,
      setupStatus: account.setupStatus,
      companySize: account.companySize,
      city: account.city,
      province: account.province,
      country: account.country,
      industry: account.industry,
      isDisabled: account.isDisabled,
      lastUsageRecordUpdate: account.lastUsageRecordUpdate,
      stripeCustomerId: account.stripeCustomerId,
      subscriptionId: account.subscriptionId,
      subscriptionTier: account.subscriptionTier,
      retentionPolicy: account.retentionPolicy
    };

    const isValid = validateFormBeforeSubmit(data, (msg) => {
      dispatch(
        setMessages({
          value: msg,
          severity: 'warning'
        })
      );
    });

    if (!isValid) return;

    // overwrite url param
    if (!data.url) {
      data.url = 'https://www.vouched.id/';
    }

    asyncDispatch(
      adminUpdateAccount(
        account.id,
        secretClientKey ? { ...data, secretClientKey } : data
      )
    ).catch(console.error);
  };

  const goToAccountBundles = () => {
    history.push(`/admin/accounts/${id}/bundles`);
  };

  const goToAccountProperties = () => {
    history.push(`/admin/accounts/${id}/properties`);
  };

  const handleName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, name: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, email: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, lastName: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, firstName: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleUrl = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, url: e.target.value }));
    setInvalidData(false);
  }, []);

  const handlePhoneNumber = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, phoneNumber: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleSetupAccountStep = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccount((a) => ({ ...a!, setupAccountStep: e.target.value }));
      setInvalidData(false);
    },
    []
  );

  const handleSetupStatus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, setupStatus: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleCountry = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, country: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleCity = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, city: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleProvince = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, province: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleCompanySize = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, companySize: e.target.value as CompanySize }));
    setInvalidData(false);
  }, []);

  const handleStripeCustomerId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccount((a) => ({ ...a!, stripeCustomerId: e.target.value }));
      setInvalidData(false);
    },
    []
  );

  const handleSubscriptionId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccount((a) => ({ ...a!, subscriptionId: e.target.value }));
      setInvalidData(false);
    },
    []
  );

  const handleSubscriptionTier = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccount((a) => ({ ...a!, subscriptionTier: e.target.value }));
      setInvalidData(false);
    },
    []
  );

  const handleIndustry = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAccount((a) => ({ ...a!, industry: e.target.value }));
    setInvalidData(false);
  }, []);

  const handleLastUsageRecordUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccount((a) => ({
        ...a!,
        lastUsageRecordUpdate: new Date(e.target.value)
      }));

      setInvalidData(false);
    },
    []
  );

  const handleSecretClientKey = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSecretClientKey(e.target.value);
      setInvalidData(false);
    },
    []
  );

  const handleSetAutoPay = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setAccount((a) => ({ ...a!, autoPay: checked }));
    },
    []
  );

  const handleSetDisabled = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setAccount((a) => ({ ...a!, isDisabled: checked }));
    },
    []
  );

  const handleSetActiveBilling = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setAccount((a) => ({ ...a!, hasActiveBilling: checked }));
    },
    []
  );

  const confirmResetMessage =
    "This will delete from Stripe all metered products in this account's subscription " +
    'along with all usage data, then create new products and set current month-to-date usage. ' +
    'Are you sure you want to do this?';

  const resetMeteredUsage = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(confirmResetMessage)) {
      asyncDispatch(adminResetMeteredUsage(id))
        .then(() => {
          dispatch(
            setMessages({
              value: `Metered usage data has been reset`,
              severity: 'success'
            })
          );
        })
        .catch(console.error);
    }
  };

  const confirmDisableMessage =
    "This will cancel the account's Stripe subscription (if any) and disable the account. " +
    'Are you sure you want to do this?';

  const disableAccount = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(confirmDisableMessage)) {
      asyncDispatch(adminDisableAccount(id))
        .then((data) => {
          setAccount((a) => ({
            ...a!,
            isDisabled: data.isDisabled,
            subscriptionId: data.subscriptionId ?? '',
            autoPay: data.autoPay,
            hasActiveBilling: data.hasActiveBilling
          }));

          dispatch(
            setMessages({
              value: `Account has been disabled`,
              severity: 'success'
            })
          );
        })
        .catch(console.error);
    }
  };

  if (!account) {
    return <>Loading...</>;
  }

  return (
    <div>
      <h1>Update Account</h1>
      <div className="filters-container">
        <section className="grid-class-container">
          <Grid className="grid__col" container>
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="ID"
              required
              value={account.id}
            />
          </Grid>
          <Grid className="grid__col" container>
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="SID"
              required
              value={account.sid}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Email"
              onChange={handleEmail}
              required
              value={account.email}
            />
          </Grid>

          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="First name"
              onChange={handleFirstName}
              required
              value={account.firstName}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Last name"
              onChange={handleLastName}
              required
              value={account.lastName}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Name"
              onChange={handleName}
              required
              value={account.name}
            />
          </Grid>

          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="URL"
              onChange={handleUrl}
              required
              value={account.url}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Phone Number"
              onChange={handlePhoneNumber}
              required
              value={account.phoneNumber}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Setup Account Step"
              onChange={handleSetupAccountStep}
              required
              value={account.setupAccountStep}
            />
          </Grid>

          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Setup Status"
              onChange={handleSetupStatus}
              required
              value={account.setupStatus}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="City"
              onChange={handleCity}
              required
              value={account.city}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Country"
              onChange={handleCountry}
              required
              value={account.country}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Province"
              onChange={handleProvince}
              required
              value={account.province}
            />
          </Grid>

          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Industry"
              onChange={handleIndustry}
              required
              value={account.industry}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Last Usage Record Update"
              onChange={handleLastUsageRecordUpdate}
              required
              value={account.lastUsageRecordUpdate}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Company Size"
              onChange={handleCompanySize}
              required
              value={account.companySize}
            />
          </Grid>

          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Stripe Customer ID"
              onChange={handleStripeCustomerId}
              required
              value={account.stripeCustomerId}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Subscription ID"
              onChange={handleSubscriptionId}
              required
              value={account.subscriptionId}
            />
          </Grid>
          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              label="Subscription Tier"
              onChange={handleSubscriptionTier}
              required
              value={account.subscriptionTier}
            />
          </Grid>
          {account.retentionPolicy ? (
            <Grid className="grid__col">
              <TextField
                className="g-full-width u-margin-bottom-small"
                disabled
                error={invalidData}
                label="Retention Policy Days (Read Only)"
                type="number"
                value={account.retentionPolicy}
              />
            </Grid>
          ) : null}

          <Grid className="grid__col">
            <TextField
              className="g-full-width u-margin-bottom-small"
              error={invalidData}
              helperText={
                "This field will not load the actual secret key but if you enter anything and hit update, it will update the user's key"
              }
              label="Secret Client Key"
              multiline
              onChange={handleSecretClientKey}
              rows={3}
              value={secretClientKey}
            />
          </Grid>

          <Grid className="grid__col">
            <div className="form-checkbox">AutoPay</div>

            <Grid item xs={1}>
              <Checkbox
                checked={!!account.autoPay}
                className="form-checkbox"
                disabled
                onChange={handleSetAutoPay}
              />
            </Grid>
          </Grid>

          <Grid className="grid__col">
            <div className="form-checkbox">Disabled</div>

            <Grid item xs={1}>
              <Checkbox
                checked={account.isDisabled}
                className="form-checkbox"
                onChange={handleSetDisabled}
              />
            </Grid>
          </Grid>

          <Grid className="grid__col">
            <div className="form-checkbox">Has Active Billing</div>

            <Grid item xs={1}>
              <Checkbox
                checked={!!account.hasActiveBilling}
                className="form-checkbox"
                disabled
                onChange={handleSetActiveBilling}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={3} xs={12}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Button onClick={updateAccount}>Update</Button>
            </Grid>
          </Grid>
        </section>
      </div>

      <h1>Manage Account Properties and Billing</h1>
      <span className="u-margin-right-small">
        <Button onClick={goToAccountProperties}>Manage Properties</Button>
      </span>
      <span className="u-margin-right-small">
        <Button onClick={goToAccountBundles}>Manage Bundles</Button>
      </span>
      <span className="u-margin-right-small">
        <Button onClick={resetMeteredUsage}>Reset Metered Usage</Button>
      </span>
      <span className="u-margin-right-small">
        <Button onClick={disableAccount}>Disable Account</Button>
      </span>
    </div>
  );
};
