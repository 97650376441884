import React, { FC } from 'react';

interface LogoProps {
  readonly fill: string;
  readonly width: string;
  readonly maxWidth: string;
}

export const VouchedLogo: FC<LogoProps> = ({ fill, width, maxWidth }) => (
  <svg
    aria-labelledby="titleId descId"
    data-name="Group 161416"
    role="img"
    style={{
      display: 'block',
      height: 'auto',
      margin: '0 auto',
      maxWidth,
      width
    }}
    viewBox="0 0 364.195 80.777"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title id="titleId">Vouched: Identity Verification</title>
    <desc id="descId">
      Purple bold text "Vouched" with a checkmark for the letter V, signifying
      trust and verification.
    </desc>
    <defs>
      <clipPath>
        <rect fill={fill} height="80.777" width="364.195" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)" transform="translate(0 0)">
      <path
        d="M798.452,43.451V15.54H811.1V86.147H799.526V81.413c-.331,0-.511-.055-.577.008-11.974,11.533-31.436,5.76-35.807-10.789-2.329-8.819-1.239-17.253,4.692-24.578,7.58-9.361,22.239-9.986,29.653-3.223a9.115,9.115,0,0,0,.964.62m.141,20.668c.019-7.119-2.677-11.555-7.411-13.311a11.72,11.72,0,0,0-14.43,5.21c-3.109,5.612-1.775,13.537,2.985,17.1a11.569,11.569,0,0,0,12.474,1.259c4.771-2.211,6.244-6.464,6.381-10.26"
        fill={fill}
        transform="translate(-446.906 -9.114)"
      />
      <path
        d="M525.407,86.335c-4.1,0-7.815.046-11.527-.059-.456-.013-.951-.787-1.307-1.293-.163-.232-.033-.669-.033-1.014q0-33.069,0-66.138V15.638h12.789V43.11c.744-.524,1.212-.84,1.665-1.176,7.209-5.358,18.052-4.577,23.779,1.753a18.412,18.412,0,0,1,4.587,12.525c.129,9.643.072,19.289.079,28.933a5.726,5.726,0,0,1-.224,1.077H542.67c0-.809,0-1.6,0-2.388,0-8.336.058-16.673-.029-25.008-.065-6.122-4.745-9.732-10.718-8.442-3.92.847-6.473,4.171-6.5,8.678-.047,8.267-.016,16.534-.018,24.8v2.471"
        fill={fill}
        transform="translate(-300.57 -9.172)"
      />
      <path
        d="M24.378,80.777,0,37.01l11.673-6.6L24.548,53.457,55.185,0l11.61,6.724L24.378,80.777"
        fill={fill}
      />
      <path
        d="M646.419,99.546c1.2,5.447,5.753,8.821,11.987,9.027A19.932,19.932,0,0,0,670.29,105.1c.563-.372,1.128-.743,1.835-1.208l5.281,8.687a28.164,28.164,0,0,1-13.538,6.667c-10.6,2.145-21.694-2.452-27.075-11.259a25.154,25.154,0,0,1,2.582-29.241,24.373,24.373,0,0,1,27.539-6.218A21,21,0,0,1,679.5,92.8c-.06,1.645-.393,3.279-.462,4.924-.066,1.583-.884,1.852-2.285,1.844-9.365-.05-18.731-.026-28.1-.026h-2.241m20.407-9.228c.2-5.071-3.458-8.7-9.028-9.159-5.431-.452-10.748,3.853-11.3,9.159Z"
        fill={fill}
        transform="translate(-371.408 -41.416)"
      />
      <path
        d="M153.956,119.567c-13.964-.045-24.7-10.719-24.673-24.526.029-13.705,10.942-24.407,24.874-24.392,13.982.015,25.029,10.889,24.967,24.577s-11.109,24.385-25.168,24.341m.145-37.032c-6.9.022-11.818,5.274-11.822,12.627,0,7.277,5.028,12.611,11.888,12.6,6.816-.01,12.01-5.631,11.9-12.884-.1-7.093-5.217-12.366-11.969-12.345"
        fill={fill}
        transform="translate(-75.823 -41.435)"
      />
      <path
        d="M266.44,73.358c3.843,0,7.352,0,10.862,0,.547,0,1.093.045,2.014.085v2.406c0,8.336-.053,16.672.024,25.008.057,6.085,5.471,9.927,11.293,8.127a7.782,7.782,0,0,0,5.562-6.082,17.051,17.051,0,0,0,.329-3.692c.028-7.785.014-15.57.014-23.354V73.495h12.551a3.213,3.213,0,0,1,.208.788c-.031,9.23.218,18.472-.189,27.686-.517,11.723-10.482,20.152-22.2,19.309-12.652-.91-20.415-9.189-20.464-21.877-.031-7.991-.007-15.983-.007-23.974V73.358"
        fill={fill}
        transform="translate(-156.259 -43.023)"
      />
      <path
        d="M423.208,101.1l8.726,6.729c-3.176,8.129-14.286,13.31-24.445,11.533a24.4,24.4,0,0,1-20.559-23.92c-.306-11.661,7.739-21.583,19.634-24.216,10.395-2.3,21.633,2.584,25.383,11.194l-8.7,6.825c-2.952-4.189-6.567-7.1-12.012-6.718a11.557,11.557,0,0,0-8.03,3.71c-4.472,4.841-4.269,13.692.473,18.271a11.7,11.7,0,0,0,10.481,3.11c4.015-.64,6.667-3.329,9.055-6.518"
        fill={fill}
        transform="translate(-226.925 -41.443)"
      />
    </g>
  </svg>
);
