import React, { forwardRef, HTMLAttributes } from 'react';

export const InfoIcon = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    return (
      <i
        className="fa fa-question-circle"
        ref={ref}
        style={{ fontSize: '1.3rem' }}
        {...props}
      />
    );
  }
);

InfoIcon.displayName = 'InfoIcon';
