import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { useContext } from 'react';

interface DataTableProps {
  readonly caption?: string;
  readonly items: Record<string, number | string | undefined>;
}

export const DataTable: React.FC<DataTableProps> = ({ caption, items }) => {
  const { tokens } = useContext(ThemeContext).theme;

  const tCellStyles = {
    border: '1px solid #dddddd',
    padding: '8px',
    verticalAlign: 'center'
  };

  const thStyles = {
    textAlign: 'left' as const,
    backgroundColor: tokens.colors.purple,
    color: tokens.colors.white
  };

  return (
    <Box marginY="spacing.xlarge">
      <table
        style={{
          borderCollapse: 'collapse',
          width: '100%',
          margin: '25px 0'
        }}
      >
        {caption && (
          <caption style={{ padding: '10px', fontWeight: 'bold' }}>
            {caption}
          </caption>
        )}
        <tbody>
          <tr>
            <th
              style={{
                ...tCellStyles,
                ...thStyles
              }}
            >
              Field
            </th>
            <th
              style={{
                ...tCellStyles,
                ...thStyles
              }}
            >
              Value
            </th>
          </tr>
          {Object.entries(items).map(([key, value]) => (
            <tr key={key}>
              <td style={tCellStyles}>
                <Box alignItems="center" display="flex">
                  <Text
                    style={{
                      marginRight: '10px',
                      ...(value === undefined && {
                        color: tokens.colors.textInverseMuted
                      })
                    }}
                  >
                    {key}
                  </Text>
                </Box>
              </td>
              <td style={tCellStyles}>
                <Text
                  style={{
                    ...(value === undefined && {
                      color: tokens.colors.textInverseMuted
                    })
                  }}
                >
                  {value}
                </Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};
