import React, { useEffect, useState } from 'react';
import { Map } from './map';
import { JobResult } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { getGeocode } from 'store/sagas/sagas';

interface Props {
  readonly result: JobResult;
  readonly setDistance: (distance: number) => void;
}

type LatLngLiteral = google.maps.LatLngLiteral;

export const Location: React.FC<Props> = ({ result, setDistance }) => {
  const { asyncDispatch } = useGlobalState();
  const [idGeocode, setIdGeocode] = useState<LatLngLiteral | null>(null);

  const { idAddress } = result;
  useEffect(() => {
    if (!idAddress) {
      return;
    }

    const addressStr = Object.values(idAddress).join(' ');
    asyncDispatch(getGeocode(addressStr)).then((response) => {
      if (!response) {
        return;
      }

      setIdGeocode(response.result);
    }, console.error);
  }, []);

  return (
    <Map idGeocode={idGeocode} result={result} setDistance={setDistance} />
  );
};
