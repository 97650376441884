import React from 'react';
import './footer.css';
import { Message } from 'components/message';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';

export const Footer: React.FC = () => {
  const { dispatch, state } = useGlobalState();

  const handleRemoveError = () => {
    // close it without clear since the animation will slide up
    let { messages } = state;
    messages = messages.map((m) => ({ ...m, close: true }));
    dispatch(setMessages(messages));
  };

  let message = null;
  let open = false;
  [message] = state.messages;
  open = !!state.messages.some((m) => m.close !== true);

  return (
    <div className="footer">
      <Message
        handleClose={handleRemoveError}
        open={open}
        severity={message?.severity}
        value={message ? message.value : null}
      />
    </div>
  );
};
