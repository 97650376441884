import React, { ReactElement } from 'react';
import { ReactComponent as CandidatesIcon } from './icons/icon_candidates.svg';
import { ReactComponent as DashboardIcon } from './icons/icon_dashboard.svg';
import { ReactComponent as DocumentationIcon } from './icons/icon_documentation.svg';
import { ReactComponent as GettingStartedIcon } from './icons/icon_getting_started.svg';
import { ReactComponent as HelpIcon } from './icons/icon_help.svg';
import { ReactComponent as JobsIcon } from './icons/icon_jobs.svg';
import { ReactComponent as ProfileIcon } from './icons/icon_profile.svg';
import { ReactComponent as SettingsIcon } from './icons/icon_settings.svg';
import { User } from 'models/user/user.model';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  ADMIN_PERMISSIONS,
  isAuthorized,
  REVIEWER_PERMISSIONS,
  SUPPORT_ADMIN_PERMISSIONS,
  UserPermissions
} from 'security/authorization';

export interface MenuItem {
  name: string;
  label: string;
  url?: string;
  icon?: ReactElement;
  permissions: UserPermissions[];
  description?: string;
  subItems?: MenuItem[];
}

export const filterItems = (user: User, items: MenuItem[]): MenuItem[] => {
  return items.filter((item) => {
    const itemCopy = item;

    if (item.subItems) {
      itemCopy.subItems = item.subItems.filter((subItem) => {
        return isAuthorized(user, subItem.permissions);
      });
    }

    return isAuthorized(user, itemCopy.permissions);
  });
};

export const dashboardItem: MenuItem = {
  name: 'dashboard',
  label: 'Dashboard',
  url: '/account/dashboard',
  icon: <DashboardIcon />,
  description:
    'View all of your recent jobs activity in the Dashboard. It includes statistics on creation, approvals, rejections, and statuses over the past 7 and 30 days.',
  permissions: ACCOUNT_ADMIN_PERMISSIONS
};

export const menuItemsTop: MenuItem[] = [
  {
    name: 'candidates',
    label: 'Candidates',
    url: '/account/candidates',
    icon: <CandidatesIcon />,
    description:
      'Invite customers to verify their ID from the Candidates page. You can also view a list of pending candidates.',
    permissions: REVIEWER_PERMISSIONS
  },
  {
    name: 'jobs',
    label: 'Jobs',
    url: '/account/jobs',
    icon: <JobsIcon />,
    description:
      "View a list of your account's jobs and their statuses and details on the Jobs page.",
    permissions: REVIEWER_PERMISSIONS
  },
  {
    name: 'settings',
    label: 'Settings',
    icon: <SettingsIcon />,
    description:
      'Settings contains the pages Users, Groups, Keys, and Billing. Invite users to your account in Users and create and manage groups in Groups. Generate API keys with the Keys page and view your subscription details in Billing.',
    permissions: ACCOUNT_ADMIN_PERMISSIONS,
    subItems: [
      {
        name: 'company-users',
        label: 'Users',
        url: '/account/users',
        permissions: ACCOUNT_ADMIN_PERMISSIONS
      },
      {
        name: 'company-groups',
        label: 'Groups',
        url: '/account/groups',
        permissions: ACCOUNT_ADMIN_PERMISSIONS
      },
      {
        name: 'company-key',
        label: 'Keys',
        url: '/account/key',
        permissions: ACCOUNT_ADMIN_PERMISSIONS
      },
      {
        name: 'billing-payments',
        label: 'Billing',
        url: '/account/billing-payments',
        permissions: ACCOUNT_ADMIN_PERMISSIONS
      }
    ]
  },
  {
    name: 'admin',
    label: 'Administrator',
    icon: <ProfileIcon />,
    description: 'Manage all jobs, accounts and users as an Administrator.',
    permissions: SUPPORT_ADMIN_PERMISSIONS,
    subItems: [
      {
        name: 'admin-jobs',
        label: 'All Jobs',
        url: '/admin/jobs',
        permissions: SUPPORT_ADMIN_PERMISSIONS
      },
      {
        name: 'account-manager',
        label: 'Account Manager',
        url: '/admin/accounts',
        permissions: SUPPORT_ADMIN_PERMISSIONS
      },
      {
        name: 'user-manager',
        label: 'User Manager',
        url: '/admin/users',
        permissions: SUPPORT_ADMIN_PERMISSIONS
      },
      {
        name: 'template-manager',
        label: 'Template Manager',
        url: '/admin/templates',
        permissions: ADMIN_PERMISSIONS
      },
      {
        name: 'usage-reports',
        label: 'Usage Reports',
        url: '/admin/usage-reports',
        permissions: SUPPORT_ADMIN_PERMISSIONS
      }
    ]
  }
];

export const menuItemsBottom: MenuItem[] = [
  {
    name: 'get-started',
    label: 'Get Started',
    url: '/account/get-started',
    icon: <GettingStartedIcon />,
    permissions: ACCOUNT_ADMIN_PERMISSIONS
  },
  {
    name: 'documentation',
    label: 'Documentation',
    url: 'https://docs.vouched.id/docs',
    icon: <DocumentationIcon />,
    permissions: REVIEWER_PERMISSIONS
  },
  {
    name: 'help',
    label: 'Help',
    url: 'https://www.vouched.id/for-developers/support/',
    icon: <HelpIcon />,
    permissions: REVIEWER_PERMISSIONS
  }
];
