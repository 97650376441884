import React, { FC, useCallback, useEffect, useState } from 'react';
import './get-started.css';
import { NavLink } from 'react-router-dom';
import {
  dashboardItem,
  filterItems,
  menuItemsTop
} from 'components/account/sidebar-v2/menu-item-util';
import { User } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import { useFlags } from 'utils/hooks';

const CloseButton: FC<{ readonly onClick: () => void }> = ({ onClick }) => (
  <svg
    className="tutorial-close"
    height="35px"
    onClick={onClick}
    viewBox="0 0 512 512"
    width="35px"
  >
    <circle cx="256" cy="256" fill="#DDD" r="256" />
    <line
      stroke="black"
      strokeWidth="25px"
      x1="150"
      x2="362"
      y1="150"
      y2="362"
    />
    <line
      stroke="black"
      strokeWidth="25px"
      x1="150"
      x2="362"
      y1="362"
      y2="150"
    />
  </svg>
);

export const GetStarted: FC = () => {
  const { state } = useGlobalState();
  const user = {
    ...state.user
  } as User;

  const [tutorialPage, setTutorialPage] = useState(0);
  const [showTutorial, setShowTutorial] = useState(false);

  const { dashboardFeature } = useFlags();

  const addFeatureItems = () => {
    const items = [...menuItemsTop];
    if (dashboardFeature) {
      items.unshift(dashboardItem);
    }

    return items;
  };

  const topItems = filterItems(user, addFeatureItems());

  const tutorialPages = topItems.map((item) => (
    <div key={item.name}>
      <div className="item-title">
        {item.icon}
        <span>{item.label}</span>
      </div>
      <p className="item-description">{item.description}</p>
    </div>
  ));

  useEffect(() => {
    const vimeoScript = document.getElementById('vimeo');
    if (!vimeoScript) {
      const script = document.createElement('script');
      script.src = 'https://player.vimeo.com/api/player.js';
      script.id = 'vimeo';
      script.async = true;
      document.body.append(script);
    }
  }, []);

  const handleClose = useCallback(() => {
    setShowTutorial(false);
    setTutorialPage(0);
  }, []);

  return (
    <div>
      <h1 className="gs-h1">Get Started</h1>
      <div className="gs-content">
        <h2 className="gs-header gs-h2">Introduction</h2>
        <p className="intro-text">
          Vouched is an identity verification platform powered by artificial
          intelligence. It is completely automated and requires no extra human
          intervention for verifications. The process is simple. Using our tech,
          have your users take a picture of their ID and themselves, send them
          to us, and our backend will verify the authenticity of their identity.
          You can start verifying your users right now in the{' '}
          <NavLink to="/account/candidates">Candidates</NavLink> page!
        </p>
        <div className="video-container">
          {/* eslint-disable-next-line react/iframe-missing-sandbox */}
          <iframe
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            src="https://player.vimeo.com/video/473354297?h=1326e115c7"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />
        </div>
        <h2 className="gs-header gs-h2">Menu Items</h2>
        <p className="intro-text">
          Launch a quick tour of our menu items.
          <button
            className="page-button page-button--tour"
            onClick={() => {
              setShowTutorial(true);
            }}
            type="button"
          >
            Take a Tour
          </button>
        </p>
        {showTutorial && (
          <div className="tutorial-overlay">
            <div className="tutorial-overlay__content">
              <CloseButton onClick={handleClose} />
              {tutorialPages[tutorialPage]}
              <div className="button-container">
                {tutorialPage !== 0 && (
                  <button
                    className="page-button page-button--back"
                    onClick={() => {
                      setTutorialPage((prevState) => prevState - 1);
                    }}
                    type="button"
                  >
                    Back
                  </button>
                )}
                {tutorialPage !== tutorialPages.length - 1 && (
                  <button
                    className="page-button page-button--next"
                    onClick={() => {
                      setTutorialPage((prevState) => prevState + 1);
                    }}
                    type="button"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <h2 className="gs-header gs-h2">Integrations</h2>
        <p className="intro-text">
          Need to integrate Vouched into your custom application? Whether your
          app is desktop, web, or mobile, Vouched offers many solutions for
          seamless integration. With your new account, you are already halfway
          there!{' '}
          <b>
            Generate your Public and Private API keys on the{' '}
            <NavLink to="/account/key">Keys</NavLink> page
          </b>{' '}
          and follow one of our integration guides to start verifying your
          users' identities. You can also read our{' '}
          <a
            href="https://docs.vouched.id/docs"
            rel="noreferrer"
            target="_blank"
          >
            docs
          </a>{' '}
          for further information.
        </p>
        <h3 className="gs-header gs-h3">JS Plugin</h3>
        <p className="intro-text">
          Our Javascript (JS) Plugin is the recommended way to integrate
          Vouched. You will need a Public API Key for this method. Our{' '}
          <a
            href="https://docs.vouched.id/docs/js-plugin-wizard"
            rel="noreferrer"
            target="_blank"
          >
            JS Plugin Wizard
          </a>{' '}
          is an easy way to quickly create your plugin file with your key.
          Follow the steps and we will generate the code for you! Advanced users
          can take a look at more customizable starter code{' '}
          <a
            href="https://docs.vouched.id/docs/js-plugin"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </p>
        <h3 className="gs-header gs-h3">Mobile Webview</h3>
        <p className="intro-text">
          Vouched{' '}
          <a
            href="https://docs.vouched.id/docs/mobile-webview"
            rel="noreferrer"
            target="_blank"
          >
            Webview
          </a>{' '}
          allows you to integrate a view of your Vouched JS Plugin within your
          mobile application. This method supports iOS and Android.
        </p>

        <h3 className="gs-header gs-h3">Invite API</h3>
        <p className="intro-text">
          Send ID verification invites to your users through our{' '}
          <a
            href="https://docs.vouched.id/reference/sendinvites"
            rel="noreferrer"
            target="_blank"
          >
            API
          </a>
          . You will need a Private Key for this method.
        </p>

        <h3 className="gs-header gs-h3">API Responses</h3>
        <p className="intro-text">
          Our{' '}
          <a
            href="https://docs.vouched.id/reference/submitjob-1"
            rel="noreferrer"
            target="_blank"
          >
            API responses
          </a>{' '}
          contain the full result of a single verification process.
        </p>

        <h3 className="gs-header gs-h3">Other Integrations</h3>
        <p className="intro-text">
          For other customized integrations, please contact our support team at
          support@vouched.id and we will be happy to work with you on a
          solution. You can also read our{' '}
          <a
            href="https://docs.vouched.id/docs"
            rel="noreferrer"
            target="_blank"
          >
            docs
          </a>{' '}
          for further information.
        </p>

        <p className="intro-text" style={{ fontStyle: 'italic' }}>
          Vouched is built with privacy in mind so you can protect your users'
          data. Vouched has SOC2 Type 1 certification as well as ISO/IEC-27001
          and ISO/IEC 27701 certifications. Personal information is processed in
          compliance with California Consumer Privacy Act (CCPA) and General
          Data Protection Regulation (GDPR).
        </p>
      </div>
    </div>
  );
};
