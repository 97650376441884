export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export type Filterable = Readonly<
  Record<string, Date | string[] | boolean | number | string | null | undefined>
>;

export const trimIsoDateOnly = (date: Date): string => {
  return date.toISOString().replace(/T.*?Z/, 'Z');
};

const trimIsoMS = (date: Date) => {
  return date.toISOString().replace(/\.\d+Z/, 'Z');
};

export const sanitizeFilters = (
  filters: Filterable
): Record<string, boolean | number | string | null> => {
  const out: Record<string, boolean | number | string | null> = {};

  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      const filter = value as unknown as string[];

      if (filter.length === 1) {
        out[key] = filter[0]!.trim();
      }
    }

    if (typeof value === 'string') {
      out[key] = value.trim();
      continue;
    }

    if (typeof value === 'number' || typeof value === 'boolean') {
      out[key] = value;
    }

    if (value instanceof Date) {
      out[key] = trimIsoMS(value);
    }
  }

  return out;
};
