import { CircularProgress } from '@material-ui/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import React, { useEffect, useState } from 'react';
import {
  getCreatedJobsSinceLogin,
  getJobs,
  getJobsApproved,
  getJobsCountByStatus,
  getJobsVolumeByMonth
} from '../../../../../store/sagas/sagas';
import { JobChartProps, JobChartType } from '../job-charts';
import {
  COLORS_STATUS,
  createDataGetCreatedJobsSinceLogin,
  createDataGetJobs,
  createDataGetJobsApproved,
  createDataGetJobsCountByStatus,
  createDataGetJobsVolumeByMonth
} from '../utils';
import { useGlobalState } from 'store/reducers/reducer';
import './job-chart.css';

Chart.register(...registerables);
export const JobChart: React.FC<JobChartProps> = ({ type }) => {
  const { asyncDispatch } = useGlobalState();
  const [configData, setConfigData] = useState<
    | ChartConfiguration<'doughnut', string[], string>
    | ChartConfiguration<'line', number[], string>
    | null
  >(null);

  const [stringData, setStringData] = useState<number | null>(null);
  const [dataFetchError, setDataFetchError] = useState(false);
  useEffect(() => {
    switch (type) {
      case JobChartType.getJobsLastSixMonths:
        asyncDispatch(getJobsVolumeByMonth())
          .then((data) => {
            setConfigData(createDataGetJobsVolumeByMonth(data));
          })
          .catch(() => {
            setDataFetchError(true);
          });

        break;
      case JobChartType.getJobs:
        asyncDispatch(getJobs())
          .then((data) => {
            setConfigData(createDataGetJobs(data));
          })
          .catch(() => {
            setDataFetchError(true);
          });

        break;
      case JobChartType.getJobsApproved:
        asyncDispatch(getJobsApproved())
          .then((data) => {
            setConfigData(createDataGetJobsApproved(data));
          })
          .catch(() => {
            setDataFetchError(true);
          });

        break;

      case JobChartType.getCreatedJobsSinceLogin:
        asyncDispatch(getCreatedJobsSinceLogin())
          .then((data) => {
            setStringData(createDataGetCreatedJobsSinceLogin(data));
          })
          .catch(() => {
            setDataFetchError(true);
          });

        break;
      default:
        asyncDispatch(getJobsCountByStatus())
          .then((data) => {
            setConfigData(createDataGetJobsCountByStatus(data));
          })
          .catch(() => {
            setDataFetchError(true);
          });

        break;
    }
  }, []);

  useEffect(() => {
    if (!configData) return;
    try {
      const chart = document.getElementById(`myChart${type}`);

      // eslint-disable-next-line no-new
      new Chart(
        chart as HTMLCanvasElement,
        // ASSERTION: No idea why this is needed but it really hates the type union :shrug
        configData as ChartConfiguration<'doughnut', string[], string>
      );
    } catch (error) {
      console.error(error);
    }
  }, [configData]);

  const isChartStateError = () => {
    return stringData === null && !configData && dataFetchError;
  };

  const isChartStateProgress = () => {
    return stringData === null && !configData && !dataFetchError;
  };

  return (
    <div className="job-charts">
      {isChartStateProgress() && <CircularProgress />}
      {isChartStateError() && (
        <div className="job-chart-error">
          <i className="fas fa-exclamation-triangle" style={{ fontSize: 25 }} />
          <h3>
            There was an issue loading the chart. Please try refreshing the
            page.
          </h3>
        </div>
      )}

      {stringData === null && configData?.type === 'doughnut' && (
        <div className="job-chart">
          {configData.data.datasets[0]!.data.length === 2 && (
            <h3 className="job-chart-header">
              Approved/Rejected Jobs Percentage ( Last 7 days )
            </h3>
          )}
          {configData.data.datasets[0]!.data.length === 3 && (
            <h3 className="job-chart-header">Job Status (Last 7 days)</h3>
          )}
          <div className="job-chart-data">
            <div className="job-chart-canvas">
              <canvas id={`myChart${type}`} />
            </div>
            <div className="job-chart-mappings">
              {configData.data.datasets[0]!.data.length === 2 && (
                <>
                  <div className="job-chart-mappings-container">
                    <div
                      className="job-chart-mappings-colors"
                      style={{
                        backgroundColor: COLORS_STATUS.APPROVED
                      }}
                    >
                      {`${Number.parseInt(
                        configData.data.datasets[0]!.data[0]!,
                        10
                      ).toFixed(0)}%`}
                    </div>
                    <div className="job-chart-mappings-text">Approved</div>
                  </div>
                  <div className="job-chart-mappings-container">
                    <div
                      className="job-chart-mappings-colors"
                      style={{
                        backgroundColor: COLORS_STATUS.REJECTED
                      }}
                    >
                      {`${Number.parseInt(
                        configData.data.datasets[0]!.data[1]!,
                        10
                      ).toFixed(0)}%`}
                    </div>
                    <div className="job-chart-mappings-text">Rejected</div>
                  </div>
                </>
              )}

              {configData.data.datasets[0]!.data.length === 3 && (
                <>
                  <div className="job-chart-mappings-container">
                    <div
                      className="job-chart-mappings-colors"
                      style={{
                        backgroundColor: COLORS_STATUS.COMPLETED
                      }}
                    >
                      {`${Number.parseInt(
                        configData.data.datasets[0]!.data[2]!,
                        10
                      ).toFixed(0)}%`}
                    </div>
                    <div className="job-chart-mappings-text">Completed</div>
                  </div>
                  <div className="job-chart-mappings-container">
                    <div
                      className="job-chart-mappings-colors"
                      style={{
                        backgroundColor: COLORS_STATUS.ACTIVE
                      }}
                    >
                      {`${Number.parseInt(
                        configData.data.datasets[0]!.data[0]!,
                        10
                      ).toFixed(0)}%`}
                    </div>
                    <div className="job-chart-mappings-text">Active</div>
                  </div>
                  <div className="job-chart-mappings-container">
                    <div
                      className="job-chart-mappings-colors"
                      style={{
                        backgroundColor: COLORS_STATUS.REMOVED
                      }}
                    >
                      {`${Number.parseInt(
                        configData.data.datasets[0]!.data[1]!,
                        10
                      ).toFixed(0)}%`}
                    </div>
                    <div className="job-chart-mappings-text">Removed</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {stringData === null &&
        configData?.type !== 'doughnut' &&
        configData?.data.datasets.length === 1 && (
          <div className="job-chart-line">
            <h3 className="job-chart-header">Jobs Volume</h3>
            <canvas id={`myChart${type}`} />
          </div>
        )}

      {stringData === null &&
        configData?.type !== 'doughnut' &&
        configData?.data.datasets.length === 3 && (
          <div className="job-chart-line">
            <h3 className="job-chart-header">Jobs Status</h3>
            <canvas id={`myChart${type}`} />
          </div>
        )}

      {stringData !== null && (
        <div className="job-chart-flex-column">
          <h3 className="job-chart-header">Jobs Created in the Last 7 days</h3>
          <div className="job-chart-flex-row">
            <div className="job-chart">
              <span className="chart-data">{stringData.toLocaleString()}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
