import React, { FC, ReactElement } from 'react';

export const Text: FC<{
  readonly value: ReactElement | string[] | number | string | null | undefined;
  readonly strongest?: boolean;
  readonly overflow?: boolean;
  readonly wrap?: boolean;
  readonly htmlColor?: string;
  readonly dataTest?: string;
}> = ({
  value,
  strongest = true,
  overflow = true,
  wrap = false,
  htmlColor,
  dataTest
}) => {
  if (value && Array.isArray(value)) {
    // filter out empty values
    const filter = value.map((v) => v);
    value = filter.join(' ');
  }

  return (
    <div
      className={`${overflow ? 'text-ellipsis' : ''} ${
        wrap ? 'text-wrap' : ''
      } ${strongest ? 'text-strongest' : ''}`}
      data-test={dataTest}
      style={htmlColor ? { color: htmlColor } : {}}
    >
      {value ?? ''}&nbsp;
    </div>
  );
};

export const TextWithTitle: FC<{
  readonly title: string | null;
  readonly value: string | null;
}> = ({ title, value }) => {
  return (
    <div>
      <b>{title}</b> {value}
    </div>
  );
};
