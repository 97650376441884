import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';

export interface Option<T extends number | string> {
  id: T;
  name: string;
}

export type SelectEvent<T extends number | string> = React.ChangeEvent<{
  name: string;
  value: T;
}>;

interface Props<T extends number | string> {
  readonly disabled?: boolean;
  readonly value: T | undefined;
  readonly defaultValue?: number | string;
  readonly onChange: (event: SelectEvent<T>) => void;
  readonly label?: string;
  readonly items: Option<T>[];
  readonly name?: string;
  readonly className?: string;
  readonly required?: boolean;
  readonly isClearable?: boolean;
  readonly style?: React.CSSProperties;
  readonly placeholder?: string;
  readonly testId?: string;
  readonly itemTestId?: string;
  readonly endAdornment?: ReactNode;
}

export const ReusableSelect = <T extends number | string>({
  label,
  disabled,
  value,
  defaultValue,
  onChange,
  items,
  name,
  className = '',
  required = false,
  isClearable = true,
  style,
  placeholder,
  testId,
  itemTestId,
  endAdornment
}: Props<T>): ReactElement => {
  return (
    <FormControl className={className} required={required}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        autoWidth
        data-test={testId}
        defaultValue={defaultValue}
        disabled={disabled}
        endAdornment={endAdornment}
        name={name}
        onChange={
          onChange as (
            event: React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => void
        }
        placeholder={placeholder}
        style={style}
        value={value}
      >
        {isClearable && <MenuItem value="">Clear</MenuItem>}
        {items.map((item) => (
          <MenuItem data-test={itemTestId} key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
