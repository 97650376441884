import React from 'react';

import './index.css';

export type State =
  | 'active'
  | 'approved'
  | 'notApplicable'
  | 'rejected'
  | 'removed'
  | 'warning';

interface Props {
  readonly disabled?: boolean;
  readonly id?: string;
  readonly state: State | null;
  readonly style?: React.CSSProperties;
}

const StateIcon = {
  approved: 'fa-check-circle',
  rejected: 'fa-times-circle',
  active: 'fa-circle',
  warning: 'fa-pause-circle',
  removed: 'fa-minus-circle',
  notApplicable: 'not-applicable-icon'
};

export const Tick: React.FC<Props> = ({ style, disabled, state, id }) => {
  if (state === 'notApplicable') {
    return (
      <div className={`${state} ${disabled ? 'disabled' : ''} tick`} id={id}>
        <img
          className="not-applicable-icon"
          src="/assets/na.svg"
          style={style}
        />
      </div>
    );
  }

  const icon = state ? StateIcon[state] : 'fa-circle';
  return (
    <div
      className={`${state ?? ''} ${disabled ? 'disabled' : ''} tick`}
      id={id}
    >
      <i className={`fas ${icon}`} style={{ ...style }} />
    </div>
  );
};
