import React from 'react';
import { useGlobalState } from 'store/reducers/reducer';

import './sandbox-container.css';

export const SandboxContainer: React.FC<{
  readonly isAdmin: boolean | null;
}> = ({ isAdmin = false, children }) => {
  const { state } = useGlobalState();
  return state.sandboxToggled && !isAdmin ? (
    <>
      <div className="sandbox-banner">
        <div className="sandbox-content">
          <span>SANDBOX DATA</span>
        </div>
      </div>
      <div className="sandbox-container">{children}</div>
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
