import { Box, Text } from '@vouched-id/vault';
import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DLVCaption } from './DLVCaption';
import { DLVSuccessfulResult } from './DLVSuccessfulResult';
import { DLVSubmittedData, getVisualDlvStatus } from './DLVUtils';
import { DlvJobResult } from 'models/jobs/jobs';

interface DLVProps {
  readonly dlvJobResult: DlvJobResult;
  readonly jobStatus: JobStatus;
  readonly dlvSubmittedData: DLVSubmittedData;
}

export const DLVDropdown: FC<DLVProps> = ({
  dlvJobResult,
  jobStatus,
  dlvSubmittedData
}) => {
  if (dlvJobResult.result?.wasCheckPerformed) {
    return (
      <DLVSuccessfulResult
        dlvResult={dlvJobResult.result}
        dlvSubmittedData={dlvSubmittedData}
        jobStatus={jobStatus}
      />
    );
  }

  return (
    <Dropdown title="DLV">
      <Box>
        <DLVCaption />
        <Box display="flex" style={{ gap: '20px' }}>
          <Text>Status:</Text>
          <Text style={{ fontWeight: '600' }}>
            {getVisualDlvStatus(
              dlvJobResult.dlvStatusMessage,
              dlvJobResult.error,
              dlvJobResult.result?.stateSupported
            )}
          </Text>
        </Box>
      </Box>
    </Dropdown>
  );
};
