import { Button, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ColumnProp, List } from 'components/reusable/list';
import { ReusableSelect as Select } from 'components/reusable/select';
import { User } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import { loadUsersBySearch, UsersFilter } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { useDebounce } from 'utils/hooks';
import { humanDate } from 'utils/ui.utils';
import {
  MapData,
  pushFiltersSearchPath,
  QueryData,
  rawParseQuery,
  transformQueryTypes
} from 'utils/url';

const columns: ColumnProp<User>[] = [
  {
    xs: 1,
    name: 'ID',
    id: 'id'
  },
  {
    xs: 2,
    name: 'Account',
    id: 'sid'
  },
  {
    xs: 2,
    name: 'Email',
    id: 'email'
  },
  {
    xs: 1,
    name: 'First Name',
    id: 'firstName'
  },
  {
    xs: 2,
    name: 'Last Name',
    id: 'lastName'
  },

  {
    xs: 1,
    name: 'Email Verified',
    id: 'emailVerified'
  },
  {
    xs: 1,
    name: 'Disabled',
    id: 'isDisabled'
  },
  {
    xs: 2,
    name: 'Last Login',
    customRenderValue: (item) => {
      return item.loginAt ? humanDate(item.loginAt) : 'N/A';
    }
  }
];

interface Props {
  readonly basePath: string;
  readonly isAdmin: boolean;
}

const initialData: UsersFilter = {
  firstName: '',
  lastName: '',
  email: '',
  page: 1,
  isAccountAdmin: '',
  isAdmin: '',
  isSupport: '',
  isReviewer: '',
  isDownloader: '',
  selfServe: '',
  isDisabled: '',
  pageSize: 50
};

const mapData: MapData = {
  firstName: 'string',
  lastName: 'string',
  email: 'string',
  page: 'number',
  isAccountAdmin: 'boolean',
  isAdmin: 'boolean',
  isSupport: 'boolean',
  isReviewer: 'boolean',
  isDownloader: 'boolean',
  selfServe: 'boolean',
  isDisabled: 'boolean',
  pageSize: 'number'
};

const goToEditUser = (userId: number) => {
  history.push(`/admin/users/${userId}`);
};

const filterOptions = {
  isDisabled: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  isAccountAdmin: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  isAdmin: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  isSupport: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  isReviewer: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  isDownloader: [
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' }
  ],
  pageSize: [
    { id: 10, name: '10' },
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 250, name: '250' },
    { id: 500, name: '500' }
  ]
};

export const UserManager: React.FC<Props> = ({ basePath, isAdmin }) => {
  if (!isAdmin) {
    history.push('/');
  }

  const [totalUsers, setTotalUsers] = useState(0);

  const { state, asyncDispatch } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(true);
  const [filter, setFilter] = useState<UsersFilter>({
    ...initialData,
    ...transformQueryTypes(mapData, rawParseQuery())
  });

  const debouncedFilter = useDebounce(filter, 250);

  const updateFilter = (newFilter: Partial<UsersFilter>) => {
    setFilter({ ...initialData, ...newFilter });
  };

  const handleChangeFilters = useCallback(
    (e: ChangeEvent<{ value: number | string; name: string }>) => {
      updateFilter({
        ...filter,
        [e.target.name]: e.target.value
      });
    },
    [filter]
  );

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    updateFilter({
      ...filter,
      page
    });
  };

  useEffect(() => {
    const { isInitial } = pushFiltersSearchPath({
      filter: debouncedFilter as unknown as QueryData,
      initialData: initialData as unknown as QueryData,
      mapData,
      path: basePath
    });

    if (!isOpenFilters && !isInitial) {
      setIsOpenFilters(true);
    }

    setLoading(true);
    asyncDispatch(loadUsersBySearch(debouncedFilter))
      .then((res) => {
        setTotalUsers(res.total);
      }, console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedFilter]);

  return (
    <div className="account-manager-container">
      <h1>User Management</h1>

      <div className="filters__container">
        <div
          className="filters"
          onClick={() => {
            setIsOpenFilters(!isOpenFilters);
          }}
        >
          <FilterListIcon
            style={{
              color: isOpenFilters ? '#4122af' : 'grey'
            }}
          />
          <span>Filter</span>
        </div>

        {isOpenFilters && (
          <div className="jobs__filters-container-container">
            <div className="jobs__filters-container">
              <div className="grid-class-container">
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.pageSize}
                    label="Size"
                    name="pageSize"
                    onChange={handleChangeFilters}
                    value={filter.pageSize}
                  />
                </div>
                <div className="grid__col">
                  <TextField
                    className="g-full-width u-margin-bottom-small"
                    label="Email"
                    name="email"
                    onChange={handleChangeFilters}
                    value={filter.email}
                  />
                </div>
                <div className="grid__col">
                  <TextField
                    className="g-full-width u-margin-bottom-small"
                    label="First Name"
                    name="firstName"
                    onChange={handleChangeFilters}
                    value={filter.firstName}
                  />
                </div>
                <div className="grid__col">
                  <TextField
                    className="g-full-width u-margin-bottom-small"
                    label="Last Name"
                    name="lastName"
                    onChange={handleChangeFilters}
                    value={filter.lastName}
                  />
                </div>
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.isDisabled}
                    label="Disabled"
                    name="isDisabled"
                    onChange={handleChangeFilters}
                    value={filter.isDisabled}
                  />
                </div>
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.isAccountAdmin}
                    label="Is Account Admin"
                    name="isAccountAdmin"
                    onChange={handleChangeFilters}
                    value={filter.isAccountAdmin}
                  />
                </div>
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.isAdmin}
                    label="Is System Admin"
                    name="isAdmin"
                    onChange={handleChangeFilters}
                    value={filter.isAdmin}
                  />
                </div>
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.isSupport}
                    label="Is Support Admin"
                    name="isSupport"
                    onChange={handleChangeFilters}
                    value={filter.isSupport}
                  />
                </div>
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.isReviewer}
                    label="Is Reviewer"
                    name="isReviewer"
                    onChange={handleChangeFilters}
                    value={filter.isReviewer}
                  />
                </div>
                <div className="grid__col">
                  <Select
                    className="g-full-width u-margin-bottom-small"
                    items={filterOptions.isDownloader}
                    label="Is Downloader"
                    name="isDownloader"
                    onChange={handleChangeFilters}
                    value={filter.isDownloader}
                  />
                </div>
              </div>
              <div className="u-margin-bottom-large">
                <Button
                  color="primary"
                  onClick={() => {
                    setFilter({ ...initialData });
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="account-grid">
        <List
          columns={columns}
          handlePageChange={handlePageChange}
          isClickable={() => true}
          items={state.searchedUsers}
          label="Users"
          loading={loading}
          onClick={(item) => {
            goToEditUser(item.id);
          }}
          page={filter.page}
          total={totalUsers}
          totalPages={Math.ceil(totalUsers / filter.pageSize)}
        />
      </div>
    </div>
  );
};
