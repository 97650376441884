import {
  DlvPipelineError,
  DlvStatusMessage,
  VerifyDlvResponse
} from 'models/jobs/jobs';

const dlvPipelineErrorRec: Record<DlvPipelineError, string> = {
  [DlvPipelineError.UnsupportedLicenseType]: 'Unsupported License Type',
  [DlvPipelineError.UnsupportedCountry]: 'Unsupported Country',
  [DlvPipelineError.MissingState]: 'Missing State',
  [DlvPipelineError.MissingLicenseNumber]: 'Missing License Number',
  [DlvPipelineError.IdentificationTypeUnsupportedByState]:
    'Identification Card Validation Unsupported By State'
};

export const getVisualDlvStatus = (
  dlvStatusMessage: DlvStatusMessage,
  dlvError?: DlvPipelineError,
  dlvStateSupported?: boolean
): DlvStatusMessage | string => {
  if (dlvStatusMessage === DlvStatusMessage.ERROR) {
    if (dlvError && dlvPipelineErrorRec[dlvError]) {
      return dlvPipelineErrorRec[dlvError];
    }

    return DlvStatusMessage.ERROR;
  }

  if (dlvStateSupported === false) {
    return 'Unsupported State';
  }

  return dlvStatusMessage;
};

export interface DLVSubmittedData {
  id?: string;
  state?: string;
  lastName?: string;
  birthDate?: string;
  expirationDate?: string;
}

export const getDlvMatch = (
  dlvResult: VerifyDlvResponse,
  field: string
): boolean | undefined => {
  if (dlvResult.fieldMatchesUnavailable.includes(field)) {
    return undefined;
  }

  if (dlvResult.fieldMatchesAvailable.includes(field)) {
    if (dlvResult.fieldMatchesSucceeded.includes(field)) {
      return true;
    }

    if (dlvResult.fieldMatchesFailed.includes(field)) {
      return false;
    }
  }

  return undefined;
};
