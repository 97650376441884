import React, { ReactElement } from 'react';
import { CvsResponseItem, ListRenderItem, SSNJob } from './ssn_model';
import {
  getRecordErrorCode,
  getRecordErrorCodeDesc,
  getSSNResultFailed
} from './utils';
import { Text } from 'components/text';
import { Job } from 'models/jobs/jobs';

const sSNErrorSummary = (item: ReactElement): ListRenderItem => {
  return {
    name: `Summary`,
    customRenderType: (
      <div className="job-modal-ip">
        <Text strongest={false} value="ID" />
        <Text strongest={false} value="Result" />
        <Text strongest={false} value="Error Code" />
        <Text strongest={false} value="Error Message" />
      </div>
    ),
    customRenderValue: item
  };
};

export const sSNRecordErrorSummary = (
  job: Job,
  cvsResponse: CvsResponseItem
): ListRenderItem => {
  return sSNErrorSummary(
    <div className="job-modal-ip">
      <Text value={job.id} />
      <Text value={getSSNResultFailed()} />
      <Text value={getRecordErrorCode(cvsResponse)} />
      <Text value={getRecordErrorCodeDesc(cvsResponse)} />
    </div>
  );
};

export const sSNRequestErrorSummary = (
  job: SSNJob | undefined
): ListRenderItem => {
  return sSNErrorSummary(
    <div className="job-modal-ip">
      <Text value={job?.id ?? 'n/a'} />
      <Text value={getSSNResultFailed()} />
      <Text
        value={
          job?.response_data?.cvsResponseData?.data.errorCode ??
          'Could not find the error code'
        }
      />
      <Text
        value={
          job?.response_data?.cvsResponseData?.data.errorCodeDesc ??
          'Could not find the error description'
        }
      />
    </div>
  );
};
