import { Grid } from '@material-ui/core';
import React from 'react';
import { statusMapper } from '../job-status';
import { JobType } from '../job_type';
import {
  humanJobType,
  toAccount,
  toAccountGroupName,
  toProperty
} from '../utils';
import { ColumnProp } from 'components/reusable/list';
import { Tick } from 'components/reusable/tick';
import {
  CompanyAccount,
  CompanyAccountGroup
} from 'models/company-account/company-account.model';
import { Job } from 'models/jobs/jobs';
import { humanDate, humanPhone } from 'utils/ui.utils';

type JobColumn<T> = ColumnProp<T> & {
  showMobile?: boolean;
};

export const getJobsColumns = (
  isAdmin: boolean | null,
  accounts: CompanyAccount[] | null | undefined,
  accountGroups: CompanyAccountGroup[] | null | undefined
): JobColumn<Job>[] => {
  const jobsColumns: JobColumn<Job>[] = [
    {
      xs: 1,
      name: 'Status',
      customRenderValue: (item) => {
        const { status, request, result } = item;
        if (
          request.type !== JobType.idVerification &&
          request.type !== JobType.idReverification
        )
          return null;
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const success = !!(result?.successWithSuggestion || result?.success);

        const { reviewSuccess } = item;
        const warnings = item.result?.warnings;
        const { state } = statusMapper(status, success, warnings);

        return (
          <Grid container item spacing={0} xs={12}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  marginTop: '2px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {reviewSuccess !== null && (
                  <div>
                    <Tick
                      state={reviewSuccess ? 'approved' : 'rejected'}
                      style={{}}
                    />
                  </div>
                )}
                <div>
                  <Tick state={state} style={{}} />
                </div>
              </div>
            </Grid>
          </Grid>
        );
      },
      showMobile: true
    },
    {
      xs: 2,
      name: 'Id',
      customRenderValue: (item) => {
        const { id, accountGroupSid } = item;
        const internalId = toProperty('Internal Id', item);
        let account: CompanyAccount | null = null;
        if (isAdmin) {
          account = toAccount(item, accounts);
        }

        return (
          <>
            <div className="text-ellipsis">{id}</div>
            <div className="text-ellipsis">{humanJobType(item)}</div>
            <div className="text-ellipsis">{account ? account.name : null}</div>
            <div className="text-ellipsis">
              {toAccountGroupName(accountGroupSid, accountGroups)}
            </div>
            <div className="text-ellipsis">{internalId}</div>
          </>
        );
      },
      showMobile: true
    },
    {
      xs: 2,
      name: 'Name',
      customRenderValue: (item) => {
        let first: string | null | undefined = toProperty('First Name', item);
        let last: string | null | undefined = toProperty('Last Name', item);
        if (!first && !last) {
          first = item.request.parameters?.firstName;
          last = item.request.parameters?.lastName;
        }

        return (
          <>
            <div className="text-ellipsis">{first}</div>
            <div className="text-ellipsis">{last}</div>
          </>
        );
      },
      showMobile: true
    },
    {
      xs: 2,
      name: 'Contact',
      customRenderValue: (item) => {
        let phone: string | null | undefined = toProperty('Phone', item);
        let email: string | null | undefined = toProperty('Email', item);
        if (!phone && !email) {
          phone = item.request.parameters?.phone;
          email = item.request.parameters?.email;
        }

        return (
          <>
            <div className="text-ellipsis">{humanPhone(phone)}</div>
            <div className="text-ellipsis">{email}</div>
          </>
        );
      }
    },
    {
      xs: 2,
      name: 'Document',
      customRenderValue: (item) => {
        const first = item.result?.firstName;
        const last = item.result?.lastName;

        return (
          <>
            <div className="text-ellipsis">{first}</div>
            <div className="text-ellipsis">{last}</div>
          </>
        );
      }
    },
    {
      xs: 1,
      name: 'Reviewed',
      id: 'accountReviewed'
    },
    {
      xs: 2,
      name: 'Dates',
      className: '',
      customRenderValue: (item) => {
        return (
          <>
            <div className="job-date">{humanDate(item.submitted)}</div>
            <div className="job-date">{humanDate(item.updatedAt)}</div>
          </>
        );
      },
      showMobile: true
    }
  ];

  return jobsColumns;
};
