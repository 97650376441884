import React, { FC, useReducer } from 'react';
import { createAsyncMiddleware } from 'services/async-middleware';
import { storeDevtoolsMiddleware } from 'services/store-devtools';
import {
  initialState,
  rootReducer,
  StoreProvider
} from 'store/reducers/reducer';

export const AppContext: FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    storeDevtoolsMiddleware(rootReducer),
    initialState
  );

  const asyncDispatch = createAsyncMiddleware(state, dispatch);
  return (
    <StoreProvider
      value={{
        state,
        dispatch,
        asyncDispatch
      }}
    >
      {children}
    </StoreProvider>
  );
};
