import { Checkbox, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Bundle } from './account-bundles';
import { Text } from 'components/text';
interface AccountBundleItemProps {
  readonly bundle: Bundle;
  readonly jobTypeLabel: string;
  readonly onDelete: (bundle: Bundle) => void;
}

export const AccountBundleItem: React.FC<AccountBundleItemProps> = ({
  onDelete,
  bundle,
  jobTypeLabel = 'N/A'
}: AccountBundleItemProps) => {
  return (
    <div>
      <Grid container>
        <Grid
          className="grid-result-content"
          container
          item
          spacing={3}
          xs={12}
        >
          <Grid
            className="center-content input-form-group text-left"
            item
            xs={1}
          >
            <span className="text-weak u-margin-bottom-small">
              Bundle Job Type
            </span>
            <Text dataTest="account-bundle__job-type" value={jobTypeLabel} />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span className="text-weak" data-test="account-bundle-label__idv">
              Include ID Verification
            </span>
            <Checkbox
              checked={bundle.idvIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__idv"
            />
          </Grid>

          <Grid className="center-content input-form-group" item xs={1}>
            <span
              className="text-weak"
              data-test="account-bundle-label__crosscheck"
            >
              Include Crosscheck
            </span>
            <Checkbox
              checked={bundle.crosscheckIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__crosscheck"
            />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span className="text-weak" data-test="account-bundle-label__dlv">
              Include DLV
            </span>
            <Checkbox
              checked={bundle.dlvIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__dlv"
            />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span
              className="text-weak"
              data-test="account-bundle-label__darkweb"
            >
              Include Dark Web
            </span>
            <Checkbox
              checked={bundle.darkwebIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__darkweb"
            />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span className="text-weak" data-test="account-bundle-label__cadlv">
              Include Canadian Driver License Verification
            </span>
            <Checkbox
              checked={bundle.cdlIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__cadlv"
            />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span
              className="text-weak"
              data-test="account-bundle-label__phaddr"
            >
              Include Physical Address
            </span>
            <Checkbox
              checked={bundle.physicalIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__phaddr"
            />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span className="text-weak" data-test="account-bundle-label__aml">
              Include Aml
            </span>
            <Checkbox
              checked={bundle.amlIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__aml"
            />
          </Grid>
          <Grid className="center-content input-form-group" item xs={1}>
            <span className="text-weak" data-test="account-bundle-label__ip">
              Include IP Address
            </span>
            <Checkbox
              checked={bundle.ipIncluded}
              className="form-checkbox"
              data-test="account-bundle-checkbox__ip"
            />
          </Grid>
        </Grid>
        <div className="close-icon center-content">
          <CloseIcon
            data-test="delete-bundle-button"
            onClick={() => {
              onDelete(bundle);
            }}
          />
        </div>
      </Grid>
      <span className="line">&nbsp;</span>
    </div>
  );
};
