import { MuiThemeProvider } from '@material-ui/core';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { theme } from './theme';
import { Footer } from 'components/footer';
import { setDDUserData } from 'datadog-rum';
import { useGlobalState } from 'store/reducers/reducer';
import './app.css';

const LAUNCHDARKLY_CLIENT_SIDE_ID =
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  process.env['REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID'] || '';

interface Props {
  readonly children?: React.ReactNode;
}

const AppRoot: React.FC = ({ children }: Props) => {
  const { state } = useGlobalState();
  const { user } = state;
  const ldlClient = useLDClient();

  const { id, email, accountId } = user ?? {};
  useEffect(() => {
    if (!id || !accountId) {
      return;
    }
    /*
     * After we have loaded the users info  we want to identify the logged
     * in user to launch darkly so that we can target them by account id and user id ( key )
     */

    ldlClient
      ?.identify({
        kind: 'multi',
        org: {
          key: String(accountId)
        },
        user: {
          key: String(id),
          // compability
          accountId: String(accountId)
        }
      })
      .catch(console.error);

    setDDUserData(id, accountId, email);
  }, [id, accountId, email, ldlClient]);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="app">
        {children}
        <Footer />
      </div>
    </MuiThemeProvider>
  );
};

export const AppRootWithProvider = withLDProvider({
  clientSideID: LAUNCHDARKLY_CLIENT_SIDE_ID
})(AppRoot);
