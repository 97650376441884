import { RotateLeft, RotateRight } from '@material-ui/icons';
import React, { useState } from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import { ImagesViewer } from '../images-viewer';
import { ReactComponent as ZoomIcon } from './icon_zoom.svg';
import { Image, SmartImage } from './smart-image';
import { JobImageType } from 'models/jobs/jobs';
import 'react-alice-carousel/lib/alice-carousel.css';
import './index.css';

interface ItemProps {
  readonly image: Image;
  readonly type?: string | null;
  readonly rotate?: number;
}

const ImageItem: React.FC<ItemProps> = ({ image, type, rotate }) => {
  return (
    <SmartImage
      degree={rotate}
      enableZoom
      image={image}
      maxHeightPerc={0.4}
      noPoints
      type={type}
    />
  );
};

interface SliderProps {
  readonly images: Image[];
  readonly selectedImage: number;
  readonly type?: string | null;
  readonly imageType?: JobImageType | undefined;
  readonly jobResult?: {
    id: string;
    country: string;
    state: string;
    type: string;
    version: string;
  };
}

export const ImageSlider: React.FC<SliderProps> = ({
  images,
  selectedImage,
  type,
  imageType,
  jobResult
}) => {
  const [firstImage] = images;

  const [rotationState, setRotationState] = useState(0);
  const [currentSelectedImage, setCurrentSelectedImage] =
    useState(selectedImage);

  const isSingleImage = images.length === 1;
  const rotateClass = () =>
    `rotate deg${Math.abs(rotationState)} ${rotationState < 0 ? 'neg' : ''}`;

  const handleRotateLeft = () => {
    const rotate = rotationState - 90;
    if (rotate === -360) setRotationState(0);
    else setRotationState(rotate);
  };

  const handleRotateRight = () => {
    const rotate = rotationState + 90;
    if (rotate === 360) setRotationState(0);
    else setRotationState(rotate);
  };

  const onImageChange = (slice: EventObject) => {
    setRotationState(0);
    setCurrentSelectedImage(slice.item);
  };

  const mapImages = images.map((image, index) => (
    <div className={rotateClass()} key={image.url}>
      <ImageItem
        image={image}
        rotate={index === currentSelectedImage ? rotationState : 0}
      />
    </div>
  ));

  const [zoom, setZoom] = useState(false);

  if (!firstImage) {
    return null;
  }

  return (
    <div className="job-modal__main_carousel" id="job-modal__main_carousel">
      <ImagesViewer
        imageType={imageType}
        jobResult={jobResult}
        mainImage={firstImage}
      >
        {isSingleImage ? (
          <div className={rotateClass()}>
            <ImageItem image={firstImage} rotate={rotationState} type={type} />
          </div>
        ) : (
          <AliceCarousel
            activeIndex={currentSelectedImage}
            animationType="fadeout"
            disableButtonsControls
            disableDotsControls={false}
            items={mapImages}
            onSlideChanged={onImageChange}
          />
        )}
        <div className="footer-options">
          <div
            aria-pressed="false"
            className="item"
            onClick={handleRotateLeft}
            role="button"
          >
            <RotateLeft />
          </div>
          <div
            aria-pressed="false"
            className="item"
            onClick={handleRotateRight}
            role="button"
          >
            <RotateRight />
          </div>
          <div
            className="zoom-icon"
            onClick={() => {
              setZoom((prevState) => !prevState);
            }}
          >
            <ZoomIcon />
          </div>
          {zoom && (
            <div
              className="image-overlay"
              onClick={() => {
                setZoom((prevState) => !prevState);
              }}
            >
              <img
                src={
                  isSingleImage
                    ? firstImage.url
                    : images[currentSelectedImage]!.url
                }
              />
            </div>
          )}
        </div>
      </ImagesViewer>
    </div>
  );
};
