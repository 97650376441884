import { User } from '../models/user/user.model';

/*
 * compares the user against a OR list of permssion
 * where a permission is a non-null value
 */
export const isAuthorized = (
  user: User,
  permissions: UserPermissions[]
): boolean => {
  // find a true value
  const found = permissions.find((p) => {
    const compareProps = (Object.keys(p) as (keyof UserPermissions)[]).filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (key) => p[key] !== null && p[key] !== undefined
    );
    // find a failure

    const failed = compareProps.find((prop) => {
      return p[prop] !== user[prop];
    });

    // find a success
    return !failed;
  });

  return !!found;
};

export interface UserPermissions {
  isAdmin?: boolean;
  isAccountAdmin?: boolean;
  emailVerified?: boolean;
  isDownloader?: boolean;
  isSupport?: boolean;
  isReviewer?: boolean;
}

export const ACCOUNT_ADMIN_PERMISSIONS: UserPermissions[] = [
  {
    isAccountAdmin: true,
    emailVerified: true
  }
];

export const ADMIN_PERMISSIONS: UserPermissions[] = [
  {
    isAdmin: true,
    emailVerified: true
  }
];

export const SUPPORT_ADMIN_PERMISSIONS: UserPermissions[] = [
  {
    isAdmin: true,
    emailVerified: true
  },
  {
    isSupport: true,
    emailVerified: true
  }
];

export const DOWNLOADER_PERMISSIONS: UserPermissions[] = [
  {
    isDownloader: true,
    emailVerified: true
  }
];
export const REVIEWER_PERMISSIONS: UserPermissions[] = [
  {
    isReviewer: true,
    emailVerified: true
  }
];
