import React, { FC, ReactElement } from 'react';
import { toNameFull } from '../../utils';
import { List } from 'components/reusable/list';
import { Text } from 'components/text';
import { Job } from 'models/jobs/jobs';
import { humanIdType, humanPropertyName } from 'utils/jobs';
import { humanBoolean, humanDate, humanPhone } from 'utils/ui.utils';

interface RenderItem {
  name: string;
  customRenderValue: ReactElement;
  customRenderType?: ReactElement;
}

export const Request: FC<{ readonly job: Job }> = ({ job }) => {
  const address = job.request.parameters!.address!;
  const ipAddress = job.result!.ipAddress!;

  const items = [
    {
      name: 'Created',
      customRenderValue: <Text value={humanDate(job.submitted)} />
    },
    {
      name: 'Updated',
      customRenderValue: <Text value={humanDate(job.updatedAt)} />
    },

    {
      name: 'Referer',
      customRenderValue: <Text value={job.request.requestInfo?.referer} />
    },
    {
      name: 'User Agent',
      customRenderValue: (
        <Text
          overflow={false}
          value={job.request.requestInfo?.useragent}
          wrap
        />
      )
    },
    job.result?.clientOutput?.theme
      ? {
          name: 'Theme',
          customRenderValue: <Text value={job.result.clientOutput.theme} />
        }
      : undefined,
    job.result?.clientOutput?.client
      ? {
          name: 'Client',
          customRenderValue: <Text value={job.result.clientOutput.client} />
        }
      : undefined,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    job.request.parameters?.firstName || job.request.parameters?.lastName
      ? {
          name: 'Name',
          customRenderValue: (
            <>
              <Text
                value={toNameFull(
                  job.request.parameters.firstName ?? 'n/a',
                  null,
                  job.request.parameters.lastName ?? 'n/a'
                )}
              />{' '}
            </>
          )
        }
      : undefined,
    job.request.parameters?.phone
      ? {
          name: 'Phone',
          customRenderValue: (
            <Text value={humanPhone(job.request.parameters.phone)} />
          )
        }
      : undefined,
    job.request.parameters?.email
      ? {
          name: 'Email',
          customRenderValue: <Text value={job.request.parameters.email} />
        }
      : undefined,
    ...(job.request.properties?.map((prop, idx) => ({
      name: idx === 0 ? 'Properties' : null,
      customRenderType: (
        <Text
          overflow={false}
          strongest={false}
          value={
            prop.name in humanPropertyName
              ? humanPropertyName[prop.name as keyof typeof humanPropertyName]
              : prop.name
          }
          wrap
        />
      ),
      customRenderValue: <Text value={prop.value} />
    })) ?? []),

    job.request.parameters?.address
      ? {
          name: 'Address',
          customRenderValue: (
            <>
              <Text value={address.unit} />{' '}
              <Text value={address.streetAddress} />{' '}
              <Text value={address.city} />
              <Text value={address.state} />
              <Text value={address.postalCode} />
              <Text value={address.country} />
            </>
          )
        }
      : undefined,
    job.result?.ipAddress
      ? {
          name: 'IP',
          customRenderType: (
            <div className="job-modal-ip">
              <Text strongest={false} value="Address" />
              <Text strongest={false} value="Type" />
              <Text strongest={false} value="Anonymous" />
              <Text strongest={false} value="City" />
              <Text strongest={false} value="State" />
              <Text strongest={false} value="Country" />
              <Text strongest={false} value="ISP" />
              <Text strongest={false} value="Organization" />
              <Text strongest={false} value="Confidence" />
              <Text strongest={false} value="Warnings" />
            </div>
          ),
          customRenderValue: (
            <div className="job-modal-ip">
              <Text value={job.request.requestInfo?.ipaddress} />
              <Text value={humanIdType(ipAddress.userType)} />
              <Text value={humanBoolean(ipAddress.isAnonymous)} />
              <Text value={ipAddress.city} />
              <Text value={ipAddress.state} />
              <Text value={ipAddress.country} />
              <Text value={ipAddress.isp} />
              <Text value={ipAddress.organization} />
              <Text value={ipAddress.confidence} />
              <Text value={ipAddress.warnings} />
            </div>
          )
        }
      : {
          name: 'IP',
          customRenderType: <Text strongest={false} value="Address" />,
          customRenderValue: <Text value={job.request.requestInfo?.ipaddress} />
        }
  ].filter(Boolean) as RenderItem[];

  return (
    <List
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'type',
          xs: 3,
          id: 'customRenderType'
        },
        {
          name: 'value',
          xs: 7,
          customRenderValue: (item) => item.customRenderValue
        }
      ]}
      items={items}
      page={1}
      showHeaders={false}
    />
  );
};
