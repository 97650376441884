import {
  CompanyAccount,
  CompanyAccountGroup
} from 'models/company-account/company-account.model';
import { Job } from 'models/jobs/jobs';
import { humanPropertyName } from 'utils/jobs';
import { MapData } from 'utils/url';

const JobTypeMappings = {
  'id-verification': null as string | null,
  'id-crosscheck': 'CrossCheck',
  'id-aml': 'AML',
  'id-aamva': 'AAMVA',
  'id-reverification': 'Reverification',
  'private-ssn': 'SSN',
  'ecbsv-ssn': 'ECBSV SSN',
  dob: 'DOB'
};

export const mapData: MapData = {
  pageSize: 'number',
  page: 'number',
  status: 'string',
  success: 'string',
  accountReviewed: 'string',
  from: 'date',
  to: 'date',
  sortBy: 'string',
  sortOrder: 'string',
  tableView: 'number',
  respIdType: 'string',
  respIdState: 'string',
  ids: 'string',
  respIdCountry: 'string',
  lastName: 'string',
  firstName: 'string',
  is_disabled: 'string',
  includeResults: 'boolean',
  billableType: 'string',
  billable: 'string',
  aamvaSource: 'string',
  type: 'string',
  accountId: 'string',
  aml: 'string',
  email: 'string',
  phone: 'string'
};

export const toProperty = (hp: string, item: Job): string | null => {
  const prop = Object.values(humanPropertyName).find(
    (key) => humanPropertyName[key as keyof typeof humanPropertyName] === hp
  );

  if (!prop || !item.request.properties) {
    return null;
  }

  const found = item.request.properties.find((p) => p.name === prop);
  return found ? found.value : null;
};

export const toAccount = (
  job: Job,
  accounts: CompanyAccount[] | null | undefined
): CompanyAccount | null => {
  if (!accounts) {
    return null;
  }

  const account = accounts.find((a) => a.sid === job.accountId);
  if (!account) {
    return null;
  }

  return account;
};

export const humanJobType = (job: Job): string | null | undefined => {
  if (!(job.request.type in JobTypeMappings)) {
    return undefined;
  }

  return JobTypeMappings[job.request.type as keyof typeof JobTypeMappings];
};

export const toAccountGroupName = (
  sid: string,
  accountGroups: CompanyAccountGroup[] | null | undefined
): string | null => {
  if (!accountGroups || !sid) {
    return null;
  }

  const found = accountGroups.find((a) => a.sid === sid);
  return found ? found.name : null;
};
