import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import React, { ChangeEvent, isValidElement, ReactElement } from 'react';
import { humanBoolean, humanDate } from 'utils/ui.utils';
import { useIsMobile, useWindowDimensions } from 'utils/window';
import './list.css';

export type CustomRenderType<T> = (data: T) => React.ReactNode;

export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface BaseColumnProp {
  name: string;
  className?: string;
  xs?: ColumnSize;
  isClickable?: boolean;
}

interface CustomColumnProp<T> extends BaseColumnProp {
  customRenderValue: CustomRenderType<T>;
}

interface DirectColumnProp<T> extends BaseColumnProp {
  id: keyof T;
}

export type ColumnProp<T> = CustomColumnProp<T> | DirectColumnProp<T>;

const isCustomProp = <T,>(
  props: CustomColumnProp<T> | DirectColumnProp<T>
): props is CustomColumnProp<T> => 'customRenderValue' in props;

export interface Props<T> {
  readonly items: T[] | null;
  readonly columns: ColumnProp<T>[];
  readonly label?: string;
  readonly onClick?: (item: T) => void;
  readonly isClickable?: (item: T) => boolean;
  readonly totalPages?: number;
  readonly total?: number;
  readonly rowColors?: boolean;
  readonly page?: number;
  readonly showHeaders?: boolean;
  readonly loading?: boolean;
  readonly handlePageChange?: (e: ChangeEvent<unknown>, n: number) => void;
  readonly listSource?: string;
  readonly margin?: boolean;
}

const isDateLikeString = (str: string): boolean => {
  return /^\d{4}-\d\d-\d\d-/.test(str);
};

const formatValue = (data: unknown): ReactElement | string | null => {
  if (typeof data === 'string') {
    if (isDateLikeString(data)) {
      return humanDate(data);
    }

    return data;
  }

  if (typeof data === 'boolean') {
    return humanBoolean(data);
  }

  if (typeof data === 'number') {
    return data.toString();
  }

  if (data instanceof Date) {
    return humanDate(data);
  }

  if (isValidElement(data)) {
    return data;
  }

  return null;
};

export const List = <T,>({
  items,
  columns,
  onClick,
  isClickable,
  showHeaders = true,
  rowColors = false,
  total = 0,
  label,
  totalPages = 0,
  loading,
  page,
  handlePageChange,
  listSource = '',
  margin = true
}: Props<T>): ReactElement => {
  const isMobile = useIsMobile();
  const { width } = useWindowDimensions();

  return (
    <>
      {label && !loading && (
        <div className="data-count">
          {total} {label} found
        </div>
      )}
      {loading && (
        <div className="data-count">
          <CircularProgress size="1.2rem" style={{ color: '#290e88' }} />
        </div>
      )}
      {width > 600 ? (
        <div className="list__container-container">
          <div className="list__container">
            {showHeaders && (
              <Grid className="list__container-header" container item xs={12}>
                {columns.map((item) => {
                  if (!item.name) {
                    return null;
                  }

                  return (
                    <Grid
                      className="list__header"
                      item
                      key={item.name}
                      xs={item.xs ?? 1}
                    >
                      <span className={item.className}>
                        {item.name.toUpperCase()}
                      </span>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {items?.map((item, idx) => {
              return (
                <Grid
                  className={`${rowColors ? 'row-colors' : ''} ${
                    idx % 2 === 0 ? 'even' : 'odd'
                  }  list__container-body ${idx === 0 ? 'first' : ''} ${
                    idx === items.length - 1 ? 'end' : ''
                  }
                    ${idx !== 0 && idx !== items.length - 1 ? 'middle' : ''}
                    `}
                  container
                  item
                  // TODO: This is bad, we need an id per row in the future
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  xs={12}
                >
                  {columns.map((col) => {
                    return (
                      <Grid
                        className={`${
                          col.isClickable !== false && isClickable?.(item)
                            ? 'pointer'
                            : ''
                        } list__body`}
                        item
                        key={col.name}
                        onClick={() => {
                          if (col.isClickable === false) {
                            return;
                          }

                          if (isClickable?.(item)) {
                            onClick?.(item);
                          }
                        }}
                        xs={col.xs ?? 1}
                      >
                        <div className="text-ellipsis" data-test="item">
                          {isCustomProp(col)
                            ? col.customRenderValue(item)
                            : formatValue(item[col.id])}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className={
            listSource === 'key'
              ? 'list__container-container-key'
              : 'list__container-container'
          }
        >
          <div className="list__container">
            {showHeaders && (
              <Grid
                className={
                  listSource === 'jobs'
                    ? 'list__container-header list__container-jobs'
                    : 'list__container-header'
                }
                item
                xs={12}
              >
                {columns.map((item) => (
                  <Grid
                    className="list__header"
                    item
                    key={item.name}
                    xs={item.xs ?? 1}
                  >
                    <span className={item.className}>
                      {item.name.toUpperCase()}
                    </span>
                  </Grid>
                ))}
              </Grid>
            )}
            {items?.map((item, idx) => {
              return (
                <Grid
                  className={`${rowColors ? 'row-colors' : ''} ${
                    idx % 2 === 0 ? 'even' : 'odd'
                  }  list__container-body ${idx === 0 ? 'first' : ''} ${
                    idx === items.length - 1 ? 'end' : ''
                  }
                    ${idx !== 0 && idx !== items.length - 1 ? 'middle' : ''}
                    `}
                  item
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  xs={12}
                >
                  {columns.map((col) => {
                    return (
                      <Grid
                        className={`${
                          col.isClickable !== false && isClickable?.(item)
                            ? 'pointer'
                            : ''
                        } list__body ${
                          isMobile ? `list__body__${listSource}` : ''
                        }`}
                        item
                        key={col.name}
                        onClick={() => {
                          if (col.isClickable === false) {
                            return;
                          }

                          if (isClickable?.(item)) {
                            onClick?.(item);
                          }
                        }}
                        xs={6}
                      >
                        <div className="text-ellipsis">
                          {isCustomProp(col)
                            ? col.customRenderValue(item)
                            : formatValue(item[col.id])}
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </div>
        </div>
      )}

      {margin && <div className="u-margin-bottom-large">&nbsp;</div>}
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          onChange={(event, newPage) => {
            if (page === newPage) {
              return;
            }

            window.scrollTo(0, 0);
            handlePageChange?.(event, newPage);
          }}
          page={page}
          shape="rounded"
          variant="outlined"
        />
      )}
      {margin && <div className="u-margin-bottom-large">&nbsp;</div>}
    </>
  );
};
