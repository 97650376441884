import { Box, Heading, Text } from '@vouched-id/vault';
import React, { useMemo } from 'react';
import { Tooltips } from '../../tooltips';
import {
  DEFAULT_CONFIDENCE_THRESHOLD,
  itemHasErrorOrWarning,
  JobStatus
} from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { ExtractedIdData } from './ExtractedIdData';
import { SubmittedIdData } from './SubmittedIdData';
import { JobError, JobRequestParameters, JobResult } from 'models/jobs/jobs';

interface IdDocumentProps {
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobResult: JobResult;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

export const IdDocument: React.FC<IdDocumentProps> = ({
  jobRequestParams,
  jobResult,
  jobErrors,
  jobStatus
}) => {
  const idConfidence = jobResult.confidences?.id;
  const idExpiredConfidence = jobResult.confidences?.idExpired;
  const selfieConfidence = jobResult.confidences?.selfie;
  const faceMatchConfidence = jobResult.confidences?.faceMatch;
  const barcodeMatchConfidence = jobResult.confidences?.barcodeMatch;
  const nameMatchConfidence = jobResult.confidences?.nameMatch;
  const birthDateMatchConfidence = jobResult.confidences?.birthDateMatch;

  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      const dropdownItemsWithoutIcons: (
        | BooleanDropdownItem
        | ConfidenceDropdownItem
      )[] = [
        {
          text: 'Valid ID',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: idConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof idConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'InvalidIdPhotoError'
          ),
          tooltip: Tooltips.ValidId
        },
        {
          text: 'Expiration Date',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: idExpiredConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof idExpiredConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'ExpiredIdError'),
          tooltip: Tooltips.ExpirationDate
        },
        {
          text: 'Valid Selfie',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: selfieConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof selfieConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'InvalidUserPhotoError'
          ),
          tooltip: Tooltips.ValidSelfie
        },
        {
          text: 'ID matches Selfie',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: faceMatchConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof faceMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'FaceMatchError'),
          tooltip: Tooltips.IdMatchesSelfie
        },
        {
          text: 'ID matches Barcode',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: barcodeMatchConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof barcodeMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'BarcodeMatchError'
          ),
          tooltip: Tooltips.IdMatchesBarcode
        },
        {
          text: 'ID matches Submitted Name',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: nameMatchConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof nameMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'NameMatchError'),
          tooltip: Tooltips.IdMatchesSubmittedName
        },
        {
          text: 'ID matches Submitted DOB',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          confidence: birthDateMatchConfidence,
          threshold: DEFAULT_CONFIDENCE_THRESHOLD,
          available: typeof birthDateMatchConfidence === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'BirthDateMatchError'
          ),
          tooltip: Tooltips.IdMatchesSubmittedDOB
        }
      ];

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [
      jobErrors,
      itemHasErrorOrWarning,
      idConfidence,
      idExpiredConfidence,
      selfieConfidence,
      faceMatchConfidence,
      barcodeMatchConfidence,
      nameMatchConfidence,
      birthDateMatchConfidence
    ]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="ID Document"
    >
      <Box>
        <Text>
          This section contains data checks performed against the submitted ID
          Document and Selfie photos.
        </Text>
        <DropdownItemsSection dropdownItems={dropdownItems} table={false} />
        <Box
          display="flex"
          style={{
            gap: '15px 35px',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <Box flex="1">
            <Heading
              colorVariation="secondary"
              style={{ marginBottom: '15px', fontWeight: '600' }}
              variation="h4"
            >
              Extracted
            </Heading>
            <ExtractedIdData
              birthDate={jobResult.birthDate}
              country={jobResult.country}
              expireDate={jobResult.expireDate}
              firstName={jobResult.firstName}
              id={jobResult.id}
              issueDate={jobResult.issueDate}
              lastName={jobResult.lastName}
              middleName={jobResult.middleName}
              state={jobResult.state}
              type={jobResult.type}
            />
          </Box>
          <Box flex="1">
            <Heading
              colorVariation="secondary"
              style={{ marginBottom: '15px', fontWeight: '600' }}
              variation="h4"
            >
              Submitted
            </Heading>
            {jobRequestParams ? (
              <SubmittedIdData
                dob={jobRequestParams.dob}
                email={jobRequestParams.email}
                firstName={jobRequestParams.firstName}
                lastName={jobRequestParams.lastName}
                phone={jobRequestParams.phone}
              />
            ) : (
              <Text>No data submitted</Text>
            )}
          </Box>
        </Box>
      </Box>
    </Dropdown>
  );
};
