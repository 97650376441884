import React, { FC } from 'react';
import { Location } from 'components/account/jobs/location';
import { List } from 'components/reusable/list';
import { TextWithTitle } from 'components/text';
import { JobResult } from 'models/jobs/jobs';

interface LocationProps {
  readonly result: JobResult;
  readonly setDistanceBetween: (distance: number) => void;
  readonly distanceBetween: number;
}

export const LocationColumn: FC<LocationProps> = ({
  result,
  setDistanceBetween,
  distanceBetween
}) => {
  const { ipAddress } = result;
  const ipAddressString = `${ipAddress?.city ?? ''} ${ipAddress?.state ?? ''} ${
    ipAddress?.country ?? ''
  }`.trim();

  const { idAddress } = result;
  const idAddressString = `${idAddress?.streetNumber ?? ''} ${
    idAddress?.street ?? ''
  } ${idAddress?.city ?? ''} ${idAddress?.state ?? ''} ${
    idAddress?.country ?? ''
  }`.trim();

  const items = [
    {
      name: 'Map View',
      customRenderValue: (
        <Location result={result} setDistance={setDistanceBetween} />
      )
    },
    {
      name: 'Location',
      customRenderValue: (
        <div className="job-modal-ip">
          {ipAddressString && (
            <TextWithTitle title="Device Location:" value={ipAddressString} />
          )}
          {idAddressString && (
            <TextWithTitle title="Document Location:" value={idAddressString} />
          )}
          {distanceBetween ? (
            <TextWithTitle
              title="Distance Between Locations in Miles:"
              value={distanceBetween.toFixed(2).toString()}
            />
          ) : null}
        </div>
      )
    }
  ];

  return (
    <List
      columns={[
        {
          name: 'name',
          id: 'name',
          xs: 2
        },
        {
          name: 'value',
          xs: 10,
          customRenderValue: (item) => item.customRenderValue
        }
      ]}
      items={items}
      page={1}
      showHeaders={false}
    />
  );
};
