import { Box } from '@vouched-id/vault';
import React, { useMemo } from 'react';
import { itemHasErrorOrWarning, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { AMLCaption } from './AMLCaption';
import { AMLDetailsTables } from './AMLDetailsTables';
import { JobExtractedData } from './AMLDropdowns';
import {
  getAmlCountries,
  getAmlHitsCount,
  getAmlMatchedTypes,
  getAmlMatchlists,
  getAmlPoliticalPositions
} from 'components/account/jobs/job-modal/job-modal-tabs/utils';
import { JobError } from 'models/jobs/jobs';
import { AMLData } from 'store/sagas/sagas';
import { humanDate } from 'utils/ui.utils';

export interface AMLSanctionDetails {
  dates: {
    start: string;
    end: string;
  };
  name: string;
  type: string;
  url: string;
}

interface AMLCompletedResultProps {
  readonly amlDetailsData: AMLData;
  readonly jobExtractedData: JobExtractedData;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

export const AMLCompletedResult: React.FC<AMLCompletedResultProps> = ({
  amlDetailsData,
  jobExtractedData,
  jobErrors,
  jobStatus
}) => {
  const name = amlDetailsData.submittedTerm ?? amlDetailsData.submitted_term;
  const hits = getAmlHitsCount(amlDetailsData);
  const matchedTypes = getAmlMatchedTypes(amlDetailsData);
  const countries = getAmlCountries(amlDetailsData);
  const yearOfBirth = amlDetailsData.dob ?? 'None';
  const aka = getAmlMatchlists(amlDetailsData);
  const politicalPositions = getAmlPoliticalPositions(amlDetailsData);
  const matches = amlDetailsData.hits?.matches;
  const sanctions = useMemo(() => {
    const sanctionsArray: AMLSanctionDetails[] = [];
    if (matches) {
      for (const details of Object.values(matches)) {
        for (const detail of details) {
          sanctionsArray.push({
            dates: {
              start: detail.listing_started_utc
                ? humanDate(new Date(detail.listing_started_utc), true)!
                : 'N/A',
              end: detail.listing_ended_utc
                ? humanDate(new Date(detail.listing_ended_utc), true)!
                : 'N/A'
            },
            name: detail.name,
            url: detail.url,
            type: detail.type
          });
        }
      }
    }

    return sanctionsArray;
  }, [matches, humanDate]);

  const { dropdownHasWarnings, dropdownHasRejections } = useMemo(() => {
    const dropdownItemsWithoutIcons: (
      | BooleanDropdownItem
      | ConfidenceDropdownItem
    )[] = [
      {
        text: 'AML',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: true,
        threshold: 0.5,
        hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'AMLError')
      }
    ];

    return addIconsToDropdownItems(dropdownItemsWithoutIcons);
  }, [jobErrors, itemHasErrorOrWarning]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="AML"
    >
      <Box>
        <AMLCaption />
        <AMLDetailsTables
          extractedData={jobExtractedData}
          politicalPositions={politicalPositions}
          sanctions={sanctions}
          summary={{ name, hits, matchedTypes, countries, yearOfBirth, aka }}
        />
      </Box>
    </Dropdown>
  );
};
