import React, { FC } from 'react';
import { DataTable } from '../DataTable';
import { readableBoolean, readableNumber, readableString } from '../utils';
import {
  CrossCheckAddressResult,
  CrossCheckEkataIpResult,
  CrossCheckEmailResult,
  CrossCheckPhoneResult
} from 'models/jobs/crosscheck';
import { cleanArray } from 'utils/jobs';

interface CrossCheckDetailsTablesProps {
  readonly address: CrossCheckAddressResult | null;
  readonly phone: CrossCheckPhoneResult | null;
  readonly email: CrossCheckEmailResult | null;
  readonly ip: CrossCheckEkataIpResult | null;
}

export const CrossCheckDetailsTables: FC<CrossCheckDetailsTablesProps> = ({
  address,
  phone,
  email,
  ip
}) => {
  const addressItems = {
    Valid: readableBoolean(address?.isValid),
    Match: readableBoolean(address?.isMatch),
    Name: readableString(address?.name),
    Type: readableString(address?.type),
    Forwarder: readableBoolean(address?.isForwarder),
    Commercial: readableBoolean(address?.isCommercial),
    Errors: cleanArray(address?.errors, 'error').join(', '),
    Warnings: cleanArray(address?.warnings, 'warnings').join(', ')
  };

  const phoneItems = {
    Valid: readableBoolean(phone?.isValid),
    Match: readableBoolean(phone?.isMatch),
    Name: readableString(phone?.name),
    Type: readableString(phone?.type),
    Carrier: readableString(phone?.carrier),
    Prepaid: readableBoolean(phone?.isPrepaid),
    Disposable: readableBoolean(phone?.isDisposable),
    Commercial: readableBoolean(phone?.isCommercial),
    Errors: cleanArray(phone?.errors, 'error').join(', '),
    Warnings: cleanArray(phone?.warnings, 'warnings').join(', ')
  };

  const emailItems = {
    Valid: readableBoolean(email?.isValid),
    Match: readableBoolean(email?.isMatch),
    Name: readableString(email?.name),
    'Days First Seen': readableNumber(email?.daysFirstSeen),
    'Auto Generated': readableBoolean(email?.isAutoGenerated),
    Disposable: readableBoolean(email?.isDisposable),
    Errors: cleanArray(email?.errors, 'error').join(', '),
    Warnings: cleanArray(email?.warnings, 'warnings').join(', ')
  };

  const ipItems = {
    Valid: readableBoolean(ip?.isValid),
    Distance: readableNumber(ip?.distance)
  };

  return (
    <>
      <DataTable caption="Address Details" items={addressItems} />
      <DataTable caption="Phone Details" items={phoneItems} />
      <DataTable caption="Email Details" items={emailItems} />
      <DataTable caption="IP Details" items={ipItems} />
    </>
  );
};
