import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItem } from './menu-item-util';

interface AccordionProps {
  readonly item: MenuItem;
  readonly menuReset?: () => void;
}

const arrow = (active: boolean) => (
  <svg height="24px" id="arrow" viewBox="0 0 24 24" width="24px">
    <g
      transform={
        active ? 'translate(24 0) rotate(90)' : 'translate(0 24) rotate(-90)'
      }
    >
      <g>
        <g transform="translate(8 7)">
          <path
            d="M0,6.586V0H6.586"
            fill="none"
            stroke="#5047FF"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            transform="translate(0.686 5.157) rotate(-45)"
          />
        </g>
      </g>
    </g>
  </svg>
);

const Accordion = ({ item, menuReset }: AccordionProps) => {
  const { label, icon, subItems } = item;
  const [active, setActive] = useState(false);

  return (
    <div className="accordion">
      <div
        className="accordion__title"
        data-cy={label}
        onClick={() => {
          setActive((prevState) => !prevState);
        }}
      >
        {icon}
        <span>{label}</span>
        <span className="drop-arrow">{arrow(active)}</span>
      </div>
      {active && subItems && (
        <div className="accordion__items">
          {subItems.map((subItem) => (
            <SidebarMenuItem
              item={subItem}
              key={subItem.name}
              menuReset={menuReset}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface SidebarMenuItemProps {
  readonly item: MenuItem;
  readonly menuReset?: () => void;
}

export const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  item,
  menuReset
}) => {
  const { label, url, icon } = item;

  return url ? (
    url.startsWith('/', 0) ? (
      <NavLink
        activeClassName="nav-link--active"
        className="nav-link"
        data-cy={label}
        onClick={() => {
          if (menuReset) menuReset();
        }}
        to={url}
      >
        <div className="nav-link__items">
          {icon}
          <span>{label}</span>
        </div>
      </NavLink>
    ) : (
      <a
        className="nav-link"
        data-cy={label}
        href={url}
        onClick={() => {
          if (menuReset) menuReset();
        }}
        rel="noreferrer"
        target="_blank"
      >
        <div className="nav-link__items">
          {icon}
          <span>{label}</span>
        </div>
      </a>
    )
  ) : (
    <Accordion item={item} menuReset={menuReset} />
  );
};
