import React from 'react';
import { CvsRequestItem, ListRenderItem, SignatureType_Map } from './ssn_model';
import { getDOB, getSignatureType } from './utils';
import { Text } from 'components/text';

export const sSNRequestDataSummary = (
  requestData: CvsRequestItem
): ListRenderItem => {
  return {
    name: 'Request',
    customRenderType: (
      <div className="job-modal-ip">
        <Text strongest={false} value="First Name" />
        <Text strongest={false} value="Middle Name" />
        <Text strongest={false} value="Last Name" />
        <Text strongest={false} value="Date of Birth" />
        <Text strongest={false} value="Signature Type" />
      </div>
    ),
    customRenderValue: (
      <div className="job-modal-ip">
        <Text value={requestData.firstName} />
        <Text value={requestData.middleName} />
        <Text value={requestData.lastName} />
        <Text value={getDOB(requestData.dateOfBirth)} />
        <Text
          value={
            SignatureType_Map[
              getSignatureType(requestData.additionalParams?.signatureType)
            ]
          }
        />
      </div>
    )
  };
};
