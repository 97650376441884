import React, { useEffect, useState } from 'react';
import { DobProps } from './dob_model';
import { dobRequestDataSummary } from './dob_request_data_summary';
import { dobResponseDataSummary } from './dob_response_data_summary';
import { FeatureNotEnabled } from 'components/account/jobs/feature_not_enabled';
import { JobType } from 'components/account/jobs/job_type';
import { List } from 'components/reusable/list';
import { useGlobalState } from 'store/reducers/reducer';
import { DobJob, getDobJob } from 'store/sagas/sagas';

export const DobView: React.FC<DobProps> = ({ job }) => {
  const { asyncDispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState<DobJob>({});

  useEffect(() => {
    asyncDispatch(getDobJob(job.id)).then(
      (res) => {
        setData(res);
        setIsLoading(false);
      },
      () => {
        setHasError(true);
        setIsLoading(false);
      }
    );
  }, [job.id]);

  if (job.request.type !== JobType.dob)
    return (
      <FeatureNotEnabled
        copy={['The DOB job type associated with this jobId is invalid']}
      />
    );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (hasError) {
    return <div>Something went wrong!</div>;
  }

  return (
    <div data-test="dob-details">
      <List
        columns={[
          {
            name: 'name',
            id: 'name',
            xs: 2
          },
          {
            name: 'type',
            xs: 2,
            customRenderValue: (item) => item.customRenderType
          },
          {
            name: 'value',
            xs: 8,
            customRenderValue: (item) => item.customRenderValue
          }
        ]}
        items={[
          dobRequestDataSummary(data.requestData),
          dobResponseDataSummary(data.responseData)
        ]}
        page={1}
        showHeaders={false}
      />
    </div>
  );
};
