import React, { useState } from 'react';
import mail from './assets/sittingManPlant.svg';
import { ButtonWithLoader } from 'components/reusable/button-loader';
import { User } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import { resendVerification } from 'store/sagas/sagas';

import './verification-page.css';

export const VerificationPage: React.FC = () => {
  const { state, asyncDispatch } = useGlobalState();

  const user = {
    ...state.user
  } as User;

  const [showVerify, setShowVerify] = useState(true);

  const handleVerifyEmail = () => {
    if (state.user?.email) {
      asyncDispatch(resendVerification(state.user.email))
        .then(() => {
          setShowVerify(false);
        })
        .catch(console.error);
    }
  };

  return (
    <div className="verify-container">
      <h1>Thanks for creating a Vouched account!</h1>
      <img className="verify-graphic" src={mail} />
      <p className="verify-text top-text">
        We have sent you a verification email. Please check your inbox!
      </p>

      <p className="verify-text bottom-text">
        If you cannot see the verification email after checking all folders in
        your inbox, click the "Resend" button to have another email sent.
      </p>
      {!user.emailVerified && showVerify && (
        <ButtonWithLoader
          disabled={false}
          isLoading={false}
          name="Resend"
          onClick={handleVerifyEmail}
        />
      )}
    </div>
  );
};
