import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import chunk from 'lodash.chunk';
import React from 'react';
import { AamvaDataUnavailable } from '../dlv/utils';
import { DetailedAamva } from './detail-aamva';
import { List } from 'components/reusable/list';
import { Tick } from 'components/reusable/tick';
import { Text } from 'components/text';
import { AAmvaResult, Job } from 'models/jobs/jobs';
import { AMLJobResult } from 'store/sagas/sagas';
import { humanDate, roundNumber } from 'utils/ui.utils';

const getAamvaSource = (country: string | undefined): string => {
  return country === 'CA' ? 'CA DMV' : 'AAMVA';
};

const getAamvaVerificationStatus = (aamva: AAmvaResult) => {
  if (
    aamva.status === null ||
    aamva.status === 'Pending' ||
    aamva.status === 'In Progress'
  ) {
    return null;
  }

  if (aamva.status === 'Not Applicable') {
    return 'notApplicable';
  }

  if (aamva.status === 'Completed' && !aamva.hasErrors) {
    return 'approved';
  }

  return 'rejected';
};

interface AamvaProps {
  readonly job: Job;
  readonly detailedAamva: AMLJobResult | null;
}

export const Aamva: React.FC<AamvaProps> = ({ job, detailedAamva }) => {
  const { result } = job;
  if (!result?.aamva?.completedAt) {
    return <AamvaDataUnavailable />;
  }

  const { aamva, country } = result;

  return (
    <div>
      <List
        columns={[
          {
            name: 'name',
            id: 'name',
            xs: 2
          },
          {
            name: 'value',
            xs: 7,
            customRenderValue: (item) => item.customRenderValue()
          },
          {
            name: 'pass',
            xs: 3,
            customRenderValue: (item) =>
              item.checkRender ? item.checkRender() : null
          }
        ]}
        items={[
          {
            name: 'Source',
            customRenderValue: () => <Text value={getAamvaSource(country)} />
          },
          {
            name: 'Job Status',
            checkRender: () => {
              const status = getAamvaVerificationStatus(aamva);
              return <Tick state={status} />;
            },
            customRenderValue: () => <Text value={aamva.status} />
          },
          {
            name: 'Status Message',
            customRenderValue: () => <Text value={aamva.statusMessage} />
          },
          {
            name: 'Confidence Score',
            customRenderValue: () => (
              <Text value={roundNumber(aamva.confidenceScore)} />
            )
          },
          {
            name: 'Enabled',
            customRenderValue: () => {
              const aamvaEnabled = aamva.enabled;
              return aamvaEnabled !== null && aamvaEnabled !== undefined ? (
                <Text value={aamvaEnabled.toString()} />
              ) : (
                <Text value="false" />
              );
            }
          },
          {
            name: 'Warnings',
            detailedAamva,
            customRenderValue: () => {
              const warningStr = detailedAamva?.warnings;

              if (warningStr === undefined || warningStr === null) {
                return null;
              }

              const warnings = JSON.parse(warningStr) as
                | { warnings: string[] }
                | undefined;

              const chunkedWarnings = warnings
                ? chunk(warnings.warnings, 3)
                : [];

              return (
                <Table>
                  <TableBody>
                    {chunkedWarnings.map((warningChunk) => {
                      return (
                        <TableRow key={warningChunk.join('')}>
                          {warningChunk.map((warning: string) => {
                            return (
                              <TableCell key={warning}>
                                <Text value={warning} />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              );
            }
          },
          {
            name: 'Errors',
            detailedAamva,
            customRenderValue: () => {
              const errorStr = detailedAamva?.errors;

              if (errorStr === undefined || errorStr === null) {
                return null;
              }

              const errors = JSON.parse(errorStr) as
                | { errors: string[] }
                | undefined;

              const chunkedErrors = errors ? chunk(errors.errors, 3) : [];

              return (
                <Table>
                  <TableBody>
                    {chunkedErrors.map((warningChunk) => {
                      return (
                        <TableRow key={warningChunk.join('')}>
                          {warningChunk.map((error: string) => {
                            return (
                              <TableCell key={error}>
                                <Text value={error} />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              );
            }
          },
          {
            name: 'Created At',
            customRenderValue: () => <Text value={humanDate(aamva.createdAt)} />
          },
          {
            name: 'Updated At',
            customRenderValue: () => <Text value={humanDate(aamva.updatedAt)} />
          }
        ]}
        page={1}
        showHeaders={false}
      />
      {detailedAamva && (
        <DetailedAamva country={country} detailData={detailedAamva} />
      )}
    </div>
  );
};
