// Resources related to GCP identity-aware proxy

import Button from '@material-ui/core/Button';
import React, { FC, useCallback } from 'react';

let iapAuthRequired = false;

export const AuthWithGoogleButton: FC = () => {
  const handleClick = useCallback(() => {
    open(getIapAuthUrl());
  }, []);

  return <Button onClick={handleClick}>Auth with Google</Button>;
};

export function resetIapAuthRequired(): void {
  iapAuthRequired = false;
}

export function isIapAuthRequired(): boolean {
  return iapAuthRequired;
}

export function setIapAuthRequired(): void {
  iapAuthRequired = true;
}

export function isIapProtectedUrl(url: string | undefined): boolean {
  return url ? url.includes('/admin/') : false;
}

function getIapAuthUrl() {
  return `${process.env['REACT_APP_API']!}/admin?gcp-iap-mode=DO_SESSION_REFRESH`;
}
