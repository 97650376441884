import { Button, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Datepicker } from 'components/reusable/datepicker';
import { ReusableSelect as Select } from 'components/reusable/select';
import './JobsFilter.css';
import { SelectEvent } from 'components/reusable/select/select';
import { useGlobalState } from 'store/reducers/reducer';
import { JobsOptions } from 'utils/jobs';
import { isValidDate } from 'utils/validation';

export interface JobsFilterData {
  pageSize: number;
  page: number;
  status: string;
  success: string;
  accountGroupSid: string;
  accountReviewed: string;
  from: Date | null;
  to: Date | null;
  sortBy: string;
  sortOrder: string;
  tableView: number;
  respIdType: string;
  respIdState: string;
  ids: string;
  respIdCountry: string;
  lastName: string;
  firstName: string;
  is_disabled: string;
  includeResults: boolean;
  billableType: string;
  billable: string;
  aamvaSource: string;
  type: string;
  accountId: string;
  aml: string;
  email: string;
  phone: string;
}

interface JobsFilterProps {
  readonly isAdmin: boolean | null;
  readonly filterFields: JobsFilterData;
  readonly jobsOptions: JobsOptions;
  readonly onFilter: (
    fieldName: string,
    value: Date | number | string | null
  ) => void;
  readonly onReset: () => void;
}

export const JobsFilter: React.FC<JobsFilterProps> = ({
  isAdmin,
  filterFields: filter,
  jobsOptions,
  onFilter,
  onReset
}) => {
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const { state } = useGlobalState();

  const handleChangeFilters = useCallback(
    (e: SelectEvent<number | string>) => {
      onFilter(e.target.name, e.target.value);
    },
    [onFilter]
  );

  const handleChangeDateFilter = (date: Date | null, fieldName: string) => {
    onFilter(fieldName, date && isValidDate(date) ? date : null);
  };

  return (
    <div className="filters__container">
      <div
        className="filters"
        onClick={() => {
          setIsOpenFilters(!isOpenFilters);
        }}
      >
        <FilterListIcon
          style={{
            color: isOpenFilters ? '#4122af' : 'grey'
          }}
        />
        <span>Filter</span>
      </div>
      {isOpenFilters && (
        <div className="jobs-filters-container u-padding-top-medium u-margin-bottom-large">
          <div className="filters-row row-fixed">
            <Select
              isClearable={false}
              items={jobsOptions.tableView}
              label="View"
              name="tableView"
              onChange={handleChangeFilters}
              value={filter.tableView}
            />
          </div>
          <div className="filters-row">
            <Select
              items={jobsOptions.pageSize}
              label="Size"
              name="pageSize"
              onChange={handleChangeFilters}
              value={filter.pageSize}
            />
            <Select
              items={jobsOptions.status}
              label="Status"
              name="status"
              onChange={handleChangeFilters}
              value={filter.status}
            />
            <Select
              items={jobsOptions.success}
              label="Success"
              name="success"
              onChange={handleChangeFilters}
              value={filter.success}
            />
            <Select
              items={jobsOptions.accountReviewed}
              label="Reviewed"
              name="accountReviewed"
              onChange={handleChangeFilters}
              value={filter.accountReviewed}
            />
            <Select
              items={jobsOptions.sortBy}
              label="Sort By"
              name="sortBy"
              onChange={handleChangeFilters}
              value={filter.sortBy}
            />
          </div>
          <div className="filters-row">
            <Select
              items={jobsOptions.sortOrder}
              label="Order By"
              name="sortOrder"
              onChange={handleChangeFilters}
              value={filter.sortOrder}
            />
            <Select
              items={jobsOptions.billableType}
              label="Billable Type"
              name="billableType"
              onChange={handleChangeFilters}
              value={filter.billableType}
            />
            <Select
              items={jobsOptions.aamvaSource}
              label="Aamva Source"
              name="aamvaSource"
              onChange={handleChangeFilters}
              value={filter.aamvaSource}
            />
            <Select
              items={jobsOptions.type}
              label="Job Type"
              name="type"
              onChange={handleChangeFilters}
              value={filter.type}
            />
          </div>
          <div className="filters-row row-fixed">
            <Datepicker
              date={filter.from}
              label="From"
              maxDate={filter.to ?? undefined}
              onChange={(date) => {
                handleChangeDateFilter(date, 'from');
              }}
            />
            <Datepicker
              date={filter.to}
              label="To"
              minDate={filter.from ?? undefined}
              onChange={(date) => {
                handleChangeDateFilter(date, 'to');
              }}
            />
            <Select
              items={jobsOptions.amLJobs}
              label="AML"
              name="aml"
              onChange={handleChangeFilters}
              value={filter.aml}
            />
          </div>
          <div className="filters-row">
            <TextField
              label="Type"
              name="respIdType"
              onChange={handleChangeFilters}
              value={filter.respIdType}
            />
            <TextField
              label="State"
              name="respIdState"
              onChange={handleChangeFilters}
              value={filter.respIdState}
            />
            <TextField
              label="Country"
              name="respIdCountry"
              onChange={handleChangeFilters}
              value={filter.respIdCountry}
            />
            <TextField
              label="Last Name"
              name="lastName"
              onChange={handleChangeFilters}
              value={filter.lastName}
            />
            <TextField
              label="First Name"
              name="firstName"
              onChange={handleChangeFilters}
              value={filter.firstName}
            />
          </div>
          <div className={`filters-row ${isAdmin ? '' : 'row-fixed'}`}>
            <TextField
              label="Ids"
              name="ids"
              onChange={handleChangeFilters}
              value={filter.ids}
            />
            {isAdmin && (
              <Autocomplete
                debug
                defaultValue={state.account?.name}
                onChange={(_e, value) => {
                  if (state.accounts) {
                    const sid = value
                      ? state.accounts.find((a) => a.name === value)!.sid
                      : null;

                    handleChangeFilters({
                      target: {
                        value: sid as number | string,
                        name: 'accountId' as string
                      }
                    } as Extract<ChangeEvent, 'target'>);
                  }
                }}
                options={(state.accounts ?? []).map((a) => a.name)}
                renderInput={(params) => (
                  <TextField
                    className="g-full-width"
                    label="Account"
                    {...params}
                  />
                )}
              />
            )}
            {isAdmin && (
              <Select
                items={jobsOptions.is_disabled}
                label="Account Status"
                name="is_disabled"
                onChange={handleChangeFilters}
                value={filter.is_disabled}
              />
            )}
            {isAdmin && (
              <Select
                items={jobsOptions.billable}
                label="Billable"
                name="billable"
                onChange={handleChangeFilters}
                value={filter.billable || ''}
              />
            )}

            <Select
              items={(state.accountGroups ?? []).map((a) => ({
                id: a.sid,
                name: a.name
              }))}
              label="Subgroup"
              name="accountGroupSid"
              onChange={handleChangeFilters}
              value={filter.accountGroupSid || ''}
            />
            <TextField
              label="Contact Email"
              name="email"
              onChange={handleChangeFilters}
              type="email"
              value={filter.email}
            />
            <TextField
              label="Contact Phone"
              name="phone"
              onChange={handleChangeFilters}
              value={filter.phone}
            />
          </div>
          <div className="filters-row row-fixed row-command">
            <Button
              color="primary"
              onClick={() => {
                onReset();
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
