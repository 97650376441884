import './password-input.css';

import { Input } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import React, { ChangeEvent, useState } from 'react';

interface PasswordInputProps {
  readonly handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly value: string | null;
  readonly invalidData: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  invalidData,
  value,
  handleChangePassword
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputLabel
        className={classNames({
          'input_error-label': invalidData || value === null
        })}
        htmlFor="standard-adornment-password"
        shrink
      />
      <Input
        className="u-margin-bottom-medium"
        endAdornment={
          <InputAdornment className="icon-btn_password" position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword((prevShowPassword) => !prevShowPassword);
              }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        error={invalidData || value === null}
        fullWidth
        id="standard-adornment-password"
        onChange={handleChangePassword}
        placeholder="Password"
        type={showPassword ? 'text' : 'password'}
        value={value}
      />
      <span className="input_error-msg">
        {value === null ? 'Invalid password' : ''}
      </span>
    </>
  );
};
